<script>
import Layout from '../../layouts/main';
import PageHeader from '../../components/page-header';

import SideSlidePanel from "../../components/widgets/SideSlidePanel";
import SearchBar from "../../components/widgets/searchBar";
import EditModal from "../../components/widgets/editModal";
import CompanyProductSearch from '../../components/widgets/CompanyProductSearch';
import CompanyProductList from '../../components/widgets/CompanyProductList';
/**
 * Shops component
 */
export default {
  components: { Layout, PageHeader, SideSlidePanel, SearchBar, EditModal, CompanyProductSearch, CompanyProductList },
  data() {
    return {
      deleteMode: 'single',
      csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      shopsData: '',
      viewSupplier: false,
      productsData: [],
      showListingProducts: 0,
      addProductToListing: 0,
      addSupplierAddressPanel: false,
      addSupplierContactPanel: false,
      addProductToggle: false,
      deleteAddressPanel: false,
      deleteContactPanel: false,
      deleteSupplierPanel: false,
      deleteContactIndex: 0,
      deleteAddressIndex: 0,
      selectedListing:[],
      deletesupplieraddress: '',
      deletesuppliercontact: '',
      searchproduct: '',
      shops_error: [],
      company_info: [],
      countries: [],
      counties: [],
      categoryProducts: [],
      supplierAddresses: [],
      supplierContacts: [],
      productsLimit: '100',
      productsCurrentPage: 1,
      productListingSelected: [],
      error_reponse: [],
      error_message: false,
      success_message: false,
      success_text: '',
      loadingForm: false, 
      loadingList: false,
      newquantity: 0,
      listingquantity: 0,
      transferquantity: 0,
      currentPage: 1,
      orderDesc: false,
      priceRange: [0,999999],
      trade_productsactive: '',
      trade_active: '',
      public_active: '',
      public_productsactive: '',
      totalListingsQuantity: 0,
      editModal: 0,
      productsactive: '',
      brands: '',
      collections: '',
      active: '',
      view: 'list',
      search: '',
      limit: '25',
      sortby: 'id',
      order: 'desc',
      isCheckAll: false,
      addNewPanel: false,
      editPanel: false,
      addressesPanel: false,
      contactsPanel: false,
      selectedids: [],
      isCustom: 0,
      newsupplier: {
        id: null,
        name: '',
        description: '',
        image: '',
        active: 0,
      },
      editsupplier: {
        id: null,
        name: '',
        description: '',
        image: '',
        active: 0,
      },
      newsupplieraddress:{
        id: null,
        alias: '',
        address1: '',
        address2: '',
        address3: '',
        county: '',
        country: '',
        city: '',
        postcode: '',
        default: 0,
        invoice: 0,
        delivery: 0,
        type: '',
      },
      editsupplieraddress: {
        id: null,
        alias: '',
        address1: '',
        address2: '',
        address3: '',
        county: '',
        country: '',
        city: '',
        postcode: '',
        default: 0,
        invoice: 0,
        delivery: 0,
        type: '',
      },
      newsuppliercontact:{
        id: null,
        name: '',
        position: '',
        phone: '',
        email: '',
        mobile: '',
        fax: '',
        default: 0,
      },
      editsuppliercontact: {
        id: null,
        name: '',
        position: '',
        phone: '',
        email: '',
        mobile: '',
        fax: '',
        default: 0,
      },
      dropzoneOptions: {
            url: 'https://httpbin.org/post',
            thumbnailHeight: 300,
            thumbnailWidth: 300,
          },
          limits: [
            {value: 25, text: "25"},
            {value: 50, text: "50"},
            {value: 100, text: "100"},
          ],
          transferOptions: [
            {value: 0, text: "Company Stock"},
            {value: 1, text: "Another Shop or Listing"},
          ],
          transferOption: 0,
      title: 'Suppliers',
      items: [
        {
          text: 'Stock',
          href: '/',
        },
        {
          text: 'Suppliers',
          active: true,
        },
      ],
    }
  },
  methods: {
    getCountries(){
        if(this.countries.length == 0){
          axios.get('/api/countries', {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
            this.countries = response.data;
          }).catch(error => {
            this.load_error = error.data;
          })
        }
      },
      getCounties(country_id){
        // get counties as country selected
        axios.get('/api/country/'+country_id+'/counties', {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
          this.counties = response.data;
        }).catch(error => {
          this.load_error = error.data;
        })
      },
    getProducts(id){
      this.categoryProducts = [];
      this.loadingForm = true;
      axios.get('/api/company/product/supplier/'+id+'/products', {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
        this.categoryProducts = response.data;
         let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 500);
      }).catch(error => {
            this.error_reponse = {error: ["Could not load supplier products. Please try again."]};
            this.error_message = true;
      });
    },
    getSellerProducts(search_query){
      if(this.orderDesc == true){
        this.order = 'desc';
      }else{
        this.order = 'asc';
      }
      axios.get('/api/company/suppliers/'+this.selectedListing.id+'/product?page='+this.productsCurrentPage+'&limit='+this.productsLimit+'&sortby='+this.sortby+'&order='+this.order+'&search='+search_query+'&trade_active='+this.trade_productsactive+'&public_active='+this.public_productsactive+'&active='+this.productsactive+'&brands='+this.brands+'&collections='+this.collections+'&pricerange='+this.priceRange, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
        console.log(response);
        this.productsData = response.data.data;
      });
    },
    closeEditModal(){
      this.$refs['edit-modal'].hide()
    },
    getSupplierAddresses(){
      axios.get('/api/company/suppliers/'+this.selectedListing.id+'/addresses', {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
        this.supplierAddresses = response.data;
      }).catch(error => {
        this.error_reponse =  {error: ["Could not load supplier addresses. Please try again."]};
      })
    },
    getSupplierContacts(){
      axios.get('/api/company/suppliers/'+this.selectedListing.id+'/contacts', {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
        this.supplierContacts = response.data;
      }).catch(error => {
        this.error_reponse =  {error: ["Could not load supplier contacts. Please try again."]};
      })
    },
    addSupplier(){
      this.loadingForm = true;
      var postForm = new FormData(document.getElementById("addsupplierform"));
      axios.post('/api/company/product/supplier/create', postForm, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
        console.log(response);
        this.success_text = 'Congratulations, your new category has been added.';
        this.success_message = true;
        //this.resetProjectData();
        //this.addPanel = false;
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
            self.addNewPanel = false;
            self.productQuery();
          }, 1500);
      }).catch( error => {
        
        this.error_reponse = error.response.data.errors;
        this.error_message = true;
        //this.resetProjectData();
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 1500);
      });
    },
    updateSupplier(){
      this.success_message = false;
      this.error_message = false;
      this.loadingForm = true;
      var postForm = new FormData(document.getElementById("updatesupplierform"));
      axios.post('/api/company/product/supplier/'+this.editsupplier.id+'/update', postForm, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
        console.log(response);
        this.success_text = 'Congratulations, your category has been updated.';
        this.success_message = true;
        //this.resetProjectData();
        //this.addPanel = false;
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
            self.editPanel = false;
            self.productQuery();
          }, 500);
      }).catch( error => {
        
        this.error_reponse = error.response.data.errors;
        this.error_message = true;
        //this.resetProjectData();
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 500);
      });

    },
    destroySupplier(id){
        axios.post('/api/company/product/supplier/'+id+'/destroy', {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
            this.success_text = 'Congratulations, the supplier has been deleted.';
            this.success_message = true;
        }).catch(error => {
            this.error_reponse = {error: ["Error - could not delete. Please try again."]};
            this.error_message = true;
        });
    },
    addSupplierAddress(){
        this.loadingForm = true;
      var postForm = new FormData(document.getElementById("addsupplieraddressform"));
      axios.post('/api/company/product/supplier/'+this.selectedListing.id+'/address/create', postForm, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
        console.log(response);
        this.success_text = 'Congratulations, the supplier address has been added.';
        this.success_message = true;
        this.supplierAddresses.push(response.data);
        //this.resetProjectData();
        //this.addPanel = false;
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
            self.addNewPanel = false;
            self.productQuery();
          }, 1500);
      }).catch( error => {
        
        this.error_reponse = error.response.data.errors;
        this.error_message = true;
        //this.resetProjectData();
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 1500);
      });
    },
    updateSupplierAddress(){
         this.loadingForm = true;
      var postForm = new FormData(document.getElementById("updatesupplieraddressform"));
      axios.post('/api/company/product/supplier/address/'+this.editsupplieraddress.id+'/update', postForm, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
        console.log(response);
        this.success_text = 'Congratulations, the supplier address has been updated.';
        this.success_message = true;
        
        //this.addPanel = false;
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
            self.addNewPanel = false;
            self.productQuery();
            self.resetAddressData();
          }, 1500);
      }).catch( error => {
        
        this.error_reponse = error.response.data.errors;
        this.error_message = true;
        //this.resetProjectData();
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 1500);
      });
    },
    resetAddressData(){
        this.editsupplieraddress = {
        id: null,
        alias: '',
        address1: '',
        address2: '',
        address3: '',
        county: '',
        country: '',
        city: '',
        postcode: '',
        default: 0,
        invoice: 0,
        delivery: 0,
        type: '',
      };
    },
    destroySupplierAddress(id, index){
        axios.post('/api/company/product/supplier/address/'+id+'/destroy', {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
            this.success_text = 'Congratulations, the supplier address has been deleted.';
            this.success_message = true;
        }).catch(error => {
            this.error_reponse = {error: ["Error - could not delete. Please try again."]};
            this.error_message = true;
        });
    },
    addSupplierContact(){
        this.loadingForm = true;
      var postForm = new FormData(document.getElementById("addsuppliercontactform"));
      axios.post('/api/company/product/supplier/'+this.selectedListing.id+'/contact/create', postForm, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
        console.log(response);
        this.success_text = 'Congratulations, the supplier contact has been added.';
        this.success_message = true;
        //this.resetProjectData();
        //this.addPanel = false;
        this.supplierContacts.push(response.data);
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
            self.addNewPanel = false;
            self.productQuery();
          }, 1500);
      }).catch( error => {
        
        this.error_reponse = error.response.data.errors;
        this.error_message = true;
        //this.resetProjectData();
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 1500);
      });
    },
    updateSupplierContact(){
           this.loadingForm = true;
      var postForm = new FormData(document.getElementById("updatesupplieraddressform"));
      axios.post('/api/company/product/supplier/contact/'+this.editsuppliercontact.id+'/update', postForm, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
        console.log(response);
        this.success_text = 'Congratulations, the supplier contact has been updated.';
        this.success_message = true;
        
        //this.addPanel = false;
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
            self.addNewPanel = false;
            self.productQuery();
            self.resetAddressData();
          }, 1500);
      }).catch( error => {
        
        this.error_reponse = error.response.data.errors;
        this.error_message = true;
        //this.resetProjectData();
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 1500);
      });
    },
    setActive(active){
            axios.post('/api/company/product/supplier/set-active', {ids: this.selectedids, active: active}, {headers: {
              'Authorization' : 'Bearer '+this.$attrs.user.api_token
            }}).then(response => {
              
              this.success_text = 'Congratulations, your action has been saved';
              this.success_message = true;
              if(this.selectedids.length > 1){
                return this.productQuery();
              }else{
                return true;
              }
                
            }).catch(error => {

            });
    },
    destroyResource(product_id){
            axios.post('/api/company/product/supplier/'+this.selected_product_id+'/destroy', {}, {headers: {
              'Authorization' : 'Bearer '+this.$attrs.user.api_token
            }}).then(response => {
                this.$bvModal.hide('delete-modal');
                return this.productQuery();
            }).catch(error => {

            });
    },
     bulkDestroyResource(){
            axios.post('/api/company/product/supplier/bulk-destroy', {ids: this.selectedids}, {headers: {
              'Authorization' : 'Bearer '+this.$attrs.user.api_token
            }}).then(response => {
                this.$bvModal.hide('delete-modal');
                this.selectedids = [];
                return this.productQuery();
            }).catch(error => {

            });
     },
     bulkDuplicateResource(){
      axios.post('/api/company/product/supplier/duplicate', {ids: this.selectedids}, {headers : {
        'Authorization' : 'Bearer '+this.$attrs.user.api_token
      }}).then(response => {
        console.log(response);
        this.success_text = 'Congratulations, your collection(s) have been updated.';
        this.success_message = true;
        //this.resetProjectData();
        this.addPanel = false;
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
            self.productQuery();
            //self.$bvModal.hide('move-modal');
          }, 1500);
      }).catch( error => {
        
        this.error_reponse = error.response.data.errors;
        this.error_message = true;
        //this.resetProjectData();
        let self = this;
          setTimeout(function(){
            self.loadingForm = false;
          }, 1500);
      });
    },
    destroySupplierContact(id, index){
         axios.post('/api/company/product/supplier/contact/'+id+'/destroy', {}, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
            this.success_text = 'Congratulations, the supplier contact has been deleted.';
            this.success_message = true;
        }).catch(error => {
            this.error_reponse = {error: ["Error - could not delete. Please try again."]};
            this.error_message = true;
        });
    },
    showStatusMessage(result){
      if(result == false){
        this.error_reponse = {error: ["Product Already Exists in Category"]};
        this.error_message = true;
      }else{
        this.success_message = true;
        this.success_text = 'Congratulations, your action has been saved.';
        this.getProducts(this.selectedListing.id)
      }
    },
    priceIncVat(price, tax_percentage){
      var tax = 1 + (tax_percentage / 100);
      return price * tax;
    },
     productQuery(){
     
      if(this.orderDesc == true){
        this.order = 'desc';
      }else{
        this.order = 'asc';
      }
      axios.get('/api/company/product/suppliers?page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search+'&trade_active='+this.trade_active+'&public_active='+this.public_active+'&active='+this.active+'', {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
        console.log(response);
        this.shopsData = response.data;
      });
    },
    filterSelected(id){
        if(this.selectedids.length > 0){
          var exists = this.selectedids.filter(item => item == id);
          if(exists.length > 0){
            return true;
          }else{
            return false
          }
        }else{
          return false;
        }
      },
      checkAll: function(){ 
                 this.isCheckAll = !this.isCheckAll; 
                 this.selectedids = []; 
                 if(this.isCheckAll){ 
                     // Check all 
                     for (var key in this.shopsData.data) {          
                        this.selectedids.push(this.shopsData.data[key].id); 
                     } 
                 } 
              }, 
      updateCheckall: function(){ 
                 if(this.selectedids.length == this.shopsData.data.length){
                    this.isCheckAll = true; 
                 }else{ 
                    this.isCheckAll = false; 
                 } 
      },
      sanitizeTitle: function(title) {
            var nickname = "";
            // Change to lower case
            var titleLower = title.toLowerCase();
            // Letter "e"
            nickname = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e');
            // Letter "a"
            nickname = nickname.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a');
            // Letter "o"
            nickname = nickname.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o');
            // Letter "u"
            nickname = nickname.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u');
            // Letter "d"
            nickname = nickname.replace(/đ/gi, 'd');
            // Trim the last whitespace
            nickname = nickname.replace(/\s*$/g, '');
            // Change whitespace to "-"
            nickname = nickname.replace(/\s+/g, '-');
            // Remove quotations
            nickname = nickname.replace(/["']/g, "");
            // Remove symbols
            nickname = nickname.replace(/@|#|!/g, '');
            return nickname;
        }
  },
  computed:{
    allProductListingQuantities: function(){
      if(this.productListingSelected.length !== 0){
          if(this.productListingSelected.listings.length > 0){
            this.totalListingsQuantity = 0;
            for(var i = 0; i < this.productListingSelected.listings.length; i++){
              //console.log(this.productListingSelected.listings[i].quantity);
              this.totalListingsQuantity = Number.parseFloat(this.totalListingsQuantity) + Number.parseFloat(this.productListingSelected.listings[i].quantity);
            }
            return this.totalListingsQuantity;
          }else{
            this.totalListingsQuantity = 0;
            return 0;
          }
      }else{
        return 0;
      }
    }
  },
  created(){
    //console.log(localStorage.getItem('default_company'));
    //this.company_info = JSON.parse(localStorage.getItem('default_company'));
    this.productQuery();
    this.getCountries();
  },
  mounted(){

  },
}
</script>

<template>
  <Layout :nickname="$attrs.nickname" :user="$attrs.user" :menuItems="$attrs.menuItems">
    <PageHeader :title="title" :items="items" />
    <div class="row">
       <SideSlidePanel :editPanel="deleteSupplierPanel" :panelClass="'edit-sidepanel'" @close-panel="deleteSupplierPanel = !deleteSupplierPanel">
         <div class="row">
          <div class="col-md-12" v-if="deleteSupplierPanel == true">
            <p>You are about to delete {{selectedListing.name}}. Are you sure?</p>
            <a class="btn btn-success" @click="destroySupplier(selectedListing.id)">Confirm</a>
            <a class="btn btn-danger" @click="deleteSupplierPanel = false">Cancel</a>
          </div>
        </div>
        </SideSlidePanel>
       <b-col md="12">
                    <transition name="slide-success" mode="out-in">
                  <b-alert id="success-message" variant="success" :show="success_message"  dismissible @dismissed="success_message = !success_message">
                      <p class="font-size-14">{{success_text}}</p>
                    </b-alert>
                  </transition>
                  </b-col>
      <div class="col-md-12">
        <div class="row mb-3">
          <div class="col-xl-1 col-sm-6">
            <div class="mt-2">
              <b-form-select class="form-control" v-model="limit" :options="limits" @input="productQuery()"></b-form-select>
            </div>
          </div>
         
          <div class="col-lg-2 col-sm-6 d-flex">
          <form class="my-auto mr-auto">
            <small class="mx-auto my-2 text-center d-block">Results {{shopsData.from}} - {{shopsData.to}} of {{shopsData.total}}</small>
          </form>
          <div v-if="view == 'grid'" class="text-center my-auto ml-auto"><div
                        class="form-check form-switch form-switch-sm mb-0 mr-auto p-0 mt-auto d-flex"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input mx-auto my-auto" 
                          type="checkbox"
                          id="SwitchCheckSizeCheckall"
                         
                          v-model="isCheckAll"
                          @click='checkAll()'
                          name="active"
                        />
                        <label class="d-inline-block my-auto ml-2" for="SwitchCheckSizeCheckall">Select All</label>
                    </div></div>
        </div>
        <div class="col-lg-3">
          <b-form-group class="my-auto ml-auto mr-3">
            <b-form-radio-group id="btn-radios-1" button-variant="outline-light"
        size="md" v-model="marketplace_brands" :options="quickfilter_options" buttons @input="productQuery()"></b-form-radio-group>
      
          
          </b-form-group>
        </div>
        <div class="col-lg-3 col-sm-6 d-flex">
          
            <div class="mt-sm-0 form-inline w-100" >
              <div class="search-box mr-2 ml-auto">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control w-100 border-0"
                    placeholder="Search name, reference or description..."
                    v-model="search"
                    @keyup.enter="productQuery()"
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6 d-flex">
            <div class="d-flex ml-auto">
              <ul class="nav nav-pills product-view-nav">
                
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'grid' }" href="javascript: void(0);" @click="view = 'grid'">
                    <i class="bx bx-grid-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{'active': view == 'list' }" href="javascript: void(0);" @click="view = 'list'">
                    <i class="bx bx-list-ul"></i>
                  </a>
                </li>
                <li class="nav-item">
                 <b-dropdown text="Bulk Actions" variant="primary" class="rounded">
                  <b-dropdown-item :disabled="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" href="javascript: void(0);" @click="setActive(true)">Set Active</b-dropdown-item>
                  <b-dropdown-item :disabled="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" href="javascript: void(0);" @click="setActive(false)">Set Inactive</b-dropdown-item>
                  <b-dropdown-item :disabled="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" href="javascript: void(0);" @click="bulkDuplicateResource()">Duplicate</b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item :disabled="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.delete.includes($attrs.active_menu.id)" href="javascript: void(0);" v-b-modal.delete-modal @click="deleteMode = 'bulk'">Delete</b-dropdown-item>
                </b-dropdown>
                </li>
                <li class="nav-item">
                 <b-button
                    variant="success"
                    :disabled="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.add.includes($attrs.active_menu.id)"
                    class="btn btn-success btn-rounded mb-2 mr-2 text-white"
                    href="javascript:;"
                    @click="addNewPanel = !addNewPanel"
                  >
                    <i class="mdi mdi-plus mr-1"></i> New
                  </b-button>
                </li>
              </ul>
              <div class="text-sm-right">
                  
                </div>
          </div>
        </div>
      </div>
       <div class="d-flex flex-column justify-content-center h-100 restricted-col" v-if="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.read.includes($attrs.active_menu.id)" >
          <AccessRestricted :page="false"></AccessRestricted>
        </div>
        <div v-else>
        <div class="card" v-if="view == 'list'" >
          <div class="card-body p-0">
            <div class="table-responsive">
              <table class="table table-centered table-hover">

                <thead class="thead-light">
                  <tr>
                    <th scope="col"  style="width: 100px" class="text-center"><div
                        class="form-check form-switch form-switch-sm mb-0 mx-auto p-0 mt-auto d-flex"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input mx-auto my-auto" 
                          type="checkbox"
                          id="SwitchCheckAll"
                         
                          v-model="isCheckAll"
                          @click='checkAll()'
                          name="active"
                        />
                    </div></th>
                    <th scope="col"><a :class="{'text-dark': sortby != 'name'}" href="javascript: void(0);" @click="orderDesc = !orderDesc,  sortby = 'name', productQuery()">Name <i v-if="orderDesc == true" class="bx bx-caret-up"></i><i v-if="orderDesc == false" class="bx bx-caret-down"></i></a></th>
                    <th scope="col">Description</th>
                    <th scope="col">Contacts</th>
                    <th scope="col">Addresses</th>
                    <th scope="col">Products</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
               
                <tbody>
                  
                  <tr v-for="list in shopsData.data" :key="list.id" valign="middle">
                    <td>
                       <div class="text-center" v-if="list.logo">
                      <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="list.id" button-variant="primary" @input="updateSelected" button>
                        <i v-if="filterSelected(list.id)" class="bx bx-check"></i>
                        <img v-else :src="'https://'+$attrs.company.nickname+'.s3.eu-west-2.amazonaws.com/'+list.logo" alt class="avatar-sm rounded-circle" />
                      </b-form-checkbox>
                    </div>
                    <div class="text-center" v-else >
                        <b-form-checkbox class="select-row-btn" v-model="selectedids" :value="list.id" button-variant="primary" @input="updateSelected" button>

                          <i v-if="filterSelected(list.id)" class="bx bx-check"></i><span class="avatar-title rounded-circle" v-else>{{(list.name).slice(0,1)}}</span></b-form-checkbox>
                    </div> 
                    </td>
                    <td>
                        <h5 class="m-0">{{list.name}}</h5>
                    </td>
                    <td>
                      <small class="text-muted mb-0" v-if="list.description != null" v-html="(list.description).slice(0, 100)+'...'"></small>
                    </td>
                     <td>
                      <a href="javascript:;" v-b-tooltip.hover title="Stock" placement="left" @click="selectedListing = list, supplierContacts = list.contacts, contactsPanel = true" class="btn btn-outline-light btn-rounded"><i class="bx bx bx-phone"></i></a>
                    </td>
                     <td>
                      <a href="javascript:;" v-b-tooltip.hover title="Stock" placement="left" @click="selectedListing = list, supplierAddresses = list.addresses, addressesPanel = true" class="btn btn-outline-light btn-rounded"><i class="bx bx bx-home-alt"></i></a>
                    </td>
                    <td>
                      <a href="javascript:;" v-b-tooltip.hover title="Stock" placement="left" @click="selectedListing = list, getProducts(list.id), showListingProducts = 1" class="btn btn-outline-light btn-rounded"><i class="bx bx bx-purchase-tag-alt"></i></a>
                    </td>
                    <td>
                       <div
                        class="form-check form-switch form-switch-sm my-auto ml-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckSize-${list.id}`"
                          v-model="list.active"
                          :value="list.active"
                          name="active"
                          :disabled="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)"
                          @change="selectedids = [], selectedids.push(list.id), setActive(list.active)"
                        />
                        <label class="form-check-label" :for="`SwitchCheckSize-${list.id}`" 
                          >Active</label
                        >
                    </div>
                    </td>
                    <td>
                      <ul class="list-inline font-size-20 contact-links mb-0">
                        <li class="list-inline-item" v-if="($attrs.user.companies[0].userrole.name == 'Owner') || $attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)"><a  href="javascript:;" v-b-tooltip.hover title="Edit" placement="left" class="" @click="editsupplier = list, editPanel = true" ><i class="bx bx-edit-alt"></i>
                          </a>
                        </li>
                      <li class="list-inline-item" v-if="($attrs.user.companies[0].userrole.name == 'Owner') || $attrs.user.companies[0].permissions.view.includes($attrs.active_menu.id)">
                        <a href="javascript:;" v-b-tooltip.hover title="View" placement="left" @click="selectedListing = list, supplierContacts = list.contacts, supplierAddresses = list.addresses, viewSupplier = true" class=""><i class="mdi mdi-eye-outline"></i></a>
                      </li>
                      <li class="list-inline-item" v-if="($attrs.user.companies[0].userrole.name == 'Owner') || $attrs.user.companies[0].permissions.delete.includes($attrs.active_menu.id)">
                        <a href="javascript:;" v-b-tooltip.hover title="Delete" placement="left" v-b-modal.delete-modal @click="selected_product_id = list.id, deleteMode = 'single'" class=""><i class="bx bx-trash-alt"></i></a>
                      </li>
                        <li class="list-inline-item">
                           <b-dropdown v-b-tooltip.hover title="More Options" placement="left" dropleft class="more-options-dropdown"  menu-class="dropdown-menu-right">
                        <template v-slot:button-content>
                           <i class="bx bx-dots-horizontal-rounded"></i>
                        </template>
                          <b-dropdown-item :disabled="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" href="javascript: void(0);"  @click="selectedids = [], selectedids.push(list.id), bulkDuplicateResource()">Duplicate</b-dropdown-item>
                      </b-dropdown>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <b-pagination
              v-if="shopsData.last_page > 1"
              class="justify-content-center"
              pills
              v-model="currentPage"
              :total-rows="shopsData.total"
              :per-page="shopsData.per_page"
              aria-controls="my-table"
              @input="brandQuery"
            ></b-pagination>
             <small class="mx-auto my-2 text-center d-block">Records {{shopsData.from}} - {{shopsData.to}} of {{shopsData.total}} // Page {{shopsData.current_page}} of {{shopsData.last_page}}</small>
              </div>
            </div>
          </div>
      </div>
    <div class="row" v-if="view == 'grid'" >
     <div class="col-xl-4"  v-for="list in shopsData.data" :key="list.id">
        <div class="card overflow-hidden">
          <div class="bg-soft-primary min-h-100">
            <div class="row">
              
              <div class="col-12 align-self-end">
                <img :src="'/public/storage/'+list.banner" alt class="img-fluid" />
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div class="row">
              <div class="col-sm-12 text-center">
               <div class="avatar-md mx-auto">
                   <div class="text-center" v-if="list.image">
                      <b-form-checkbox class="select-row-btn w-image" v-model="selectedids" :value="list.id" button-variant="primary" @input="updateSelected" button>
                        <i v-if="filterSelected(list.id)" class="bx bx-check"></i>
                        <img v-else :src="`${list.image}`" alt class="avatar-md img-thumbnail rounded-circle h-100" />
                      </b-form-checkbox>
                    </div>
                    <div class="text-center" v-else >
                        <b-form-checkbox class="select-row-btn w-image" v-model="selectedids" :value="list.id" button-variant="primary" @input="updateSelected" button>

                          <i v-if="filterSelected(list.id)" class="bx bx-check"></i><span class="avatar-title rounded-circle" v-else>{{(list.name).slice(0,1)}}</span></b-form-checkbox>
                    </div> 
                </div>
                <h4 class="font-size-18 text-truncate mt-3">{{list.name}}</h4>
                <p class="text-muted mb-0 text-truncate" v-html="list.description"></p>
              </div>

              <div class="col-sm-12">
                <div class="pt-4">
                  <div class="mt-4 d-flex">
                    <a href="javascript:;" v-b-tooltip.hover title="Contacts" placement="left" @click="selectedListing = list, supplierContacts = list.contacts, contactsPanel = true" class="btn btn-outline-light font-size-16 btn-rounded ml-2 my-auto"><i class="bx bx bx-phone"></i></a>
                    <a href="javascript:;" v-b-tooltip.hover title="Addresses" placement="left" @click="selectedListing = list, supplierAddresses = list.addresses, addressesPanel = true" class="btn btn-outline-light font-size-16 btn-rounded ml-2 my-auto"><i class="bx bx bx-home-alt"></i></a>
                    <a href="javascript:;" @click="selectedListing = list, getProducts(list.id), showListingProducts = 1" class="btn btn-outline-light btn-rounded ml-2"><i class="bx bx bx-purchase-tag-alt my-1"></i></a>
                    <b-tooltip target="tooltip-add">Add Product</b-tooltip>
                    <a id="tooltip-add" @click="editsupplier = list, editPanel = true" class="btn btn-outline-light font-size-16 btn-rounded ml-2 my-auto" v-if="($attrs.user.companies[0].userrole.name == 'Owner') || $attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)"><i class="bx bx-pencil"></i></a>
                    <a href="javascript:;" v-if="($attrs.user.companies[0].userrole.name == 'Owner') || $attrs.user.companies[0].permissions.view.includes($attrs.active_menu.id)" v-b-tooltip.hover title="View" placement="left" @click="selectedListing = list, supplierContacts = list.contacts, supplierAddresses = list.addresses, viewSupplier = true" class="btn btn-outline-light btn-rounded ml-2"><i class="mdi mdi-eye-outline"></i></a>
                     <a href="javascript:;" v-if="($attrs.user.companies[0].userrole.name == 'Owner') || $attrs.user.companies[0].permissions.delete.includes($attrs.active_menu.id)" v-b-tooltip.hover title="Delete" placement="left" v-b-modal.delete-modal @click="selected_product_id = list.id, deleteMode = 'single'"  class="btn btn-outline-light btn-rounded ml-2"><i class="bx bx-trash-alt"></i></a>
                     <b-dropdown v-b-tooltip.hover title="More Options" placement="left" dropleft class="more-options-dropdown btn btn-outline-light btn-rounded mr-auto ml-2">
                        <template v-slot:button-content>
                           <i class="bx bx-dots-horizontal-rounded"></i>
                        </template>
                         <b-dropdown-item href="javascript: void(0);"  :disabled="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" @click="selectedids = [], selectedids.push(list.id), bulkDuplicateResource()">Duplicate</b-dropdown-item>
                      </b-dropdown>
                     <div
                        class="form-check form-switch form-switch-sm my-auto ml-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckSize-${list.id}`"
                          v-model="list.active"
                          :value="list.active"
                          name="active"
                          :disabled="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)"
                          @change="selectedids = [], selectedids.push(list.id), setActive(list.active)"
                        />
                        <label class="form-check-label" :for="`SwitchCheckSize-${list.id}`" 
                          >Active</label
                        >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>

              <div class="col-lg-12">
                <b-pagination
              v-if="shopsData.data.length > 0"
              class="justify-content-center"
              pills
              v-model="currentPage"
              :total-rows="shopsData.total"
              :per-page="shopsData.per_page"
              aria-controls="my-table"
              @input="brandQuery"
            ></b-pagination>
             <small class="mx-auto my-2 text-center d-block">Records {{shopsData.from}} - {{shopsData.to}} of {{shopsData.total}} // Page {{shopsData.current_page}} of {{shopsData.last_page}}</small>
              </div>
      </div>
    </div>
    </div>
    </div>
    <!--  end row -->

    <div class="row">
      <!-- end col-->
    </div>
     <b-modal id="delete-modal" size="md" hide-footer hide-header centered>
        <b-card class="border-radius-1em border shadow-none">
          <h4>Are you sure?</h4>
          <p v-if="deleteMode == 'bulk'">You are about to delete {{selectedids.length}} products. Are you sure you want to do this? This action is not reversable</p>
           <p v-if="deleteMode == 'single'">You are about to delete this category. Are you sure you want to do this? This action is not reversable</p>

           <div class="d-flex">
            <a class="btn btn-danger" v-if="deleteMode == 'bulk'" @click="bulkDestroyResource">
              Delete
            </a>
             <a class="btn btn-danger"  v-if="deleteMode == 'single'" @click="destroyResource">
              Delete
            </a>
            <a class="btn btn-success" @click="$bvModal.hide('delete-modal')">
              Cancel
            </a>
          </div>
        </b-card>
      </b-modal>
         <SideSlidePanel :editPanel="viewSupplier" :panelClass="'edit-sidepanel'" @close-panel="viewSupplier = 0">
           <div class="row px-3">
        
              <div class="col-md-12">
                <b-card class="border-radius-1em border shadow-none">
                  <h4 class="my-3">{{selectedListing.name}}</h4>
                  <p v-html="selectedListing.description"></p>
                </b-card>
              </div>
              <div class="col-md-12">
                 <b-card class="border-radius-1em border shadow-none">
                <h5 class="my-3">Supplier Addresses</h5>
            <ul class="list-group product-list w-100 no-shadow" v-if="(supplierAddresses.length !== 0) && (deleteAddressPanel == false)">
                              <li v-for="(address, index) in supplierAddresses" class="list-group-item d-flex px-0 py-0 border product-list-item mb-2 shadow-none">
                                <div class="d-flex product-list-item-container w-100">
                                  <div class="mr-auto ml-2 my-auto flex-grow-1 mr-3 py-3">
                                  <strong class="mr-auto my-auto ml-4 text-primary">{{address.address_alias}}</strong>
                                  <span class="mx-4">{{address.address_type}}</span>
                        <p class="text-muted italic no-margin d-inline">{{address.address1}}, {{address.address2}}, {{address.address3}}, <span v-if="address.county">{{address.county.name}}, </span>{{address.postcode}} <span v-if="address.country">{{address.country.name}}</span></p>
                                </div>
                              
              
                              </div>
                              </li>
                              

                              <!--<li class="list-group-item py-4 text-center" ><a class="btn btn-outline-light btn-rounded">Load More</a>
                              </li>-->
                            </ul>
                <ul class="list-group product-list w-100 no-shadow" v-if="(supplierAddresses.length == 0) && (deleteAddressPanel == false)">
                    <li class="list-group-item d-flex">
                      <i class="bx bx-warning"></i> No Addresses Assigned to this supplier
                    </li> 
                </ul>
               
             </b-card>
        </div>
        <div class="col-md-12">
           <b-card class="border-radius-1em border shadow-none">
           <h5 class="my-3">Supplier Contacts</h5>
            <ul class="list-group product-list w-100 no-shadow" v-if="(supplierContacts.length !== 0) && (deleteContactPanel == false)">
                              <li v-for="(contact, index) in supplierContacts" class="list-group-item d-flex px-0 py-0 border product-list-item mb-2 shadow-none">
                                <div class="d-flex product-list-item-container w-100">
                                  <div class="mr-auto ml-2 my-auto flex-grow-1 mr-3 py-3">
                                  <strong class="mr-auto my-auto ml-4 text-primary">{{contact.name}}</strong>
                                  <span class="mx-4">{{contact.email}}</span>
                                  <span class="mx-4">{{contact.phone}}</span>
                                  <span class="mx-4">{{contact.mobile}}</span>
                                  <span class="mx-4">{{contact.fax}}</span>
                                </div>
                             
              
                              </div>
                              </li>
                              

                              <!--<li class="list-group-item py-4 text-center" ><a class="btn btn-outline-light btn-rounded">Load More</a>
                              </li>-->
                            </ul>
                <ul class="list-group product-list w-100 no-shadow" v-if="(supplierContacts.length == 0) && (deleteContactPanel == false)">
                    <li class="list-group-item d-flex">
                      <i class="bx bx-warning"></i> No Addresses Assigned to this supplier
                    </li> 
                </ul>
             
                  </b-card>
        </div>
    
            </div>
         </SideSlidePanel>
    <SideSlidePanel :editPanel="showListingProducts" :panelClass="'edit-sidepanel'" @close-panel="showListingProducts = 0">
    <div class="row">
        
        <div class="col-md-12">
           <b-row>
                     <b-col md="12">
                    <transition name="slide-error" mode="out-in">
                    <b-alert id="errors" variant="danger" :show="error_message"  dismissible @dismissed="error_message = !error_message">
                      <p class="font-size-14">Oops! Please correct the following errors in your submission</p>
                      <ul>
                        <li v-for="(error, key) in error_reponse">
                          {{error[0]}}
                        </li>
                      </ul>
                    </b-alert>
                    
                  </transition>
                  </b-col>
          </b-row>
        <b-card class="shadow-none">
          <div class="row">
            <div class="col-md-12 d-flex pb-3">
          <h4 class="my-auto mr-auto">Products from <strong class="text-primary">{{selectedListing.name}}</strong> </h4><a v-if="($attrs.user.companies[0].userrole.name == 'Owner') || $attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" id="tooltip-add" class="btn btn-outline-light font-size-16 btn-rounded float-right" @click="addProductToggle = !addProductToggle"><i class="mdi mdi-plus"></i></a><b-tooltip target="tooltip-add">Add Product</b-tooltip>
        </div>
      </div>
          <CompanyProductSearch v-if="addProductToggle" class="mt-1 mb-4" :resource="'category'" :resource_id="selectedListing.id" :api_token="$attrs.user.api_token" :resource_route="'/api/company/product/supplier/'+selectedListing.id+'/add-product?'" @product-added="showStatusMessage"></CompanyProductSearch>
            <b-overlay
                            id="overlay-background"
                            :show="loadingForm"
                            variant="white"
                            opacity="0.85"
                            blur="1px"
                            rounded="md"
                          >
              <CompanyProductList :product_list="categoryProducts" :expanded="isExpanded" ></CompanyProductList>
            </b-overlay>
        </b-card>
        </div>
    </div>
    </SideSlidePanel>
    <!-- end row -->
    <SideSlidePanel :editPanel="addNewPanel" :panelClass="'edit-sidepanel'" @close-panel="addNewPanel = !addNewPanel">
       <div class="row">
        <div class="d-flex flex-column justify-content-center h-100 restricted-col" v-if="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.add.includes($attrs.active_menu.id)" >
          <AccessRestricted :page="false"></AccessRestricted>
        </div>
        <div class="col-md-12" v-else>
        <b-card class="shadow-none">
          <h4 class="my-3">Add New Supplier</h4>
          <b-overlay
                        id="overlay-background"
                        :show="loadingForm"
                        variant="white"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
                      >
         <b-form id="addsupplierform" method="POST" enctype="multipart/form-data">
                   <input type="hidden" name="_token" :value="csrf">
           <b-row>
                     <b-col md="12">
                    <transition name="slide-error" mode="out-in">
                    <b-alert id="errors" variant="danger" :show="error_message"  dismissible @dismissed="error_message = !error_message">
                      <p class="font-size-14">Oops! Please correct the following errors in your submission</p>
                      <ul>
                        <li v-for="(error, key) in error_reponse">
                          {{error[0]}}
                        </li>
                      </ul>
                    </b-alert>
                    
                  </transition>
                  </b-col>
          </b-row>
               <b-form-group label="Name">
            <b-form-input type="text" name="name" v-model="newsupplier.name"></b-form-input>
            <input type="hidden" name="slug" :value="sanitizeTitle(newsupplier.name)">
          </b-form-group>
           <b-form-group class="mt-2" label="Description">
            <b-textarea rows="6" v-model="newsupplier.description" name="description"></b-textarea>
          </b-form-group>
          <b-form-group class="mt-2" label="Logo">
            <input class="form-control mb-2" name="logo" type="file">
          </b-form-group>
          <b-form-group class="my-3">
                     <div
                        class="form-check form-switch form-switch-sm mb-0 mt-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          id="newbrand-owner"
                          v-model="newsupplier.active" value="true"
                          unchecked-value="false"
                          name="active"
                        />
                        <label class="form-check-label" for="newbrand-owner" 
                          > Active</label
                        >
                    </div>
                    <input type="hidden" :value="newsupplier.active" name="active">
          </b-form-group>
          <div class="d-flex flex-row mt-3">
          <b-button variant="success" class="w-md w-100" @click="addSupplier()"> <i class=" bx bx-save"></i> Save</b-button>
          </div>
        </b-form>
      </b-overlay>
        </b-card>
        </div>
      </div>
    </SideSlidePanel>
     <!-- end row -->
    <SideSlidePanel :editPanel="editPanel" :panelClass="'edit-sidepanel'" @close-panel="editPanel = !editPanel">
       <div class="row">
        <div class="d-flex flex-column justify-content-center h-100 restricted-col" v-if="($attrs.user.companies[0].userrole.name !== 'Owner') && !$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" >
          <AccessRestricted :page="false"></AccessRestricted>
        </div>
        <div class="col-md-12" v-else>
        <b-card class="shadow-none">
          <h4 class="my-3">Edit Supplier</h4>
          <b-overlay
                        id="overlay-background"
                        :show="loadingForm"
                        variant="white"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
                      >
         <b-form id="updatesupplierform" method="POST" enctype="multipart/form-data">
                   <input type="hidden" name="_token" :value="csrf">
           <b-row>
                     <b-col md="12">
                    <transition name="slide-error" mode="out-in">
                    <b-alert id="errors" variant="danger" :show="error_message"  dismissible @dismissed="error_message = !error_message">
                      <p class="font-size-14">Oops! Please correct the following errors in your submission</p>
                      <ul>
                        <li v-for="(error, key) in error_reponse">
                          {{error[0]}}
                        </li>
                      </ul>
                    </b-alert>
                    
                  </transition>
                  </b-col>
          </b-row>
               <b-form-group label="Name">
            <b-form-input type="text" name="name" v-model="editsupplier.name"></b-form-input>
            <input type="hidden" name="slug" :value="sanitizeTitle(editsupplier.name)">
          </b-form-group>
           <b-form-group class="mt-2" label="Description">
            <b-textarea rows="6" v-model="editsupplier.description" name="description"></b-textarea>
          </b-form-group>
          <b-form-group class="mt-2" label="Logo">
            <input class="form-control mb-2" name="logo" type="file">
          </b-form-group>
          <b-form-group class="my-3">
                     <div
                        class="form-check form-switch form-switch-sm mb-0 mt-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          id="newbrand-owner"
                          v-model="editsupplier.active" value="true"
                          unchecked-value="false"
                          name="active"
                        />
                        <label class="form-check-label" for="newbrand-owner" 
                          > Active</label
                        >
                    </div>
                    <input type="hidden" :value="editsupplier.active" name="active">
          </b-form-group>
          <div class="d-flex flex-row mt-3">
          <b-button variant="success" class="w-md w-100" @click="updateSupplier()"> <i class=" bx bx-save"></i> Save</b-button>
          </div>
        </b-form>
      </b-overlay>
        </b-card>
        </div>
      </div>
    </SideSlidePanel>

    <SideSlidePanel :editPanel="addressesPanel" :panelClass="'edit-sidepanel'" @close-panel="addressesPanel = !addressesPanel">
     
       <div class="row px-2">
        <div class="col-md-12 px-4 mb-3">
          <h4>Addresses for Supplier: <span class="text-primary">{{selectedListing.name}}</span></h4>
        </div>
        <transition name="slide-fadepanel">
          <div class="col-md-12 px-4" v-if="deleteAddressPanel == true">
            <p>You are about to delete {{deletesupplieraddress.address_alias}}. Are you sure?</p>
            <a class="btn btn-success" @click="destroySupplierAddress(deletesupplieraddress.id, deleteAddressIndex)">Confirm</a>
            <a class="btn btn-danger" @click="deleteAddressPanel = false, resetFeatureValue()">Cancel</a>
          </div>
        </transition>
        <div class="col-md-12">
            <ul class="list-group product-list w-100 no-shadow" v-if="(supplierAddresses.length !== 0) && (deleteAddressPanel == false)">
                              <li v-for="(address, index) in supplierAddresses" class="list-group-item d-flex px-0 py-0 border product-list-item mb-2 shadow-none">
                                <div class="d-flex product-list-item-container w-100">
                                  <div class="mr-auto ml-2 my-auto flex-grow-1 mr-3 py-3">
                                  <strong class="mr-auto my-auto ml-4 text-primary">{{address.address_alias}}</strong>
                                  <span class="mx-4">{{address.address_type}}</span>
                        <p class="text-muted italic no-margin d-inline">{{address.address1}}, {{address.address2}}, {{address.address3}}, <span v-if="address.county">{{address.county.name}}, </span>{{address.postcode}} <span v-if="address.country">{{address.country.name}}</span></p>
                                </div>
                                <div class="ml-auto my-auto mr-3 d-flex">
                                  <a v-if="($attrs.user.companies[0].userrole.name == 'Owner') || $attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" class="btn btn-outline-light btn-rounded ml-auto my-auto mr-2" href="javascript:;" @click="getCounties(address.country_id), addSupplierAddressPanel = false, editsupplieraddress = address"><i class="px-2 bx bx-pencil"></i></a>
                                <a v-if="($attrs.user.companies[0].userrole.name == 'Owner') || $attrs.user.companies[0].permissions.delete.includes($attrs.active_menu.id)" class="btn btn-outline-light btn-rounded my-auto" href="javascript:;" @click="deletesupplieraddress = address, deleteAddressIndex = index, deleteAddressPanel = true, addSupplierAddressPanel = false"><i class="py-1 px-2 bx bx-trash-alt"></i></a>
                              </div>
              
                              </div>
                              </li>
                              

                              <!--<li class="list-group-item py-4 text-center" ><a class="btn btn-outline-light btn-rounded">Load More</a>
                              </li>-->
                            </ul>
                <ul class="list-group product-list w-100 no-shadow" v-if="(supplierAddresses.length == 0) && (deleteAddressPanel == false)">
                    <li class="list-group-item d-flex">
                      <i class="bx bx-warning"></i> No Addresses Assigned to this supplier
                    </li> 
                </ul>
                 <div class="mt-2" v-if="($attrs.user.companies[0].userrole.name == 'Owner') || $attrs.user.companies[0].permissions.add.includes($attrs.active_menu.id)">
                 <a class="btn btn-outline-light w-100 btn-rounded" v-if="(addSupplierAddressPanel == false) && (deleteAddressPanel == false)" @click="editsupplieraddress = {id: null}, addSupplierAddressPanel = true"><i class="bx bx-home-alt"></i> Add New Address <i class="mdi mdi-plus"></i></a>
               </div>
        </div>
        <div class="col-md-12">
      <transition name="slide-fadepanel">
       <b-card class="shadow-none" v-if="(addSupplierAddressPanel == true)  && (deleteAddressPanel == false)">
          <h4 class="my-3">Add New Address</h4>
          <b-overlay
                        id="overlay-background"
                        :show="loadingForm"
                        variant="white"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
                      >
         <b-form id="addsupplieraddressform" method="POST" enctype="multipart/form-data">
                   <input type="hidden" name="_token" :value="csrf">
           <b-row>
                     <b-col md="12">
                    <transition name="slide-error" mode="out-in">
                    <b-alert id="errors" variant="danger" :show="error_message"  dismissible @dismissed="error_message = !error_message">
                      <p class="font-size-14">Oops! Please correct the following errors in your submission</p>
                      <ul>
                        <li v-for="(error, key) in error_reponse">
                          {{error[0]}}
                        </li>
                      </ul>
                    </b-alert>
                    
                  </transition>
                  </b-col>
          </b-row>
       
           <b-col md="12">
                <b-form-group  label="Address Alias/Name">
                  <b-form-input type="text" name="alias" v-model="newsupplieraddress.alias" ></b-form-input>
                </b-form-group>
                <b-form-group  label="Address 1">
                  <b-form-input type="text" name="address1" v-model="newsupplieraddress.address1" ></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Address 2">
                  <b-form-input type="text" name="address2" v-model="newsupplieraddress.address2" ></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Address 3">
                  <b-form-input type="text" name="address3" v-model="newsupplieraddress.address3" ></b-form-input>
                </b-form-group>
                
                <b-form-group class="mt-2" label="Country">
                  <b-form-select class="form-control" v-model="newsupplieraddress.country_id" name="country_id"  @input="getCounties(newsupplieraddress.country_id)">
                    <b-form-select-option v-for="country in countries" :value="country.id">
                      {{country.name}}
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>
                <b-form-group class="mt-2" label="County/State">
                  <b-form-select class="form-control" name="county_id"  v-model="newsupplieraddress.county_id"  :disabled="(counties.length == 0)">
                    <b-form-select-option v-for="county in counties" :value="county.id" >
                      {{county.name}}
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>
                <b-form-group class="mt-2" label="City">
                  <b-form-input type="text" v-model="newsupplieraddress.city" name="city"></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Postcode/Zip">
                  <b-form-input type="text" v-model="newsupplieraddress.postcode" name="postcode"></b-form-input>
                </b-form-group>
                  <div
                        class="form-check form-switch form-switch-sm mt-3 mb-2 ml-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckBilling`"
                          v-model="newsupplieraddress.invoice"
                          value="1"
                          unchecked-value="0"
                          name="active"
                        />
                        <label class="form-check-label" :for="`SwitchCheckBilling`" 
                          >Billing Address</label
                        >
                    </div>
                    <div
                        class="form-check form-switch form-switch-sm my-2 ml-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckDelivery`"
                          v-model="newsupplieraddress.delivery"
                          value="1"
                          unchecked-value="0"
                          name="active"
                        />
                        <label class="form-check-label" :for="`SwitchCheckDelivery`" 
                          >Delivery Address</label
                        >
                    </div>
                    <div
                        class="form-check form-switch form-switch-sm my-2 ml-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckDefault`"
                          v-model="newsupplieraddress.default"
                          value="1"
                          unchecked-value="0"
                          name="active"
                        />
                        <label class="form-check-label" :for="`SwitchCheckDefault`" 
                          >Default Address</label
                        >
                    </div>
               
              </b-col>
          <div class="d-flex flex-row mt-3">
          <b-button variant="success" class="w-md w-50 d-inline" @click="addSupplierAddress()"> <i class=" bx bx-save"></i> Save</b-button>
          <b-button variant="danger" class="w-md w-50 d-inline" @click="addSupplierAddressPanel = false"> <i class=" bx bx-save"></i> Cancel</b-button>
          </div>
        </b-form>
      </b-overlay>
        </b-card>
      </transition>
        </div>
        <div class="col-md-12">
      <transition name="slide-fadepanel">
       <b-card class="shadow-none" v-if="(editsupplieraddress.id !== null)  && (deleteAddressPanel == false)">
          <h4 class="my-3">Edit Address</h4>
          <b-overlay
                        id="overlay-background"
                        :show="loadingForm"
                        variant="white"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
                      >
         <b-form id="updatesupplieraddressform" method="POST" enctype="multipart/form-data">
                   <input type="hidden" name="_token" :value="csrf">
           <b-row>
                     <b-col md="12">
                    <transition name="slide-error" mode="out-in">
                    <b-alert id="errors" variant="danger" :show="error_message"  dismissible @dismissed="error_message = !error_message">
                      <p class="font-size-14">Oops! Please correct the following errors in your submission</p>
                      <ul>
                        <li v-for="(error, key) in error_reponse">
                          {{error[0]}}
                        </li>
                      </ul>
                    </b-alert>
                    
                  </transition>
                  </b-col>
          </b-row>
       
           <b-col md="12">
                <b-form-group  label="Address Alias/Name">
                  <b-form-input type="text" name="alias" v-model="editsupplieraddress.address_alias" ></b-form-input>
                </b-form-group>
                <b-form-group  label="Address 1">
                  <b-form-input type="text" name="address1" v-model="editsupplieraddress.address1" ></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Address 2">
                  <b-form-input type="text" name="address2" v-model="editsupplieraddress.address2" ></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Address 3">
                  <b-form-input type="text" name="address3" v-model="editsupplieraddress.address3" ></b-form-input>
                </b-form-group>
                
                <b-form-group class="mt-2" label="Country">
                  <b-form-select class="form-control" v-model="editsupplieraddress.country_id" name="country_id"  @input="getCounties(editsupplieraddress.country_id)">
                    <b-form-select-option v-for="country in countries" :value="country.id">
                      {{country.name}}
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>
                <b-form-group class="mt-2" label="County/State">
                  <b-form-select class="form-control" name="county_id"  v-model="editsupplieraddress.county_id"  :disabled="(counties.length == 0)">
                    <b-form-select-option v-for="county in counties" :value="county.id" >
                      {{county.name}}
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>
                <b-form-group class="mt-2" label="City">
                  <b-form-input type="text" v-model="editsupplieraddress.city" name="city"></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Postcode/Zip">
                  <b-form-input type="text" v-model="editsupplieraddress.postcode" name="postcode"></b-form-input>
                </b-form-group>
                    <div
                         class="form-check form-switch form-switch-sm mt-3 mb-2 ml-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckBilling`"
                          v-model="editsupplieraddress.invoice"
                          value="1"
                          unchecked-value="0"
                          name="active"
                        />
                        <label class="form-check-label" :for="`SwitchCheckBilling`" 
                          >Billing Address</label
                        >
                    </div>
                    <div
                        class="form-check form-switch form-switch-sm my-2 ml-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckDelivery`"
                          v-model="editsupplieraddress.delivery"
                          value="1"
                          unchecked-value="0"
                          name="active"
                        />
                        <label class="form-check-label" :for="`SwitchCheckDelivery`" 
                          >Delivery Address</label
                        >
                    </div>
                    <div
                        class="form-check form-switch form-switch-sm my-2 ml-auto"
                        dir="ltr"
                      >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          :id="`SwitchCheckDefault`"
                          v-model="editsupplieraddress.default"
                          value="1"
                          unchecked-value="0"
                          name="active"
                        />
                        <label class="form-check-label" :for="`SwitchCheckDefault`" 
                          >Default Address</label
                        >
                    </div>
               
              </b-col>
          <div class="d-flex flex-row mt-3">
          <b-button variant="success" class="w-md w-50 d-inline" @click="updateSupplierAddress()"> <i class=" bx bx-save"></i> Save</b-button>
          <b-button variant="danger" class="w-md w-50 d-inline" @click="editsupplieraddress = {id: null}"> <i class=" bx bx-save"></i> Cancel</b-button>
          </div>
        </b-form>
      </b-overlay>
        </b-card>
      </transition>
        </div>
      </div>
    </SideSlidePanel>

        <SideSlidePanel :editPanel="contactsPanel" :panelClass="'edit-sidepanel'" @close-panel="contactsPanel = !contactsPanel">

       <div class="row px-2" >
        <div class="col-md-12 px-4 mb-3">
          <h4>Contacts for Supplier: <span class="text-primary">{{selectedListing.name}}</span></h4>
        </div>
         <transition name="slide-fadepanel">
          <div class="col-md-12 px-4" v-if="deleteContactPanel == true">
            <p>You are about to delete {{deletesuppliercontact.name}}. Are you sure?</p>
            <a class="btn btn-success" @click="destroySupplierContact(deletesuppliercontact.id, deleteContactIndex)">Confirm</a>
            <a class="btn btn-danger" @click="deleteContactPanel = false">Cancel</a>
          </div>
        </transition>
        <div class="col-md-12">
            <ul class="list-group product-list w-100 no-shadow" v-if="(supplierContacts.length !== 0) && (deleteContactPanel == false)">
                              <li v-for="(contact, index) in supplierContacts" class="list-group-item d-flex px-0 py-0 border product-list-item mb-2 shadow-sm">
                                <div class="d-flex product-list-item-container w-100">
                                  <div class="mr-auto ml-2 my-auto flex-grow-1 mr-3 py-3">
                                  <strong class="mr-auto my-auto ml-4 text-primary">{{contact.name}}</strong>
                                  <span class="mx-4">{{contact.email}}</span>
                                  <span class="mx-4">{{contact.phone}}</span>
                                  <span class="mx-4">{{contact.mobile}}</span>
                                  <span class="mx-4">{{contact.fax}}</span>
                                </div>
                                <div class="ml-auto my-auto mr-3 d-flex">
                                  <a v-if="($attrs.user.companies[0].userrole.name == 'Owner') || $attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id)" class="btn btn-outline-light btn-rounded ml-auto my-auto flex-grow-1 mr-2" href="javascript:;" @click="addSupplierContactPanel = false, editsuppliercontact = contact"><i class="px-2 bx bx-pencil"></i></a>
                                <a v-if="($attrs.user.companies[0].userrole.name == 'Owner') || $attrs.user.companies[0].permissions.delete.includes($attrs.active_menu.id)" class="btn btn-outline-light btn-rounded my-auto" href="javascript:;" ><i class="py-1 px-2 bx bx-trash-alt" @click="deletesuppliercontact = contact, deleteContactIndex = index, deleteContactPanel = true"></i></a>
                              </div>
              
                              </div>
                              </li>
                              

                              <!--<li class="list-group-item py-4 text-center" ><a class="btn btn-outline-light btn-rounded">Load More</a>
                              </li>-->
                            </ul>
                <ul class="list-group product-list w-100 no-shadow" v-if="(supplierContacts.length == 0) && (deleteContactPanel == false)">
                    <li class="list-group-item d-flex">
                      <i class="bx bx-warning"></i> No Addresses Assigned to this supplier
                    </li> 
                </ul>
                <div class="mt-2" v-if="($attrs.user.companies[0].userrole.name == 'Owner') || $attrs.user.companies[0].permissions.add.includes($attrs.active_menu.id)">
                 <a class="btn btn-outline-light w-100 btn-rounded" v-if="(addSupplierContactPanel == false) && (deleteContactPanel == false)" @click="editsuppliercontact.id =  null, addSupplierContactPanel = true"><i class="bx bx-home-alt"></i> Add New Contact <i class="mdi mdi-plus"></i></a>
                </div>
        </div>
        <div class="col-md-12">
      <transition name="slide-fadepanel">
       <b-card class="shadow-none" v-if="(addSupplierContactPanel == true) && (deleteContactPanel == false)">
          <h4 class="my-3">Add New Contact</h4>
          <b-overlay
                        id="overlay-background"
                        :show="loadingForm"
                        variant="white"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
                      >
         <b-form id="addsuppliercontactform" method="POST" enctype="multipart/form-data">
                   <input type="hidden" name="_token" :value="csrf">
           <b-row>
                     <b-col md="12">
                    <transition name="slide-error" mode="out-in">
                    <b-alert id="errors" variant="danger" :show="error_message"  dismissible @dismissed="error_message = !error_message">
                      <p class="font-size-14">Oops! Please correct the following errors in your submission</p>
                      <ul>
                        <li v-for="(error, key) in error_reponse">
                          {{error[0]}}
                        </li>
                      </ul>
                    </b-alert>
                    
                  </transition>
                  </b-col>
          </b-row>
       
           <b-col md="12">
                <b-form-group  label="Name">
                  <b-form-input type="text" name="name" v-model="newsuppliercontact.name" ></b-form-input>
                </b-form-group>
                <b-form-group  label="Position">
                  <b-form-input type="text" name="position" v-model="newsuppliercontact.position" ></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Email">
                  <b-form-input type="text" name="email" v-model="newsuppliercontact.email" ></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Phone">
                  <b-form-input type="text" name="phone" v-model="newsuppliercontact.phone" ></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Mobile">
                  <b-form-input type="text" name="mobile" v-model="newsuppliercontact.mobile" ></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Fax">
                  <b-form-input type="text" name="fax" v-model="newsuppliercontact.fax" ></b-form-input>
                </b-form-group>

               
              </b-col>
          <div class="d-flex flex-row mt-3">
          <b-button variant="success" class="w-md w-50 d-inline" @click="addSupplierContact()"> <i class=" bx bx-save"></i> Save</b-button>
          <b-button variant="danger" class="w-md w-50 d-inline" @click="addSupplierContactPanel = false"> <i class=" bx bx-save"></i> Cancel</b-button>
          </div>
        </b-form>
      </b-overlay>
        </b-card>
      </transition>
        </div>
        <div class="col-md-12">
      <transition name="slide-fadepanel">
       <b-card class="shadow-none" v-if="(editsuppliercontact.id !== null)  && (deleteContactPanel == false)">
          <h4 class="my-3">Edit Address</h4>
          <b-overlay
                        id="overlay-background"
                        :show="loadingForm"
                        variant="white"
                        opacity="0.85"
                        blur="1px"
                        rounded="md"
                      >
         <b-form id="updatesuppliercontactform" method="POST" enctype="multipart/form-data">
                   <input type="hidden" name="_token" :value="csrf">
           <b-row>
                     <b-col md="12">
                    <transition name="slide-error" mode="out-in">
                    <b-alert id="errors" variant="danger" :show="error_message"  dismissible @dismissed="error_message = !error_message">
                      <p class="font-size-14">Oops! Please correct the following errors in your submission</p>
                      <ul>
                        <li v-for="(error, key) in error_reponse">
                          {{error[0]}}
                        </li>
                      </ul>
                    </b-alert>
                    
                  </transition>
                  </b-col>
          </b-row>
       
           <b-col md="12">
                <b-form-group  label="Name">
                  <b-form-input type="text" name="name" v-model="editsuppliercontact.name" ></b-form-input>
                </b-form-group>
                <b-form-group  label="Position">
                  <b-form-input type="text" name="position" v-model="editsuppliercontact.position" ></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Email">
                  <b-form-input type="text" name="email" v-model="editsuppliercontact.email" ></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Phone">
                  <b-form-input type="text" name="phone" v-model="editsuppliercontact.phone" ></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Mobile">
                  <b-form-input type="text" name="mobile" v-model="editsuppliercontact.mobile" ></b-form-input>
                </b-form-group>
                <b-form-group class="mt-2" label="Fax">
                  <b-form-input type="text" name="fax" v-model="editsuppliercontact.fax" ></b-form-input>
                </b-form-group>

               
              </b-col>
          <div class="d-flex flex-row mt-3">
          <b-button variant="success" class="w-md w-50 d-inline" @click="updateSupplierContact()"> <i class=" bx bx-save"></i> Save</b-button>
          <b-button variant="danger" class="w-md w-50 d-inline" @click="editsuppliercontact = {id: null}"> <i class=" bx bx-save"></i> Cancel</b-button>
          </div>
        </b-form>
      </b-overlay>
        </b-card>
      </transition>
        </div>
      </div>
    </SideSlidePanel>
  </Layout>
</template>
