<script type="text/javascript">
	import PublicTopbar from "../components/public-topbar";
	import PublicNav from "../components/public-nav";
	import DefaultNavBar from "../components/widgets/page-builder/templates/actinolite/DefaultNavBar";
	import RightBar from "../components/right-bar";
	//import DefaultNav from "../components/widgets/page-builder/templates/actinolite/DefaultNav";
	import DefaultBreadcrumbs from "../components/widgets/page-builder/templates/actinolite/DefaultBreadcrumbs";
	import DefaultFooter from "../components/widgets/page-builder/templates/actinolite/DefaultFooter";
	import DefaultContent404 from "../components/widgets/page-builder/templates/actinolite/DefaultContent-404";
	import DefaultContentProduct from "../components/widgets/page-builder/templates/actinolite/DefaultContent-Product";
	import DefaultContentProducts from "../components/widgets/page-builder/templates/actinolite/DefaultContent-Products";
	import DefaultContentComingSoon from "../components/widgets/page-builder/templates/actinolite/DefaultContent-ComingSoon";
	import DefaultContentHome from "../components/widgets/page-builder/templates/actinolite/DefaultContent-Home";
	import DefaultContentCustomerAccount from "../components/widgets/page-builder/templates/actinolite/DefaultContent-CustomerAccount";
	import DefaultContentOrder from "../components/widgets/page-builder/templates/actinolite/DefaultContent-Order";
	import DefaultContentContact from "../components/widgets/page-builder/templates/actinolite/DefaultContent-Contact";
	import DefaultContentCategories from "../components/widgets/page-builder/templates/actinolite/DefaultContent-Categories";
	import DefaultContentCategory from "../components/widgets/page-builder/templates/actinolite/DefaultContent-Category";
	import PageBuilderProcessor from "../components/widgets/page-builder/PageBuilderProcessor"

	export default {
		name: 'Page',
		props: {
			domain: {
				type: Object
			},
			user: {
				type: Object
			}
		},
		data(){
			return {
				windowInnerWidth: 1200,
				contentWidth: 575,
				viewportSize: 'xl',
				loadDefaultHeader: false,
				loadDefaultContent: false,
				loadDefaultFooter: false,
				resource: '',
				loadingPage: true,
				page_loaded: false,
				page_raw_content: [],
				page: {
					header: null,
					content: null,
					footer: null,
					fonts: [],
				}
			}
		},
		components: {
		    PublicTopbar,
		    PublicNav,
		    DefaultNavBar,
		    DefaultBreadcrumbs,
		    DefaultFooter,
		    DefaultContent404,
		    DefaultContentComingSoon,
		    DefaultContentCustomerAccount,
		    DefaultContentOrder,
		    DefaultContentHome,
		    DefaultContentProduct,
		    DefaultContentProducts,
		    DefaultContentContact,
		    DefaultContentCategories,
		    DefaultContentCategory,
		    RightBar,
		    PageBuilderProcessor
		  },
		  mounted(){
		  	this.sidePanelMaxHeight = window.innerHeight - 80;
        	this.contentWidth = window.innerWidth;
		  	this.getPage();
		  },
		  created(){
		  	window.addEventListener("resize", this.windowResize);
		  },
		  destroyed() {
            window.removeEventListener("resize", this.windowResize);
    	  },
		  computed: {
		  	/*loadedFonts(){
		  		if(this.page.fonts.length > 0){
			  		for(var i = 0; i < this.page.fonts.length; i++){
			  			
				  			var h = document.getElementsByTagName('head').item(0);
				   			var l = document.createElement("link");
				   			var firstchild = h.childNodes[0];
							l.rel = "stylesheet"; 
							l.id = "loadedFonts-"+i;
							h.insertBefore(l, firstchild);
			   				document.getElementById('loadedFonts-'+i).href = 'https://fonts.googleapis.com/css2?family='+this.page.fonts[i].family.replaceAll(' ','+')+'&display=swap';
		   			}
	   			}
		  	},*/
		  	setViewportSize(){
				if(this.windowInnerWidth <= 576){
					this.viewportSize = 'xs';
					return 'xs';
				}else if(this.windowInnerWidth <= 769){
					this.viewportSize = 'sm';
					return 'sm';
				}else if(this.windowInnerWidth <= 992){
					this.viewportSize = 'md';
					return 'md';
				}else if(this.windowInnerWidth <= 1200){
					this.viewportSize = 'lg';					
					return 'lg';
				}else if(this.windowInnerWidth > 1200){
					this.viewportSize = 'xl';
					return 'xl';
				}
			},
		  },
		  methods: {
		  	loadFonts(){
		  		var loadedFonts = [];
		  		for(var i = 0; i < this.page.fonts.length; i++){
		  			var font = this.page.fonts[i].family.replaceAll(' ','+');
		  			 if (!loadedFonts.includes(font)) {
			  			var h = document.getElementsByTagName('head').item(0);
			   			var l = document.createElement("link");
			   			var firstchild = h.childNodes[0];
						l.rel = "stylesheet"; 
						l.id = "loadedFonts-"+i;
						h.insertBefore(l, firstchild);
		   				document.getElementById('loadedFonts-'+i).href = 'https://fonts.googleapis.com/css2?family='+this.page.fonts[i].family.replaceAll(' ','+')+'&display=swap';
		   				loadedFonts.push(font);
  					}
	   			}
		  	},
		  	windowResize(e) {
    			this.windowInnerWidth = window.innerWidth; 
          	},
		  	async getResourceWebtemplate(){
		  		 var route_array =  this.$route.path.split("/");
		  		 console.log(route_array);
		  		 var resource = '';
		  		 var type = '';
		  		 var slug = '';
                if((route_array[1] == 'product') || (route_array[1] == 'category') || (route_array[1] == 'collection') || (route_array[1] == 'shop')){
                    resource = route_array[1];
                    type = 'page';
    
                }else if((route_array[1] == 'products') || (route_array[1] == 'categories')){
                    if(route_array[1].includes('product')){
                        resource = 'product';
                    }else if(route_array[1].includes('category')){
                        resource = 'category';
                    }else{
                        resource = 'collection';
                    }
                    type = 'list';
                }else{
                    resource = 'page';
                    type = null;
                }
		  		axios.get('/website-api/resource/'+type+'/template/'+resource).then(response => {
		  			console.log(response.data);

		  			if((response.data.company.resource_webtemplate.header !== null)){
								this.page.header = response.data.company.resource_webtemplate.header;
							}
							if((response.data.company.resource_webtemplate.footer !== null)){
								this.page.footer = response.data.company.resource_webtemplate.footer;
							}
							if((response.data.company.resource_webtemplate.content !== null)){
								this.page.content = response.data.company.resource_webtemplate.content;
										
										var contentstring = JSON.stringify(response.data.company.resource_webtemplate.content);
								this.page_loaded = true;

							}
							if((response.data.company.resource_webtemplate.fonts !== null)){
								this.page.fonts = response.data.company.resource_webtemplate.fonts;
							}
		  			
		  		}).catch(error => {
		  			
		  		});
		  	},
		  	async getPage(){
			  		console.log(this.$route.path);
			  		if(this.$route.path == '/' || this.$route.path == '/shop'){
			  					if(this.domain.company.default_webpage !== null){
						  			this.page = this.domain.company.default_webpage;
						  			this.page_loaded = true;
					  			}else{
					  				this.page.content = null;
					  			}
			  					if(this.domain.company.default_webtemplate !== null){
									this.page.header = this.domain.company.default_webtemplate.header;
									this.page.footer = this.domain.company.default_webtemplate.footer;
									if(this.domain.company.default_webtemplate.fonts !== null){
										this.page.fonts = this.page.fonts.concat(this.domain.company.default_webtemplate.fonts);
									}
									//this.loadFonts();
									if(this.page.header == null){
										this.loadDefaultHeader = true;
									}
									if(this.page.footer == null){
										this.loadDefaultFooter = true;
									}
								}else{
									console.log('No Templates');
									this.page.header = null;
									this.page.footer = null;
									this.loadDefaultHeader = true;
									this.loadDefaultFooter = true;
									//this.loadFonts();
								}
								

								if(this.domain.company.default_webtheme !== null){
									var myEle = document.getElementById("themeStylesheet");
			  						if(!myEle){
									var h = document.getElementsByTagName('head').item(0);
									var s = document.createElement("link");
									s.rel = "stylesheet"; 
									s.id = "themeStylesheet";
									s.href = 'https://'+this.domain.company.nickname+'.s3.eu-west-2.amazonaws.com/public/web-themes/'+this.domain.company.default_webtheme.uid+'.css';
									//s.href = 'https://'+this.domain.company.nickname+'.eu-west-2.amazonaws.com/'+this.domain.company.uid+'/web-themes/'+this.domain.company.default_webtheme.uid+'.css';
									h.appendChild(s);

									var b = document.getElementsByTagName('body').item(0);
									b.id = "theme";
									}
								}
			  			let self = this;
			  				setTimeout(function(){
					            self.loadingPage = false;
					          }, 500);
			  		}else{
			  		var routearray = this.$route.path.substring(1).split('/');
			  		console.log(routearray);

			  		if((routearray[0] !== 'product') && (routearray[0] !== 'category')){
			  			await axios.get('/website-api/page/'+routearray[routearray.length - 1]).then(response => {
							//console.log(response.data);
								this.page = response.data;
								this.page_raw_content = response.data.content;
								if(response.data.template !== null){
									if((response.data.template.header !== null)){
									this.page.header = response.data.template.header;
									}
									if((response.data.template.footer !== null)){
									this.page.footer = response.data.template.footer;
									}
									if((response.data.template.content !== null)){
										this.page.content = response.data.template.content;
										
										var contentstring = JSON.stringify(response.data.template.content);

									}
									if((response.data.template.fonts !== null)){
										this.page.fonts = response.data.template.fonts;
										//this.loadFonts();
									}
								}else if((this.domain.company.default_webtemplate !== null) && (this.domain.company.default_webtemplate > 0)){
									this.page.header = this.domain.company.default_webtemplate.header;
									this.page.footer = this.domain.company.default_webtemplate.footer;
									if(this.domain.company.default_webtemplate.fonts !== null){
										this.page.fonts = this.page.fonts.concat(this.domain.company.default_webtemplate.fonts);
									}

									
								}else{
									console.log('No Templates');
									this.page.header = null;
									this.page.footer = null;
									
								}

								if(reponse.data.theme !== null){
									var myEle = document.getElementById("themeStylesheet");
			  						if(!myEle){
									var h = document.getElementsByTagName('head').item(0);
									var s = document.createElement("link");
									s.rel = "stylesheet"; 
									s.id = "themeStylesheet";
									s.href = 'https://'+this.domain.company.nickname+'.s3.eu-west-2.amazonaws.com/public/web-themes/'+response.data.theme.uid+'.css';
									//s.href = 'https://'+this.domain.company.nickname+'.eu-west-2.amazonaws.com/'+this.domain.company.uid+'/web-themes/'+response.data.theme.uid+'.css';
									h.appendChild(s);

									var b = document.getElementsByTagName('body').item(0);
									b.id = "theme";
									}
								}else if(this.domain.company.default_webtheme !== null){
									var myEle = document.getElementById("themeStylesheet");
			  						if(!myEle){
									var h = document.getElementsByTagName('head').item(0);
									var s = document.createElement("link");
									s.rel = "stylesheet"; 
									s.id = "themeStylesheet";
									s.href = 'https://'+this.domain.company.nickname+'.s3.eu-west-2.amazonaws.com/public/web-themes/'+this.domain.company.default_webtheme.uid+'.css';
									//s.href = 'https://'+this.domain.company.nickname+'.eu-west-2.amazonaws.com/'+this.domain.company.uid+'/web-themes/'+this.domain.company.default_webtheme.uid+'.css';
									h.appendChild(s);

									var b = document.getElementsByTagName('body').item(0);
									b.id = "theme";
									}
								}
								this.page_loaded = true;
								let self = this;
					          setTimeout(function(){
					            self.loadingPage = false;
					            	if(self.page.header == null){
										self.loadDefaultHeader = true;
									}
									if(self.page.footer == null){
										self.loadDefaultFooter = true;
									}
					          }, 500);
			  			}).catch(error => {
			  					
			  				
			  					if(this.domain.company.default_webtemplate !== null){
									this.page.header = this.domain.company.default_webtemplate.header;
									this.page.footer = this.domain.company.default_webtemplate.footer;
									if(this.domain.company.default_webtemplate.fonts !== null){
										this.page.fonts = this.page.fonts.concat(this.domain.company.default_webtemplate.fonts);
									}
									//this.loadFonts();
									
								}
								

								if(this.domain.company.default_webtheme !== null){
									var myEle = document.getElementById("themeStylesheet");
			  						if(!myEle){
									var h = document.getElementsByTagName('head').item(0);
									var s = document.createElement("link");
									s.rel = "stylesheet"; 
									s.id = "themeStylesheet";
									s.href = 'https://'+this.domain.company.nickname+'.s3.eu-west-2.amazonaws.com/public/web-themes/'+this.domain.company.default_webtheme.uid+'.css';
									//s.href = 'https://'+this.domain.company.nickname+'.eu-west-2.amazonaws.com/'+this.domain.company.uid+'/web-themes/'+this.domain.company.default_webtheme.uid+'.css';
									h.appendChild(s);

									var b = document.getElementsByTagName('body').item(0);
									b.id = "theme";
									}

								}
								if(!error.response){
									this.page_loaded = true;
								}else{
									this.page_loaded = false;
								}
			  			let self = this;
			  				setTimeout(function(){
					            self.loadingPage = false;
					            	if(self.page.header == null){
										self.loadDefaultHeader = true;
									}
									if(self.page.footer == null){
										self.loadDefaultFooter = true;
									}
					          }, 500);
			  	
			  			});
			  		}else{
			  			this.resource = routearray[0];

			  			if(this.domain.company.default_webtheme !== null){
			  				var myEle = document.getElementById("themeStylesheet");
			  				if(!myEle){
									var h = document.getElementsByTagName('head').item(0);
									var s = document.createElement("link");
									s.rel = "stylesheet"; 
									s.id = "themeStylesheet";
									s.href = 'https://'+this.domain.company.nickname+'.s3.eu-west-2.amazonaws.com/public/web-themes/'+this.domain.company.default_webtheme.uid+'.css';
									//s.href = 'https://'+this.domain.company.nickname+'.eu-west-2.amazonaws.com/'+this.domain.company.uid+'/web-themes/'+this.domain.company.default_webtheme.uid+'.css';
									h.appendChild(s);

									var b = document.getElementsByTagName('body').item(0);
									b.id = "theme";
								}
								}
			  			//this.page.content = null;

						if(this.domain.company.default_webtemplate !== null){
									this.page.header = this.domain.company.default_webtemplate.header;
									this.page.footer = this.domain.company.default_webtemplate.footer;
									if(this.domain.company.default_webtemplate.fonts !== null){
										this.page.fonts = this.page.fonts.concat(this.domain.company.default_webtemplate.fonts);
									}
									//this.loadFonts();
									/*if(this.page.header == null){
										this.loadDefaultHeader = true;
									}
									if(this.page.footer == null){
										this.loadDefaultFooter = true;
									}*/
								}
						
						const resourceTemp = await this.getResourceWebtemplate();

						if(this.domain.company.resource_webtemplate !== null){
			  				if((this.domain.company.resource_webtemplate.header !== null)){
								this.page.header = this.domain.company.resource_webtemplate.header;
							}
							if((this.domain.company.resource_webtemplate.footer !== null)){
								this.page.footer = this.domain.company.resource_webtemplate.footer;
							}
							if((this.domain.company.resource_webtemplate.fonts !== null)){
								this.page.fonts = this.domain.company.resource_webtemplate.fonts;
								//this.loadFonts();
							}
							if((this.domain.company.resource_webtemplate.content !== null)){
								this.page.content = this.domain.company.resource_webtemplate.content;
										
										var contentstring = JSON.stringify(this.domain.company.resource_webtemplate.content);
								this.page_loaded = true;

							}
			  			}else{
			  				this.getResourceWebtemplate();
			  				
			  				
			  				//this.page_loaded = false;
			  			}

			  			let self = this;
			  				setTimeout(function(){
					            self.loadingPage = false;

					            if(self.page.header == null){
										self.loadDefaultHeader = true;
									}
									if(self.page.footer == null){
										self.loadDefaultFooter = true;
									}
					          }, 500);
			  		}
		  		}
		  		this.loadFonts();
		  	}
		  },
		  watch: {
        $route() {
        	this.$nextTick(() => {
        		 this.loadingPage = true;
        		 this.page_loaded = false;
        		 this.page = {
						header: null,
						content: null,
						footer: null,
					};
        		 return this.getPage();

        		});
        	}
        }
	}
</script>
<template>
	<div>
		<PageBuilderProcessor v-if="(page.header !== null)"  :page="page.header" :content="page.header[viewportSize]" :company="domain.company" :user="user" :domain="domain" :exchange_rates="$attrs.exchange_rates"  :currencies="$attrs.currencies" :selected_currency="$attrs.selected_currency" :selected_currency_symbol="$attrs.selected_currency_symbol" :viewPortSize="setViewportSize" :windowInnerWidth="windowInnerWidth"></PageBuilderProcessor>
		<div v-else-if="loadDefaultHeader == true">
	 <DefaultNavBar v-if="(page.header == null)" :company="domain.company" :user="user" :menu="''" :domain="domain" :exchange_rates="$attrs.exchange_rates"  :currencies="$attrs.currencies" :selected_currency="$attrs.selected_currency" :selected_currency_symbol="$attrs.selected_currency_symbol" />
	 
	 <!--<PublicTopbar v-if="(page.header == null)" :domain="domain" />
      <PublicNav v-if="(page.header == null)" ></PublicNav>-->
		
		<DefaultBreadcrumbs  v-if="((page.header == null)) && ($route.path !== '/')"></DefaultBreadcrumbs>
		</div>
      <!-- ============================================================== -->
      <!-- Start right Content here -->
      <!-- ============================================================== -->
      	<div class="main-content">
        	<div class="page-content bg-white p-0" style="min-height: 100vh;">  <!-- container-fluid -->
        		<b-overlay
        			:show="loadingPage" variant="white" opacity="1" nowrap>
						<PageBuilderProcessor :viewPortSize="setViewportSize" :windowInnerWidth="windowInnerWidth" :page="page.content" :content="page.content[viewportSize]" v-if="page_loaded == true && ($route.path.substring(1).split('/')[0] !== 'account')" :company="domain.company" :user="user" :domain="domain" :inner_content="page_raw_content" :resource="resource" :exchange_rates="$attrs.exchange_rates"  :currencies="$attrs.currencies" :selected_currency="$attrs.selected_currency" :selected_currency_symbol="$attrs.selected_currency_symbol"></PageBuilderProcessor>
						<DefaultContentContact v-else-if="(page.content == null) && ($route.path == ('/contact' || '/contact/'))" :company="domain.company" :exchange_rates="$attrs.exchange_rates"  :currencies="$attrs.currencies" :selected_currency="$attrs.selected_currency" :selected_currency_symbol="$attrs.selected_currency_symbol"></DefaultContentContact>
						<DefaultContentCategory v-else-if="(page.content == null) && ($route.path.substring(1).split('/')[0] == 'category')" :company="domain.company" :resource="'category'" :exchange_rates="$attrs.exchange_rates"  :currencies="$attrs.currencies" :selected_currency="$attrs.selected_currency" :selected_currency_symbol="$attrs.selected_currency_symbol"></DefaultContentCategory>
						<DefaultContentCategory v-else-if="(page.content == null) && ($route.path.substring(1).split('/')[0] == 'collection')" :company="domain.company" :resource="'collection'" :exchange_rates="$attrs.exchange_rates"  :currencies="$attrs.currencies" :selected_currency="$attrs.selected_currency" :selected_currency_symbol="$attrs.selected_currency_symbol"></DefaultContentCategory>
						<DefaultContentProduct v-else-if="(page.content == null) && ($route.path.substring(1).split('/')[0] == 'product')" :company="domain.company" :user="user" :resource="'product'" :exchange_rates="$attrs.exchange_rates"  :currencies="$attrs.currencies" :selected_currency="$attrs.selected_currency" :selected_currency_symbol="$attrs.selected_currency_symbol"></DefaultContentProduct>
						<DefaultContentProducts v-else-if="(page.content == null) && ($route.path.substring(1).split('/')[0] == 'shop')" :company="domain.company" :resource="'products'" :exchange_rates="$attrs.exchange_rates"  :currencies="$attrs.currencies" :selected_currency="$attrs.selected_currency" :selected_currency_symbol="$attrs.selected_currency_symbol"></DefaultContentProducts>
						
						
						<DefaultContentHome v-else-if="(page.content == null) && ($route.path == '/')" :company="domain.company" :resource="'categories'" :exchange_rates="$attrs.exchange_rates"  :currencies="$attrs.currencies" :selected_currency="$attrs.selected_currency" :selected_currency_symbol="$attrs.selected_currency_symbol" ></DefaultContentHome>
						<DefaultContentCategories v-else-if="(page.content == null) && ($route.path == ('/categories' || '/categories/'))" :company="domain.company" :resource="'categories'" :exchange_rates="$attrs.exchange_rates"  :currencies="$attrs.currencies" :selected_currency="$attrs.selected_currency" :selected_currency_symbol="$attrs.selected_currency_symbol"></DefaultContentCategories>
						<DefaultContentCategories v-else-if="(page.content == null) && ($route.path == ('/collections' || '/collections/'))" :company="domain.company" :resource="'collections'" :exchange_rates="$attrs.exchange_rates"  :currencies="$attrs.currencies" :selected_currency="$attrs.selected_currency" :selected_currency_symbol="$attrs.selected_currency_symbol"></DefaultContentCategories>
						
						<DefaultContentComingSoon v-else-if="(page.content == null) && ($route.path == ('/services' || '/services/'))" :company="domain.company"></DefaultContentComingSoon>
						<DefaultContentCustomerAccount v-else-if="($route.path.substring(1).split('/')[0] == 'account')" :company="domain.company" :user="user" :exchange_rates="$attrs.exchange_rates"  :currencies="$attrs.currencies" :selected_currency="$attrs.selected_currency" :selected_currency_symbol="$attrs.selected_currency_symbol"></DefaultContentCustomerAccount>
						<DefaultContentOrder v-else-if="($route.path.substring(1).split('/')[0] == 'order')" :company="domain.company" :domain="domain" :user="user" :exchange_rates="$attrs.exchange_rates"  :currencies="$attrs.currencies" :selected_currency="$attrs.selected_currency" :selected_currency_symbol="$attrs.selected_currency_symbol"></DefaultContentOrder>

						<DefaultContent404 v-else></DefaultContent404>
					</b-overlay>
        	</div>
        <!-- End Page-content -->
        
        <DefaultFooter v-if="page.footer == null" :company="domain.company" :user="user" />
				<PageBuilderProcessor v-else :domain="domain" :page="page.footer" :content="page.footer[viewportSize]" :company="domain.company" :user="user" :exchange_rates="$attrs.exchange_rates"  :currencies="$attrs.currencies" :selected_currency="$attrs.selected_currency" :selected_currency_symbol="$attrs.selected_currency_symbol" :viewPortSize="setViewportSize" :windowInnerWidth="windowInnerWidth" />
				
      </div>
	</div>
	
	
</template>

<style type="text/css">
	
	body{
		overflow-x: hidden;
	}
	body[data-layout=horizontal] .navbar-header{
		max-width: 100%;
	}
</style>
