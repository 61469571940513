<script>
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import vue2Dropzone from 'vue2-dropzone'
import Multiselect from 'vue-multiselect'
import SideSlidePanel from "../../components/widgets/SideSlidePanel";
import Layout from '../../layouts/main'
import PageHeader from '../../components/page-header'
import FilterListTree from "../../components/widgets/FilterListTree";
//import ProductDetails from "../../components/widgets/ecommerce/ProductDetails";
//import ProductPreview from "../../components/widgets/ecommerce/ProductPreview";
import ProductContent from "../../components/widgets/admin/product/ProductContent";
import FilterProductBrands from "../../components/widgets/FilterProductBrands";
import SelectCompanyProductCategory from "../../components/widgets/SelectCompanyProductCategory"
import SelectCompanyProductBrand from "../../components/widgets/SelectCompanyProductBrand"
import SelectCompanyProductCategories from "../../components/widgets/SelectCompanyProductCategories"
import SelectCompanyProductAttribute from "../../components/widgets/SelectCompanyProductAttribute"
import SelectCompanyProductAttributeValue from "../../components/widgets/SelectCompanyProductAttributeValue"
import CompanyProductSearch from "../../components/widgets/CompanyProductSearch"
import CompanyProductList from "../../components/widgets/CompanyProductList"
import ImageCropper from "../../components/widgets/ImageCropper";
import ProductLabel from "../../components/widgets/admin/print/ProductLabel";
import simplebar from "simplebar-vue";
import VueBarcode from '@chenfengyuan/vue-barcode';
import DateTime from 'luxon/src/datetime.js';
import OrderList from '../../components/widgets/admin/list/OrderList'
import ReviewCard from '../../components/widgets/forms/ReviewCard'
import AccessRestricted from "../../views/utility/access-restricted";
import VueSlider from 'vue-slider-component';
import CategorySelectButtons from './category-select-buttons'; 
import { cutClarityData } from "../../clarity-cuts";
import { EventBus } from "../../app";

/**
 * Add-product component
 */
export default {
  components: { vueDropzone: vue2Dropzone, Multiselect, Layout, PageHeader, ckeditor: CKEditor.component, FilterListTree, FilterProductBrands, SelectCompanyProductCategory, SelectCompanyProductBrand, SelectCompanyProductCategories, SelectCompanyProductAttribute, SelectCompanyProductAttributeValue, ImageCropper, SideSlidePanel, simplebar, VueBarcode, ProductLabel, CompanyProductSearch, CompanyProductList, DateTime, ProductContent, OrderList, ReviewCard, AccessRestricted, VueSlider, CategorySelectButtons },
  data() {
    return {
      custom_field: {
        type: '',
        name: '',
        options: [],
        value: '',
      },
      custom_fieldRaw: {
        type: '',
        name: '',
        options: [],
        value: '',
      },
      variantFormLoading: false,
      clarityCuts: cutClarityData,
      defaultPriceRange: [0, 10000],
      seleted_fieldset: '',
      selected_tax_rule: null,
      loadingCustomFields: false,
      loadingEditAttribute: false,
      loadingEditAttributeProgress: 50,
      orderprevioustotal: 0.00,
      chartDisplay: 'by-date',
      orderDataLoading: true,
      ordersstartdate: '', 
      ordersenddate: '',
      toplistingsdata: [],
      topsellersdata: [],
      productstats: [],
      addManualQty: 0,
      syncAllQty: 0,
      push_quantity: 0,
      selected_integration: '',
      selected_product: '',
      selected_channel_products: '',
      ecommerce_channels_settings: [],
      marketplace_channels_settings: [],
      all_active_commerce_channels: [],
      active_ecommerce_integrations: [],
      loadingIntegrationDetails: false,
      loadingIntegrations: false,
      loadingActiveIntegrations: false,
      loadingProducts: false,
      products_loading_progress: 0,
      products_loading_text: 'Channel Products Loading...',
      selected_integration: '',
      selected_integration_uid: '',
      channel_product_details: {},
      channel_product_details_id: '',
      all_commerce_channels: [],
      reviewsFilteredBy: '',
      toplistingsdata: [],
      topsellersdata: [],
      product_orders: '',
      productslimit: '',
      productsortby: '',
      productsorder: '',
      productsearch: '',
      shopProducts: '',
      productsCurrentPage: 1,
      selectedQrType: 'pos',
      loadingForm: false,
      loadingImgForm: false,
      refs: 'AddProduct',
      name: 'AddProduct',
      title: 'Add Product',
      items: [
        {
          text: 'Stock',
          href: '/',
        },
        {
          text: 'Products',
          href: '/stock/products',
        },
        {
          text: 'Add Product',
          active: true,
        },
      ],
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        timeout: 10000,
        thumbnailHeight: 150,
        thumbnailWidth: 150,
        autoProcessQueue: false,
      },
      updateDropzoneOptions: {
        url: '',
        timeout: 10000,
        thumbnailHeight: 150,
        thumbnailWidth: 150,
        acceptedFiles: 'image/*',
        autoProcessQueue: true,
        parallelUploads: 1,
        headers: null,
        retryChunks: true,
        maxFiles: 10,
      },
      updateVideoDropzoneOptions: {
        url: '',
        timeout: 10000,
        thumbnailHeight: 150,
        thumbnailWidth: 150,
        autoProcessQueue: true,
        acceptedFiles: '.mp4,.mov,.webm',
        parallelUploads: 1,
        headers: null,
        retryChunks: true,
         maxFiles: 10,
      },
      showPurchaseResults: false,
      storageurl: 'https://'+this.$attrs.company.nickname+'.s3.eu-west-2.amazonaws.com',
      editImageUrl: '',
      editImage: false,
      editable: true,
      editcaption: -1,
      editcaptionrawtext: '',
      showDetails: false, 
      success_message: false,
      success_messagetext: 'Congratulations, your action was successful',
      editor: ClassicEditor,
      editorData:
        "<h3>Hello World!</h3><h5><b>This is an simple editable area.</b></h5>",
      content: "<h1>Some initial content</h1>",
      value: null,
      value1: null,
      view: 'basic-info',
      isFixed: false,
      newFeature: false,
      windowTop: '',
      windowInnerHeight: '',
      purchaselimit: 25,
      shopslimit: 25,
      shopscurrentPage: 1,
      shopsearch: '',
      shop_type_id: '',
      shopsortby: 'id',
      shoporder: 'desc',
      error_reponse: '',
      error_message: false,
      purchase: null,
      measurement_unit_value: '',
      shippingoptions: [],
      all_brands: [],
      all_tax_rules: [],
      all_attributes: [],
      attribute_values: [],
      active_collections: [],
      active_categories: [],
      active_public_categories: [],
      active_trade_categories: [],
      categories: [],
      public_categories: [],
      trade_categories: [],
      selected_customer_groups: [],
      selected_customer_type: '',
      product_brand_id: null,
      product_type_id: 0,
      cost_price: 0,
      trade_price: 0,
      sales_price: 0,
      inc_tax_price: 0,
      import_tax: 0,
      export_tax: 0,
      active_tax_rule: '',
      active_tax_percentage: 0.00,  
      carnet_price: 0,
      shopsData: [],
      carnet_weight: 0,
      profit_margin: 0,
      calculated_cost_price: 0,
      calculated_tax_duties: 0,
      bulkquantity: 0,
      active_submenu: 'product-status',
      product_attributes: [],
      product_variants: [],
      product_variantForm: '',
      product_combinationsForm: {
        variants: [],
        quantity: '0',
        price_difference: '0',
        product_id: 0,
      },
      channels_excluded_export_keys: ['subName', 'productType', 'sellOutStock'],
      gemesys_excluded_export_keys: ['id', 'uid', 'company_id', 'url_rewrite', 'trade_description', 'meta_keywords', 'feature_values_id', 'attribute_values_id', 'cost_price', 'trade_price', 'taxrule_id', 'created_at', 'updated_at', 'default_category_id', 'pubmarket_category_id', 'trademarket_category_id', 'brand_id', 'company_supplier_id', 'marketplace_brand_id', 'bulk_price_id', 'carnet_price', 'carnet_weight', 'import_tax', 'export_tax', 'company_purchase_order_id', 'featured', 'public_featured', 'trade_featured', 'public', 'trade', 'approved', 'product_type_id', 'search_keywords', 'barcode', 'condition', 'condition_custom_text', 'condition_notes', 'reviews_avg_rating', 'purchase', 'brand', 'company_defaultcategory', 'public_marketplace_defaultcategory', 'trade_marketplace_defaultcategory', 'company_categories', 'public_marketplace_categories', 'trade_marketplace_categories', 'collections', 'related_products', 'combinations', 'attributes', 'variants', 'tax_rule', 'reviews', 'listings', 'product_channels'],
      channel_product_update_keys: [],
      channel_product: {
      sku:"",
      name:null,
      subName:null,
      description:null,
      currency:null,
      productType:null,
      quantity:null,
      price:null,
      //dimensions:{
      //  width:null,
      //  height:null,
      //  length:null,
      //  weight:null
      //},
      //units:{
      //  width:null,
      //  height:null,
      //  length:null,
      //  weight:null
      //},
      //tags:[],
      status:{
        //id:null,
        //visibility:"visible",
        active:"active",
        //status:"publish",
        //ecartapiId:"1",
        //ecartapi:"active",
        //hasOptions:null
      },
      //images:[
        //{
        // id:null, 
        // url:null,
        // variantIds:[],
         //ecartapiUrl: null
        //}
      //],
      //imageUrl:null,
      //condition:null,
      //link:null,
      //dates:{},
      vendor:null,
      sellOutStock:true
      },
      product_bulk_pricing: [],
      product_condition: [],
      product_shipping_options: [],
      selected_attribute: {id: 0, name: 'Select Attribute', image: ''},
      selected_value: {id: 0, name: 'Select Value', image: ''},
      selected_measurement_unit: '',
      selected_measurement_value: '',
      selected_cut: '',
      selected_clarity: '',
      selected_colour: '',
      active_tax_rule: 0,
      searchbrand: '',
      purchasesearch:'',
      purchase_orders: [],
      searchresult: '',
      qrData: {
        type: 'product',
        reference: '',
        uid: '',
        id: '',
      },
      productData: {
          id: null,
          uid: null,
          company_id: null,
          name:"",
          short_description:"",
          long_description:"",
          trade_description: "",
          images:[],
          cover_image: null,
          videos:[],
          tryon: {
            url: '',
            service: '',
          },
          cover_video: null,
          feature_values_id:null,
          attribute_values_id:null,
          cost_price: 0.00,
          trade_price: 0.00,
          sales_price: 0.00,
          taxrule_id:1,
          reference:"",
          created_at:"",
          updated_at:"",
          company_defaultcategory: null,
          trade_marketplace_defaultcategory: null,
          public_marketplace_defaultcategory: null,
          default_category_id:null,
          pubmarket_category_id:null,
          trademarket_category_id:null,
          company_categories: [],
          trade_marketplace_categories: [],
          public_marketplace_categories: [],
          brand_id:10,
          brand: [],
          variants: [],
          combinations: [],
          condition: null, 
          condition_custom_text: '',
          condition_notes: '',
          company_purchase_order_id: null, 
          company_supplier_id:0,
          marketplace_brand_id:null,
          bulk_price_id:null,
          carnet_price:null,
          carnet_weight:null,
          import_tax:null,
          export_tax:null,
          purchase: null,
          featured:0,
          public:1,
          trade:1,
          active:1,
          price_range: [0,100],
          poa: false,
          product_type_id:0,
          total_qty:0,
          min_sale_qty: 0,
          max_sale_qty: 0,
          low_stock_level: 0,
          attributes: [],
          shipping_options: [],
          product_channels: [],
          collections: [],
          categories: [],
          trade_market_categories: [],
          public_market_categories: [],
          listings: [],
          search_keywords: [],
          meta_keywords: [],
          custom_fields: {
            fields: []
          },
      },
      brandsearch_results: [],
      collections: [],
      col_limit: 25,
      col_search: '',
      saved_field_sets: '',
      save_fieldset: {
        id: null,
        name: '',
        fields: [],
      },
      listing_types: [],
      colcurrentPage: 1,
      brandresults_start: 0,
      brandresults_end: 5,
      product_attribute_success: null,
      limits: [
            {value: 25, text: "25"},
            {value: 50, text: "50"},
            {value: 100, text: "100"},
          ],
      quantity_lang:{
            bulk:{
              qtytooltip: 'Add quantity where discount starts to apply. For example if you enter quantity of 10, discounts will apply on orders of 10 and above.'
            }
          },
      conditions: [
        'New',
        'Bespoke',
        'Used',
        'Antique',
        'Refurbished',
        'Custom',
      ],
      options: [
        'Alaska',
        'Hawaii',
        'California',
        'Nevada',
        'Oregon',
        'Washington',
        'Arizona',
        'Colorado',
        'Idaho',
        'Montana',
        'Nebraska',
        'New Mexico',
        'North Dakota',
        'Utah',
        'Wyoming',
        'Alabama',
        'Arkansas',
        'Illinois',
        'Iowa',
      ],
      donutChart: {
            series: [0, 0, 0, 0, 0],
            chartOptions: {
                chart: {
                    type: "donut",
                    height: 240
                },
                labels: ["My Website", "Point of Sale", "Direct Input", "Public Market", "Trade Market"],
                colors: ["rgba(252, 184, 163, 0.4)", "#FEA100", "#f46a6a", "#5E3BB0", "#2a307d"],
                legend: {
                    position: 'bottom',
                    show:false
                },
                responsive: [{
                  breakpoint: 480,
                  options: {
                    chart: {
                      width: 200
                    },
                    legend: {
                      show: false
                    }
                  }
                }],
                plotOptions: {
                    pie: {
                        donut: {
                            size: "70%"
                        }
                    }
                }
            }
        },
      lineChart:{
            series: [
                {
                    name: "Earrnings",
                    type: 'line',
                    data: []
                },
                {
                    name: "OrderValue",
                    type: 'column',
                    data: []
                }
            ],
            chartOptions: {
                chart: {
                    height: 320,
                    type: "line",
                    toolbar: "false",
                    dropShadow: {
                        enabled: true,
                        color: "#000",
                        top: 18,
                        left: 7,
                        blur: 8,
                        opacity: 0.2
                    }
                },
                dataLabels: {
                    enabled: false
                },
                colors: ["#5E3BB0", "rgba(52, 195, 143, 0.8)"],

                stroke: {
                    curve: "smooth",
                    width: 3
                },
                xaxis: {
                    type: "datetime",
                    labels: {
                        show: false
                    },
                    categories: [],
                },
                yaxis: [{
                    title: {
                        text: "Total Earnings",
                        style: {
                          color: "#00E396"
                        }
                      },
                      labels: {
                        formatter: function (value) {
                          return value.toFixed(2);
                        }
                      },
                },
                {
                    seriesName: "OrderValue",
                    opposite: true,
                    title: {
                        text: "Order Value",
                        style: {
                          color: "#00E396"
                        }
                      },
                      labels: {
                        formatter: function (value) {
                          return value.toFixed(2);
                        }
                      },
                }
                ]
            }
        },
    }
  },
  mounted(){
    this.ordersstartdate = DateTime.local().minus({months: 1}).toISODate();
      this.ordersenddate = DateTime.local().plus({days: 1}).toISODate();
    var url = window.location.href;
    var hash = window.location.hash;
    if(url == 'http://'+this.$attrs.company.nickname+'.gemesys.co.uk/stock/product'){
      window.location.href = 'http://'+this.$attrs.company.nickname+'.gemesys.co.uk/stock/product/add#basic-info'
    }
    if(hash !== '#'){
    this.view = hash.replace('#','');
    }
      if(this.$attrs.id !== ('' || 'add')){
      this.getProduct(this.$attrs.id);
    }
    
    
    if(url.search("edit=true") > 0){
      this.editable = true;
    }
    if(url.search("edit=false") > 0){
      this.editable = false;
    }
    this.getAllAttributes();
    this.getListingTypes();
    this.getShippingOptions();
    this.getShops();
    if(this.view == 'categories-collections'){
      this.getCategories('company');
      this.getCategories('public');
      this.getCategories('trade');
      this.getCollections();
    }
    if(this.view == 'listings'){
      this.getCommerceChannels();
    }
    if(this.view == 'integrations'){
      this.getCommerceChannels();
      this.fetchChannelsIntegrationsDetails('channels-api');
    }
    if(this.view == 'custom'){
      this.getCustomFieldSets();
    }
    let self = this;
       EventBus.$on('update-category', function(payload){
        console.log(payload);
      self.updateActiveCategories(payload[0], payload[1]);
    });
    window.addEventListener('hashchange', function() {
      console.log(window.location.hash);
      console.log(self.view);
      self.view = window.location.hash.replace('#', '');

    }, false);
 
    //window.addEventListener("scroll", this.onScroll);
  },
  beforeMount(){
    if(this.$attrs.user.companies[0].permissions == null) {
        //this.$attrs.user.companies[0].permissions = {add: [], edit: [], read: [], view: [], delete: []};
      }
  },

  methods:{
      currencyConversion(from, to){
        axios.post('/api/currency/conversion/'+from+'/'+to+'').then(response => {
          console.log(response.data);
        }).catch(error => {

        });
      },
      loadFieldset(){
        this.loadingCustomFields = true;
        if(this.productData.custom_fields == null){
          this.productData.custom_fields = [];
          this.productData.custom_fields['fields'] = JSON.parse(JSON.stringify(this.selected_fieldset.fields));
        }else if(Object.keys(this.productData.custom_fields).includes('fields') && this.productData.custom_fields["fields"].length == 0){
           this.productData.custom_fields['fields'] = JSON.parse(JSON.stringify(this.selected_fieldset.fields));
        }else if(this.productData.custom_fields["fields"].length > 0){
           this.productData.custom_fields['fields'].push(JSON.parse(JSON.stringify(this.selected_fieldset.fields)));
        }

        let self = this;
              setTimeout(function(){
                    self.loadingCustomFields = false;
              }, 800);
      },
      getCustomFieldSets(){
        axios.get('/api/company/product/customfield-sets', {headers: {'Authorization' : 'Bearer ' +this.$attrs.user.api_token}}).then(response => {
          this.saved_field_sets = response.data;
        }).catch(error => {

        });
      },
      saveFieldSet(fieldData){
        console.log(fieldData);
        var fieldSet = fieldData.fields;
        for(var i = 0; i < fieldSet.length; i++){
          fieldSet[i]['value'] = ''; 
        }
        fieldData.fields = fieldSet;
        axios.post('/api/company/product/save-field-set', fieldData, {headers: {'Authorization' : 'Bearer ' +this.$attrs.user.api_token}}).then(response => {
          this.saved_field_sets = response.data;
        }).catch(error => {

        });
      },
      deleteFieldSet(){
        axios.post('/api/company/product/delete-field-set', fieldSet, {headers: {'Authorization' : 'Bearer ' +this.$attrs.user.api_token}}).then(response => {
          this.saved_field_sets = response.data;
        }).catch(error => {

        });
      },
      filterSelectedCategories(id){
            
              var exists = this.categories.findIndex(item => item == id);
              console.log(exists);
              if(exists == -1){
                this.categories.push(id);
              }else{
                this.categories.splice(exists, 1);
              }
              //this.createQueryString();
              this.productQuery();
      },
    updateDates(minus_months){
        this.ordersstartdate = DateTime.local().minus({months: minus_months}).toISODate();
      this.ordersenddate = DateTime.local().plus({days: 1}).toISODate();
      //this.lineChart.chartOptions.xaxis.min = this.ordersstartdate;
      //this.lineChart.chartOptions.xaxis.max = this.ordersenddate;
      this.getProductOrderStats(this.productData.id);
      },
    productPostExport(response, items, isMultiple, pass){
      this.productLink(response, items, isMultiple);
      if(isMultiple == false){
        if(items["cover_image"] !== null){
        this.productImagesExport(response, items, isMultiple, pass);
        }
      }
      let self = this;
              setTimeout(function(){
                    self.products_loading_text = "Product Exported";
                    self.products_loading_progress = 100;
                    self.loadingProducts = false;
                    self.all_products = '';
                  //return self.getProducts();
              }, 800);
      
    },
    productPostUpdateExport(response, items, isMultiple, pass){
      this.productLink(response, items, isMultiple);
      if(isMultiple == false){
        if(items["cover_image"] !== null){
        //this.productImagesExport(response, items, isMultiple, pass);
        }
      }
      let self = this;
              setTimeout(function(){
                    self.products_loading_text = "Product Exported";
                    self.products_loading_progress = 100;
                    self.loadingProducts = false;
                    self.all_products = '';
                  //return self.getProducts();
              }, 800);
      
    },
    async productExportUpdatePost(exportData, items, isMultiple, pass){

        axios.post('/api/company/integrations/channelsreq', {url: 'api/v2/products/'+this.channel_product_details.id, auth: pass, postdata: {product: exportData}, type: 'PUT'}, {headers: { 
              'Authorization' : 'Bearer '+this.$attrs.user.api_token,
            }}).then(response => {
              let self = this;
              setTimeout(function(){
                      self.products_loading_text = "Product Successfully Exported. Linking Resources...";
                    self.products_loading_progress = 80;
                    //self.loadingIntegrationProducts = false;
                    return self.productPostExportUpdate(response.data, items, isMultiple, pass);
              }, 300);
              
          }).catch(error => {

          });
        /*axios.put('https://api.ecartapi.com/api/v2/products/'+this.channel_product_details.id, {product: exportData}, {headers: { 
              'Authorization' : pass,
              'Content-Type': 'application/json'
            }}).then(response => {
              let self = this;
              setTimeout(function(){
                      self.products_loading_text = "Product Successfully Exported. Linking Resources...";
                    self.products_loading_progress = 80;
                    //self.loadingIntegrationProducts = false;
                    return self.productPostExportUpdate(response.data, items, isMultiple, pass);
              }, 300);
              
          }).catch(error => {

          });*/
      },
    async productExportPost(exportData, items, isMultiple, pass){
        axios.post('/api/company/integrations/channelsreq', {url: 'api/v2/products', auth: pass, postdata: {product: exportData}, type: 'POST'}, {headers: { 
              'Authorization' : 'Bearer '+this.$attrs.user.api_token,
             }}).then(response => {
              let self = this;
              setTimeout(function(){
                      self.products_loading_text = "Product Successfully Exported. Linking Resources...";
                    self.products_loading_progress = 80;
                    //self.loadingIntegrationProducts = false;
                    return self.productPostExport(response.data, items, isMultiple, pass);
              }, 300);
              
          }).catch(error => {

          });
        /*axios.post('https://api.ecartapi.com/api/v2/products', {product: exportData}, {headers: { 
              'Authorization' : pass,
              'Content-Type': 'application/json'
            }}).then(response => {
              let self = this;
              setTimeout(function(){
                      self.products_loading_text = "Product Successfully Exported. Linking Resources...";
                    self.products_loading_progress = 80;
                    //self.loadingIntegrationProducts = false;
                    return self.productPostExport(response.data, items, isMultiple, pass);
              }, 300);
              
          }).catch(error => {

          });*/
      },
      channelResourceImportUpdate(resource, items, isMultiple ){
        this.integration_loading_text = "Importing Product. Please wait...";
        this.integration_loading_progress = 20;
        this.loadingIntegrationProducts = true;
        axios.post('/api/company/integrations/import/'+this.productData.id+'/update', {multiple: isMultiple, import: items, type: resource, integration: this.selected_integration, emailCustomer: this.push_email_customer}, {headers: {
          'Authorization' : 'Bearer '+this.$attrs.user.api_token
        }}).then(response => {
          this.integration_loading_progress = 95;
          let self = this;
              setTimeout(function(){
                    self.integration_loading_progress = 100;
                    self.integration_loading_text = "Product Successfully Imported";
                    self.loadingIntegrationProducts = false;
                
              }, 500);
        }).catch(error => {

        });
      },
      async channelResourceExportUpdate(resource, items, isMultiple, integration, pass ){
        var integration_key = pass;
        console.log(integration_key);
        var exportData = '';
        if(isMultiple == true){
          for(var i = 0; i < items.length; i++){
            if(resource == 'product'){
              this.loadingProducts = true;
              exportData[i] = await this.compileProductExportUpdate(items[i]);
            }
          }
        }else{
          if(resource == 'product'){
            console.log(items);
            this.loadingProducts = true;
            exportData = await this.compileProductExportUpdate(items);
            return this.productExportUpdatePost(exportData, items, isMultiple, integration_key);
          }else if(resource == 'category'){
            exportData = await this.compileCategoryExport(items);
            return this.categoryExportPost(exportData, items, isMultiple);
          }


        }
        if(resource == 'product'){
          
        }
      },
      async channelResourceExport(resource, items, isMultiple, integration, pass ){
        var integration_key = pass;
        console.log(integration_key);
        var exportData = '';
        if(isMultiple == true){
          for(var i = 0; i < items.length; i++){
            if(resource == 'product'){
              this.loadingProducts = true;
              exportData[i] = await this.compileProductExport(items[i]);
            }
          }
        }else{
          if(resource == 'product'){
            console.log(items);
            this.loadingProducts = true;
            exportData = await this.compileProductExport(items);
            return this.productExportPost(exportData, items, isMultiple, integration_key);
          }else if(resource == 'category'){
            exportData = await this.compileCategoryExport(items);
            return this.categoryExportPost(exportData, items, isMultiple);
          }


        }
        if(resource == 'product'){
          
        }
      },
      async toImageBlob(url) {
        const response = await fetch(url);
        const blob = await response.blob();
        const reader = new FileReader();
        await new Promise((resolve, reject) => {
          reader.onload = resolve;
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
        return reader.result.replace(/^data:.+;base64,/, '');
      },
      async productImagesExport(responsedata, items, isMultiple, pass){
        if(isMultiple == false){
          var id = responsedata["product"]["id"];
          if(items["cover_image"] !== null){
            var coverimgfname = (items["cover_image"]["gallery"]).replaceAll('//', '/');
            coverimgfname = coverimgfname.split("/");
            coverimgfname = coverimgfname[coverimgfname.length - 1];
            var imageUrl = this.storageurl+(items["cover_image"]["gallery"]).replaceAll('//', '/');
            var img = await this.toImageBlob(imageUrl);
          }
         axios.post('/api/company/integrations/channelsreq', {url: 'api/v2/products/'+id+'/images', auth: pass, postdata: {image: {filename: coverimgfname, content: img}}, type: 'POST'}, {headers: { 
              'Authorization' : 'Bearer '+this.$attrs.user.api_token,
             }}).then(response => {
            
          }).catch(error => {

          });
        /*axios.post('https://api.ecartapi.com/api/v2/products/'+id+'/images', {image: {filename: coverimgfname, content: img}}, {headers : {
          'Authorization' : pass,
          'Content-Type': 'application/json'
          }}).then(response => {
            
          }).catch(error => {

          });*/
        }else{
          for(var i = 0; i < items.length; i++){
            var id = responsedata["product"]["id"];
            if(items[i]["cover_image"] !== null){
              var coverimgfname = (items[i]["cover_image"]["gallery"]).replaceAll('//', '/');
              coverimgfname = coverimgfname.split("/");
              coverimgfname = coverimgfname[coverimgfname.length - 1];
              var imageUrl = this.storageurl+(items[i]["cover_image"]["gallery"]).replaceAll('//', '/');
              var img = await this.toImageBlob(imageUrl);
            }
            axios.post('/api/company/integrations/channelsreq', {url: 'api/v2/products/'+id+'/images', auth: pass, postdata: {image: {filename: coverimgfname, content: img}}, type: 'POST'}, {headers: { 
              'Authorization' : 'Bearer '+this.$attrs.user.api_token,
             }}).then(response => {
            
          }).catch(error => {

          });
          /*axios.post('https://api.ecartapi.com/api/v2/products/'+id+'/images', {image: {filename: coverimgfname, content: img}}, {headers : {
            'Authorization' : this.selected_integration.pass,
            'Content-Type': 'application/json'
            }}).then(response => {
              
            }).catch(error => {

            });*/
            }
        }
        let self = this;
              setTimeout(function(){
                      self.products_loading_text = "Product Images Exported";
                    self.products_loading_progress = self.products_loading_progress + 5;
                  
              }, 300);
      },
      async compileProductExportUpdate(resource){
              var product = {};
              
              if(this.channel_product_update_keys.includes('reference') == true){
                console.log('why is this:' +this.channel_product_update_keys.includes('reference'));
                product["sku"] = resource["reference"];
              }
              if(this.channel_product_update_keys.includes('name') == true){
                product["name"] = resource["name"];
              }
              if(this.channel_product_update_keys.includes('long_description') == true){
                if(this.selected_integration.integration_name !== 'Prestashop'){
                  product["description"] = resource["long_description"];
                }else{
                  product["description"] =  resource["long_description"].replace(/<[^>]+>/g, '') ;
                }
              }
              if(this.channel_product_update_keys.includes('short_description') == true){
                if(this.selected_integration.integration_name !== 'Prestashop'){
                  product["description"] = resource["short_description"];
                }else{
                  product["description"] =  resource["short_description"].replace(/<[^>]+>/g, '') ;
                }
              }
            if(this.channel_product_update_keys.includes('currency') == true){
              product["currency"] = resource["currency"];
            }
            if(this.channel_product_update_keys.includes('total_qty') == true){
                product["quantity"] = (this.push_quantity).toString();
            }
            if(this.channel_product_update_keys.includes('sales_price') == true){
            //product["inventoryQuantity"] = (resource["total_qty"]).toString();
                product["price"] = resource["sales_price"];
            }

            if(this.channel_product_update_keys.includes('sales_price') == true){
              if(resource["active"] == 1){
                product["status"] = {
                  //id:'',
                  //visibility:"visible",
                  active:"true",
                  //status:"publish",
                };
              }else{
                product["status"] = {
                  //id:'',
                  //visibility:"visible",
                  active:"false",
                  //status:"publish",
                };
              }
            }

            return product;
      },
      async compileProductExport(resource){
              var product = JSON.parse(JSON.stringify(this.channel_product));
              //product["id"] = '';
            product["sku"] = resource["reference"];
            product["name"] = resource["name"];
            product["subName"] = resource["subName"];
            if(resource["barcode"] !== null){
              if(resource["barcode"]["UPC"] !== null){
                //product["upc"] = resource["barcode"]["UPC"];
              }
            }
            if(this.selected_integration.integration_name !== 'Prestashop'){
              product["description"] = resource["long_description"];
            }else{
              product["description"] =  resource["long_description"].replace(/<[^>]+>/g, '') ;
            }
            product["currency"] = resource["currency"];
            product["productType"] = resource["productType"];
            if(this.addManualQty == false){
              product["quantity"] = (resource["total_qty"]).toString();
            }else if(this.addManualQty == true){
              product["quantity"] = (this.push_quantity).toString();
            }
            //product["inventoryQuantity"] = (resource["total_qty"]).toString();
            product["price"] = resource["sales_price"];
            /*if(resource["cost_price"] > 0){
              product["cost"] = resource["cost_price"];
            }*/
            //product["dimensions"] = {};
            //product["units"] = {};
            /*
            if((this.selected_integration.integration_name !== 'Prestashop') && (this.push_category !== 0)){
              product["category"] = 
              {
               id: this.push_category["id"], 
               name: this.push_category["name"], 
              };
            }
            */
            if(resource["search_keywords"] !== null){
              product["tags"] = resource["search_keywords"];
            }else{
              //product["tags"] = [];
            }
            product["status"] = {
              //id:'',
              //visibility:"visible",
              active:"true",
              //status:"publish",
            };
            //product["variants"] = [];
            //product["options"] = [];
            //product["associatedItems"] = [],
            /*product["logistic"] = {
              mode:null,
              type:null,
              free:"false",
              direction:null,
              dimensions:null
            };
            if(resource["images"] !== null){
              product["images"] = [];
              for(var imgind; imgind < (resource["images"].length - 1); imgind++){
                var imageItem = {
                 url: this.toImageBlob(this.storageurl + (resource["images"][imgind]["gallery"]).replaceAll('//', '/'), function(dataUrl) {
                      return dataUrl;
                  }),
                 variantIds:[],
                };
                product["images"].push(imageItem);
              }
            }else{
              product["images"] = [];
            }
            if(resource["cover_image"] !== null){
              if(this.selected_integration.integration_name !== 'Prestashop'){
              var imageUrl = this.storageurl + (resource["cover_image"]["gallery"]).replaceAll('//', '/');
              product["imageUrl"] = await this.toImageBlob(imageUrl);
              console.log(product["imageUrl"]);
              }
            }*/
            if(resource["condition"] !== null){
              if(this.selected_integration.integration_name == 'Prestashop'){
                if(resource["condition"] == 'Antique'){
                  product["condition"] = 'used';
                }else if(resource["condition"] == 'Bespoke'){
                  product["condition"] = 'new';
                }
              }else{
                product["condition"] = resource["condition"];
              }
            }
            //product["link"] = null;
            //product["dates"] = {};
            product["vendorId"] = "1";
            product["vendor"] = this.$attrs.company.name;
            product["sellOutStock"] = "true";
            return product;
    },
    channelResourceLink(gemesys_product, external_product, isMultiple, resource){
      this.loadingActiveIntegrations = true;
        if(resource == 'product'){
          var extprod = [];
          extprod["product"] = external_product;
          this.productLink(extprod, gemesys_product, isMultiple);
        }
      },
    productLink(response, items, isMultiple){
      if(isMultiple == false){
        axios.post('/api/company/integrations/product/'+items.id+'/link', {integration: this.selected_integration, integration_product: response["product"]}, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
          let self = this;
              setTimeout(function(){
                    self.products_loading_text = "Product Linked";
                    self.products_loading_progress = self.products_loading_progress + 5;
                    //self.all_products = '';
                    self.$refs['manual-link'].hide();
                    self.productData.product_channels.push(response.data);
                    self.productData.product_channels[self.productData.product_channels.length - 1]["integration"] = self.selected_integration;
                    self.loadingActiveIntegrations = false;
                    return self.fetchChannelsIntegrationsDetails('channels-api');
              }, 300);
          
        }).catch(error => {

        });
      }else{

      }
    },
    checkExistingChannel(integration){
      var index = this.productData.product_channels.findIndex(item => item.company_app_integration_id == integration.id);
      if(index > -1){
        return false;
      }else{
        return true;
      }
    },
    checkExistingProduct(external_product){
      if(this.selected_integration.products.length > 0){
        var integrationExists = [];
        integrationExists = this.selected_integration.products.filter(item => item.channels_product_id == external_product.id);
         console.log(integrationExists);
        if(integrationExists.length > 0){
          console.log(integrationExists);
          return integrationExists[0];
        }else{
          return false;
        }
      }else{
        console.log('no products');
        return false;
      }
    },
    checkGemesysLink(external_product){
      
        //console.log(external_product);
        /*for(var i = 0; i < this.all_products.data.length; i++){
          if(this.all_products.data[i].product_channels.length > 0){
            index = this.all_products.data[i].product_channels.findIndex(item => (item.company_app_integration_id == this.selected_integration.id) && (item.channels_product_id == external_product.id) );
              console.log(index);
              if(index > -1){
                return true;
              }else{
                return false;
              }
          }
        }*/
        var integrationIndex = [];
        integrationIndex = this.productData.product_channels.filter(subitem => subitem.company_app_integration_id == this.selected_integration.id);
        if(integrationIndex.length > 0){
        var index = integrationIndex.findIndex(subitem => subitem.channels_product_id == external_product.id);
        console.log(index);

              if(index > -1){
                return false;
              }else{
                return true;
              }
        }else{
          return true;
        }
        
        
      },
      channelResourceUnlink(gemesys_product, external_product, isMultiple, resource){
        if(resource == 'product'){
          var extprod = [];
          extprod["product"] = this.selected_product;
          console.log(extprod);
          this.unlinkProduct(extprod, gemesys_product, isMultiple);
        }
      },
    unlinkProduct(response, items, isMultiple){
      if(isMultiple == false){
        axios.post('/api/company/integrations/product/'+items.id+'/unlink', {integration: this.selected_integration, integration_product: response["product"]}, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
          let self = this;
              setTimeout(function(){
                    self.products_loading_text = "Product Unlinked";
                    self.products_loading_progress = self.products_loading_progress + 5;
                    var chanIndex = self.productData.product_channels.findIndex(item => item.company_app_integration_id == self.selected_integration.id);
                    self.productData.product_channels.splice(chanIndex, 1);
                    self.selected_integration = '';
                    //self.all_products = '';
                  return self.$refs['unlink-channel'].hide();
              }, 300);
          
        }).catch(error => {

        });
      }else{

      }
    },
    deleteChannelProduct(gemesys_product, external_product, isMultiple, resource){
      this.loadingActiveIntegrations = true;
      axios.post('/api/company/integrations/channelsreq', {url: 'api/v2/products/'+external_product.channels_product_id+'', auth: pass, type: 'DELETE'}, {headers: { 
              'Authorization' : 'Bearer '+this.$attrs.user.api_token,
             }}).then(response => {
              let self = this;
              setTimeout(function(){
                self.loadingActiveIntegrations = false;

                return self.channelResourceUnlink(gemesys_product, external_product, isMultiple, resource);
              });
            }).catch(error => {

            });

      /*axios.delete('https://api.ecartapi.com/api/v2/products/'+external_product.channels_product_id+'', {headers: { 
              'Authorization' : this.selected_integration.pass,
              'Content-Type': 'application/json'
            }}).then(response => {
              let self = this;
              setTimeout(function(){
                self.loadingActiveIntegrations = false;

                return self.channelResourceUnlink(gemesys_product, external_product, isMultiple, resource);
              });
            }).catch(error => {

            });*/
    },
    updateChannelQuantity(integration){
      if(this.selected_integration_uid == ''){
          return this.getActiveChannel(integration);
      }else{
          axios.post('/api/company/integrations/channelsreq', {url: 'api/v2/products/'+integration.channels_product_id, auth: pass, type: 'POST', postdata: {}}, {headers: { 
              'Authorization' : 'Bearer '+this.$attrs.user.api_token,
             }}).then(response => {
                this.channel_product_details = response.data.product;
                this.loadingIntegrationDetails = false;
            }).catch(error => {

            });
          /*axios.post('https://api.ecartapi.com/api/v2/products/'+integration.channels_product_id, {}, {headers: { 
                'Authorization' : this.selected_integration.pass,
              }}).then(response => {
                this.channel_product_details = response.data.product;
                this.loadingIntegrationDetails = false;
            }).catch(error => {

            });*/
      }
    },
    updateChannelData(method){
      if(method == 'push'){
        this.channelResourceExportUpdate('product', this.productData, false, this.selected_integration, this.selected_integration.pass);
      }else if(method == 'pull'){
        var item = {};
        for(var i = 0; i < this.channel_product_update_keys.length; i++){
            item['' + this.channel_product_update_keys[i] + ''] = this.channel_product_details['' + this.channel_product_update_keys[i] + ''];
        }
        this.channelResourceImportUpdate('product', item, false);
      }
    },
     fetchChannelsIntegrationsDetails(service){
      this.loadingIntegrations = true;
        axios.get('/api/company/integrations?service='+service, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
          this.ecommerce_channels_settings = response.data;
          this.marketplace_channels_settings = response.data;
          this.all_active_commerce_channels = response.data.data;
          this.active_ecommerce_integrations = this.filterActiveChannels(response.data.data, 'ecommerce');
          let self = this;
          setTimeout(
            function(){
              self.loadingIntegrations = false;
            });
        }).catch(error => {

        });
      },
      filterActiveChannels(list, integrationtype){
        return list.filter(item => item.type = integrationtype);
      },
    getActiveChannelProductDetails(integration){
      this.loadingIntegrationDetails = true;
      if(this.selected_integration_uid == ''){
          if(this.selected_integration_uid !== integration.uid){
          axios.get('/api/company/integrations/'+integration.uid, {headers: {
            'Authorization' : 'Bearer '+this.$attrs.user.api_token
          }}).then(response => {
            this.selected_integration = response.data;
            this.selected_integration_uid = response.data.uid;
            var integrationData = this.productData.product_channels.filter(item => item.company_app_integration_id == this.selected_integration.id);
            console.log(integrationData);
            return this.getChannelProductDetails(integrationData[0]);
          }).then(response => {

          });
          }
        }
      },
      getActiveChannelPass(integration){
      this.loadingIntegrationDetails = true;
      if(this.selected_integration_uid == ''){
          if(this.selected_integration_uid !== integration.integration.uid){
          axios.get('/api/company/integrations/'+integration.integration.uid, {headers: {
            'Authorization' : 'Bearer '+this.$attrs.user.api_token
          }}).then(response => {
            this.selected_integration = response.data;
            this.selected_integration_uid = response.data.uid;
            //return this.getChannelProductDetails(integration);
          }).then(response => {

          });
          }
        }
      },
      async getActiveChannelExportPass(resource, items, isMultiple, integration){
      this.loadingIntegrationDetails = true;
          axios.get('/api/company/integrations/'+integration.uid, {headers: {
            'Authorization' : 'Bearer '+this.$attrs.user.api_token
          }}).then(response => {
            this.selected_integration = response.data;
            this.selected_integration_uid = response.data.uid;
            //return this.getChannelProductDetails(integration);
            return this.channelResourceExport(resource, items, isMultiple, integration, response.data.pass )
          }).then(response => {

          });
      },
    getActiveChannel(integration){
      this.loadingIntegrationDetails = true;
      if(this.selected_integration_uid == ''){
          if(this.selected_integration_uid !== integration.integration.uid){
          axios.get('/api/company/integrations/'+integration.integration.uid, {headers: {
            'Authorization' : 'Bearer '+this.$attrs.user.api_token
          }}).then(response => {
            this.selected_integration = response.data;
            this.selected_integration_uid = response.data.uid;
            return this.getChannelProductDetails(integration);
          }).then(response => {

          });
          }
        }
      },
      getActiveChannelProducts(integration){
      this.loadingIntegrationDetails = true;
      if(this.selected_integration_uid == ''){
          if(this.selected_integration_uid !== integration.uid){
          axios.get('/api/company/integrations/'+integration.uid, {headers: {
            'Authorization' : 'Bearer '+this.$attrs.user.api_token
          }}).then(response => {
            this.selected_integration = response.data;
            this.selected_integration_uid = response.data.uid;
            this.selected_channel_products = '';
            return this.getChannelProducts();
          }).then(response => {

          });
          }
        }
      },
    getChannelProductDetails(integration){
      if(this.channel_product_details_id !== integration.channels_product_id){
        //console.log(integration.integration.pass);
        this.channel_product_details_id = integration.channels_product_id;
        axios.post('/api/company/integrations/channelsreq', {url: 'api/v2/products/'+integration.channels_product_id, auth: pass, type: 'GET', postdata: {}}, {headers: { 
              'Authorization' : 'Bearer '+this.$attrs.user.api_token,
             }}).then(response => {
                this.channel_product_details = response.data.product;
                this.loadingIntegrationDetails = false;
            }).catch(error => {

            });

        /*axios.get('https://api.ecartapi.com/api/v2/products/'+integration.channels_product_id, {headers: { 
                'Authorization' : this.selected_integration.pass,
              }}).then(response => {
                this.channel_product_details = response.data.product;
                this.loadingIntegrationDetails = false;
            }).catch(error => {

            });*/
        }
    },
    getChannelProducts(){
        if(this.selected_channel_products == ''){
          this.loadingProducts = true;
          this.products_loading_progress = 50;
          axios.post('/api/company/integrations/channelsreq', {url: 'api/v2/products/', auth: pass, type: 'GET', postdata: {}}, {headers: { 
              'Authorization' : 'Bearer '+this.$attrs.user.api_token,
             }}).then(response => {
            this.selected_channel_products = response.data;
            this.products_loading_progress = 100;
              let self = this;
            setTimeout(
              function(){
                self.loadingProducts = false;
              });
          }).catch(error => {

          });

          /*axios.get('https://api.ecartapi.com/api/v2/products', {headers: { 
            'Authorization' : this.selected_integration.pass,
            'Content-Type': 'application/json'
          }}).then(response => {
            this.selected_channel_products = response.data;
            this.products_loading_progress = 100;
              let self = this;
            setTimeout(
              function(){
                self.loadingProducts = false;
              });
          }).catch(error => {

          });*/
        }
      },
    getChannelsImage(channel){
        return this.all_commerce_channels.filter(item => item.name == channel)[0]["image"];
      },
    getCommerceChannels(){
      var channels = localStorage.getItem('all_commerce_channels');
      var parseChannels = JSON.parse(channels);
      if(parseChannels !== null){
        this.all_commerce_channels = parseChannels;
      }
      if(this.all_commerce_channels.length == 0){
        axios.post('/api/company/integrations/channelsreq', {url: 'ecommerces', auth: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJOak01T1RFd01UZzRaakpsTkdJd01ERTJOVGsyWmpVMSIsImlhdCI6MTY3MTAyNTM4MzgwMX0.er8IVWgd7hhTdw3tBFHvMnEWb6BbJeDv1YceFLfvx1E', type: 'GET', postdata: {}}, {headers: { 
              'Authorization' : 'Bearer '+this.$attrs.user.api_token,
            }}).then(response => {
            this.all_commerce_channels = response.data;
            localStorage.setItem('all_commerce_channels', JSON.stringify(response.data));
          }).catch(error => {

          });
          /*axios.get('https://api.ecartapi.com/ecommerces', {headers: { 
            'Authorization' : 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJOak01T1RFd01UZzRaakpsTkdJd01ERTJOVGsyWmpVMSIsImlhdCI6MTY3MTAyNTM4MzgwMX0.er8IVWgd7hhTdw3tBFHvMnEWb6BbJeDv1YceFLfvx1E'
          }}).then(response => {
            this.all_commerce_channels = response.data;
            localStorage.setItem('all_commerce_channels', JSON.stringify(response.data));
          }).catch(error => {

          });*/
        }
      },
    getDate(date){
        var createdOn = DateTime.fromISO(date, { zone: 'utc'});
        console.log(createdOn);
        return createdOn.day+' '+createdOn.monthLong+' '+createdOn.weekYear+'';
      },
    addRelatedProduct(selectedOption){
      var response = [];
      response = selectedOption['relation'];
      response['related_product_details'] = selectedOption['related_product_details'];
      this.productData.related_products.push(response);

    },
    removeRelatedProduct(product, index){
      axios.post('/api/company/product/'+this.$attrs.id+'/related-products/delete', {product_id: product.id}, {
                headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}
              }).then(response => {
                this.productData.related_products.splice(index, 1);
              }).catch(error => {
                this.$emit('resource-error', error.data);
              });
      
    },
    removeCombination(selectedOption){
      console.log(selectedOption);
      var option = selectedOption;
      var index = this.product_combinationsForm.variants.findIndex(item => item == option);
      console.log(index);
      this.product_combinationsForm.variants.splice(index, 1);
    },
    addToCategoryCollection(category_id, index, marketplace, type){
      if(this.productData.id !== null){
        axios.post('/api/company/product/'+type+'/'+category_id+'/add-product', {marketplace_categories: marketplace, product_id: this.productData.id}, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
          this.success_messagetext = "Product Added to "+type;
          this.success_message = true;

        }).catch(error => {
          this.error_message = true;
          this.error_reponse = errors.response.data.errors;
        });
      }
    },
    removeFromCategory(category_id, index, marketplace){
      if(this.productData.id !== null){
        var defaultcat = '';
            if(marketplace == 'company'){
              if(category_id == this.productData.default_category_id){
                defaultcat = 1;
              }else{
                defaultcat = 0;
              }
            }
            if(marketplace == 'trade'){
              if(category_id == this.productData.trademarket_category_id){
                defaultcat = 1;
              }else{
                defaultcat = 0;
              }
            }
            if(marketplace == 'public'){
              if(category_id == this.productData.pubmarket_category_id){
                defaultcat = 1;
              }else{
                defaultcat = 0;
              }
            }
            axios.post('/api/company/product/category/'+category_id+'/remove-product', {marketplace_categories: marketplace, product_id: this.productData.uid, is_default: defaultcat}, {
              headers: {
                'Authorization' : 'Bearer '+this.$attrs.user.api_token
              }
            }).then(response => {
              this.$emit('product-removed', response.data);
              this.success_messagetext = 'Product Removed from Category';
              this.success_message = true;
              let self = this;
              setTimeout(function(){
                self.success_message = false;
              }, 300);
            }).catch(error => {
              //this.$emit('resource-error', error.data);
            });
      }
    },
    removeFromCollection(category_id, index, marketplace){
      if(this.productData.id !== null){
            axios.post('/api/company/product/collection/'+category_id+'/remove-product', {product_id: this.productData.uid}, {
              headers: {
                'Authorization' : 'Bearer '+this.$attrs.user.api_token
              }
            }).then(response => {
              this.$emit('product-removed', response.data);
              this.success_messagetext = 'Product Removed from Collection';
              this.success_message = true;
          
              let self = this;
              setTimeout(function(){
                self.success_message = false;
              }, 300);
            }).catch(error => {

            });
          }
    },
      getCollections(){
      axios.get('/api/company/product/collections?page='+this.colcurrentPage+'&limit='+this.col_limit+'&sortby=name&order=asc&search='+this.col_search, {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
        this.collections = response.data;
      }).catch(error => {
        this.brands_error = error.data;
      });
    },
    getProductOrders(id){
          axios.get('/api/company/product/'+id+'/orders?page='+this.productsCurrentPage+'&limit='+this.productslimit+'&sortby='+this.productsortby+'&order='+this.productsorder+'&search='+this.productsearch, { headers : {
        'Authorization': 'Bearer ' + this.$attrs.user.api_token
      } }).then( response => {
            this.product_orders = response.data;
          }).catch(error => {
            //this.error_message = true;
          });
        },
        getProductOrderStats(id){
          axios.get('/api/company/product/'+id+'/order-stats?startdate='+this.ordersstartdate+'&enddate='+this.ordersenddate, { headers : {
        'Authorization': 'Bearer ' + this.$attrs.user.api_token
      } }).then( response => {
            this.productstats = response.data;
            this.orderprevioustotal = response.data[1];
            if(this.chartDisplay == 'by-order'){
                return this.compileOrderChart();
              }else if(this.chartDisplay == 'by-date'){
                 return this.compileChartByDate();
              }
          }).catch(error => {
            //this.error_message = true;
          });
        },
        getTopListingsData(id){
          //this.orderDataLoading = true;
          axios.get('/api/company/product/'+this.productData.id+'/top-listings', {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
              this.toplistingsdata = response.data;
              //this.compileOrderChart();
            
          }).catch(error => {

          });
      },
      getTopSellersData(id){
          //this.sellersDataLoading = true;
          axios.get('/api/company/product/'+this.productData.id+'/top-sellers', {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
              this.topsellersdata = response.data;
              //this.compileOrderChart();
            let self = this;
             setTimeout(() => {
              //self.sellersDataLoading = false;
            }, 1500);
          }).catch(error => {

          });
      },
      compileChartByDate(){
        this.lineChart.chartOptions.xaxis.type = "datetime";
        this.orderDataLoading = true;
        this.lineChart.series[0].data = [];
        this.lineChart.series[1].data = [];
        this.lineChart.chartOptions.labels = [];
        this.lineChart.chartOptions.xaxis.labels.show = true;
        var keys = Object.keys(this.productstats[2]);
        this.lineChart.chartOptions.labels = keys;
        this.lineChart.chartOptions.xaxis.categories = keys;
        console.log(keys);
        for(var date in this.productstats[2]){
            console.log(date);
                if(this.lineChart.series[0].data.length == 0){
                    this.lineChart.series[0].data[0] = parseFloat(this.productstats[2][''+date+'']["orders_total"]);
                }else{
                    this.lineChart.series[0].data.push(parseFloat(this.productstats[2][date]["orders_total"]) + parseFloat(this.lineChart.series[0].data[this.lineChart.series[0].data.length -1]));
                }

                if('website_orders_total' in this.productstats[2][date]){
                this.donutChart.series[0] += parseFloat(this.productstats[2][date]['website_orders_total']);
                }
                if('pos_orders_total' in this.productstats[2][date]){
                    this.donutChart.series[1] += parseFloat(this.productstats[2][date]['pos_orders_total']);
                }
                if('trade_orders_total' in this.productstats[2][date]){
                    this.donutChart.series[4] += parseFloat(this.productstats[2][date]['trade_orders_total']);
                }
                if('customer_orders_total' in this.productstats[2][date]){
                    this.donutChart.series[3] += parseFloat(this.productstats[2][date]['customer_orders_total']);
                }

                this.lineChart.series[1].data.push(this.productstats[2][date]["orders_total"]);
        }

        let self = this;
                 setTimeout(() => {
         self.orderDataLoading = false;
        }, 500);
  },
  compileOrderStatCharts(value){
    if(value == 'by-order'){
      this.compileOrderChart();
    }else if(value == 'by-date'){
        this.compileChartByDate();
    }
  },
    compileOrderChart(){
      this.orderDataLoading = true;
      this.lineChart.chartOptions.xaxis.type = "category";
        this.lineChart.series[0].data = [];
        this.lineChart.series[1].data = [];
        this.lineChart.chartOptions.xaxis.categories = [];
        for(var i = 0; i < this.productstats.orders.length; i++){
           if(i == 0){
            this.lineChart.series[0].data.push(parseFloat(this.productstats.orders[i].total_order_inc));
            }else{
               this.lineChart.series[0].data.push((parseFloat(this.lineChart.series[0].data[i-1]) + parseFloat(this.productstats.orders[i].total_order_inc) ) ) ; 
            }
            /** 
            if(this.productstats.orders[i].trade_orders.length > 0){
                this.donutChart.series[4] += parseFloat(this.productstats.orders[i].total_order_inc);
            }
            if(this.productstats.orders[i].customer_orders.length > 0){
                this.donutChart.series[3] += parseFloat(this.productstats.orders[i].total_order_inc);
            }
            **/
            this.lineChart.series[1].data.push(parseFloat(this.productstats.orders[i].total_order_inc));
            this.lineChart.chartOptions.xaxis.categories.push(this.productstats.orders[i].reference);
        }
          let self = this;
             setTimeout(() => {
              self.orderDataLoading = false;
            }, 500);
    },
    getCategories(type){
      axios.get('/api/company/product/categories?page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search+'&marketplace_categories='+type, {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
        if(type == 'company'){
          this.categories = response.data;
        }
        if(type == 'public'){
          this.public_categories = response.data;
        }
        if(type == 'trade'){
          this.trade_categories = response.data;
        }
        //return response.data;

      }).catch(error => {
        this.response_error = error.data;
      });
    },
      handleImage(e) {
      const selectedImage = e.target.files[0]; // get first file
      this.createBase64Image(selectedImage);

    },
    createBase64Image(fileObject) {
      const reader = new FileReader();

      reader.onload = (e) => {
        this.editImageUrl = e.target.result;
        //this.uploadImage();
        this.editImage = true;
      };
      reader.readAsDataURL(fileObject);
    },
    updateMetaDescription(){
      if(this.productData.meta_description == ""){
        if(this.productData.short_description.length > 125){
          this.productData.meta_description = this.productData.short_description.slice(0, 125);

        }else{
          this.productData.meta_description = this.productData.short_description;
        }

      }
    },
        categorySelected(payload, params){
          console.log([payload, params]);

            if(params == 'company'){
              this.productData.default_category_id = payload.id;
             this.productData.company_defaultcategory = payload;
            }
            if(params == 'trade'){
              this.productData.trademarket_category_id = payload.id;
             this.productData.trade_marketplace_defaultcategory = payload;
            }
            if(params == 'public'){
              this.productData.pubmarket_category_id = payload.id;
             this.productData.public_marketplace_defaultcategory = payload;
            }
        },
        shippingSelected(shipping){
          if(shipping){
            var index = this.productData.shipping_options.findIndex(item => item.id == shipping.id);
            if(index == -1){
              return false;
            }else{
              return true;
            }
          }
        },
        addShippingOption(option){
          var index = this.productData.shipping_options.findIndex(item => item.id == option.id);
          if(index == -1){
            this.productData.shipping_options.push(option);
          }else{
            this.productData.shipping_options.splice(index, 1);
          }
        },
        getProduct(id){
          axios.get('/api/company/product/'+id+'', {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then( response => {
            this.productData = response.data;
            if(response.data.tryon == null){
              this.productData.tryon = {url: '', service: ''}; 
            }
            this.title = 'Edit Product ';
            this.items[2] = this.productData.name;
            
            this.updateDropzoneOptions.url = '/api/company/product/'+this.productData.uid+'/upload-image';
            this.updateDropzoneOptions.headers = {'Authorization' : 'Bearer '+this.$attrs.user.api_token};
            this.updateVideoDropzoneOptions.url = '/api/company/product/'+this.productData.uid+'/upload-video';
            this.updateVideoDropzoneOptions.headers = {'Authorization' : 'Bearer '+this.$attrs.user.api_token};
            this.qrData.reference = this.productData.reference;
                this.qrData.uid = this.productData.uid;
                this.qrData.id = this.productData.id;
            return this.loadViewAssets(this.view, response.data);
          }).catch(error => {
            //this.error_message = true;
          });
        },
        loadViewAssets(view, product){
          if(view == 'orders'){
            this.getProductOrders(product.id);
          }
          if(view == 'sales-stats'){
            if(this.toplistingsdata.length == 0){
              this.getTopListingsData(product.id);
            }
            if(this.topsellersdata.length == 0){
              this.getTopSellersData(product.id);
            }
            if(this.productstats.length == 0){
              this.getProductOrderStats(product.id);
            }
            
          }
        },
        onScroll(e) {
          this.windowTop = window.top.scrollY;
          
          console.log(window.top.scrollY);
          if (window.top.scrollY >= 67) {
            this.isFixed = true;
          } else {
            this.isFixed = false;
          }
        },
       searchBrands(){
            if(this.searchbrand.length > 2){
                this.brandsearch_results = this.all_brands.filter(item => item.name.toLowerCase().match(this.searchbrand));
                    if(!this.brandsearch_results.length){
                            this.brandsearch_results = this.all_brands.filter(item => item.name.match(this.searchbrand));
                    }
            }
          },
          activeBrand(id){
             return this.all_brands.filter(item => item.id === id);
          },
          brandsDefault(){
            if(this.searchbrand.length < 2 || this.searchbrand === undefined){
            this.brandsearch_results = this.all_brands;
            }
          },
          brandsOnBlur(){
            if(this.searchbrand.length < 1){
                //this.brandsearch_results = [];
            }
            this.brandresults_end = 5;
            this.brandresults_start = 0;
          },
          brandsOnFocus(){
             axios.get('/seller/allbrands').then(response => {
                 console.log(response);
                this.all_brands = response.data;
                this.brandsearch_results = this.all_brands;
            })
            
          },
          getShops(){
            axios.get('/api/company/listings?page='+this.shopscurrentPage+'&limit='+this.shopslimit+'&sortby='+this.shopsortby+'&order='+this.shoporder+'&search='+this.shopsearch+'&type_id='+this.shop_type_id, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
            this.shopsData = response.data;
            }).catch(error => {
              this.shops_error = error.data;
            });
          },
          toggleBrand(isdelete, selectedbrandid){
            if(isdelete === false){
            this.product_brand_id = selectedbrandid;
            }
            if(isdelete === true){
            this.product_brand_id = null;
            }
            /*axios.post('http://localhost:7080/gemesislabs-framework/public/seller/product/'+this.productdata[0].id+'/togglebrand', {brandid: selectedbrandid, delete: isdelete}).then(response => {
                 console.log(response);
                //this.product_attribute_success = response.data;
            })*/
          },
          toggleBulkPricing(isdelete, bulk_price_id){
            if(isdelete === false){
                if( (this.discount_type != '') && (this.discount_amount != 0) && (this.bulkquantity != 0) && (this.selected_customer_type != '') && (this.selected_customer_group != '')){
                    axios.post('http://localhost:7080/gemesislabs-framework/public/seller/product/'+this.productdata[0].id+'/togglebulkprice', {discounttype: this.discount_type, discountamount: this.discount_amount, quantity: this.bulkquantity, customertype: this.selected_customer_type, customergroup: this.selected_customer_group, delete: isdelete, bulkpricing_id: bulk_price_id}).then(response => {
                         console.log(response);
                        //this.product_attribute_success = response.data;
                    })
                }
            }
            if(isdelete === true){
                 axios.post('http://localhost:7080/gemesislabs-framework/public/seller/product/'+this.productdata[0].id+'/togglebulkprice', {discounttype: this.discount_type, discountamount: this.discount_amount, quantity: this.bulkquantity, customertype: this.selected_customer_type, customergroup: this.selected_customer_group, delete: isdelete, bulkpricing_id: bulk_price_id}).then(response => {
                     console.log(response);
                    //this.product_attribute_success = response.data;
                }) 
            }
            this.$emit('bulkprices-added');
          },
          addTax(rule, price){
            var tax_rule = rule;
            console.log(tax_rule);
            var country_id = this.$attrs.company.country_id;
       
          if(tax_rule !== null && tax_rule.country_rules.length > 0){
            var selected_rule = null;
            var selected_ruleIndex = -1;
            for(var ti = 0; ti < tax_rule.country_rules.length; ti++){
              selected_ruleIndex = tax_rule.country_rules[ti].countries.findIndex(item => item == country_id);
              if(selected_ruleIndex !== -1){
                selected_rule = tax_rule.country_rules[ti];
                ti = tax_rule.country_rules.length;
              }
            }
            console.log(selected_rule);
            if(tax_rule.type == 1){
                if(selected_rule !== null){
                  var percentage = (parseFloat(selected_rule.value) / 100) + 1;
                  this.active_tax_percentage = percentage;
                  return price * percentage;
                }else{
                  return price;
                }
              }else{
                if(selected_rule !== null){
                  this.active_tax_percentage = selected_rule.value;
                  return price + selected_rule.value;
                }else{
                  return price;
                }
              }
            }else if(tax_rule !== null && tax_rule.country_rules.length > 0){
              var percentage = (parseFloat(tax_rule.percentage) / 100) + 1;
              return price * percentage;
            }else {
              return price;
            }
          },
          calcCostPrice(){
            var tax_duties = JSON.parse(this.import_tax) + JSON.parse(this.export_tax) + JSON.parse(this.carnet_price);
            this.calculated_tax_duties = tax_duties.toFixed(2);
            var cost_price = JSON.parse(this.productData.cost_price) + JSON.parse(this.calculated_tax_duties);
            this.calculated_cost_price = cost_price.toFixed(2);
            this.calcProfitMargin();
          }, 
          updateTaxCalc(){
            /*var setMultiplier = this.activeTaxPercentage / 100;
            var multiplier = setMultiplier + 1;
            var calculation = JSON.parse(this.productData.sales_price) * multiplier;*/
            this.inc_tax_price = this.addTax(this.selected_tax_rule, this.productData.sales_price);
            this.productData.sales_price = this.productData.sales_price.toFixed(2);
            this.calcProfitMargin();
          },
          removeVatCalc(){ 
            if(this.selected_tax_rule.type == 1){
              //var setMultiplier = this.active_tax_percentage / 100;
              //var multiplier = setMultiplier + 1;
              var calculation = JSON.parse(this.inc_tax_price) / this.active_tax_percentage;
            }else{
              var calculation = JSON.parse(this.inc_tax_price) - this.active_tax_percentage;
            }
            this.productData.sales_price = calculation.toFixed(2);
            this.calcProfitMargin();
          },
           activeTaxRule(id){
            var activeObject = this.all_tax_rules.filter(item => item.id === id);
            console.log(activeObject);
            this.selected_tax_rule = activeObject[0];
            this.active_tax_percentage = activeObject[0].percentage;
            return activeObject[0].percentage;
          },
          calcProfitMargin(){
            var salesSubCost = (JSON.parse(this.productData.sales_price)- JSON.parse(this.calculated_cost_price)).toFixed(2);
            var multiplier = salesSubCost / JSON.parse(this.calculated_cost_price).toFixed(2);
            var calculation = multiplier * 100;
            this.profit_margin = calculation.toFixed(2);
          },
          formatCurrency: function(price){
            return price.toFixed(2);
          },
          quantityInListing(id){
           var index = this.productData.listings.findIndex(item => item.company_listing_id == id);
           if(index !== -1){
            return true;
           }else{
            return false;
           }
          },
          variantQuantityInListing(id, variant_id){
           var index = this.productData.listings.findIndex(item => (item.company_listing_id == id) && (item.variant_id == variant_id));
           if(index !== -1){
            return true;
           }else{
            return false;
           }
          },
          combinationQuantityInListing(id, combination_id){
           var index = this.productData.listings.findIndex(item => (item.company_listing_id == id) && (item.combination_id == combination_id));
           if(index !== -1){
            return true;
           }else{
            return false;
           }
          },
          listingQuantity(value){
            return this.productData.listings.findIndex(item => item.company_listing_id == value);
            //return items[0];
          },
          variantListingQuantity(value, variant){
            return this.productData.listings.findIndex(item => (item.company_listing_id == value) && (item.variant_id == variant.id));
            //return items[0];
          },
          combinationListingQuantity(value, combination){
            return this.productData.listings.findIndex(item => (item.company_listing_id == value) && (item.combination_id == combination.id));
            //return items[0];
          },
          getAllAttributes(){
            axios.get('/api/company/product/attributes?page='+this.currentPage+'&limit=0&sortby=name&order=desc&search='+this.search, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
                this.all_attributes = response.data;
              }).catch(error => {
                this.brands_error = error.data;
              });
          },
          getAttributeValues(selectedOption){
            this.product_attribute_success = null;
            this.selected_value = '';
            /*if(this.productData.attributes.length > 0){
            this.productData.attributes[this.productData.attributes.length - 1] = selectedOption;
            }*/
            this.selected_attribute = selectedOption;
            this.attribute_values = [];
            this.attribute_values = selectedOption.values;
          },
          selectAttributeValues(selectedOption){
            this.selected_value = selectedOption;
          },
          selectVariantAttributeValues(index, selectedOption){
            this.product_variantForm["value"] = selectedOption;
          },
          variantAttributeSelected(index, event){
            console.log(index);
            console.log(event);
            this.product_variantForm.attribute = event;
            this.product_variantForm.attribute_values = event.values;
          },
          getAttributeName(id){
            var attributes = this.all_attributes;
            var name = attributes.filter(item => item.id == id);
            return name[0].name;
          },
          getAttributeValueName(attributes, id){
            var filteredResult = attributes.filter(item => item.id == id);
            return filteredResult[0].name;
          },
          getAttributeVariantValues(index){
            this.product_attribute_success = null;
            //this.selected_value = '';
            //this.attribute_values = [];
            axios.get('/seller/product/attribute/'+this.product_variantForm.attribute_id+'/values').then(response => {
                 console.log(response);
                this.product_variantForm.attribute_values = response.data;
            })
          },
          addProductAttribute(){
           if(this.$attrs.id !== 'add'){
              axios.post('/api/company/product/'+this.$attrs.id+'/attribute/create', {product_id: this.productData.id, attribute_id: this.selected_attribute.id, value_id: this.selected_value.id, measurement_unit_id: this.selected_measurement_unit.id, measurement_value: this.selected_measurement_value}, {headers : {
                'Authorization' : 'Bearer '+this.$attrs.user.api_token 
              }}).then(response => {
                   console.log(response);
                  this.product_attribute_success = response.data;
                  var attribute = {id: 0, attribute:  JSON.parse(JSON.stringify(this.selected_attribute)), value: JSON.parse(JSON.stringify(this.selected_value)), measurement_value: JSON.parse(JSON.stringify(this.selected_measurement_value)), units: JSON.parse(JSON.stringify(this.selected_measurement_unit))};
                    this.productData.attributes.push(attribute);
                    this.success_messagetext = 'Product Feature Added';
                    this.success_message = true;
                    this.selected_value = '';
                    this.selected_attribute = '';
                    this.selected_measurement_unit = '';
                    this.measurement_unit_value = '';
                    let self = this;
                    setTimeout(function(){
                      self.success_message = false;
                    }, 300);
                }).catch(error => {
                  this.error_message = true;
                  this.error_reponse = errors.response.data.errors;
                });
              
              //this.attribute_values = [];
              //this.showAlert();
            }else{
                var attribute = {id: 0, attribute:  this.selected_attribute, value: this.selected_value, measurement_value: this.selected_measurement_value, units: this.selected_measurement_unit};
                this.productData.attributes.push(attribute);
                this.selected_value = '';
                this.selected_attribute = '';
                this.selected_measurement_unit = '';
                this.measurement_unit_value = '';
                this.success_messagetext = 'Product Feature Added';
                    this.success_message = true;
                    let self = this;
                    setTimeout(function(){
                      self.success_message = false;
                    }, 300);
                //this.attribute_values = [];
                this.newFeature = false;
            }
          },
          destroyProductAttribute(id, index){
            if (this.$attrs.id !== 'add'){
            axios.post('/api/company/product/'+id+'/attribute/'+this.id+'/destroy', {}, {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
                 console.log(response);
                //this.product_attribute_success = response.data;
                  this.productData.attributes.splice(index, 1);
                  this.success_messagetext = 'Product Feature Deleted';
                    this.success_message = true;
                    let self = this;
                    setTimeout(function(){
                      self.success_message = false;
                    }, 300);
            }).catch(error => {
                this.error_reponse = {message: ["Feature could not be deleted, please try again."]};
                this.error_message = true;
            });
            }else{
              this.productData.attributes.splice(index, 1);
            }
          },
          destroyProduct(){
            axios.post('/api/company/product/'+this.productData.id+'/destroy', {}, {headers: {
              'Authorization' : 'Bearer '+this.$attrs.user.api_token
            }}).then(response => {
                window.location.hash = '';
                window.location.pathname = '/stock/products';
            }).catch(error => {

            });
          },
          editProductAttribute(attribute, index){
            this.loadingEditAttribute = true;
            this.selected_value = '';
            this.selected_attribute = '';
            this.selected_measurement_unit = '';
            this.selected_measurement_value = '';
            console.log(attribute);
            this.selected_attribute = attribute.attribute;
              /*if(attribute.attribute.values !== null){
                this.attribute_values = attribute.attribute.values;
              }*/
            var attrib = this.all_attributes.filter(item => item.id == attribute.attribute.id);
            if(attrib.length > 0){
              this.attribute_values = attrib[0].values;
            }
            this.selected_value = JSON.parse(JSON.stringify(attribute.value));
            this.selected_measurement_unit = attribute.measurement_unit;
            this.selected_measurement_value = attribute.measurement_value;
            this.newFeature = true;
            let self = this;
            setTimeout(function () {
              self.loadingEditAttributeProgress = 100;
              self.loadingEditAttribute = false;
            }, 150);
          },
          existsInArray(array, object){
            if(array !== null){
             return array.findIndex(item => item == object);
            }
          },
           editProductVariant(attribute, index){
            this.variantFormLoading = true;
            this.loadingEditAttributeProgress = 0;
            this.product_variantForm = attribute;
              /*if(attribute.attribute.values !== null){
                this.attribute_values = attribute.attribute.values;
              }*/
            var attrib = this.all_attributes.filter(item => item.id == attribute.attribute.id);
            if(attrib.length > 0){
              this.product_variantForm.attribute["values"] == [];
              this.product_variantForm.attribute["values"] ==  attrib[0].values;
            }
            //this.product_variantForm.attribute = JSON.parse(JSON.stringify(attribute.value));
            //this.selected_measurement_unit = attribute.measurement_unit;
            //this.selected_measurement_value = attribute.measurement_value;
            //this.newFeature = true;
            let self = this;
            setTimeout(function () {
              self.loadingEditAttributeProgress = 100;
              self.variantFormLoading = false;
            }, 150);
          },
          addVariant(){
             this.variantFormLoading = true;
            this.loadingEditAttributeProgress = 0;
            var productid = '';
            if(this.$attrs.id !== 'add'){
              productid = this.productData.id;
            }
            this.product_variantForm = {
                    "product_id": productid,
                    "id": '',
                    "attribute_id": '',
                    "attribute_value_id": '',
                    "measurement_value": '',
                    "measurement_unit_id": '',
                    "price_difference": 0.00,
                    "cut": '',
                    "quality": '',
                    "quantity": 1,
                    "clarity": '',
                    "colour": '',
                    "is_custom": 0,
                    "custom_value":  '',
                    "custom_text": '', 
                    "active": 1,
                    "default": 1,
                    "images": [],
                    units: null,
                    value: null,
                    attribute_values:[],
                    attribute: null
                };

              let self = this;
                setTimeout(function () {
                  self.loadingEditAttributeProgress = 100;
                  self.variantFormLoading = false;
                }, 150);
          },
          addCustomVariant(){
            var productid = '';
            if(this.$attrs.id !== 'add'){
              productid = this.productData.id;
            }
            this.product_variantForm = {
                    "product_id": productid,
                    "id": '',
                    "attribute_id": '',
                    "attribute_value_id": '',
                    "measurement_value": '',
                    "measurement_unit_id": '',
                    "price_difference": 0.00,
                    "quantity": '',
                    "is_custom": 1,
                    "cut": '',
                    "quality": '',
                    "quantity": 1,
                    "clarity": '',
                    "colour": '',
                    "custom_value":  '',
                    "custom_text": '', 
                    "active": 1,
                    "default": 1,
                    "images": [],
                    units: null,
                    value: null,
                    attribute: null,
                    attribute_values:[]
                };
          },
          saveVariant(variant){
            if(this.$attrs.id !== 'add'){
              axios.post('/api/company/product/'+this.$attrs.id+'/variant/create', this.product_variantForm, {headers : {
                'Authorization' : 'Bearer '+this.$attrs.user.api_token 
              }}).then(response => {
                   console.log(response);
                    this.product_attribute_success = response.data;
                    this.productData.variants.push(JSON.parse(JSON.stringify(this.product_variantForm)));
                    this.product_variantForm = '';
                    this.success_messagetext = 'Product Variation Added';
                    this.success_message = true;
                    
                    let self = this;
                    setTimeout(function(){
                      self.success_message = false;
                    }, 300);
                }).catch(error => {
                  console.log(error);
                });
            }else{
              this.productData.variants.push(JSON.parse(JSON.stringify(this.product_variantForm)));
              this.product_variantForm = '';
            }
          },
          updateVariant(variant){
            if(this.$attrs.id !== 'add'){
              axios.post('/api/company/product/'+this.$attrs.id+'/variant/'+variant.id+'/update', this.product_variantForm, {headers : {
                'Authorization' : 'Bearer '+this.$attrs.user.api_token 
              }}).then(response => {
                   console.log(response);
                    this.product_attribute_success = response.data;
                    //this.productData.variants.push(JSON.parse(JSON.stringify(this.product_variantForm)));
                    this.product_variantForm = '';
                    this.success_messagetext = 'Product Variation Added';
                    this.success_message = true;
                    
                    let self = this;
                    setTimeout(function(){
                      self.success_message = false;
                    }, 300);
                }).catch(error => {
                  console.log(error);
                });
            }else{
              this.productData.variants[this.productData.variants.findIndex(item => item.id == this.product_variantForm.id)] = variant;
              this.product_variantForm = '';
            }
          },
          destroyProductVariant(variant, index){
            if(this.$attrs.id !== 'add'){
              axios.post('/api/company/product/'+this.$attrs.id+'/variant/'+variant.id+'/destroy', {}, {headers : {
                'Authorization' : 'Bearer '+this.$attrs.user.api_token 
              }}).then(response => {
                   console.log(response);
                    this.product_attribute_success = response.data;
                    this.productData.variants.push(JSON.parse(JSON.stringify(this.product_variantForm)));
                    this.product_variantForm = '';
                    this.success_messagetext = 'Product Variation Removed';
                    this.success_message = true;
                    
                    let self = this;
                    setTimeout(function(){
                      self.success_message = false;
                    }, 300);
                }).catch(error => {
                  console.log(error);
                });
            }else{
              this.productData.variant.splice(index, 1);
            }
          },
          removeVariant(index){
            
          },
          saveCombination(){
            if(this.$attrs.id !== 'add'){
              this.product_combinationsForm.product_id = this.productData.id;
              axios.post('/api/company/product/'+this.$attrs.id+'/combination/create', this.product_combinationsForm, {headers : {
                'Authorization' : 'Bearer '+this.$attrs.user.api_token 
              }}).then(response => {
                   console.log(response);
                    this.product_attribute_success = response.data;
                    for(var i = 0; i < this.product_combinationsForm.variants.length; i++){
                      this.product_combinationsForm.variants[i]["variant"] = this.product_combinationsForm.variants[i];
                    }
                    this.productData.combinations.push(Object.assign({}, this.product_combinationsForm));
                    this.product_combinationsForm = '';
                    this.success_messagetext = 'Product Combination Added';
                    this.success_message = true;
                    
                    let self = this;
                    setTimeout(function(){
                      self.success_message = false;
                    }, 300);
                }).catch(error => {
                  console.log(error);
                });

            }else{
              for(var i = 0; i < this.product_combinationsForm.variants.length; i++){
                      this.product_combinationsForm.variants[i]["variant"] = this.product_combinationsForm.variants[i];
                    }
              //this.productData.combinations.push(JSON.parse(JSON.stringify(this.product_combinationsForm)));
              this.productData.combinations.push(Object.assign({}, this.product_combinationsForm));
              this.product_combinationsForm = '';
            }
          },
          destroyCombination(combination, index){
              if(this.$attrs.id !== 'add'){
                axios.post('/api/company/product/'+this.$attrs.id+'/combination/'+combination.id+'/destroy', {}, {headers : {
                'Authorization' : 'Bearer '+this.$attrs.user.api_token 
              }}).then(response => {
                   console.log(response);
                    this.product_attribute_success = response.data;
                    this.productData.combinations.splice(index, 1);
                    this.success_messagetext = 'Product Variation Removed';
                    this.success_message = true;
                    
                    let self = this;
                    setTimeout(function(){
                      self.success_message = false;
                    }, 300);
                }).catch(error => {
                  console.log(error);
                });
              }else{
                this.productData.combinations.splice(index, 1);
              }
          },
          updateActiveCollection(collection){
            var activeCollections = this.productData.collections.findIndex(item => item.collection.id == collection.id);
            if(activeCollections < 0){
              this.addToCategoryCollection(collection["id"], 0, '', 'collection');
              collection["collection"] = collection;
              this.productData.collections.push(collection);
            }else{
              this.removeFromCollection(collection["id"], activeCollections, '');
              this.productData.collections.splice(activeCollections, 1);
            }
          },
          activeInCollections(collection){
            if(this.productData.collections.length > 0){
            var activeCollections = this.productData.collections.filter(item => item.collection.id == collection.id);
              if(activeCollections.length > 0){
                return true;
              }else{
                return false;
              }
            }

          },
          updateActiveCategories(category, type){
            if(type == 'company'){
              var activeCategories = this.productData.company_categories.findIndex(item => item.id == category.id);
              if(activeCategories < 0){
                this.addToCategoryCollection(category["id"], 0, type, 'category');
                category["category"] = category;
                this.productData.company_categories.push(category);
              }else{
                this.removeFromCategory(category["id"], activeCategories, type);
                this.productData.company_categories.splice(activeCategories, 1);
              }
            }
            if(type == 'public'){
             var activeCategories = this.productData.public_marketplace_categories.findIndex(item => item.id == category.id);
              if(activeCategories < 0){
                this.addToCategoryCollection(category["id"], 0, type, 'category');
                category["category"] = category;
                this.productData.public_marketplace_categories.push(category);
              }else{
                this.removeFromCategory(category["id"], activeCategories, type);
                this.productData.public_marketplace_categories.splice(activeCategories, 1);
              }


            }
            if(type == 'trade'){
              var activeCategories = this.productData.trade_marketplace_categories.findIndex(item => item.id == category.id);
              if(activeCategories < 0){
                this.addToCategoryCollection(category["id"], 0, type, 'category');
                category["category"] = category;
                this.productData.trade_marketplace_categories.push(category);
              }else{
                this.removeFromCategory(category["id"], activeCategories, type);
                this.productData.trade_marketplace_categories.splice(activeCategories, 1);
              }
            }
          },
          updateHistory(hash){
            window.history.pushState({}, '', '#'+hash);
            if(this.editable == false){
              this.editable = true;
            }
          },
          getShippingOptions(){
             if(this.orderDesc == true){
                this.order = 'desc';
              }else{
                this.order = 'asc';
              }
              axios.get('/api/company/order/delivery-options?page=1&limit=100&sortby=id&order=desc&search=&trade_active='+this.productData.trade+'&public_active='+this.productData.public+'&active='+this.productData.active, {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
                console.log(response);
                this.shippingoptions = response.data.data;
              });
          },
          activeInCategories(category, type){
            if(type == 'company'){
              if(this.productData.company_categories.length > 0){
                var activeCategories = this.productData.company_categories.filter(item => item.category.id == category.id);
                if(activeCategories.length > 0){
                  return true;
                }else{
                  return false;
                }
              }
            }
            if(type == 'public'){
              if(this.productData.public_marketplace_categories.length > 0){
                var activePubCategories = this.productData.public_marketplace_categories.filter(item => item.category.id == category.id);
                if(activePubCategories.length > 0){
                  return true;
                }else{
                  return false;
                }
              }
            }
            if(type == 'trade'){
              if(this.productData.trade_marketplace_categories.length > 0){
                var activeTradCategories = this.productData.trade_marketplace_categories.filter(item => item.category.id == category.id);
                if(activeTradCategories.length > 0){
                  return true;
                }else{
                  return false;
                }
              }
            }

          },
          saveProduct(product){
            var postForm = new FormData(document.getElementById("add-product"));
            var categories = [];
            for(var i = 0; i < this.productData.company_categories.length; i++){
              categories.push(this.productData.company_categories[i].id);
            }
            postForm.append('categories', categories);
            var public_marketplace_categories = [];
            for(var i = 0; i < this.productData.public_marketplace_categories.length; i++){
              public_marketplace_categories.push(this.productData.public_marketplace_categories[i].id);
            }
            postForm.append('public_market_categories', public_marketplace_categories);
            var trade_marketplace_categories = [];
            for(var i = 0; i < this.productData.trade_marketplace_categories.length; i++){
              trade_marketplace_categories.push(this.productData.trade_marketplace_categories[i].id);
            }
            postForm.append('trade_market_categories', trade_marketplace_categories);
            var collections = [];
            for(var i = 0; i < this.productData.collections.length; i++){
              collections.push(this.productData.collections[i].id);
            }
            postForm.append('collections', collections);

            var attributes = JSON.stringify(this.productData.attributes);
            postForm.append('attributes', attributes);
            postForm.append('cover_image', this.productData.cover_image);
            postForm.append('listings', this.productData.listings);
            for(var i = 0; i < this.productData.images.length; i++){
              postForm.append('images[]', this.productData.images[i]);
            }
            axios.post('/api/company/product/create', postForm, {headers: {
              'Authorization' : 'Bearer '+this.$attrs.user.api_token
            }}).then(response => {
                this.productData = response.data;
                window.location.replace("/stock/product/"+response.data.uid);
              }).catch(error => {
                this.error_reponse = error.response.data.errors;
                var self = this;
                setTimeout(function(){
                 //Vue.swal("Avatar Updated");
                 self.error_message = true;
                }, 500);
                
              });
          },
          updateProduct(product){
            var postForm = new FormData(document.getElementById("add-product"));
            //postForm.append('attributes', JSON.stringify(this.productData.attributes));
            //postForm.append('collections', this.active_collections);
            //postForm.append('categories', JSON.stringify(this.productData.company_categories));
            //postForm.append('public_market_categories', this.active_public_categories);
            //postForm.append('trade_market_categories', this.active_trade_categories);
            postForm.append('cover_image', this.productData.cover_image);
            for(var i = 0; i < this.productData.listings.length; i++){
              postForm.append('listings[]',  JSON.stringify(this.productData.listings[i]));
            }
            if(this.productData.custom_fields !== null){
              if(Object.keys(this.productData.custom_fields).includes('fields') == true){
                postForm.append('custom_fields[]',  JSON.stringify(this.productData.custom_fields['fields']));
              }
            }             
            /*for(var i = 0; i < this.productData.images.length; i++){
              postForm.append('images[]', this.productData.images[i]);
            }*/
            this.loadingForm = true;
            axios.post('/api/company/product/'+this.productData.uid+'/update', postForm, {headers: {
              'Authorization' : 'Bearer '+this.$attrs.user.api_token
            }}).then(response => {
                //this.productData = response.data;
                this.success_messagetext = "Product updated";
                this.success_message = true;
                let self = this
                setTimeout(function(){
                 //Vue.swal("Avatar Updated");
                 self.success_message = false;
                 self.loadingForm = false;
                }, 300);
              }).catch(error => {
                this.error_reponse = error.response.data.errors;
                this.error_message = true;
                var self = this;
                setTimeout(function(){
                 //Vue.swal("Avatar Updated");
                 self.loadingForm = false;
                }, 300);
                
              });
          },
          getPurchaseOrders(){
            axios.get('/api/company/product/purchases?page='+this.currentPage+'&limit='+this.purchaselimit+'&sortby=id&order=desc&search='+this.purchasesearch, {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
                console.log(response);
                this.purchase_orders = response.data;
                this.showPurchaseResults = true;
              });
          },
          sanitizeTitle: function(title) {
            var nickname = "";
            // Change to lower case
            var titleLower = title.toLowerCase();
            // Letter "e"
            nickname = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e');
            // Letter "a"
            nickname = nickname.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a');
            // Letter "o"
            nickname = nickname.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o');
            // Letter "u"
            nickname = nickname.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u');
            // Letter "d"
            nickname = nickname.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g,"");
            
            nickname = nickname.replace(/đ/gi, 'd');
            // Trim the last whitespace
            nickname = nickname.replace(/\s*$/g, '');
            // Change whitespace to "-"
            nickname = nickname.replace(/\s+/g, '-');
            // Remove quotations
            nickname = nickname.replace(/["']/g, "");
            
            return nickname;
        },
        imageAdded(file){
          if((this.productData.cover_image > 0) && (this.productData.cover_image !== null)){
            this.productData.images.push(file);
          }else{
            this.productData.cover_image = file;
          }
        },
        imageUploaded(file, response){
          if((this.productData.cover_image !== null)){
            if(this.productData.images == null){
              this.productData.images = [];
            }
            this.productData.images.push(response);
             this.$refs.myUpdateImageDropzone.removeFile(response);
          }else{
            this.productData.cover_image = response;
             this.$refs.myUpdateImageDropzone.removeFile(response);
          }
        },
        uploadError(file){
          this.error_reponse = [["File could not be uploaded, please try again."]];
          this.error_message = true;
        },
        videoAdded(file){
          if((this.productData.cover_video > 0) && (this.productData.cover_video !== null)){
              if(this.productData.videos == null){
              this.productData.images = [];
            }
            this.productData.videos.push(file);
          }else{
            this.productData.cover_video = file;
          }
        },
        videoUploaded(file, response){
          if((this.productData.cover_video !== null)){
            this.productData.videos.push(response);
          }else{
            this.productData.cover_video = response;
          }
        },
        imageDeleted(file, index, iscover){
          this.loadingImgForm = true;
          var deletefile = file;
          deletefile["iscover"] = iscover;
          deletefile["index"] = index;
          axios.post('/api/company/product/'+this.productData.uid+'/delete-image', deletefile, {headers: this.updateDropzoneOptions.headers}).then(response => {
                  if(iscover == true){
                    if(this.productData.images.length > 0){
                      this.imageMakeCover(this.productData.images[0], 0);
                      this.productData.cover_image = this.productData.images[0];
                      this.productData.images.splice(0, 1);
                    }else{
                      this.productData.cover_image = null;
                      this.loadingImgForm = false;
                    }
                  }else{
                    this.productData.images.splice(index, 1);
                    this.loadingImgForm = false;
                  }

            }).catch(error => {
              this.error_reponse = error.response.error;
              this.error_message = "Error Deleting Image.";
              this.loadingImgForm = false;
            });
          /*if((this.productData.cover_image > 0) && (this.productData.cover_image !== null)){
            this.productData.images.push(file);
          }else{
            this.productData.cover_image = file;
          }*/
        },
        imageMakeCover(file, index){
          this.loadingImgForm = true;
          var cover = {};
          cover['image'] = file;
          cover['index'] = index;
          axios.post('/api/company/product/'+this.productData.uid+'/update-image-cover', cover, {headers: this.updateDropzoneOptions.headers}).then(response => {
                      this.productData.images.splice(index, 1);
                      this.productData.images.unshift(this.productData.cover_image);
                      this.productData.cover_image = file;
                      this.loadingImgForm = false;
            }).catch(error => {
              this.error_reponse = error.response.error;
              this.error_message = "Error Making Cover Image.";
              this.loadingImgForm = false;
            });
        },
        imageCaptionUpdate(imageindex, iscover, ishidden){
          this.loadingImgForm = true;
          var updateObject = {cover: iscover, caption_text: '', index: imageindex, hideonweb: ishidden};
          updateObject["cover"] = iscover;
          if(iscover == true){
            updateObject['caption_text'] = this.productData.cover_image[0].caption;
            updateObject['index'] = 0;
          }else{
            updateObject['caption_text'] = this.productData.images[imageindex].caption;
            updateObject['index'] = imageindex;
          }
          axios.post('/api/company/product/'+this.productData.uid+'/update-image-caption', updateObject, {headers: {
            'Authorization' : 'Bearer '+this.$attrs.user.api_token
          }}).then(response => {
            this.success_messagetext = 'Image caption updated!';
            this.success_message = true;
            this.editcaption = -1;
            this.productData.images[imageindex]['hideonweb'] = ishidden;
           
            var self = this;
            setTimeout(function(){
                 //Vue.swal("Avatar Updated");
                 self.success_message = false;
                 self.loadingImgForm = false;
                }, 500);
          }).catch(error => {
             this.error_reponse = error;
          });
        },
        getListingTypes(){
          axios.get('/api/company/listing/types', {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
          this.listing_types = response.data;
          }).catch(error => {
            this.shops_error = error.data;
          });
        },
        urlRewrite(name){
          if(this.$attrs.id == "add"){
            this.productData.url_rewrite = this.sanitizeTitle(name);
            this.productData.meta_title = name;
          }
        },
        test(fieldindex){
          alert('clicked '+fieldindex);
        }
        /*addTax(price, tax_rule){
          console.log(tax_rule);
          var country_id = this.$attrs.company.country_id;
          if(tax_rule !== null && tax_rule.country_rules.length > 0){
            var selected_rule = null;
            var selected_ruleIndex = -1;
          for(var ti = 0; ti < tax_rule.country_rules.length; ti++){
            selected_ruleIndex = tax_rule.country_rules[ti].countries.findIndex(item => item == country_id);
            if(selected_ruleIndex !== -1){
              selected_rule = tax_rule.country_rules[ti];
              ti = tax_rule.country_rules.length;
            }
          }
          console.log(selected_rule);
          if(tax_rule.type == 1){
              if(selected_rule !== null){
                var percentage = (parseFloat(selected_rule.value) / 100) + 1;
                this.active_tax_percentage = percentage;
                return price * percentage;
              }else{
                return price;
              }
            }else{
              if(selected_rule !== null){
                return price + selected_rule.value;
              }else{
                return price;
              }
            }
            }else{
              return price;
            }
          },*/
  },
  created(){
        /*axios.get('/api/company/product/taxrules?api_token='+this.$attrs.user.api_token).then(response => {
                 console.log(response);
                this.all_tax_rules = response.data;
                this.active_tax_rule = response.data[0].id;
                //this.brandsearch_results = this.all_brands;
            });
        */
         axios.get('/api/company/order/tax-rules?&page='+this.productsCurrentPage+'&limit=0&sortby=id&order=desc&search=', {headers: {
              'Authorization' : 'Bearer '+this.$attrs.user.api_token
            }}).then(response => {
                this.all_tax_rules = response.data;
                if(response.data.length > 0){
                  this.active_tax_rule = response.data[0].id;
                  this.selected_tax_rule = response.data[0];
                }
         });

        axios.get('/api/company/product/attribute/measurement-units', {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
                 console.log(response);
                this.measurement_units = response.data;
            });
        var hash = window.location.hash;
        if(hash !== '#'){
            this.view = hash.replace('#','');
            }


  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll)
  },
  computed: {
    companyCurrency(){
      var currency = this.$attrs.currencies.filter(item => item.id == this.$attrs.company.trading_currency_id);
      return currency[0].symbol;
    },
    filteredReviews(){
      if(this.reviewsFilteredBy !== ''){
        return this.productData.reviews.filter(item => item.source == this.reviewsFilteredBy);
      }else{
        return this.productData.reviews;
      }
    },
          variantFormFilled(){
            if(
              ((this.product_variantForm.is_custom == 0) || (this.product_variantForm.is_custom == false))
              &&
              ((this.product_variantForm.attribute !== null) && (this.product_variantForm.value !== null))

              ){
              return false;
            }else if (
              ((this.product_variantForm.is_custom == 1) || (this.product_variantForm.is_custom == true))
              &&
              ((this.product_variantForm.attribute !== null) && (this.product_variantForm.custom_text !== ''))

              ){
              return false;
            }else{
              return true;
            }
          },
          listingsTotalQuantities(){
            var score = 0;
            for(var i = 0; i < this.productData.listings.length; i++){
              score = parseFloat(score) + parseFloat(this.productData.listings[i].quantity);
            }
            return score;
          },
            activeTaxPercentage: function(){
                /*var activeObject = this.all_tax_rules.filter(item => item.id === this.active_tax_rule);

                var country_id = this.$attrs.company.country_id;
                if(activeObject[0].country_rules.length > 0){
                  var activeTaxRule = activeObject[0].country_rules.countries.findIndex(item => item == country_id);
                  this.active_tax_percentage = parseFloat(activeObject[0].country_rules[activeTaxRule].value).toFixed(2);
                }else{
                  this.active_tax_percentage = activeObject[0].percentage;
                }
                var setMultiplier = this.active_tax_percentage / 100;
                var multiplier = setMultiplier + 1;
                var calculation = this.productData.sales_price * multiplier;
                return this.active_tax_percentage;*/
            },
            calculateIncVat: function(){
                /*var activeObject = this.all_tax_rules.filter(item => item.id === this.active_tax_rule);
                 var country_id = this.$attrs.company.country_id;
                if(activeObject[0].country_rules.length > 0){
                  var activeTaxRule = activeObject[0].country_rules.countries.findIndex(item => item == country_id);
                  this.active_tax_percentage = parseFloat(activeObject[0].country_rules[activeTaxRule].value).toFixed(2);
                }else{
                  this.active_tax_percentage = activeObject[0].percentage;
                }
                var setMultiplier = this.active_tax_percentage / 100;
                var multiplier = setMultiplier + 1;
                var calculation = this.productData.sales_price * multiplier;*/
                var calculation = this.addTax(this.selected_tax_rule, this.productData.sales_price);
                this.inc_tax_price = calculation.toFixed(2);
                this.calcCostPrice();
                this.calcProfitMargin();
                return calculation.toFixed(2);
          },

          panelHeight(){
            this.windowInnerHeight = window.innerHeight;
            return window.innerHeight;
          },
          variantsQuantities(){
            if(this.productData.variants.length > 0){
              var qty = 0.00;
              for(var i = 0; i < this.productData.variants.length; i++){
                qty += parseFloat(this.productData.variants[i].quantity);
              }
              return qty;
            }
          },
          variantAttributeGroups(){
            if(this.productData.variants.length > 0){
              var qty = 0.00;
              var activeattributes = [];
              for(var i = 0; i < this.productData.variants.length; i++){
                if(this.productData.variants[i].attribute !== null){
                  var attribexists = activeattributes.includes(this.productData.variants[i].attribute.id);
                  if(!attribexists){
                    activeattributes.push(this.productData.variants[i].attribute.id);
                    qty++;
                  }
                
                }

              }
              return qty;
            }
          },
          combinationsQuantities(){
            if(this.productData.combinations.length > 0){
              var qty = 0;
              for(var i = 0; i < this.productData.combinations.length; i++){
                qty += parseFloat(this.productData.combinations[i].quantity);
              }
              return qty;
            }
          }
  }
}
</script>
<!--<style>
html, body {margin: 0; height: 100%; overflow: hidden}
</style>-->
<template>
  <Layout  :nickname="$attrs.nickname" :user="$attrs.user" :menuItems="$attrs.menuItems" >
    <PageHeader :title="title" :items="items" />
  
    <div class="row">
      <b-col md="12">
      <transition name="slide-error" mode="out-in">
                    <b-alert id="errors" variant="danger" :show="error_message == true"  dismissible @dismissed="error_message = !error_message">
                      <p class="font-size-14">Oops! Please correct the following errors in your submission</p>
                      <ul>
                        <li v-for="(error, key) in error_reponse">
                          {{error[0]}}
                        </li>
                      </ul>
                      <small>Please Try Again. If this error persists, please contact support.</small>
                    </b-alert>
                    
                  </transition>
                </b-col>
                <b-col md="12">
                    <transition name="slide-success" mode="out-in">
                  <b-alert id="success-message" variant="success" :show="success_message"  dismissible @dismissed="success_message = !success_message">
                      <p class="font-size-14">{{success_messagetext}}</p>
                    </b-alert>
                  </transition>
                  </b-col>
      <b-col md="2" >
        <div class="product-nav">
        <b-card no-body class="py-2">
            <b-list-group flush class="pl-3">
              <b-list-group-item 
               @click="view = 'basic-info', updateHistory(view)"
               class="nav-tab d-flex"
               :class="{'active' : view == ('basic-info' || '')}">
                <i :class="{'text-primary' : view !== 'basic-info', 'text-white' : view == 'basic-info'}" class="my-auto font-size-16 mr-1 bx bx-info-circle"></i> Basic info
              </b-list-group-item>
              <b-list-group-item 
               @click="view = 'media', updateHistory(view)"
               class="nav-tab d-flex"
               :class="{'active' : view == 'media'}">
                <i :class="{'text-primary' : view !== 'media', 'text-white' : view == 'media'}" class="my-auto font-size-16 mr-1 bx bx-image-alt"></i> Media
              </b-list-group-item>
             
              <b-list-group-item 
               @click="view = 'pricing', updateHistory(view)"
               class="nav-tab d-flex"
               :class="{'active' : view == 'pricing'}">
               <i :class="{'text-primary' : view !== 'pricing', 'text-white' : view == 'pricing'}" class="my-auto font-size-16 mr-1 bx bx-pound"></i>  Pricing
              </b-list-group-item>
              <b-list-group-item 
               @click="view = 'listings', getShops(), getCommerceChannels(), updateHistory(view)"
               class="nav-tab d-flex"
               :class="{'active' : view == 'listings'}">
               <i :class="{'text-primary' : view !== 'listings', 'text-white' : view == 'listings'}" class="my-auto font-size-16 mr-1 bx bxs-store"></i> Quantity
              </b-list-group-item>
              <b-list-group-item 
               @click="getCategories('company'), getCategories('public'), getCategories('trade'), getCollections(), view = 'categories-collections', updateHistory(view)"
               class="nav-tab d-flex"
               :class="{'active' : view == 'categories-collections'}">
                <i :class="{'text-primary' : view !== 'categories-collections', 'text-white' : view == 'categories-collections'}" class="my-auto font-size-16 mr-1 mdi mdi-view-grid-plus-outline"></i> Categories & Collections
              </b-list-group-item>
              <b-list-group-item 
               @click="view = 'features-variants', updateHistory(view)"
               class="nav-tab d-flex"
               :class="{'active' : view == 'features-variants'}">
               <i :class="{'text-primary' : view !== 'features-variants', 'text-white' : view == 'features-variants'}" class="my-auto font-size-16 mr-1 bx bx-list-plus"></i> Features & Variants
              </b-list-group-item>
               <b-list-group-item 
               @click="view = 'related-products', updateHistory(view)"
               class="nav-tab d-flex"
               :class="{'active' : view == 'related-products'}">
               <i :class="{'text-primary' : view !== 'related-products', 'text-white' : view == 'related-products'}" class="my-auto font-size-16 mr-1 bx bx-list-plus"></i> Related Products
              </b-list-group-item>

              <!--<b-list-group-item 
               @click="view = 'shipping', updateHistory(view)"
               class="nav-tab d-flex"
               :class="{'active' : view == 'shipping'}">
               <i :class="{'text-primary' : view !== 'shipping', 'text-white' : view == 'shipping'}" class="my-auto font-size-16 mr-1 bx bxs-truck"></i>  Shipping
              </b-list-group-item>
              -->
              <b-list-group-item 
               @click="view = 'custom', getCustomFieldSets(), updateHistory(view)"
               class="nav-tab d-flex"
               :class="{'active' : view == 'custom'}">
                <i :class="{'text-primary' : view !== 'custom', 'text-white' : view == 'custom'}" class="my-auto font-size-16 mr-1 bx bx-list-plus"></i> Custom Fields
              </b-list-group-item>
              <b-list-group-item 
               @click="view = 'seo', updateHistory(view)"
               class="nav-tab d-flex"
               :class="{'active' : view == 'seo'}">
                <i :class="{'text-primary' : view !== 'seo', 'text-white' : view == 'seo'}" class="my-auto font-size-16 mr-1 bx bx-globe"></i> SEO
              </b-list-group-item>
              <b-list-group-item 
               @click="view = 'condition', updateHistory(view)"
               class="nav-tab d-flex"
               :class="{'active' : view == 'condition'}">
               <i :class="{'text-primary' : view !== 'condition', 'text-white' : view == 'condition'}" class="my-auto font-size-16 mr-1 bx bx-wrench"></i> Condition
              </b-list-group-item>
              <b-list-group-item 
               @click="view = 'barcodes', updateHistory(view)"
               class="nav-tab d-flex"
               :class="{'active' : view == 'barcodes'}">
               <i :class="{'text-primary' : view !== 'barcodes', 'text-white' : view == 'barcodes'}" class="my-auto font-size-16 mr-1 bx bx-barcode"></i> QR & Barcodes
              </b-list-group-item>
              <b-list-group-item 
               @click="view = 'purchase', updateHistory(view)"
               class="nav-tab d-flex"
               :class="{'active' : view == 'purchase'}">
               <i :class="{'text-primary' : view !== 'purchase', 'text-white' : view == 'purchase'}" class="my-auto font-size-16 mr-1 bx bx-barcode"></i> Purchase Order
              </b-list-group-item>
               <b-list-group-item 
               @click="view = 'integrations', getCommerceChannels(), fetchChannelsIntegrationsDetails('channels-api'), updateHistory(view)"
               class="nav-tab d-flex"
               :class="{'active' : view == 'integrations'}">
               <i :class="{'text-primary' : view !== 'integrations', 'text-white' : view == 'integrations'}" class="my-auto font-size-16 mr-1 bx bx-transfer-alt"></i> Integrations
              </b-list-group-item>
              <b-list-group-item  class="nav-tab d-flex border-bottom border-top-none border-primary bg-light text-primary border-1">
                Stats and Data
              </b-list-group-item>
               <b-list-group-item 
               @click="getProductOrders(productData.id), view = 'orders', updateHistory(view)"
               class="nav-tab d-flex"
               :class="{'active' : view == 'orders'}">
               <i :class="{'text-primary' : view !== 'orders', 'text-white' : view == 'orders'}" class="my-auto font-size-16 mr-1 bx bx-receipt"></i> Orders
              </b-list-group-item>
              <!--<b-list-group-item 
               @click="view = 'shipping', updateHistory(view)"
               class="nav-tab d-flex"
               :class="{'active' : view == 'shipping'}">
               <i :class="{'text-primary' : view !== 'shipping', 'text-white' : view == 'shipping'}" class="my-auto font-size-16 mr-1 bx bxs-truck"></i>  Shipping
              </b-list-group-item>
              -->
              <b-list-group-item 
               @click="view = 'reviews', updateHistory(view)"
               class="nav-tab d-flex"
               :class="{'active' : view == 'reviews'}">
                <i :class="{'text-primary' : view !== 'reviews', 'text-white' : view == 'reviews'}" class="my-auto font-size-16 mr-1 bx bx-star"></i> Reviews
              </b-list-group-item>
              <b-list-group-item 
               @click="view = 'sales-stats', updateHistory(view), loadViewAssets(view, productData);"
               class="nav-tab d-flex"
               :class="{'active' : view == 'sales-stats'}">
               <i :class="{'text-primary' : view !== 'sales-stats', 'text-white' : view == 'sales-stats'}" class="my-auto font-size-16 mr-1 bx bx-line-chart"></i> Sales Stats
              </b-list-group-item>
              <!--<b-list-group-item>
                Appraise
              </b-list-group-item>-->
            </b-list-group>
           
          </b-card>
         <!-- <b-button variant="info" @click="editable = false, showDetails = true" class="btn btn-block btn-rounded btn-info mt-2" v-if="productData.id !== null">
              Stats and Details

            </b-button>-->
            <b-row>
              <b-col md="12" class="mb-2">
                <button class="btn btn-primary btn-soft btn-rounded btn-block" v-b-modal.print-label>
                  <div class="d-flex mx-2 my-1">
                Print Label  <i class="bx bx-printer font-size-20 ml-auto icon-label-end"></i>
                  </div>
                </button>
              </b-col>
              <b-col md="12" class="mb-2">
                <button class="btn btn-primary btn-soft btn-rounded btn-block" v-if="editable == true"  @click="editable = !editable">
                  <div class="d-flex mx-2 my-1">
                Preview <i class="mdi mdi-eye font-size-20 ml-auto icon-label-end"></i>
                  </div>
                </button>
                <button class="btn btn-primary btn-soft btn-rounded btn-block"  v-if="editable == false"  @click="editable = !editable">
                  <div class="d-flex mx-2 my-1">
                Close Preview <i class="mdi mdi-close font-size-20 ml-auto icon-label-end"></i>
                  </div>
                </button>
              </b-col>
              <b-col md="12" class="mb-2">
                <b-button variant="success" @click="saveProduct(productData)" class="btn btn-success btn-rounded btn-block" v-if="(($attrs.user.companies[0].userrole.name == ('Owner' || 'Manager')) && productData.id == null) || ($attrs.user.companies[0].permissions.add.includes($attrs.active_menu.id) && productData.id == null)" >
                  <div class="d-flex mx-2 my-1">
                    Save
                    <i class="bx bx-check font-size-20 ml-auto icon-label-end"></i>
                 </div>
                </b-button>
                <b-button variant="success" @click="updateProduct(productData)" class="btn btn-success btn-rounded btn-block" v-if="(($attrs.user.companies[0].userrole.name == ('Owner' || 'Manager')) && productData.id !== null) || ($attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id) && productData.id !== null)">
                  <div class="d-flex mx-2 my-1">
                    Update
                   <i class="bx bx-check font-size-20 ml-auto icon-label-end"></i>
                 </div>
                </b-button>
              </b-col>
               <b-col md="12" class="mb-2">
                <button class="btn btn-danger btn-rounded btn-block" v-b-modal.delete-product>
                  <div class="d-flex mx-2 my-1">
                Delete  <i class="bx bx-trash font-size-20 ml-auto icon-label-end"></i>
                  </div>
                </button>
              </b-col>
            </b-row>
           
             
           
          </div>
      </b-col>

       <div  class="col-10" v-if="($attrs.user.companies[0].userrole.name !== ('Owner' || 'Manager')) && ((!$attrs.user.companies[0].permissions.edit.includes($attrs.active_menu.id) && (this.$attrs.id !== 'add')) || (!$attrs.user.companies[0].permissions.add.includes($attrs.active_menu.id) && (this.$attrs.id == 'add')))">
          <AccessRestricted></AccessRestricted>
        </div>
    <div class="col-10" style=""  v-else-if="editable == true" >

      <b-overlay :show="loadingForm">
       <template #overlay>
            <div class="cube-wrapper"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
          </template>
      <form id="add-product" method="post" enctype="multipart/form-data">
        <input type="hidden" name="id" :value="productData.id">
      <b-card v-show="(view == 'basic-info') || (view == '')">
        <b-row>
          <b-col md="2">
            <h5 class="d-flex">Selling Options <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
          </b-col>
          <b-col md="5">
             <div class="card shadow-none border border-radius-1em" >
          <div class="card-body">
            <h5 class="font-size-16 text-primary">Selling Options</h5>
            <hr>
            <div class="d-flex">
            <b-button
                      class="flex-grow-1 my-0 w-sm"
                      value="0"
                      :class="{'active' : (productData.active == true || productData.active == 1 || productData.active == '1')}"
                      variant="outline-light"
                      @click="productData.active = !productData.active"
                    ><i v-if="productData.active == true || productData.active == 1 || productData.active == '1'" class="bx bx-check-circle font-size-24 d-block text-success my-2"></i> 
                    <i v-else class="bx bx-basket font-size-24 d-block text-primary my-2"></i> My Shops
              </b-button>
              <b-button
                      class=" flex-grow-1 my-0 w-sm"
                      value="0"
                      :class="{'active' : productData.public == true || productData.public == 1 || productData.public == '1'}"
                      variant="outline-light"
                      @click="productData.public = !productData.public"
                    ><i v-if="productData.public == true || productData.public == 1 || productData.public == '1'" class="bx bx-check-circle font-size-24 d-block text-success my-2"></i> 
                    <i v-else class="bx bx-basket font-size-24 d-block text-primary my-2"></i> Public Market
              </b-button>
              <b-button
                      class=" flex-grow-1 my-0 w-sm"
                      value="0"
                      :class="{'active' : productData.trade == true || productData.trade == 1 || productData.trade == '1'}"
                      variant="outline-light"
                      @click="productData.trade = !productData.trade"
                    ><i v-if="productData.trade == true || productData.trade == 1 || productData.trade == '1'" class="bx bx-check-circle font-size-24 d-block text-success my-2"></i> 
                    <i v-else class="bx bx-basket font-size-24 d-block text-primary my-2"></i> Trade Market
              </b-button>
              <input type="hidden" name="active" :value="productData.active">
              <input type="hidden" name="public_active" :value="productData.public">
              <input type="hidden" name="trade_active" :value="productData.trade">
            </div>
            
          </div>
        </div>
          </b-col>
          <b-col md="5">
             <div class="card shadow-none border border-radius-1em">
          <div class="card-body">
            <h5 class="font-size-16 text-primary">Product Type</h5>
            <hr>
            <div class="form-group mt-3">
              <b-form-radio-group v-model="product_type_id" buttons button-variant="outline-light" name="product_type_id">
              <b-form-radio
                      class="custom-radio custom-radio-outline custom-radio-primary my-0 w-sm"
                      value="0"
                      
                    ><i class="bx bx-basket font-size-24 d-block text-primary my-2"></i> Standard Product
              </b-form-radio>
              <b-form-radio
                      class="custom-radio custom-radio-outline custom-radio-primary my-0"
                      value="1"
                      
                    >
                    <i class="bx bx-list-plus font-size-24 d-block text-primary my-2"></i> 
                    Product with Variations
              </b-form-radio>
              <b-form-radio
                      class="custom-radio custom-radio-outline custom-radio-primary my-0"
                      value="2"
                      
                    >
                    <i class="bx bx-download font-size-24 d-block text-primary my-2"></i> 
                    Digital Download
              </b-form-radio>
            </b-form-radio-group>
            </div>
          </div>
      </div>
          </b-col>
        </b-row>
      </b-card>
      <b-card class="shadow-none border border-radius-1em" v-show="(view == 'basic-info') || (view == '')">
            <b-row>
              <b-col md="2">
               <h5 class="d-flex">Product Information <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
              </b-col>
              <b-col md="10">
              <b-card class="card shadow-none border border-radius-1em">
              <b-col md="12">
                  <b-row>
                     <b-col md="8">
                      <div class="form-group">
                        <label for="productname">Product Name</label>
                        <input id="productname" name="name" type="text" class="form-control" v-model="productData.name" @input="urlRewrite(productData.name)" />
                      </div>
                    </b-col>
                    <b-col md="4">
                      <div class="form-group">
                        <label for="productname">SKU/Reference</label>
                        <input id="productname" name="reference" type="text" class="form-control" v-model="productData.reference" />
                      </div>
                    </b-col>
                  </b-row>
                   
            </b-col>
            <b-tabs pills variant="light" class="mt-3" content-class="px-0 pt-2 text-muted">
              <b-tab active>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-home"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Summary</span>
                </template>
                <div class="form-group">
                    <textarea id="productdesc" class="form-control" rows="12" name="short_description" v-model="productData.short_description" @change="updateMetaDescription()"></textarea>
                  </div>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="far fa-user"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Description</span>
                </template>
                  <ckeditor :editor="editor" v-model="productData.long_description"></ckeditor>
                  <input type="hidden" name="long_description" :value="productData.long_description">
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="far fa-envelope"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Trade Description</span>
                </template>
                <ckeditor :editor="editor" name="trade_description" v-model="productData.trade_description"></ckeditor>
                <input type="hidden" name="trade_description" :value="productData.trade_description">
              </b-tab>
            </b-tabs>
          </b-card>
          </b-col>
        </b-row>
          </b-card>
        <b-card class="shadow-none border-radius-1em" v-show="view == 'media'">
        <b-row>
          <b-col md="2">
           <h5 class="d-flex">Images <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
          </b-col>
          <b-col md="10">
         <div class="card shadow-none border border-radius-1em">
            <div class="card-body">
                  <div class="product-detai-imgs">
                  <!---<label>Image Cropper Direct Test</label>
        <input @change="handleImage" class="custom-input" type="file" accept="image/*">
        **Test**-->
                  <b-overlay :show="loadingImgForm" spinner-variant="primary"
                    spinner-type="grow"
                    spinner-small
                    rounded="sm">
                    <b-tabs pills end nav-wrapper-class="d-flex" nav-class="mt-4 border border-radius-1em p-3 w-100">
                      <template #tabs-start>
                        <div class="w-100 d-block">
                        <h4 class="my-3">Image Gallery</h4>
                        <hr>
                        </div>
                      </template>
                      <b-tab v-if="(productData.cover_image !== null)" active title-item-class="position-relative" title-link-class="px-0 py-0 m-2">
                        <template v-slot:title>
                          <b-img-lazy v-if="'thumb' in productData.cover_image"
                            :src="storageurl+productData.cover_image.thumb.replace('//','/')"
                            alt
                            width="100px"
                            class="img-fluid mx-auto d-block tab-img rounded border"
                          />
                          <b-img-lazy
                            v-else-if="'src' in productData.cover_image"
                            :src="storageurl+productData.cover_image.src.replace('//','/')"
                            alt
                            width="100px"
                            class="img-fluid mx-auto d-block border tab-img rounded"
                          />
                
                          <span class="badge badge-light bg-light position-absolute top-0 mt-3">
                          <i class="bx bx-x-circle text-success">
                          </i>
                          Cover Image
                          </span>
                        </template>
                        <div class="product-img border border-radius-1em py-3 position-relative">
                          <b-img-lazy
                          v-if="'src' in productData.cover_image"
                           :src="storageurl+productData.cover_image.src.replace('//','/')"
                            alt
                            class="img-fluid mx-auto d-block"
                          />
                           <b-card class="shadow-none border border-radius-1em mt-2 mx-3 mb-5" body-class="d-flex">
                            <b-textarea v-model="productData.cover_image.caption" class="underline-input" ></b-textarea>
                            <a class="btn btn-light btn-rounded w-25 ml-auto" @click="editcaption = index, editcaptionrawtext = image.caption" v-if="editcaption !== index">Edit Caption <i class="bx bx-pencil"></i></a>
                           </b-card>
                          <div class="mb-3 position-absolute w-100 text-center mx-auto bottom-0 d-flex">
                            
                              <a href="javascript:;" class="btn btn-success btn-rounded ml-3"  @click="editImageUrl = storageurl+productData.cover_image.src.replace('//','/'), editImage = true" v-b-tooltip.hover title="Cover Image">Cover <i class="bx bx-check-circle"></i></a>
                              <a href="javascript:;" class="btn btn-light btn-rounded ml-2"  @click="editImageUrl = storageurl+productData.cover_image.src.replace('//','/'), editImage = true" v-b-tooltip.hover title="Edit Image">Edit <i class="bx bx-pencil"></i></a>
                              <a href="javascript:;" class="btn btn-danger btn-rounded ml-auto mr-3"  @click="imageDeleted(productData.cover_image, 0, true)" v-b-tooltip.hover title="Delete Image">Delete <i class="bx bx-trash-alt"></i></a>
                           </div>
                        </div>
                    </b-tab>
                    <b-tab v-if="productData.images.length > 0" v-for="(image, index) in productData.images" title-link-class="px-0 py-0 m-2">
                        <template v-slot:title>
                          <b-img-lazy v-if="'thumb' in image"
                            :src="storageurl+image.thumb.replace('//','/')"
                            alt
                            width="100px"
                            class="img-fluid mx-auto d-block tab-img rounded border"
                          />
                          <b-img-lazy v-else
                           :src="storageurl+image.src.replace('//','/')"
                            alt
                            width="100px"
                            class="img-fluid mx-auto d-block tab-img rounded border"
                          />
                        </template>
                        <div class="product-img border border-radius-1em py-3 position-relative">
                          <b-img-lazy
                           :src="storageurl+image.src.replace('//','/')"
                            alt
                            class="img-fluid mx-auto d-block"
                          />
                          <b-card class="shadow-none border border-radius-1em mx-3 mb-5" body-class="d-flex">
                            <p class="mb-0 text-muted font-italic" v-if="editcaption !== index">{{image.caption}}</p>
                            <b-textarea v-else v-model="image.caption" class="underline-input" ></b-textarea>
                            <a class="btn btn-light btn-rounded w-25 ml-auto" @click="editcaption = index, editcaptionrawtext = image.caption" v-if="editcaption !== index">Edit Caption <i class="bx bx-pencil"></i></a>
                            <div class="w-25"  v-if="editcaption == index">
                              <a class="btn btn-success btn-rounded btn-block ml-auto" @click="imageCaptionUpdate(index, false, image.hideonweb)">Save <i class="bx bx-pencil"></i></a>
                            <a class="btn btn-danger btn-rounded btn-block ml-auto" @click="editcaption = -1, image.caption = editcaptionrawtext">Cancel <i class="bx bx-pencil"></i></a>
                            </div>
                            
                          </b-card>
                            <div class="mb-3 position-absolute w-100 text-center mx-auto bottom-0 d-flex">
                              <a href="javascript:;" class="btn btn-primary btn-rounded ml-3"  @click="imageMakeCover(image, index)" v-b-tooltip.hover title="Cover Image">Make Cover <i class="bx bx-image-alt"></i></a>
                              <a href="javascript:;" class="btn btn-danger btn-rounded ml-2"  @click="imageCaptionUpdate(index, false, false)" v-b-tooltip.hover title="Click to Set Public" v-if="('hideonweb' in image) && (image.hideonweb == true)">Hidden <i class="bx bx-hide"></i>
                              </a>
                              <a href="javascript:;" class="btn btn-success btn-rounded ml-2"  @click="imageCaptionUpdate(index, false, true)" v-b-tooltip.hover title="Click to Hide in Public" v-else>Public <i class="bx bx-show"></i>
                              </a>
                              <a href="javascript:;" class="btn btn-light btn-rounded ml-2"  @click="editImageUrl = storageurl+productData.cover_image.src.replace('//','/'), editImage = true" v-b-tooltip.hover title="Edit Image">Edit<i class="bx bx-pencil"></i></a>
                              <a href="javascript:;" class="btn btn-danger btn-rounded ml-auto mr-3"  @click="imageDeleted(image, index, false)" v-b-tooltip.hover title="Delete Image">Delete <i class="bx bx-trash-alt"></i></a>
                           </div>
                        </div>
                    </b-tab>
                    <b-tab title-link-class="px-0 py-0 m-2">
                      <template v-slot:title>
                       
                        <div class="img-upload-icon bg-light" v-b-tooltip.hover title="Add New Images"><i class="bx bx bx-image-add text-primary"></i></div>
                      </template>
                      <div class="product-img" v-if="($attrs.id == 'add') || ($attrs.id == '')">
                       <vue-dropzone id="dropzone" ref="myVueDropzone" :use-custom-slot="true" :options="dropzoneOptions" @vdropzone-file-added="imageAdded"  @vdropzone-drop="imageAdded">
                        <div class="dropzone-custom-content">
                            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                            <h4>Drop files here or click to upload.</h4>
                        </div>
                        </vue-dropzone>
                      </div>
                      <div class="product-img" v-else>
                       <vue-dropzone v-if="updateDropzoneOptions.headers !== null" id="dropzone" ref="myUpdateImageDropzone" :use-custom-slot="true" :options="updateDropzoneOptions" @vdropzone-success="imageUploaded" @vdropzone-error="uploadError"  >
                        <div class="dropzone-custom-content">
                            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                            <h4>Drop files here or click to upload.</h4>
                        </div>
                        </vue-dropzone>
                      </div>
                    </b-tab>
                  </b-tabs>
                </b-overlay>
                </div>
              </div>
            </div>
          </b-col>
          </b-row>
        </b-card>
         <b-card class="shadow-none border-radius-1em" v-show="view == 'media'">
        <b-row>
          <b-col md="2">
           <h5 class="d-flex">Videos <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
          </b-col>
          <b-col md="10">
         <div class="card shadow-none border border-radius-1em">
            <div class="card-body">
                  <div class="product-detai-imgs">
                  <!---<label>Image Cropper Direct Test</label>
        <input @change="handleImage" class="custom-input" type="file" accept="image/*">
        **Test**-->
                  <b-overlay :show="loadingImgForm" spinner-variant="primary"
                    spinner-type="grow"
                    spinner-small
                    rounded="sm">
                    <b-tabs pills end nav-wrapper-class="d-flex" nav-class="mt-4 border border-radius-1em p-3 w-100">
                      <template #tabs-start>
                        <div class="w-100 d-block">
                        <h4 class="my-3">Video Gallery</h4>
                        <hr>
                        </div>
                      </template>
                      <b-tab v-if="(productData.cover_video !== null)" active title-item-class="position-relative" title-link-class="px-0 py-0 m-2">
                        <template v-slot:title>
                          <div v-if="'src' in productData.cover_video"
                            
                            alt
                        
                            class="img-fluid mx-auto d-block tab-img rounded border"
                          >
                          <video width="170px" style="width: 170px !important;" preload="metadata">
                            <source :src="storageurl+ '/' +productData.cover_video.src.replaceAll('//', '/') +'#t=0.1'" :type="'video/'+productData.cover_video.src.split('.')[productData.cover_video.src.split('.').length - 1]">
                          </video>
                        </div>
                          <div
                            v-else-if="!'src' in productData.cover_video"
                            alt
                            width="100px"
                            class="img-fluid mx-auto d-block border tab-img rounded"
                          >
                          <i class="bx bx-video"></i>
                        </div>
                
                          <span class="badge badge-light bg-light position-absolute top-0 mt-3">
                          <i class="bx bx-x-circle text-success">
                          </i>
                          Cover Image
                          </span>
                        </template>
                        <div class="product-img border border-radius-1em py-3 position-relative">
                          <div
                          v-if="'src' in productData.cover_video"
                            alt
                            class="img-fluid mx-auto d-block border"
                          >
                          <video controls preload="metadata">
                            <source :src="storageurl+ '/' +productData.cover_video.src.replaceAll('//', '/') +'#t=0.1'" :type="'video/'+productData.cover_video.src.split('.')[productData.cover_video.src.split('.').length - 1]">
                          </video>
                        </div>
                          <div class="mb-3 position-absolute w-100 text-center mx-auto bottom-0 d-flex">
                              <a href="javascript:;" class="btn btn-success btn-rounded ml-3"  @click="editImageUrl = storageurl+productData.cover_video.src.replace('//','/'), editImage = true" v-b-tooltip.hover title="Cover Image">Cover <i class="bx bx-check-circle"></i></a>
                              <a href="javascript:;" class="btn btn-light btn-rounded ml-2"  @click="editImageUrl = storageurl+productData.cover_video.src.replace('//','/'), editImage = true" v-b-tooltip.hover title="Edit Image">Edit <i class="bx bx-pencil"></i></a>
                              <a href="javascript:;" class="btn btn-danger btn-rounded ml-auto mr-3"  @click="imageDeleted(productData.cover_video, 0, true)" v-b-tooltip.hover title="Delete Image">Delete <i class="bx bx-trash-alt"></i></a>
                           </div>
                        </div>
                    </b-tab>
                    <b-tab v-if="productData.images.length > 0" v-for="(image, index) in productData.videos" title-link-class="px-0 py-0 m-2">
                        <template v-slot:title>
                           <div v-if="'src' in image"
                            
                            alt
                        
                            class="img-fluid mx-auto d-block tab-img rounded border"
                          >
                          <video width="170px" style="width: 170px !important;" preload="metadata">
                            <source :src="storageurl+ '/' +image.src.replaceAll('//', '/') +'#t=0.1'" :type="'video/'+image.src.split('.')[image.src.split('.').length - 1]">
                          </video>
                        </div>
                          <div
                            v-else-if="!'src' in image"
                            alt
                            width="100px"
                            class="img-fluid mx-auto d-block border tab-img rounded"
                          >
                          <i class="bx bx-video"></i>
                        </div>
                        </template>
                        <div class="product-img border border-radius-1em py-3 position-relative">
                          <video controls preload="metadata">
                            <source :src="storageurl+ '/' +image.src.replaceAll('//', '/') +'#t=0.1'" :type="'video/'+image.src.split('.')[image.src.split('.').length - 1]">
                          </video>
                          <b-card class="shadow-none border border-radius-1em mx-3 mb-5" body-class="d-flex">
                            <p class="mb-0 text-muted font-italic" v-if="editcaption !== index">{{image.caption}}</p>
                            <b-textarea v-else v-model="image.caption" class="underline-input" ></b-textarea>
                            <a class="btn btn-light btn-rounded w-25 ml-auto" @click="editcaption = index, editcaptionrawtext = image.caption" v-if="editcaption !== index">Edit Caption <i class="bx bx-pencil"></i></a>
                            <div class="w-25"  v-if="editcaption == index">
                              <a class="btn btn-success btn-rounded btn-block ml-auto" @click="imageCaptionUpdate(index, false)">Save <i class="bx bx-pencil"></i></a>
                            <a class="btn btn-danger btn-rounded btn-block ml-auto" @click="editcaption = -1, image.caption = editcaptionrawtext">Cancel <i class="bx bx-pencil"></i></a>
                            </div>
                            
                          </b-card>
                            <div class="mb-3 position-absolute w-100 text-center mx-auto bottom-0 d-flex">
                              <a href="javascript:;" class="btn btn-primary btn-rounded ml-3"  @click="imageMakeCover(image, index)" v-b-tooltip.hover title="Cover Image">Make Cover <i class="bx bx-image-alt"></i></a>
                              <a href="javascript:;" class="btn btn-light btn-rounded ml-2"  @click="editImageUrl = storageurl+productData.cover_image.src.replace('//','/'), editImage = true" v-b-tooltip.hover title="Edit Image">Edit<i class="bx bx-pencil"></i></a>
                              <a href="javascript:;" class="btn btn-danger btn-rounded ml-auto mr-3"  @click="imageDeleted(image, index, false)" v-b-tooltip.hover title="Delete Image">Delete <i class="bx bx-trash-alt"></i></a>
                           </div>
                        </div>
                    </b-tab>
                    <b-tab title-link-class="px-0 py-0 m-2">
                      <template v-slot:title>
                       
                        <div class="img-upload-icon bg-light" v-b-tooltip.hover title="Add New Images"><i class="bx bx bx-image-add text-primary"></i></div>
                      </template>
                      <div class="product-img" v-if="($attrs.id == 'add') || ($attrs.id == '')">
                       <vue-dropzone id="dropzone" ref="myVueDropzone" :use-custom-slot="true" :options="dropzoneOptions" @vdropzone-file-added="video
                       Added"  @vdropzone-drop="video
                       Added">
                        <div class="dropzone-custom-content">
                            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                            <h4>Drop files here or click to upload.</h4>
                        </div>
                        </vue-dropzone>
                      </div>
                      <div class="product-img" v-else>
                       <vue-dropzone v-if="updateVideoDropzoneOptions.headers !== null" id="dropzone" ref="myVueDropzone" :use-custom-slot="true" :options="updateVideoDropzoneOptions" @vdropzone-success="videoUploaded">
                        <div class="dropzone-custom-content">
                            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                            <h4>Drop files here or click to upload.</h4>
                        </div>
                        </vue-dropzone>
                      </div>
                    </b-tab>
                  </b-tabs>
                </b-overlay>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card>
       <b-card class="shadow-none border-radius-1em" v-show="view == 'media'">
        <b-row>
          <b-col md="2">
           <h5 class="d-flex">Virtual Try On <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
          </b-col>
          <b-col md="10">

            <b-row>
              <b-col>
                <b-form-group label="Try On Link">
                  <b-form-input type="text" name="tryon[url]" v-model="productData.tryon['url']"></b-form-input>
                </b-form-group>
              </b-col>

              <b-col>
                <b-form-group label="Try On Service">
                  <b-form-select class="form-control" name="tryon[service]" v-model="productData.tryon['service']" :options="['PicUp Media']"></b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card>
        <b-card class="shadow-none border-radius-1em" v-show="view == 'custom'">
        <b-row>
          <b-col md="2">
           <h5 class="d-flex">Add Fields <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
          </b-col>
          <b-col md="10">
            <div class="d-flex">
            <b-form-group label="Field Type" class="w-50 pr-2">
              <b-form-select class="form-control" :options="['Text', 'Long Text', 'Number', 'Dropdown', 'Radio', 'Checkbox', 'Date']" v-model="custom_field['type']">
              </b-form-select>
             </b-form-group>

            <b-form-group label="Name" class="w-50">
              <b-form-input v-model="custom_field['name']">
              </b-form-input>
            </b-form-group>
            </div>
           
            <div v-if="custom_field['type'] == 'Dropdown' || custom_field['type'] == 'Radio' || custom_field['type'] == 'Checkbox'" class="d-flex flex-column" >
              <div class="d-flex" v-for="(option, index) in custom_field['options']">
              <b-form-group  label="Option" class="w-100">
                 <b-form-input v-model="custom_field['options'][index]" type="Value">
                </b-form-input>
              </b-form-group>

              <a class="btn btn-light mt-auto btn-rounded" @click="custom_field['options'].splice(index, 1)">
                <i class="bx bx-minus"></i>
              </a>
            </div>
            <b-button variant="light" class="btn-rounded mt-2 btn-block" @click="custom_field['options'].push('')">
              Add {{custom_field['type']}} Option
            </b-button>
            </div>

            <b-button variant="primary" class="btn-rounded mt-2 btn-block" :disabled="(custom_field['name'] == '') || (custom_field['type'] == 'Dropdown' && custom_field['options'].length == 0) || (custom_field['type'] == 'Radio' && custom_field['options'].length == 0) || (custom_field['type'] == 'Checkbox' && custom_field['options'].length == 0)" v-if="Object.keys(productData).includes('custom_fields') && productData['custom_fields'] !== null" @click="productData['custom_fields']['fields'].push(custom_field), custom_field = JSON.parse(JSON.stringify(custom_fieldRaw))">
              Add Field
            </b-button>
            <b-button variant="primary" class="btn-rounded mt-2 btn-block" :disabled="(custom_field['name'] == '') || (custom_field['type'] == 'Dropdown' && custom_field['options'].length == 0) || (custom_field['type'] == 'Radio' && custom_field['options'].length == 0) || (custom_field['type'] == 'Checkbox' && custom_field['options'].length == 0)" v-else @click="productData['custom_fields'] = [], productData['custom_fields']['fields'] = [], productData['custom_fields']['fields'].push(custom_field), custom_field = JSON.parse(JSON.stringify(custom_fieldRaw))">
              Add Field
            </b-button>



          </b-col>
        </b-row>
      </b-card>
       <b-card class="shadow-none border-radius-1em" v-show="view == 'custom'">
        <b-row>
          <b-col md="2">
           <h5 class="d-flex">Custom Fields <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>

           <hr>
           <h5 class="spaced-title font-size-12 text-uppercase">Save As:</h5>
           <b-form-input type="text" v-model="save_fieldset.name">
            </b-form-input>
            <b-button variant="success" class="btn-rounded btn-block mt-3" :disabled="save_fieldset.name == ''" @click="save_fieldset.fields = productData.custom_fields.fields, saveFieldSet(save_fieldset)">
              Save
            </b-button>
          </b-col>
          <b-col md="10">
            <b-overlay :show="loadingCustomFields">
              <template #overlay>
                <b-row>
                  <b-col md="12" class="offset-md-2" >
                  <b-card class="border-radius-1em border shadow-none mb-0">  
                      <div class="cube-wrapper mx-auto"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
                      <h6 class="text-center">Loading Custom Fields</h6>
                     
                  </b-card>
                  </b-col>
                  </b-row>
              </template>
              <div v-if="productData.custom_fields !== null">
            <b-card v-if="!loadingCustomFields && Object.keys(productData['custom_fields']).includes('fields') == true" class="border-radius-1em border shadow-none" v-for="(field, fieldindex) in productData['custom_fields']['fields']" body-class="d-flex flex-column">
                <b-form-group class="mb-2 border-bottom" v-if="field['type'] == 'Text'" :label="field['name']">
                  <b-form-input type="text" v-model="field['value']">

                  </b-form-input>
                  
                </b-form-group>
                <b-form-group class="mb-2 border-bottom pb-4" v-else-if="field['type'] == 'Long Text'" :label="field['name']">
                  <b-form-textarea rows="4" max-rows="8" v-model="field['value']">

                  </b-form-textarea>
                </b-form-group>
                <b-form-group class="mb-2 border-bottom" v-else-if="field['type'] == 'Number'" :label="field['name']">
                  <b-form-input type="number" v-model="field['value']">

                  </b-form-input>
                </b-form-group>
                <b-form-group class="mb-2 border-bottom"  v-else-if="field['type'] == 'Dropdown'" :label="field['name']">
                 <b-form-select class="form-control" :label="field['name']" v-model="field['value']" :options="field['options']">

                  </b-form-select>
                </b-form-group>
                 <b-form-group class="mb-2 border-bottom d-flex" v-else-if="field['type'] == 'Radio'" :label="field['name']">
                  
                           <b-form-radio-group class="w-100" v-model="field['value']" :options="field['options']">

                            </b-form-radio-group>

                </b-form-group>
                 <b-form-group class="mb-2 border-bottom d-flex" v-else-if="field['type'] == 'Checkbox'" :label="field['name']">
                  
                           <b-form-checkbox-group class="w-100" v-model="field['value']" :options="field['options']">

                            </b-form-checkbox-group>

                </b-form-group>
                <b-form-group class="mb-2 border-bottom d-flex" v-else-if="field['type'] == 'Date'" :label="field['name']">
                  
                           <b-form-input type="date" class="w-100" v-model="field['value']" >

                            </b-form-input>

                </b-form-group>
                <a class="btn btn-light btn-rounded mb-auto ml-auto" @click="loadingCustomFields = true, productData['custom_fields']['fields'].splice(fieldindex, 1), loadingCustomFields = false">
                    <i class="bx bx-minus"></i>
                </a>
            </b-card>
          </div>
            </b-overlay>
          </b-col>
        </b-row>
      </b-card>
      <b-card class="shadow-none border-radius-1em" v-show="view == 'custom'">
        <b-row>
          <b-col md="2">
           <h5 class="d-flex">Saved Field Sets <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
          </b-col>
          <b-col md="10">
            <b-form-group>
              <b-form-select class="form-control" v-model="selected_fieldset" >
                <b-form-select-option v-for="fieldset in saved_field_sets" :value="fieldset">
                  {{fieldset.name}}
                </b-form-select-option>
              </b-form-select>
            </b-form-group>

            <a class="btn-rounded btn btn-primary" @click="loadFieldset()">
              Load Fieldset
            </a>

          </b-col>
        </b-row>
      </b-card>
        <b-card  class="card shadow-none border border-radius-1em"  v-show="view == 'pricing'">
          <b-row>
             <b-col md="2">
               <h5 class="d-flex">Pricing <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
            </b-col>
            <b-col md="10">
               <b-card  class="card shadow-none border border-radius-1em">
              <b-row>
              <div class="col-md-3">
                <div class="form-control-group">
                   <label class="">Consumer Price <small>Exc VAT</small></label>
                   <div class="input-group">
                      <div class="input-group-prepend">
                          <span class="input-group-text"><span class="currency">{{companyCurrency}}</span></span>
                      </div>
                      <input v-on:change="updateTaxCalc" type="number" v-model="productData.sales_price" name="sales_price" placeholder="0.00" step="0.01" class="form-control">
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                      <div class="form-control-group">
                          <label class="">Tax Rule</label>
                        <select class="form-control" v-model.number="active_tax_rule" name="taxrule_id"  v-on:change="activeTaxRule(active_tax_rule)">
                          <option v-for="rule in all_tax_rules" v-bind:value="rule.id">
                        {{rule.name}} : {{rule.code}} {{ rule.type == 1 ? '%' :companyCurrency }}</option>
                          </select>
                      </div>
                </div>
                <div class="col-md-3">
                  <div class="form-control-group">
                        <label class="">
                          <small>Inc VAT</small>
                      </label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text"><span class="currency">{{companyCurrency}}</span></span>
                        </div>
                        <input  v-model="inc_tax_price" v-on:change="removeVatCalc" type="number" name="sales_price_inc" placeholder="0.00" step="0.01" class="form-control">
                      </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-control-group">
                      <label class="mt-4">Trade Price</label>
                      <div class="input-group">
                          <div class="input-group-prepend">
                          <span class="input-group-text"><span class="currency">{{companyCurrency}}</span></span>
                          </div>
                          <input v-model.number="productData.trade_price" type="number" placeholder="0.00" step="0.01" class="form-control" name="trade_price">
                      </div>
                    </div>
                  </div>
                <div class="col-md-6">
                      <div class="form-control-group">
                          <label class="mt-4">Cost Price</label>
                      <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text"><span class="currency">{{companyCurrency}}</span></span>
                          </div>
                          <input  min="0.00" v-on:change="calcCostPrice" v-model.number="productData.cost_price" data-number-to-fixed="2" type="number" step="0.01" class="form-control" name="cost_price">
                      </div>
                    </div>
                  </div>
                  
              </b-row>
              <b-row>
                <div class="col-md-12 mt-4">
                    <div class="card border border-primary">
                      <div class="card-header bg-transparent border-primary">
                        <h5 class="my-0 text-primary"><i class="mdi mdi-bullseye-arrow mr-3"></i> Pricing Calculations </h5>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="col-md-2 count-div d-flex flex-column">
                            <h5 class="text-primary font-size-24 mt-auto"><span class="currency">{{companyCurrency}}</span>{{productData.cost_price}}</h5>
                            <label class="counts-title mb-auto">Cost Price</label>
                          </div>
                          <div class="col-md-2 count-div d-flex flex-column">
                            <h5 class="text-primary font-size-24 mt-auto"><span class="currency">{{companyCurrency}}</span>{{calculated_tax_duties}}</h5>
                            <label class="counts-title mb-auto">Taxes & Duties</label>
                          </div>
                          <div class="col-md-2 count-div d-flex flex-column">
                            <h5 class="text-primary font-size-24 mt-auto"><span class="currency">{{companyCurrency}}</span>{{calculated_cost_price}}</h5>
                            <label class="counts-title mb-auto">Total Cost</label>
                          </div>
                          <div class="col-md-2 count-div d-flex flex-column">
                            <h5 class="text-primary font-size-24 mt-auto"><span class="currency">{{companyCurrency}}</span>{{productData.trade_price}}</h5>
                            <label class="counts-title mb-auto">Trade Price</label>
                          </div>
                          <div class="col-md-2 count-div d-flex flex-column">
                            <h5 class="text-primary font-size-24 mt-auto"><span class="currency">{{companyCurrency}}</span>{{productData.sales_price}} </h5><small>Exc. VAT</small><br>
                            <h5 class="text-primary font-size-24 mt-auto" v-if="all_tax_rules.length > 0"><span class="currency">{{companyCurrency}}</span>{{calculateIncVat}} </h5><small>Inc. VAT</small>
                            <label class="counts-title mb-auto">Consumer Price</label>
                          </div>
                          <div class="col-md-2 count-div d-flex flex-column">
                            <h5 class="text-primary font-size-24 mt-auto">{{profit_margin}}%</h5>
                            <label class="counts-title mb-auto">Profit Margin</label>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
              </b-row>
            </b-card>
            </b-col>
          </b-row>

        </b-card>
        <b-card  class="card shadow-none border border-radius-1em"  v-show="view == 'pricing'">
          <b-row>
            <b-col md="2">
               <h5 class="d-flex">Visibility <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
            </b-col>
            <b-col md="10">
               <b-card class="shadow-none border border-radius-1em">
                 <label class="mt-3 card-title">Price On Application</label>
                <b-form-group label="" description="Will show POA on website rather than price indicated above">
                  <b-form-radio-group :options="[true,false]" v-model="productData.poa">
                  </b-form-radio-group>
                </b-form-group>
                
                 <label class="mt-3 card-title">Price Range</label>
                  <b-form-group label="" description="Will show Price Range on website rather than price indicated above">
                  <b-form-radio-group :options="[{text: 'true', value: defaultPriceRange}, {text: 'false', value: null}]" v-model="productData.price_range">
                  </b-form-radio-group>
                </b-form-group>
            

                <div class="d-flex"  v-if="productData.price_range !== null" >
                  <b-form-group label="Min Price">
                    <b-form-input type="number" v-model="productData.price_range[0]">
                    </b-form-input>
                  </b-form-group>
                  <b-form-group label="Max Price">
                    <b-form-input type="number" v-model="productData.price_range[1]">
                    </b-form-input>
                  </b-form-group>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </b-card>
        <b-card  class="card shadow-none border border-radius-1em"  v-show="view == 'pricing'">
          <b-row>
            <b-col md="2">
               <h5 class="d-flex">Pricing <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
            </b-col>
            <b-col md="10">
              <b-card class="shadow-none border border-radius-1em">
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-12">
                    <h4 class="mb-1 pt-2 card-title">Carnet Pricing</h4>
                  </div>
                  
                  <div class="col-md-3">
                    <div class="form-control-group">
                          <label class="mt-4">Carnet Price <small>Exc VAT</small></label>
                      <div class="input-group">
                          <div class="input-group-prepend">
                          <span class="input-group-text"><span class="currency">{{companyCurrency}}</span></span>
                          </div>
                          <input v-model.number="carnet_price" type="number" placeholder="0.00" step="0.01" class="form-control"  v-on:change="calcCostPrice" name="carnet_price">
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-control-group">
                      <label class="mt-4">Carnet Weight</label>
                      <div class="input-group">
                        <input v-model.number="carnet_weight" type="number" name="carnet_weight" placeholder="0.00" step="0.01" class="form-control">
                        <div class="input-group-append">
                          <span class="input-group-text">.g</span>
                        </div>
                      </div>
                    </div>
              </div>
              <div class="col-md-3">
                <div class="form-control-group">
                  <label class="mt-4">Import Tax <small>Exc VAT</small></label>
                  <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text"><span class="currency">{{companyCurrency}}</span></span>
                        </div>
                  <input type="number" v-model.number="import_tax" v-on:change="calcCostPrice" name="import_tax" placeholder="0.00" step="0.01" class="form-control">
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-control-group">
                    <label class="mt-4">Export Tax <small>Inc VAT</small></label>
                  <div class="input-group">
                      <div class="input-group-prepend">
                          <span class="input-group-text"><span class="currency">{{companyCurrency}}</span></span>
                        </div>
                        <input v-on:change="calcCostPrice" type="number" v-model.number="export_tax" name="export_tax" placeholder="0.00" step="0.01" class="form-control">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
                    <div class="col-md-12">
                        <h5 class="mt-4 mt-4 mb-1 pt-2 form-row-title">Bulk Pricing</h5>
                    </div>
                 
                      <div class="col-md-12">
                        <div class="row">
                        <div class="col-md-12">
                        <div class="row">
                        
                    <div class="col-md-3">
                        <div class="form-control-group">
                        <label class="mt-4">
                        Start Quantity <i class="ti-help info-tooltip" v-b-tooltip.hover :title="quantity_lang.bulk.qtytooltip">
                      </i></label>
                      <div class="input-group">
                        <input type="number" placeholder="0.00" step="0.01" class="form-control" v-model.number="bulkquantity">
                      </div>
                    </div>
                  </div>
                    <div class="col-md-3">
                        <div class="form-control-group">
                        <label class="mt-4">
                        End Quantity <i class="ti-help info-tooltip" v-b-tooltip.hover :title="quantity_lang.bulk.qtytooltip">
                      </i></label>
                      <div class="input-group">
                        <input type="number" placeholder="0.00" step="0.01" class="form-control" v-model.number="bulkquantity">
                      </div>
                    </div>
                  </div>
                      <div class="col-md-2">
                          <div class="form-control-group">
                          <label class="mt-4">
                              Discount Type / Amount
                            </label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <select class="input-group-text" v-model="discount_type">
                            <option value="currency"><span class="currency">{{companyCurrency}}</span></option>
                            <option value="percentage">%</option>
                            <i class="fa fa-caret"></i>
                              </select>
                          </div>
                          <input type="number" placeholder="0.00" step="0.01" class="form-control" v-model="discount_amount">
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2 center-flex mt-4">
                       <div
                                class="form-check form-switch form-switch-sm my-1 mx-auto"
                                dir="ltr"
                              >
                              <input
                                  class="form-check-input"
                                  type="checkbox"
                                  :id="`SwitchCheckSize-publicmark`"
                                  v-model="publicmarkQty"
                                  value="1"
                                  unchecked-value="0"
                                  name="iscompany"
                                />
                                <label class="form-check-label" :for="`SwitchCheckSize-publicmark`" 
                                  > Public</label
                                >
                            </div>
                            <div
                                class="form-check form-switch form-switch-sm my-1 mx-auto"
                                dir="ltr"
                              >
                              <input
                                  class="form-check-input"
                                  type="checkbox"
                                  :id="`SwitchCheckSize-trademark`"
                                  v-model="trademarkQty"
                                  value="1"
                                  unchecked-value="0"
                                  name="iscompany"
                                />
                                <label class="form-check-label" :for="`SwitchCheckSize-trademark`" 
                                  > Trade</label
                                >
                            </div>
                            <div
                                class="form-check form-switch form-switch-sm my-1 mx-auto"
                                dir="ltr"
                              >
                              <input
                                  class="form-check-input"
                                  type="checkbox"
                                  :id="`SwitchCheckSize-shop`"
                                  v-model="shopQty"
                                  value="1"
                                  unchecked-value="0"
                                  name="iscompany"
                                />
                                <label class="form-check-label" :for="`SwitchCheckSize-shop`" 
                                  > Shops</label
                                >
                            </div>
                    </div>
                    <div class="col-md-2 center-flex mt-4">
                      <a class="btn btn-outline-light btn-rounded mt-4" @click="toggleBulkPricing(false, null)">
                      Add Discount Rule <i class="bx bx-plus"></i>
                      </a>
                    </div>
                    <div class="single-table col-md-12 mt-4">
                        <div class="table-responsive">
                          <table class="table text-center" v-if="product_bulk_pricing.length > 0">
                              <thead class="text-uppercase">
                              <tr>
                                    <th scope="col">Discount Type</th>
                                  <th scope="col">Discount</th>
                                  <th scope="col">QTY Starts at:</th>
                                  <th scope="col">Customer Type</th>
                                  <th scope="col">Customer Group</th>
                                  <th scope="col">Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="bulk in product_bulk_pricing">
                                  <th scope="row">
                                    {{bulk.discount_type}}
                                  </th>
                                  <td>{{bulk.price}}</td>
                                  <td>{{bulk.quantity}}</td>
                                  <td>{{filterCustomerType(bulk.customer_type_id)}}</td>
                                  <td>{{bulk.customer_group_id}}</td>
                                  <td>
                                    <a href="javascript:;" @click="toggleBulkPricing(true, bulk.id)">
                                      <i class="ti-trash"></i>
                                    </a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                 
                </div>
              </b-card>
            </b-col>
          </b-row>
          </b-card>
          <b-card class="card shadow-none border border-radius-1em" v-show="view == 'listings'">
            <b-row>
          <b-col md="2">
           <h5 class="d-flex">Quantity <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
          </b-col>
              <b-col md="10">
                <b-card class="card shadow-none border border-radius-1em">
                <div class="row">
                    <div class="col-md-3">
                      <div class="form-control-group">
                      <label>Total Quantity</label><input type="number" name="total_qty" class="form-control" v-model="productData.total_qty">
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-control-group">
                      <label>Minimum Sale Quantity</label><input type="number" name="min_sale_qty" v-model="productData.min_sale_qty" class="form-control">
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-control-group">
                      <label>Maximium Sale Quantity</label><input type="number" name="max_sale_qty" v-model="productData.max_sale_qty" class="form-control">
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-control-group">
                      <label>Low Stock Level</label><input type="number" name="low_stock_level" v-model="productData.low_stock_level" class="form-control">
                  </div></div>
                  <div class="col-md-3">
                      <div class="form-control-group">
                      </div>
                  </div>
                </div>
              </b-card>
              </b-col>
            </b-row>
        </b-card>
         <b-card class="card shadow-none border border-radius-1em" v-if="view == 'listings'">
            <b-row>
              <b-col md="2">
                <h5 class="d-flex">Integrations Stock<i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
              </b-col>
              <b-col md="10">
                <b-card class="border shadow-none border-radius-1em">
                  <div class="mt-sm-0 form-inline w-100" >
                    <b-list-group v-if="productData.product_channels.length > 0">
                      <b-list-group-item v-for="channelproduct in productData.product_channels" class="d-flex">
                        <img v-if="'integration' in channelproduct" :src="getChannelsImage(channelproduct.integration.integration_name)" class="my-auto mr-3" width="50px">
                        <div v-if="'integration' in channelproduct" class="my-auto mr-auto">
                          {{channelproduct.integration.integration_name}}
                        </div>
                         <div v-if="'integration' in channelproduct" class="my-auto mr-auto text-capitalize">
                          {{channelproduct.integration.type}}
                        </div>
                        <!--<div class="my-auto mx-auto d-flex flex-column">
                         <label class="mb-0">Quantity</label>
                         <span class="text-center">
                          {{channelproduct.quantity}}
                        </span>
                        </div>-->
                        <div class="my-auto mx-auto d-flex flex-column">
                           <div class="d-flex">
                          <b-button @click="channelproduct.quantity--" variant="light" class="btn-rounded rounded-circle">
                            <i class="mdi mdi-minus"></i>
                          </b-button>
                          <b-form-group class="flex-grow-2 mx-1">
                            <b-form-input type="number" v-model="channelproduct.quantity">
                            </b-form-input>
                            </b-form-group>
                            <b-button @click="channelproduct.quantity++" variant="light" class="btn-rounded rounded-circle">
                            <i class="mdi mdi-plus"></i>
                          </b-button>
                          </div>
                        </div>
                        <div class="my-auto">
                         <a class="btn btn-light my-auto ml-3 btn-rounded" v-b-modal.channel-details @click="updateChannelQuantity(channelproduct)">
                          Update Quantity
                         </a>
                        </div>
                        <div class="my-auto">
                         <a class="btn btn-light my-auto ml-3 btn-rounded" v-b-modal.channel-details @click="selected_integration_uid = '', getActiveChannel(channelproduct)">
                          Product Details
                         </a>
                        </div>
                      </b-list-group-item>
                    </b-list-group>
                    <div :addNew="false" v-else>
                      <b-card class="card bg-transparent shadow-none border-radius-1em border border-5 border-dashed" body-class="text-center justify-content-center d-flex flex-column py-5">
                        <i class="bx bx-sad mx-auto font-size-48 text-muted mb-2"></i>
                        <h5 class="text-muted">No active integrations with this product</h5>
                      </b-card>

                    </div>
                  </div>
                </b-card>
              </b-col>
            </b-row>
          </b-card>
          <b-card class="card shadow-none border border-radius-1em" v-if="view == 'listings'">
            <b-row>
              <b-col md="2">
                <h5 class="d-flex">Shop Stock <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
                <h3 class="text-primary mt-4">{{productData.total_qty}}</h3>
                <small>Total Quantity</small>
                <hr>
                <h3 class="text-primary mt-4" :class="{'text-danger' : listingsTotalQuantities > productData.total_qty}" v-if="productData.listings.length > 0">{{listingsTotalQuantities}}</h3>
                <small>Quantity Assigned to Shops</small>
                <hr>
                <h3 class="text-primary mt-4" :class="{'text-danger' : 0 < (productData.total_qty - productData.total_qty)}" v-if="productData.listings.length > 0">{{productData.total_qty - listingsTotalQuantities}}</h3>
                <small>Quantity Assigned to Web</small>
                <hr>
              </b-col>
              <b-col md="10">
                <b-card class="border shadow-none border-radius-1em">
                  <div class="mt-sm-0 form-inline w-100" >
                  <div class="search-box mr-2 ml-auto">
                    <label>Search Shops</label>
                    <div class="position-relative d-flex">
                      <input
                        type="text"
                        class="form-control w-75 border-0 bg-light"
                        placeholder="Search name, location or description..."
                        v-model="shopsearch" @keydown.enter="getShops()" 
                      />
                      <i class="bx bx-search-alt search-icon"></i>
                      
                      <b-form-select class="form-control border btn-rounded w-unset ml-2 bg-light px-3 text-uppercase" v-model="shop_type_id" @input="getShops()">
                        <b-form-select-option value="" >-- Listing Type --</b-form-select-option>
                        <b-form-select-option v-for="(listing_type, key) in listing_types" :value="listing_type.id" class="text-capitalize">{{listing_type.name}}</b-form-select-option>
                      </b-form-select>

                      <b-form-select class="form-control border btn-rounded w-unset ml-2 bg-light px-3 text-uppercase" v-model="shopsortby" @input="getShops()">
                        <b-form-select-option value="id" >-- Sort By --</b-form-select-option>
                        <b-form-select-option v-for="(listing, key) in shopsData.data[0]" :value="key" class="text-uppercase">{{(key).replace('_', ' ')}}</b-form-select-option>
                      </b-form-select>
                      
                      <b-form-select class="form-control border btn-rounded w-unset ml-2 bg-light px-3" v-model="shopslimit" :options="limits" @input="getShops()"></b-form-select>
                    </div>
                  </div>
                </div>
                </b-card>
                <simplebar
                            style="height: 800px"
                        >
                            <li class="list-group-item" v-for="(listing, index) in shopsData.data">
                                <div class="media">
                                    <div class="avatar-sm me-3 my-auto" v-if="listing.logo">
                                        <span
                                            class="avatar-title rounded-circle bg-light"
                                        >
                                            <!--<img
                                                
                                                :src="listing.logo"
                                                alt=""
                                                height="18"
                                            />-->
                                            
                                        </span>
                                      </div>
                                       <div class="avatar-sm me-3 my-auto" v-else>
                                        <span class="avatar-title rounded-circle" >{{(listing.name).slice(0,1)}}</span>
                                      </div>
                                   
                                    <div class="media-body my-auto">
                                        <a :href="'/company/shop/'+listing.uid" target="_blank"><h5 class="font-size-14 mb-0 mt-auto">
                                            {{listing.name}}
                                        </h5></a>
                                        <p class="text-muted mb-auto mt-0">
                                            <span v-if="listing.address_1">{{listing.address_1}},</span>
                    <span v-if="listing.address_2">{{listing.address_2}},</span>
                    <span v-if="listing.address_3">{{listing.address_3}},</span>
                    <span v-if="listing.county">{{listing.county.name}},</span>
                    <span v-if="listing.country">{{listing.country.name}},</span>
                    <span v-if="listing.postcode">{{listing.postcode}}</span>
                                        </p>

                                        
                                    </div>
                                    <div class="my-auto text-center">
                                            <p class="text-muted my-2" v-if="listing.listing_type">
                                                <i 
                                                    class="bx bx-buildings me-1"
                                                ></i>
                                                {{listing.listing_type.name}}
                                            </p>
                                        </div>
                                        <div class="my-auto px-4 w-25">
                                          <div v-if="(productData.variants.length == 0) && (productData.combinations.length == 0)">
                                            <a class="btn btn-light btn-rounded btn-block" v-if="!quantityInListing(listing.id)" @click="productData.listings.push({company_listing_id: listing.id, quantity: 1, variant_id: null, combination_id: null})" >
                                              Add To This Shop
                                            </a>
                                            <b-form-group label="Quantity" label-cols-lg="6" v-if="quantityInListing(listing.id)">
                                              <b-form-input :id="'quantity-'+index" class="border-radius-1em border bg-light" type="number" v-model="productData.listings[listingQuantity(listing.id)].quantity" ></b-form-input>
                                            </b-form-group>
                                          </div>
                                          <div v-if="productData.combinations.length > 0">
                                             <div v-for="(combination, index) in productData.combinations">
                                               <a class="btn btn-light btn-rounded btn-block my-1" v-if="!combinationQuantityInListing(listing.id, combination.id)" @click="productData.listings.push({company_listing_id: listing.id, quantity: 1, variant_id: null, combination_id: combination.id})" >
                                              Add Combination {{index + 1}}
                                              </a>
                                              <b-form-group label="Variant Quantity" label-cols-lg="7" v-if="combinationQuantityInListing(listing.id, combination.id)">
                                                <b-form-input :id="'quantity-'+combination.id" class="border-radius-1em border bg-light" type="number" v-model="productData.listings[combinationListingQuantity(listing.id, combination)].quantity" ></b-form-input>
                                              </b-form-group>
                                            </div>
                                          </div>
                                          <div v-else>
                                            <div v-for="(variant, index) in productData.variants">
                                               <a class="btn btn-light btn-rounded btn-block my-1" v-if="!variantQuantityInListing(listing.id, variant.id)" @click="productData.listings.push({company_listing_id: listing.id, quantity: 1, variant_id: variant.id, combination_id: null})" >
                                              Add Variant {{index + 1}}
                                              </a>
                                              <b-form-group label="Variant Quantity" label-cols-lg="7" v-if="variantQuantityInListing(listing.id, variant.id)">
                                                <b-form-input :id="'quantity-'+variant.id" class="border-radius-1em border bg-light" type="number" v-model="productData.listings[variantListingQuantity(listing.id, variant)].quantity" ></b-form-input>
                                              </b-form-group>
                                            </div>
                                          </div>
                                        </div>
                                </div>
                            </li>
                        </simplebar>
                        <b-pagination
                          class="justify-content-center"
                          pills
                          v-model="currentPage"
                          :total-rows="shopsData.total"
                          :per-page="shopsData.per_page"
                          aria-controls="my-table"
                          @input="getShops()"
                        >

                        </b-pagination>
              </b-col>
            </b-row>
          </b-card>
          <b-card  class="card shadow-none border border-radius-1em"  v-show="view == 'categories-collections'">
            <b-row>
            <b-col md="2">
               <h5 class="d-flex">Categories <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>

               <a class="" v-if="view == 'basic-info'" href="javascript:;" @click="view == 'categories-collections', updateHistory(view)">
                More Options >
                </a>
            </b-col>
            <b-col md="10">
             
               <div class="card shadow-none border border-radius-1em"  id="categories">
                        <div class="card-body">
                           <!--<b-card class="card shadow-none border-none" v-if="(productData.active == false) && (productData.trade == false) && (productData.public == false)" body-class="d-flex flex-column justify-content-center align-items-center text-center">
                              <i class="mdi mdi-view-grid-plus-outline font-size-42 text-muted"></i>
                              <h5 class="text-muted">
                                Activate your product on shops, trade or public marketplace to select categories.
                              </h5>
                           </b-card>-->
                           <b-tabs pills content-class="pt-2" variant="outline-light">
                      <b-tab active >
                        <template v-slot:title>
                          <span class="d-inline-block d-sm-none">
                        <i class="far fa-user"></i>
                      </span>
                      <span class="d-none d-sm-inline-block">My Shops</span>
                            </template>
                          <div class="form-group mt-2">
                            <label>Main Category</label>
                                  <SelectCompanyProductCategory :categories_data="categories" :user="$attrs.user" :isDisabled="false" :id="productData.company_defaultcategory" :marketplace_categories="'company'" @defaultcategory-selected="categorySelected" ></SelectCompanyProductCategory>
                                  <input type="hidden" name="default_category_id" :value="productData.default_category_id">
                            <label class="mt-2">Additional Categories</label>
                                   <div class="row">
                             <!--<b-button v-for="collection in categories"
                        class="custom-radio flex-grow-0 border-radius-1em inset-border py-4 custom-radio-outline position-relative custom-radio-primary my-0 w-25 w-sm col-md-4"
                        value="0"
                        :class="{'active' : activeInCategories(collection, 'company')}"
                        variant="outline-light"
                         @click="updateActiveCategories(collection, 'company')"
                      >
                      <b-img-lazy class="avatar d-block avatar-md rounded-circle mx-auto" v-if="collection.image !== null" :src="storageurl+ '/' + collection.image" />
                      <b-img-lazy v-else class="avatar d-block avatar-md rounded-circle mx-auto" src="/images/product-placeholder.png" />
                      <i v-if="activeInCategories(collection, 'company')" class="bx bx-check-circle font-size-24 d-block text-success my-2 position-absolute top-1"></i> {{collection.name}}
                </b-button>-->

                 <CategorySelectButtons ref="categories" :categories="categories" :productData="productData" :resource="'company'" :storageurl="storageurl"></CategorySelectButtons>
              </div>
                          </div>
                        </b-tab>
                        <b-tab v-if="productData.trade == true">
                        <template v-slot:title>
                          <span class="d-inline-block d-sm-none">
                        <i class="far fa-user"></i>
                      </span>
                      <span class="d-none d-sm-inline-block">Trade Market</span>
                            </template>
                          <div class="form-group mt-2">
                            <label>Main Category</label>
                                 <SelectCompanyProductCategory :categories_data="trade_categories"  :user="$attrs.user" :isDisabled="false" :id="productData.trade_marketplace_defaultcategory" :marketplace_categories="'trade'" @defaultcategory-selected="categorySelected"></SelectCompanyProductCategory>
                                 <input type="hidden" name="trademarket_category_id" :value="productData.trademarket_category_id">
                             <label class="mt-2">Additional Categories</label>
                                   <div class="row">
                             <!--<b-button v-for="(collection, key) in trade_categories"
                        class="custom-radio flex-grow-0 border-radius-1em inset-border py-4 custom-radio-outline position-relative custom-radio-primary my-0 w-25 w-sm col-md-4"
                        value="0"
                        :class="{'active' : activeInCategories(collection, 'trade')}"
                        variant="outline-light"
                         @click="updateActiveCategories(collection, 'trade')"
                         :key="key"
                         :id="collection.id"
                      >
                      <b-img-lazy class="avatar d-block avatar-md rounded-circle mx-auto" v-if="collection.image !== null" :src="collection.image" />
                      <b-img-lazy v-else class="avatar d-block avatar-md rounded-circle mx-auto" src="/images/product-placeholder.png" />
                      <i v-if="activeInCategories(collection, 'trade')" class="bx bx-check-circle font-size-24 d-block text-success my-2 position-absolute top-1"></i> {{collection.name}}
                </b-button>-->

                 <CategorySelectButtons ref="trade-categories" :categories="trade_categories" :productData="productData" :resource="'trade'" :storageurl="storageurl"></CategorySelectButtons>
              </div>
                          </div>
                        </b-tab>
                        <b-tab v-if="productData.public == true">
                        <template v-slot:title>
                          <span class="d-inline-block d-sm-none">
                        <i class="far fa-user"></i>
                      </span>
                      <span class="d-none d-sm-inline-block">Public Market</span>
                            </template>
                          <div class="form-group mt-2">
                            <label>Main Category</label>
                                  <SelectCompanyProductCategory :categories_data="public_categories"  :user="$attrs.user" :isDisabled="false" :id="productData.public_marketplace_defaultcategory" :marketplace_categories="'public'" @defaultcategory-selected="categorySelected"></SelectCompanyProductCategory>
                                  <input type="hidden" name="pubmarket_category_id" :value="productData.pubmarket_category_id">
                                   <label class="mt-2">Additional Categories</label>
                                   <div class="row">
                             <!--<b-button v-for="collection in public_categories"
                        class="custom-radio flex-grow-0 border-radius-1em inset-border py-4 custom-radio-outline position-relative custom-radio-primary my-0 py-3 w-25 w-sm col-md-4"
                        value="0"
                        :class="{'active' : activeInCategories(collection, 'public')}"
                        variant="outline-light"
                         @click="updateActiveCategories(collection, 'public')"
                      >
                      <img class="avatar d-block avatar-md rounded-circle mx-auto" v-if="collection.image !== null" :src="collection.image">
                      <img v-else class="avatar d-block avatar-md rounded-circle mx-auto" src="/images/product-placeholder.png">
                      <i v-if="activeInCategories(collection, 'public')" class="bx bx-check-circle font-size-24 d-block text-success my-2 position-absolute top-1"></i> {{collection.name}}
                </b-button>-->
                <CategorySelectButtons ref="public-categories" :categories="public_categories" :productData="productData"  :resource="'public'" ></CategorySelectButtons>
              </div>
                          </div>
                        </b-tab>
                        
                      </b-tabs>
                        </div>

                      </div>
            </b-col>
          </b-row>
          </b-card>
        
         <b-card  class="card shadow-none border border-radius-1em"   v-show="view == 'categories-collections'" >
          <b-row>
            <b-col md="2">
                 <h5 class="d-flex">Collections <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
            </b-col>
            <b-col md="10">
               <div class="card shadow-none border border-radius-1em"  id="categories">
                        <div class="card-body">
                          <div class="row px-3">
                           <b-button v-for="collection in collections.data"
                      class="custom-radio flex-grow-0 w-25 custom-radio-outline position-relative custom-radio-primary my-0 py-4 w-sm col-md-4 border-radius-1em inset-border border-radius-1em"
                      value="0"
                      :class="{'active' : activeInCollections(collection)}"
                      variant="outline-light"
                      @click="updateActiveCollection(collection)"
                    >
                    <img class="avatar d-block avatar-md rounded-circle mx-auto" v-if="collection.image !== null" :src="'/image/'+collection.image">
                    <img v-else class="avatar d-block avatar-md rounded-circle mx-auto" src="/images/product-placeholder.png">
                    <i v-if="activeInCollections(collection)" class="bx bx-check-circle font-size-24 d-block text-success my-2 position-absolute top-1"></i> {{collection.name}}
              </b-button>
            </div>
                        </div>

                      </div>
            </b-col>
          </b-row>
        </b-card>
        <b-card  class="card shadow-none border border-radius-1em"   v-show="view == 'categories-collections'">
          <b-row>
            <b-col md="2">
                 <h5 class="d-flex">Brand <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
            </b-col>
            <b-col md="10">
               <div class="card shadow-none border border-radius-1em"  id="categories">
                        <div class="card-body">
                           <b-tabs pills content-class="pt-2" variant="outline-light">
                      <b-tab active>
                        <template v-slot:title>
                          <span class="d-inline-block d-sm-none">
                        <i class="far fa-user"></i>
                      </span>
                      <span class="d-none d-sm-inline-block">My Shops</span>
                            </template>
                          <div class="form-group">
                                  <SelectCompanyProductBrand :user="$attrs.user" :isDisabled="false" :id="productData.brand" :marketplace_categories="'false'" @brand-selected="toggleBrand"></SelectCompanyProductBrand>
                                  <input :value="productData.brand_id" name="brand_id" type="hidden">
                          </div>
                        </b-tab>
                        <b-tab>
                        <template v-slot:title>
                          <span class="d-inline-block d-sm-none">
                        <i class="far fa-user"></i>
                      </span>
                      <span class="d-none d-sm-inline-block">Public & Trade Market</span>
                            </template>
                          <div class="form-group">
                                 <SelectCompanyProductBrand :user="$attrs.user" :isDisabled="false" :id="''" :marketplace_categories="'true'" @brand-selected="categorySelected"></SelectCompanyProductBrand>
                          </div>
                        </b-tab>
                        
                        
                      </b-tabs>
                        </div>

                      </div>
            </b-col>
          </b-row>
        </b-card>
        <b-card  class="card shadow-none border border-radius-1em"   v-show="view == 'features-variants'" >
          <b-row>
            <b-col md="2">
                 <h5 class="d-flex">Features <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
            </b-col>
            <b-col md="10">
              <b-card class="card shadow-none border border-radius-1em" >
                <div class="row">
                  <div class="col-md-12" v-if="productData.attributes.length == 0">
                    <b-card class="shadow-none text-center border mb-2 py-4">
                      <i class="bx bxs-cube font-size-24 text-muted"></i>
                      <h4>No Active Features</h4>
                  </b-card>
                  </div>
                 <div class="col-md-12 mb-2" v-if="productData.attributes.length !== 0">
                <div class="row">
               <div class="col-md-12">
                <div class="form-control-group">
                <ul class="list-group">
                <li v-for="(active_attributes, index) in productData.attributes" class="border p-0 d-flex p-3 border-radius-1em mb-2">
                <span class="my-auto pl-4 w-25" v-if="active_attributes.attribute !== null">
                  <img v-if="('image' in active_attributes.attribute && active_attributes.attribute.image !== null) " :src="storageurl +'/'+ active_attributes.attribute.image" class="avatar-xs rounded-circle mr-2">
                  <img v-else src="/images/product-placeholder.png" class="avatar-xs rounded-circle mr-2">
                {{active_attributes.attribute.name}}
              </span>
              <span class="w-25">
                <span  v-if="active_attributes.value !== null">
                <img v-if="active_attributes.value.image" :src="storageurl +'/'+ active_attributes.value.image" class="avatar-xs rounded-circle mr-3">
                <img v-else src="/images/product-placeholder.png" class="avatar-xs rounded-circle ml-2 mr-2"> {{active_attributes.value.name}}
                </span>
              </span>
              <span class="my-auto pl-4 w-25"> {{active_attributes.measurement_value}}<span  v-if="active_attributes.units !== null">{{active_attributes.units.suffix}}</span></span>
              <span class="my-auto pl-4 w-25">
                  <a class="btn btn-remove float-right border-0" href="javascript:;" v-on:click="editProductAttribute(active_attributes, index)">
                <i class="bx bx-edit-alt"></i>
                  </a>
                  <a class="btn btn-remove float-right border-0" href="javascript:;" v-on:click="destroyProductAttribute(active_attributes.id, index)">
                <i class="bx bx-trash-alt"></i>
                  </a>
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div> 
                <div class="col-md-12">
                <a class="pb-2 form-row-title btn btn-light btn-block text-center"  @click="selected_attribute = '', 
                              selected_value = '', 
                              selected_measurement_value = '', 
                              selected_measurement_unit = '', newFeature = !newFeature"><span v-if="newFeature == false">Add New <i class="mdi mdi-plus"></i> </span><span v-else>Cancel <i class="bx bx-x"></i></span></a>
               <
               <b-overlay :show="loadingEditAttribute">
                <template #overlay>
          <b-row>
                  <b-col md="12" class="offset-md-2" >
                  <b-card class="border-radius-1em border shadow-none mb-0">  
                      <div class="cube-wrapper mx-auto"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
                      <h6 class="text-center">Loading Attribute</h6>
                       <b-progress class="w-50 mx-auto" v-model="loadingEditAttributeProgress" variant="primary"></b-progress>
                  </b-card>
                  </b-col>
                  </b-row>
        </template>
                <div class="row" v-if="newFeature == true">
                <div class="col-md-12">
                </div>
                <div class="col-md-6 my-2">
                  <label>Feature</label>
                <SelectCompanyProductAttribute v-if="!loadingEditAttribute" :id="selected_attribute" :categories_data="all_attributes" :user="$attrs.user" :isDisabled="false" :marketplace_categories="'company'" @attribute-selected="getAttributeValues"></SelectCompanyProductAttribute>
                </div>
              <div class="col-md-6 my-2">
                  <label>Value</label>
                <SelectCompanyProductAttributeValue  v-if="!loadingEditAttribute && attribute_values.length > 0"  :id="selected_value" :categories_data="attribute_values" :user="$attrs.user" :isDisabled="false" :marketplace_categories="'company'" @attribute-value-selected="selectAttributeValues"></SelectCompanyProductAttributeValue>
                <b-form-select disabled class="form-control" v-else placeholder="Please Select an Attribute First">
                </b-form-select>
              </div>
              <div class="col-md-6"  >
                <div class="form-control-group mb-3">
                  <label>Measurement Value</label>
                  <input type="number" step="0.01" v-model="selected_measurement_value" class="form-control" placeholder="Add size, weight, length unit value">
                </div>
              </div>
              <div class="col-md-6"  >
                <div class="form-control-group mb-3">
                <label>Measurement Value Units</label>
                <select type="select" class="form-control" v-model="selected_measurement_unit">
                <option value="" selected>-- Select Units --</option><option v-for="values in measurement_units" v-bind:value="values">
              {{values.name}}</option></select>
                </div>
              </div>
              <div class="col-md-4"  >
                <div class="form-control-group mb-3">
                <label>Cut</label>
                <select type="select" class="form-control" v-model="selected_cut">
                <option value="" selected>-- Select Units --</option><option v-for="values in clarityCuts['cuts']" v-bind:value="values.value">
              {{values.name}}</option></select>
                </div>
              </div>
              <div class="col-md-4"  >
                <div class="form-control-group mb-3">
                <label>Colour</label>
                <select type="select" class="form-control" v-model="selected_colour">
                <option value="" selected>-- Select Units --</option><option v-for="values in clarityCuts['colour']" v-bind:value="values.value">
              {{values.name}}</option></select>
                </div>
              </div>
              <div class="col-md-4"  >
                <div class="form-control-group mb-3">
                <label>Clarity</label>
                <select type="select" class="form-control" v-model="selected_clarity">
                <option value="" selected>-- Select Units --</option><option v-for="values in clarityCuts['clarity']" v-bind:value="values.value">
              {{values.name}}</option></select>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-control-group">
                   <a class="btn btn-light btn-block" href="javascript:;" v-if="newFeature == true"  v-on:click="addProductAttribute()">
              Save</a>
                </div>
              </div>
              <div class="col-md-12 mt-3">
                <transition name="fade">
                <div v-if="product_attribute_success === true">
                <b-alert :show="dismissCountDown" dismissible variant="success" @dismissed="dismissCountDown=0" @dismiss-count-down="countDownChanged">
                Attribute added!<small class="float-right">
              This message will self destruct in: {{ dismissCountDown }} seconds...</small><b-progress variant="success" :max="dismissSecs"   :value="dismissCountDown" height="4px">
              </b-progress></b-alert></div></transition><transition name="fade" mode="out-in" enter-active-class="fade" leave-active-class="fade" :duration="{ enter: 500, leave: 800 }">
                <div v-if="product_attribute_success === false">
                <b-alert :show="dismissCountDown" dismissible variant="danger" @dismissed="dismissCountDown=0" @dismiss-count-down="countDownChanged">
                <strong>Uh-oh!</strong> Attribute set already exists! <small class="float-right">
              This message will self destruct in: {{ dismissCountDown }} seconds...</small><b-progress variant="danger" :max="dismissSecs" :value="dismissCountDown" height="4px">
              </b-progress></b-alert></div></transition>
            </div>
            </div>
          </b-overlay>
          </div>
              

            </div>
              </b-card>
            </b-col>
          </b-row>
        </b-card>
        <b-card  class="card shadow-none border border-radius-1em"  v-show="view == 'features-variants'">
          <b-row>
            <b-col md="2">
                 <h5 class="d-flex">Variations <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
            </b-col>
            <b-col md="10">
              <b-card  class="card shadow-none border border-radius-1em">
                  <div class="row">
    <div class="col-md-12">      
<div class="row" v-if="productData.variants.length == 0">
 
  <div class="col-md-12">
                    <b-card class="shadow-none text-center border mb-3 py-4">
                      <i class="bx bxs-cube font-size-24 text-primary"></i>
                      <h4>No Active Variants</h4>
                  </b-card>
                  </div>
</div>
<div class="row" v-else>
  <b-tabs pills content-class="pt-3">
    <b-tab title="list">
   <div class="col-md-12" v-for="(variants, key) in productData.grouped_variants">
          <div class="d-flex mb-2">
          <img v-if="(variants[0].attribute.image !== null)" :src="storageurl +'/'+ variants[0].attribute.image" class="avatar-xs">
          <h5 class="text-uppercase font-size-12 spaced-title ml-2 my-auto">Variation Attribute: {{variants[0].attribute.name}}</h5>
          </div>
          <b-row >
            <b-col md="12" v-for="value in variants">
            
              <a href="javascript:;" >
              <b-card class="border-radius-1em border shadow-none text-dark mb-3" body-class="d-flex">
                <b-row class="w-100">
                  <b-col md="5" class="d-flex">
                <span class="my-auto" v-if="value.value !== null"><img v-if="value.value.image !== null" :src="storageurl +'/'+ value.value.image" class="avatar-sm rounded-circle mr-2 border"> 
                </span>


                <span class="my-auto mr-2" v-if="value.value !== null">{{value.value.name}}</span> <span class="my-auto">{{value.measurement_value}}</span><span class="my-auto mr-2" v-if="value.units !== null">{{value.units.suffix}}</span>
              </b-col>
              <b-col md="4" class="d-flex">
                <span class="badge badge-light bg-light my-auto ml-auto font-size-14" v-if="value.cut !== null">
                  {{value.cut}}
                </span>
                <span class="badge badge-light bg-light my-auto ml-2 font-size-14" v-if="value.clarity !== null">
                  {{value.clarity}}
                </span>
                <span class="badge badge-light bg-light my-auto ml-2 font-size-14" v-if="value.colour !== null">
                  {{value.colour}}
                </span>
              </b-col>
              <b-col  class="d-flex">
                <span class="badge badge-light bg-light my-auto ml-auto font-size-14">
                <span class="ml-auto my-auto" v-if="value.price_difference > 0">+</span><span class="ml-auto my-auto" v-else>-</span><span class="currency">{{$attrs.company.trading_currency_code}}</span>

                  {{addTax(productData.tax_rule, value.price_difference)}}
                </span>
                <span class="badge badge-light bg-light my-auto ml-auto font-size-14">
                <span>{{value.quantity}}</span>
              </span>
            </b-col>
            <b-col class="d-flex">
               <a class="btn btn-remove ml-2 border-0 my-auto" href="javascript:;" v-on:click="editProductVariant(value, 0)">
                <i class="bx bx-edit-alt"></i>
                  </a>
                  <a class="btn btn-remove border-0 my-auto" href="javascript:;" v-on:click="destroyProductVariant(value, 0)">
                <i class="bx bx-trash-alt"></i>
                  </a>
                </b-col>
                </b-row>
              </b-card>
              </a>
         
            </b-col>
          </b-row>
        </div>
      </b-tab>
      <b-tab title="Table">
  <div class="col-md-12">
                    <b-card class="shadow-none text-center border mb-3">
                      <table class="table w-100 text-left" >
                        <thead>
                          <tr>
                            <th colspan="1">Type</th>
                            <th colspan="1">Attribute Value</th>
                            <th colspan="1">Measurement Value</th>
                            <th colspan="1">Impact on price</th>
                            <th colspan="1">Quantity</th>
                            <th colspan="1" class="text-right">Actions</th>
                          </tr>
                        </thead>
                      <tbody>
                <tr v-for="(active_attributes, index) in productData.variants" class="border p-0 p-3 border-radius-1em mb-2">
                <td class="my-auto w-25" v-if="active_attributes.attribute !== null">
                  <img v-if="active_attributes.attribute.image" :src="storageurl+'/'+active_attributes.attribute.image" class="avatar-xs rounded-circle mr-2">
                  <img v-else src="/images/product-placeholder.png" class="avatar-xs rounded-circle mr-2">
                {{active_attributes.attribute.name}}
              </td>
              <td class="my-auto w-25 mx-auto" v-if="((active_attributes.is_custom == 0) || (active_attributes.custom_variation_text == null)) && (active_attributes.value !== null)">
                <span  v-if="active_attributes.value !== null">
                <img v-if="active_attributes.value.image" :src="storageurl+'/'+active_attributes.value.image" class="avatar-xs rounded-circle mr-3">
                <img v-else src="/images/product-placeholder.png" class="avatar-xs rounded-circle ml-2 mr-2"> {{active_attributes.value.name}}
                </span>
              </td>
              <td class="my-auto mx-auto" v-if="((active_attributes.is_custom == 1) || (active_attributes.custom_variation_text !== null))  && (active_attributes.value == null)">
                 {{active_attributes.custom_variation_text}}
              </td>
              <td class="my-auto ml-auto"> {{active_attributes.measurement_value}}{{active_attributes.units.suffix}}</td>
              <td class="my-auto"> <span class="currency">{{companyCurrency}}</span>{{active_attributes.price_difference}}</td>
              <td class="my-auto"> {{active_attributes.quantity}} <strong>QTY</strong></td>
              <td class="my-auto ml-auto">
                  <a class="btn btn-remove float-right border-0" href="javascript:;" v-on:click="editProductVariant(active_attributes, index)">
                <i class="bx bx-edit-alt"></i>
                  </a>
                  <a class="btn btn-remove float-right border-0" href="javascript:;" v-on:click="destroyProductVariant(active_attributes, index)">
                <i class="bx bx-trash-alt"></i>
                  </a>
            </td>
          </tr>
          <tr>
            <td colspan="4">
              <h6>Total Variant Quantities</h6>
            </td>
            <td colspan="1">
              {{variantsQuantities}}
            </td>
          </tr>
           <tr>
            <td colspan="4">
              <h6>Variant Average</h6>
            </td>
            <td colspan="1">
              {{variantsQuantities / variantAttributeGroups}}
            </td>
          </tr>
           <tr>
            <td colspan="4">
              <h6>Total Quantity</h6>
            </td>
            <td colspan="1">
              {{productData.total_qty}}
            </td>
          </tr>
          <tr colspan="6" v-if="(variantsQuantities / variantAttributeGroups) !== productData.total_qty" class="bg-soft-danger">
           <td class=" text-right" colspan="5"><i class="text-center">Your product variant averege does not match your total quantity.</i></td>
          </tr>
        </tbody>
      </table>
                  </b-card>
                  </div>
                </b-tab>
              </b-tabs>
</div>
<div class="row mb-3" v-if="product_variantForm !== ''">
  <div class="col-md-12">
    
  </div>
</div>  
<div class="list-group">
<div class="border border-radius-1em d-flex flex-column p-3" v-if="(product_variantForm !== '') && (variantFormLoading == false)">
<b-overlay :show="variantFormLoading">
  <div class="row">
  
  <div class="col-md-6">
    <div class="form-control-group mb-3">
      <label class="mb-0">Variant Attribute</label>
      <!--<select type="select" class="form-control" v-model="product_variantForm.attribute_id" v-on:change="getAttributeVariantValues(index)">
        <option value="" selected>-- Add New Attribute --</option>
        <option  v-for="attribute in all_attributes" :value="attribute.id">
          {{attribute.name}}
        </option>
      </select>-->
      <SelectCompanyProductAttribute :id="product_variantForm.attribute" :categories_data="all_attributes" :user="$attrs.user" :isDisabled="false" :marketplace_categories="'company'" :index="index" @attribute-selected="variantAttributeSelected(index, $event)"></SelectCompanyProductAttribute>
    </div>
  </div>
  <div class="col-md-6">
    <div class="form-control-group mb-3" v-if="product_variantForm.is_custom == 0"> 
    <label class="mb-0">Variant Attribute Type</label>
    <!--<select v-if="product_variantForm.attribute !== null" type="select" class="form-control" v-model="product_variantForm.attribute_value_id">
    <option value="" selected>-- Add New Value --</option><option v-for="values in product_variantForm.attribute.values" v-bind:value="values.id">
  {{values.name}}</option></select>-->

  <SelectCompanyProductAttributeValue  :id="product_variantForm.value" :categories_data="product_variantForm.attribute !== null ? product_variantForm.attribute['values'] : []" :user="$attrs.user" :isDisabled="product_variantForm.attribute !== null ? false : true" :marketplace_categories="'company'" @attribute-value-selected="selectVariantAttributeValues(index, $event)"></SelectCompanyProductAttributeValue>
    </div>
    <div v-else class="form-control-group mb-3" v-if="product_variantForm.is_custom == 1"> 
    <label class="mb-0">Custom Variant Type</label>
    <input v-model="product_variantForm.custom_text" type="text" class="form-control">
    </div>

  </div>
  <div class="col-md-6">
    <div class="form-control-group mb-3">
      <label class="mb-0">Value</label>
      <input type="number" step="0.01" v-model="product_variantForm.measurement_value" class="form-control" placeholder="Add size, weight, length unit value">
    </div>
  </div>
  <div class="col-md-6">
    <div class="form-control-group mb-3">
    <label class="mb-0">Value Units</label>
    <select type="select" class="form-control" v-model="product_variantForm.units">
    <option value="" selected>-- Select Units --</option><option v-for="values in measurement_units" v-bind:value="values">
  {{values.name}}</option></select>
    </div>
  </div>
  <div class="col-md-6">
    <div class="form-control-group mb-3">
      <label class="mb-0">Price Impact</label>
      <input type="number" step="0.01" v-model="product_variantForm.price_difference" class="form-control" placeholder="Price Difference">
    </div>
  </div>
  <div class="col-md-6">
    <div class="form-control-group mb-3">
      <label class="mb-0">Quantity</label>
      <input type="number" step="0.01" v-model="product_variantForm.quantity" class="form-control" placeholder="Quantity">
    </div>
  </div>
  <div class="col-md-12 mb-2">
    <label class="mb-0">Cover image</label>
    <b-card class="border-radius-1em border shadow-none">
    <div class="d-flex">
     <div v-if="(productData.images.length !== null) && (productData.images.length > 0)" v-for="(image, index) in productData.images" title-link-class="px-0 py-0 m-2">
                          <a v-if="existsInArray(product_variantForm.images, image)" @click="product_variantForm.images.push(image)">
                            <b-img-lazy v-if="'thumb' in image"
                              :src="storageurl+image.thumb.replace('//','/')"
                              alt
                              width="100px"
                              class="img-fluid mx-auto d-block tab-img rounded border"
                            />
                            <b-img-lazy v-else
                             :src="storageurl+image.src.replace('//','/')"
                              alt
                              width="100px"
                              class="img-fluid mx-auto d-block tab-img rounded border"
                             
                            />
                          </a>
                          <a v-else @click="product_variantForm.images.splice(existsInArray(product_variantForm.images, image), 1)">
                            <b-img-lazy v-if="'thumb' in image"
                              :src="storageurl+image.thumb.replace('//','/')"
                              alt
                              width="100px"
                              class="img-fluid mx-auto d-block tab-img rounded border border-primary"
                            />
                            <b-img-lazy v-else
                             :src="storageurl+image.src.replace('//','/')"
                              alt
                              width="100px"
                              class="img-fluid mx-auto d-block tab-img rounded border"
                             
                            />
                          </a>
                        
      </div>
    </div>
  </b-card>
  </div>
  <div class="col-md-4"  >
                <div class="form-control-group mb-3">
                <label>Cut</label>
                <select type="select" class="form-control" v-model="product_variantForm.cut">
                <option value="" selected>-- Select Units --</option><option v-for="values in clarityCuts['cuts']" v-bind:value="values.value">
              {{values.name}}</option></select>
                </div>
              </div>
              <div class="col-md-4"  >
                <div class="form-control-group mb-3">
                <label>Colour</label>
                <select type="select" class="form-control" v-model="product_variantForm.colour">
                <option value="" selected>-- Select Units --</option><option v-for="values in clarityCuts['colour']" v-bind:value="values.value">
              {{values.name}}</option></select>
                </div>
              </div>
              <div class="col-md-4"  >
                <div class="form-control-group mb-3">
                <label>Clarity</label>
                <select type="select" class="form-control" v-model="product_variantForm.clarity">
                <option value="" selected>-- Select Units --</option><option v-for="values in clarityCuts['clarity']" v-bind:value="values.value">
              {{values.name}}</option></select>
                </div>
              </div>
  <div class="col-md-12 d-flex pt-1"  v-if="(product_variantForm['id'] == null) || (product_variantForm['id'] == '')">
    <a class="btn btn-light btn-block btn-rounded text-danger" href="javascript:;" @click="product_variantForm = ''">
      Cancel <i class="bx bx-x"></i>
    </a>
    <b-button class="my-auto btn btn-block btn-success btn-rounded" href="javascript:;" :disabled="variantFormFilled"  @click="saveVariant(product_variantForm)">
      Save <i class="mdi mdi-check"></i>
    </b-button>
  </div>
  <div  v-else class="col-md-12 d-flex pt-1">
      <a class="my-auto btn btn-light text-danger btn-block  btn-rounded ml-auto" href="javascript:;"  @click="product_variantForm = ''">
     Cancel <i class="mdi mdi-trash-can-outline"></i>
    </a>
    <b-button class="my-auto btn btn-block btn-success btn-rounded" href="javascript:;" :disabled="variantFormFilled"  @click="updateVariant(product_variantForm)">
      Update <i class="mdi mdi-check"></i>
    </b-button>
  </div>
    <div class="col-md-12">
    </div>
  </div>
</b-overlay>
</div>
</div>
  <div class="row">
    <div class="col-md-12 pt-2 d-flex">
      <a class="btn btn-light btn-block mr-1" href="javascript:;" @click="addVariant()">Add Variation <i class="mdi mdi-plus"></i></a>

      <!--<a class="btn btn-light btn-block ml-1" href="javascript:;" @click="addCustomVariant()">Add Custom Variation <i class="mdi mdi-plus"></i></a>-->

    </div>
  </div>
</div>
</div>
              </b-card>
            </b-col>
          </b-row>
        </b-card>
        <b-card  class="card shadow-none border border-radius-1em"  v-show="view == 'features-variants'">
          <b-row>
            <b-col md="2">
                 <h5 class="d-flex">Variation Combinations <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
            </b-col>
            <b-col md="10">
              <b-card  class="card shadow-none border border-radius-1em">
                  <div class="row" v-if="productData.variants.length == 0">
                    <div class="col-md-12">
                      <b-card class="shadow-none text-center border mb-3 py-4">
                        <i class="bx bx-list-plus font-size-24 text-primary"></i>
                        <h4>No Variants to Combine</h4>
                      </b-card>
                    </div>
                  </div>
                  <div class="row" v-else>
                    <div class="col-md-12">
                      <b-card v-if="productData.combinations.length == 0" class="shadow-none text-center border mb-3 py-4">
                        <i class="bx bx-list-plus font-size-24 text-primary"></i>
                        <h4>No Combinations Created</h4>
                      </b-card>
                      <b-card v-else class="shadow-none text-center border mb-3 py-4">
                         <b-list-group>
                            <b-list-group-item v-for="(combination, index) in productData.combinations">
                              <h5 class="heading-label spaced-title text-left mt-2 pb-2 text-uppercase font-size-12">
                                Combination #{{index + 1}}
                              </h5>
                              <b-row class="w-100">
                                <b-col md="8">
                                <b-row  v-for="(variant, vindex) in combination.variants" :class="{'pb-2 mb-2 border-bottom' : vindex !== (combination.variants.length - 1)}">
                                  <b-col>
                                  <img v-if="variant.variant.attribute.image" :src="storageurl + '/' + variant.variant.attribute.image" class="avatar-xs rounded-circle mr-2">
                                  <img v-else src="/images/product-placeholder.png" class="avatar-xs rounded-circle mr-2">
                                   <span class="mr-2 my-auto">{{variant.variant.attribute.name}}</span>
                                 </b-col>
                                 <b-col v-if="variant.variant.is_custom == 0">
                                  <span class="d-flex" >
                                    <img v-if="variant.variant.value.image" :src="storageurl + '/' + variant.variant.value.image" class="avatar-xs rounded-circle mr-2">
                                  <img v-else src="/images/product-placeholder.png" class="avatar-xs rounded-circle mr-2">
                                    <span class="mr-2 my-auto">{{variant.variant.value.name}}</span>
                                  </span>
                                </b-col>
                                <b-col v-else>
                                  <span class="my-auto">
                                    {{variant.variant.custom_text}}
                                  </span>
                                </b-col>
                                <b-col class="d-flex">
                                  <span class="my-auto">{{variant.variant.measurement_value}}</span>
                                  <span class="my-auto" v-if="variant.variant.units !== null">
                                    {{variant.variant.units.suffix}}
                                  </span>
                                </b-col>
                                </b-row>
                              </b-col>
                              <b-col class="d-flex flex-column justify-content-center border border-2 border-radius-1em">
                                <span>
                                {{combination.quantity}} <small>QTY</small>
                                </span>
                              </b-col>
                              <b-col class="d-flex">
                                <span class="badge badge-light bg-light font-size-14 my-auto">
                                <span v-if="combination.price_difference > 0"> + </span>
                                <span v-else> - </span>
                                <span class="currency">{{companyCurrency}}</span>{{combination.price_difference}}
                                </span>
                              </b-col>
                              <b-col class="d-flex">
                                <a class="btn btn-remove ml-auto border-0 my-auto">
                                   <i class="bx bx-pencil"></i>
                                </a>

                                <a class="btn btn-remove border-0 my-auto"  @click="destroyCombination(combination, index)">
                                   <i class="bx bx-trash-alt"></i>
                                </a>
                              </b-col>
                              </b-row>
                            </b-list-group-item>
                          </b-list-group>
                      </b-card>
                      <b-card class="shadow-none border mb-3 py-4">
                        <b-row>
                          <b-col md="5">
                        <b-form-group label="Variants">
                          <multiselect v-model="product_combinationsForm.variants" label="{ option }" :options="productData.variants" :option-height="104" :multiple="true" :taggable="true" @remove="removeCombination(option)" >
                     <!--<template slot="singleLabel" slot-scope="{ option }">{{ option.name }} - {{ option.jobtitle }}</template>-->
                            <template slot="tag" slot-scope="{ option, remove }"><div class="d-flex w-100 h-100 border border-radius-1em custom__tag p-2 my-2">
                                            <img v-if="option.attribute.image" :src="storageurl + '/' + option.attribute.image" class="avatar-sm rounded-circle mr-2">
                                            <div v-else class="avatar-sm mr-2 my-auto">
                                                <span class="avatar-title rounded-circle">{{(option.attribute.name).slice(0,1)}}</span>
                                              </div>
                              <div class="option__desc d-flex flex-row w-75"><span v-if="option.is_custom == 0" class="option__title ml-2 my-auto w-100">{{ option.attribute.name }} : {{ option.value.name }} : {{option.measurement_value}} {{option.units.suffix}}</span>
                                <span v-if="option.is_custom == 1" class="option__title ml-2 my-auto w-100">{{ option.attribute.name }} : {{ option.custom_variation_text }} : {{option.measurement_value}} {{option.units.suffix}}</span>
                                <span class="custom__remove my-auto btn-small btn btn-light btn-rounded"  @click="removeCombination(option)">
                                  <i class="mdi mdi-close"></i>
                                </span>
                              </div>
                              </div></template>
                            <template slot="option" slot-scope="{ option }">
                              <div class="d-flex">
                                <img v-if="option.attribute.image" :src="storageurl + '/' + option.attribute.image" class="avatar-sm rounded-circle mr-2">
                                            <div v-else class="avatar-sm mr-2">
                                                <span class="avatar-title rounded-circle">{{(option.attribute.name).slice(0,1)}}</span>
                                              </div>
                              <div class="option__desc d-flex flex-row w-50"><span class="option__title ml-2 my-auto w-100" v-if="option.is_custom == 0">{{ option.attribute.name }} : {{ option.value.name }} : {{option.measurement_unit_value}} {{option.units.suffix}}</span>
                                <span class="option__title ml-2 my-auto w-100" v-if="option.is_custom == 1">{{ option.attribute.name }} : {{ option.custom_text }} : {{option.measurement_unit_value}} {{option.units.suffix}}</span>
                              </div>
                              </div>
                            </template>
                            </multiselect>
                        </b-form-group>
                        </b-col>
                        <b-col md="2">
                           <b-form-group label="Quantity">
                            <b-form-input type="number" v-model="product_combinationsForm.quantity">
                            </b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col md="2">
                           <b-form-group label="Price Impact">
                            <b-form-input type="number" v-model="product_combinationsForm.price_difference">
                            </b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col md="3" class="d-flex">
                          <b-button variant="success" class="btn-rounded btn-success mt-auto mb-2" @click="saveCombination()">
                            Add Combination
                          </b-button>
                        </b-col>
                      </b-row>
                      </b-card>
                    </div>
                  </div>
                </b-card>
              </b-col>
            </b-row>
          </b-card>
          <b-card  class="card shadow-none border border-radius-1em"   v-show="view == 'related-products'" >
            <b-row>
              <b-col md="2">
                   <h5 class="d-flex">Related Products <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
              </b-col>
              <b-col md="10">
                 <CompanyProductSearch class="mt-1 mb-4" :company="$attrs.company" :resource="'product'" :resource_id="''" :api_token="$attrs.user.api_token" :resource_route="'/api/company/product/'+$attrs.id+'/related-products/add?'" @product-added="addRelatedProduct"></CompanyProductSearch>

                 <b-card class="border-radius-1em border shadow-none">
                  <b-list-group>
                    <b-list-group-item v-for="(product, index) in productData.related_products" class="d-flex">
                      <div class="my-auto">
                        <img v-if="product.related_product_details.cover_image == null" src="/images/product-placeholder.png" class="avatar-sm rounded-circle">
                        <img v-else :src="storageurl +product.related_product_details.cover_image.src.replaceAll('//','/')" class="avatar-sm rounded-circle">
                        
                      </div>
                      <div class="my-auto ml-2">
                        <span class="text-primary">{{product.related_product_details.reference}}</span><br>
                        <h6>
                        {{product.related_product_details.name}}
                        </h6>
                      </div>
                      <div class="my-auto ml-auto">
                        <a class="btn btn-outline-light btn-rounded" @click="removeRelatedProduct(product.related_product_details, index)">
                          <i class="bx bx-trash-alt"></i></a>
                      </div>
                    </b-list-group-item>
                  </b-list-group>
                  </b-card>
            <!--<b-overlay
                            id="overlay-background"
                            :show="loadingForm"
                            variant="white"
                            opacity="0.85"
                            blur="1px"
                            rounded="md"
                          >
              <CompanyProductList :resource="'category'" :product_list="" :resource_id="selectedCategory.id" :default_resource_key="default_resource_key" :expanded="isExpanded" @remove-product="removeFromResource" ></CompanyProductList>
              <b-pagination v-if="categoryProducts !== ''" v-model="categoryProductsPage" :total-rows="categoryProducts.total" :per-page="categoryProducts.per_page" align="right" @input="getProducts(selectedCategory.id)"></b-pagination>
            </b-overlay>-->
              </b-col>
            </b-row>

          </b-card>
        <!--  <b-card  class="card shadow-none border border-radius-1em"  v-show="view == 'features-variants'">
          <b-row>
            <b-col md="2">
                 <h5 class="d-flex">Purchasing Options <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
            </b-col>
            <b-col md="10">

            </b-col>
          </b-row>
        </b-card>
       <b-card  class="card shadow-none border border-radius-1em"  v-show="view == 'shipping'">
          <b-row>
            <b-col md="2">
                 <h5 class="d-flex">Shipping <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
            </b-col>
            <b-col md="10">
              <b-card class="shadow-none d-flex border border-radius-1em mb-2 mt-2">
            
                <b-row>
                  <b-col v-if="shippingoptions.length > 0" md="3" v-for="shippingoption in shippingoptions">
                    <b-card class="shadow-sm text-center border border-radius-1em py-3" @click="addShippingOption(shippingoption)" :class="{ 'bg-primary text-white' : shippingSelected(shippingoption) == true}">
                      <img v-if="shippingoption.icon !== (null || '')" :src="storageurl+shippingoption.icon">
                      <i v-else class="bx bxs-truck d-block font-size-24"></i>
                      {{shippingoption.name}}
                                        
                    </b-card>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
        </b-card>-->


        <b-card class="card shadow-none border border-radius-1em"  v-show="view == 'seo'">
          <b-row>
            <b-col md="2">
                <h5 class="d-flex">SEO & Meta Data <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
            </b-col>
            <b-col md="10">
              <b-card class="shadow-none border border-radius-1em">
              <div class="row">
                <div class="col-sm-12">
                  <b-form-group label="Product URL">
                   
                    <input id="metatitle" name="url_rewrite" type="text" class="form-control" v-model="productData.url_rewrite" />
                  </b-form-group>
                  <b-form-group class="mt-2" label="Meta title">
                    <input id="metatitle" name="meta_title" type="text" class="form-control" v-model="productData.meta_title"/>
                  </b-form-group>
                  <b-form-group class="mt-2" label="Meta Keywords">
                     <b-form-tags placeholder="Add new tag..." input-id="tags-basic" v-model="productData.meta_keywords" class="my-2 text-muted" name="meta_keywords" tag-pills tag-variant="primary"></b-form-tags>
                  </b-form-group>
                  
                </div>

                <div class="col-sm-12">
                  <b-form-group class="mt-2" label="Meta Description">
                    <textarea id="metadescription" class="form-control" rows="5" name="meta_description" v-model="productData.meta_description"></textarea>
                  </b-form-group>
                </div>
              </div>            
          </b-card>
            </b-col>
             <b-col md="2">
                <h5 class="d-flex">Tags <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
            </b-col>
            <b-col md="10">
              <b-card class="shadow-none border-radius-1em border">
             
            <div class="form-group">
                
                    <b-form-tags placeholder="Add new tag..." input-id="tags-basic" v-model="productData.search_keywords" class="my-2 text-muted" name="search_keywords" tag-pills tag-variant="primary"></b-form-tags>
            </div>

          



              <!--<button type="submit" class="btn btn-primary mr-1">Save Changes</button>
              <button type="submit" class="btn btn-secondary">Cancel</button>-->
            
          </b-card>
            </b-col>

          </b-row>

        </b-card>

        <b-card class="card shadow-none border border-radius-1em"  v-show="view == 'condition'">
          <b-row>
           <b-col md="2">
                <h5 class="d-flex">Product Condition <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
            </b-col>
            <b-col md="10">
          <div class="card shadow-none border border-radius-1em">
          <div class="card-body">
                <b-form-group class="mt-2" label="Condition">
                  <multiselect v-model="productData.condition" :options="conditions"></multiselect>
                  <input type="hidden" name="condition" :value="productData.condition">
                    </b-form-group>
                  <b-form-group class="mt-2" label="Custom Condition" v-if="productData.condition == 'Custom'">
                    <b-form-input
                        id="input-1"
                        v-model="productData.condition_custom_text"
                        type="text"
                        placeholder="Custom Condition"
                        label="Custom"
                        name="condition_custom_text"
                      ></b-form-input>
                  </b-form-group>
                  <b-form-group class="mt-2" label="Condition Notes">
                    <b-form-textarea
                      id="textarea"
                      v-model="productData.condition_notes"
                      placeholder="Enter something..."
                      rows="4"
                      name="condition_notes"
                    ></b-form-textarea>
                  </b-form-group>
          </div>
        </div>
        </b-col>
      </b-row>
        </b-card>
        <b-card class="card shadow-none border border-radius-1em" v-if="view == 'purchase'" >
          <b-row>
            <b-col md="2">
              <h5 class="d-flex">Purchases <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
            </b-col>
            <b-col md="10">
              <b-row >
                 <b-col md="8">
                      <div class="mt-sm-0 form-inline w-100" >
                  <div class="search-box mr-2 ml-auto mt-3">
                    <div class="position-relative">
                      <input
                        type="text"
                        class="form-control w-100 border-0 bg-light"
                        placeholder="Search name, reference or description..."
                        v-model="purchasesearch" @keydown.enter="getPurchaseOrders()" 
                      />
                      <i class="bx bx-search-alt search-icon"></i>
                    </div>
                  </div>
                </div>
              
                <simplebar
                            style="height: 300px"
                            class="searchbar-searchlist"
                            v-if="(purchase_orders !== '') && (showPurchaseResults == true)"
                        >
                            <li  class="list-group-item" v-for="listing in purchase_orders.data">
                                <div class="media align-items-center">
                                    <div class="avatar-sm me-3" v-if="listing.logo">
                                        <span
                                            class="avatar-title rounded-circle bg-light"
                                        >
                                            <!--<img
                                                
                                                :src="listing.logo"
                                                alt=""
                                                height="18"
                                            />-->
                                            
                                        </span>
                                      </div>
                                       <div class="avatar-sm me-3" v-else>
                                        <span class="avatar-title rounded-circle" >{{(listing.purchase_order_no).charAt(0)}}</span>
                                      </div>
                                   
                                    <div class="media-body">
                                        <h5 class="font-size-14">
                                            {{listing.purchase_order_no}}
                                        </h5>
                                    </div>  
                                        <p class="text-muted mb-0 float-end">
                                            <a class="btn btn-primary" href="javascript:;" @click="productData.purchase = listing, productData.company_purchase_order_id = listing.id, showPurchaseResults = false">Use Purchase Order</a>
                                        </p>
                                    
                                </div>
                            </li>
                             <li class="list-group-item"  v-if="purchaselimit < purchase_orders.total">
                              <a class="btn btn-block btn-outline-light" @click="purchaselimit += 25">Load More</a>
                            </li>
                        </simplebar>
                       </b-col>
              <b-col md="4" class="d-flex">
                    <a class="btn btn-outline-light btn-rounded mt-auto btn-block">Create Purchase Order</a>
                </b-col>
            </b-row>
            <b-row class="mt-3" v-if="productData.purchase !== null">
              <b-col md="12">
                <ul class="list-group">
                  <li class="list-group-item border-radius-1em" >
                                <div class="media align-items-center">
                                   
                                       <div class="avatar-sm me-3">
                                        <span class="avatar-title rounded-circle" >{{(productData.purchase.purchase_order_no).slice(0,1)}}</span>
                                      </div>
                                   
                                    <div class="media-body">
                                        <h5 class="font-size-14">
                                            {{productData.purchase.purchase_order_no}}
                                        </h5>
                                    </div>
                                        <p class="text-muted mb-0 float-end">
                                            <a class="btn btn-primary" @click="productData.purchase = null, productData.company_purchase_order_id = null, showPurchaseResults = true">Remove</a>
                                        </p>
                                    
                                </div>
                            </li>
                </ul>

              </b-col>
            </b-row>
            <input v-if="productData.purchase !== null" type="hidden" name="company_purchase_order_id" v-model="productData.purchase.id">
            <input v-else type="hidden" name="company_purchase_order_id" value="">
            </b-col>
          </b-row>
        </b-card>
        <b-card class="card shadow-none border border-radius-1em"  v-show="view == 'barcodes'">
          <b-row>
            <b-col md="2">
              <h5 class="d-flex">Barcode <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
            </b-col>
            <b-col md="10">
              <b-card class="card shadow-none border border-radius-1em" >
                <b-row>
                  <b-col md="4">
                <b-form-group class="my-2" label="Barcode">
                    <input id="barcode" type="text" class="form-control" name="barcode[CODE128]"/>
                </b-form-group>
                <div class="mx-auto text-center" v-if="(productData.id !== null)">
                  <VueBarcode value="123456789012" :options="{ displayValue: true, format: 'CODE128', lineColor: '#5E3BB0', width: 2, background: '#f7f7f7' }"></VueBarcode>
                  
                  <div class="position-absolute bg-white" v-if="productData.barcode.CODE128 == ''">
                    EXAMPLE BARCODE
                  </div>
                </div>
                <div class="mx-auto text-center" v-else>
                  <VueBarcode value="123456789012" :options="{ displayValue: true, format: 'CODE128', lineColor: '#5E3BB0', width: 2, background: '#f7f7f7' }"></VueBarcode>
                   <div class="position-absolute bg-white" style="top: 60%; margin: auto; width: 100%;">
                    EXAMPLE BARCODE
                  </div>
                </div>
              </b-col>

                  <b-col md="4">
                <b-form-group class="my-2" label="EAN-13 Barcode">
                    <input id="ean_jan_barcode" type="text" class="form-control" name="barcode[EAN13]"/>
                </b-form-group>
                <div class="mx-auto text-center" v-if="productData.id !== null">
                  <VueBarcode value="123456789012" :options="{ displayValue: true, format: 'EAN13', lineColor: '#5E3BB0', width: 2, background: '#f7f7f7' }"></VueBarcode>
                   <div class="position-absolute bg-white" v-if="productData.barcode.EAN13 == ''">
                    EXAMPLE BARCODE
                  </div>
                </div>
                <div class="mx-auto text-center" v-else>
                  <VueBarcode value="123456789012" :options="{ displayValue: true, format: 'EAN13', lineColor: '#5E3BB0', width: 2, background: '#f7f7f7' }"></VueBarcode>
                  
                  <div class="position-absolute bg-white" style="top: 60%; margin: auto; width: 100%;">
                    EXAMPLE BARCODE
                  </div>
                </div>
              </b-col>
              <b-col md="4">
                <b-form-group class="my-2" label="UPC Barcode">
                    <input id="upc_barcode" type="text" class="form-control" name="barcode[UPC]" />
                </b-form-group>
                <div class="mx-auto text-center" v-if="productData.id !== null">
                  <VueBarcode value="123456789012" :options="{ displayValue: true, format: 'UPC', lineColor: '#5E3BB0', width: 2, background: '#f7f7f7' }"></VueBarcode>
                  
                   <div class="position-absolute bg-white" v-if="productData.barcode.UPC == ''">
                    EXAMPLE BARCODE
                  </div>
                </div>
                <div class="mx-auto text-center" v-else>
                  <VueBarcode value="123456789012" :options="{ displayValue: true, format: 'UPC', lineColor: '#5E3BB0', width: 2, background: '#f7f7f7' }"></VueBarcode>
                  <div class="position-absolute bg-white" style="top: 60%; margin: auto; width: 100%;">
                    EXAMPLE BARCODE
                  </div>
                </div>
              </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>

        </b-card>
        <b-card class="card shadow-none border border-radius-1em"  v-show="view == 'barcodes'">
          <b-row>
            <b-col md="2">
              <h5 class="d-flex">QR Code <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
            </b-col>
            <b-col md="10">
              <b-card class="card shadow-none border border-radius-1em" >
              <b-row>
                <b-col md="4">
                <div class="bg-light bg-soft d-inline-block" v-if="productData.id !== null">
                 <qrcode-vue v-if="qrData.reference !== ''" :value="JSON.stringify(qrData)" :size="250" level="H" foreground="#5E3BB0" background="#f7f7f7" margin="30" class="p-3" />
               </div>
                <div class="bg-light bg-soft d-inline-block" v-else>
                  <b-overlay :show="true" rounded="lg" opacity="0.95">
                    <qrcode-vue :value="'demo'" :size="250" level="H" foreground="#5E3BB0" background="#f7f7f7" margin="30" class="p-3" />
                    <template #overlay> 
                      <i class="mdi mdi-qrcode-plus d-block mx-auto text-center font-size-20"></i>
                      <h5 class="text-center">QR Code Generated On Product Save</h5>
                    </template>
                  </b-overlay>
               </div>
             </b-col>
             <b-col md="8" class="d-flex justify-content-center align-items-center border border-radius-1em">
               <div class="d-inline-block">
                <div class="">
                    <h4>QR Type</h4>
                    <b-button-group variant="light">
                      <b-button variant="light" v-if="selectedQrType = 'pos'">POS Scanner</b-button>
                      <b-button variant="light">Public Link</b-button>
                      <b-button variant="light">Trade Link</b-button>
                      <b-button variant="light">Website Link</b-button>
                    </b-button-group>
                </div>
                </div>
              </b-col>
            </b-row>

          </b-card>
            </b-col>
          </b-row>
        </b-card>
         <b-card class="card shadow-none border border-radius-1em" v-if="view == 'integrations'">
            <b-row>
              <b-col md="2">
                <h5 class="d-flex">Active Integrations<i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
              </b-col>
              <b-col md="10">
                <b-card class="border shadow-none border-radius-1em">
                   <b-overlay :show="loadingActiveIntegrations" variant="white" opacity="0.95">
          <template #overlay>
              <b-row>
                  <b-col md="12" class="offset-md-2" >
                  <b-card class="border-radius-1em border shadow-none mb-0">  
                      <div class="cube-wrapper mx-auto"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
                      <h6 class="text-center">Loading Your Integration</h6>
                       <b-progress class="w-50 mx-auto" variant="primary"></b-progress>
                  </b-card>
                  </b-col>
                  </b-row>
                </template>
                  <div class="mt-sm-0 form-inline w-100" >
                    <b-list-group flush>
                      <b-list-group-item v-for="channelproduct in productData.product_channels" class="d-flex">
                        <img :src="getChannelsImage(channelproduct.integration.integration_name)" class="my-auto mr-3" width="50px">
                        <div v-if="'integration' in channelproduct" class="my-auto mr-auto">
                          {{channelproduct.integration.integration_name}}
                        </div>
                        <div v-if="'integration' in channelproduct" class="my-auto mr-auto text-capitalize">
                          {{channelproduct.integration.type}}
                        </div>
                        <!--<div class="my-auto mx-auto d-flex flex-column">
                         <label class="mb-0">Quantity</label>
                         <span class="text-center">
                          {{channelproduct.quantity}}
                        </span>
                        </div>
                        <div class="my-auto mx-auto d-flex flex-column">
                           <div class="d-flex">
                          <b-button @click="channelproduct.quantity--" variant="light" class="btn-rounded rounded-circle">
                            <i class="mdi mdi-minus"></i>
                          </b-button>
                          <b-form-group class="flex-grow-2 mx-1">
                            <b-form-input type="number" v-model="channelproduct.quantity">
                            </b-form-input>
                            </b-form-group>
                            <b-button @click="channelproduct.quantity++" variant="light" class="btn-rounded rounded-circle">
                            <i class="mdi mdi-plus"></i>
                          </b-button>
                          </div>
                        </div>-->
                        <div class="my-auto">
                         <a class="btn btn-light my-auto ml-2 btn-rounded" v-b-modal.channel-details @click="selected_integration_uid = '', getActiveChannel(channelproduct)">
                          Product Details
                         </a>
                        </div>
                       <div class="my-auto">
                         <a class="btn btn-primary my-auto ml-2 btn-rounded" v-b-modal.publish-integration-updates @click="getActiveChannel(channelproduct)">
                          Publish Updates
                         </a>
                        </div>
                        <div class="my-auto">
                          <b-dropdown v-b-tooltip.hover variant="light" title="More Options" placement="left" dropleft class="more-options-dropdown ml-2 btn-rounded rounded-circle" menu-class="dropdown-menu-right mt-4">
                        <template v-slot:button-content>
                           <i class="bx bx-dots-horizontal-rounded"></i>
                        </template>
                          <b-dropdown-item href="javascript: void(0);" v-b-modal.unlink-channel @click="getActiveChannelPass(channelproduct), selected_product = channelproduct"><i class="bx bx-copy-alt"></i> Unlink Product</b-dropdown-item>
                          <b-dropdown-item href="javascript: void(0);" v-b-modal.delete-channel @click="getActiveChannelPass(channelproduct), selected_product = channelproduct"><i class="bx bx-copy-alt"></i> Delete Product</b-dropdown-item>
                      </b-dropdown>
                        </div>
                      </b-list-group-item>
                    </b-list-group>
                  </div>
                </b-overlay>
                </b-card>
              </b-col>
            </b-row>
          </b-card>
           <b-card class="card shadow-none border border-radius-1em" v-if="view == 'integrations'">
            <b-row>
              <b-col md="2">
                <h5 class="d-flex">New Integration<i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
              </b-col>
              <b-col md="10">
                 <b-tabs pills variant="primary" content-class="pt-3">
                  <b-tab title="E-commerce">
                <b-card class="border shadow-none border-radius-1em">
                  <div class="mt-sm-0 form-inline w-100" >
                    <b-overlay :show="loadingIntegrations" variant="white" opacity="0.95">
          <template #overlay>
              <b-row>
                  <b-col md="12" class="offset-md-2" >
                  <b-card class="border-radius-1em border shadow-none mb-0">  
                      <div class="cube-wrapper mx-auto"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
                      <h6 class="text-center">Loading Your Integration</h6>
                       <b-progress class="w-50 mx-auto" variant="primary"></b-progress>
                  </b-card>
                  </b-col>
                  </b-row>
                </template>
               
                    <b-list-group flush>
                      <b-list-group-item v-for="integration in active_ecommerce_integrations" class="d-flex">
                       <div class="d-flex flex-column w-100">
                      <div class="d-flex w-100">
                        <img :src="getChannelsImage(integration.integration_name)" class="my-auto mr-3" width="50px">
                        
                        
                        <div class="d-flex flex-column">
                          <h5 class="mt-auto mb-0 mr-2">
                        {{integration.integration_name}}
                        </h5>
                        <h6 class="mb-auto">
                        {{integration.type}}
                        </h6>
                          
                        </div>
                        <div class="ml-auto d-flex my-auto">
                        <a class="badge badge-light bg-light font-size-14" :href="integration.url" target="_blank">
                            <i class="bx bx-link-external"></i>
                            {{integration.url}}
                          </a>
                        </div>
                        <div class="d-flex ml-2 my-auto">
                          <a class="btn btn-info my-auto btn-rounded mr-2" v-if="checkExistingChannel(integration)" v-b-modal.manual-link @click="selected_integration_uid = '', getActiveChannelProducts(integration), selected_integration_uid = integration.uid">
                            Link To Channel
                          </a>
                          <a class="btn btn-light my-auto btn-rounded" v-else v-b-modal.channel-details @click="selected_integration_uid = '', getActiveChannelProductDetails(integration)">
                          <i class="bx bx-check-circle text-success font-size-14"></i> Product Linked
                          </a>
                          <a class="btn btn-primary my-auto btn-rounded" v-if="checkExistingChannel(integration)" v-b-modal.export-product @click="selected_integration = integration">
                            Export To Channel
                          </a>
                        </div>
                      </div>
                        <!--<div class="d-flex mr-auto flex-column">
                            <span class="badge badge-light bg-light mx-auto mt-auto mb-1">
                              Total Products: {{integration.channel_products.total}}
                            </span>
                            <span class="badge badge-light bg-light  mx-auto mb-auto mt-1">
                              Matched Products: {{integration.channel_products.empty_matched_sku}}
                            </span>
                          </div>-->
                      
                    </div>
                      </b-list-group-item>
                    </b-list-group>
                 
                  </b-overlay>
                  </div>
                </b-card>
                 </b-tab>
                  <b-tab title="Marketplace" @click="filterActiveIntegrations(all_active_commerce_channels, 'marketplace')">
                  </b-tab>
                </b-tabs>
              </b-col>
            </b-row>
          </b-card>
         <b-card class="card shadow-none border border-radius-1em"  v-if="view == 'reviews'">
          <b-row>
              <b-col md="2">
                <h5 class="d-flex">Product Reviews <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
                <h3 class="text-primary mt-4" v-if="productData.reviews_avg_rating !== null">{{parseFloat(productData.reviews_avg_rating).toFixed(2)}} / <small class="font-size-15 text-dark">5</small></h3>
                <h3 class="text-primary mt-4" v-else>0 / <small class="font-size-15 text-dark">5</small></h3>
                <small>Avg. Product Review</small>
                <hr>
              </b-col>
              <b-col md="10">
                <div  v-if="productData.reviews.length == 0" style="height: 300px;" class="w-100 d-flex justify-content-center align-items-center">
                <div class="mx-auto my-auto text-center">
                  <i class="bx bx-shopping-bag font-size-42 text-muted d-block mx-auto pb-2"></i>
                  <span class="text-muted">
                    No Orders Listed
                  </span>
                </div>
              </div>
              <div v-else>
                <b-row>
                  <b-col md="12" class="mb-3">
                    Filter By: 
                    <b-button variant="light" :class="{'bg-primary border-primary text-white' : reviewsFilteredBy == ''}" class="btn-rounded" @click="reviewsFilteredBy = ''">
                      All
                    </b-button>
                    <b-button variant="light" :class="{'bg-primary border-primary text-white' : reviewsFilteredBy == 'public'}" class="btn-rounded" @click="reviewsFilteredBy = 'public'">
                      Public
                    </b-button>
                     <b-button variant="light" :class="{'bg-primary border-primary text-white' : reviewsFilteredBy == 'trade'}" class="btn-rounded" @click="reviewsFilteredBy = 'trade'">
                      Trade
                    </b-button>
                     <b-button variant="light" :class="{'bg-primary border-primary text-white' : reviewsFilteredBy == 'website'}" class="btn-rounded" @click="reviewsFilteredBy = 'website'">
                      Website
                    </b-button>
                  </b-col>
                  <b-col md="6" v-for="item in filteredReviews">
                    <ReviewCard :view="'admin'"  :review="item"></ReviewCard>
                  </b-col>
                </b-row>
              </div>
              </b-col>
          </b-row>
        </b-card>
        <b-row v-if="view == 'orders'">
            <b-col md="12">
                          <b-card class="border-radius-1em shadow-sm">
             
          
    
             <b-row>
              <b-col md="2">
                <h5 class="d-flex">Product Orders <i class="bx bx-right-arrow-circle text-primary font-size-20 ml-2"></i></h5>
                <h3 class="text-primary mt-4">{{product_orders.total}}</h3>
                <small>Total Orders</small>
                <hr>
                <!--
                <h3 class="text-primary mt-4" v-if="product.listings.length > 0">{{listingsTotalQuantities}}</h3>
                <small>Quantity Assigned to Shops</small>
                <hr>
                -->
              </b-col>
              <b-col md="10">
             <b-card class="shadow-none border-radius-1em border" v-if="'data' in product_orders">
              <div  v-if="product_orders.data.length == 0" style="height: 300px;" class="w-100 d-flex justify-content-center align-items-center">
                <div class="mx-auto my-auto text-center">
                  <i class="bx bx-shopping-bag font-size-42 text-muted d-block mx-auto pb-2"></i>
                  <span class="text-muted">
                    No Orders Listed
                  </span>
                </div>
              </div>
             
             <div v-else>
              <OrderList :orderData="product_orders"></OrderList>
            </div>
            <div class="row">
              <div class="col-lg-12" v-if="'data' in product_orders">
                <b-pagination
              v-if="product_orders.last_page > 1"
              class="justify-content-center"
              pills
              v-model="productsCurrentPage"
              :total-rows="product_orders.total"
              :per-page="product_orders.per_page"
              aria-controls="my-table"
              @input="getProductOrders(productData.id)"
            ></b-pagination>
             <small class="mx-auto my-2 text-center d-block">Records {{product_orders.from}} - {{product_orders.to}} of {{product_orders.total}} // Page {{product_orders.current_page}} of {{product_orders.last_page}}</small>
              </div>
            </div>
          </b-card>
          </b-col>
        </b-row>
                          </b-card>
                        </b-col>
          </b-row>
        
        <!-- end card-->
        <b-row v-if="view == 'sales-stats'" class="mb-3">
          <b-col>
              <b-card class="border-radius-1em shadow-sm mb-0">
                  <div class="d-flex align-items-center mb-3"><div class="avatar-xs mr-3"><span class="avatar-title rounded-circle bg-soft-primary text-white font-size-18"><i class="bx bx-copy-alt"></i></span></div> <h5 class="font-size-14 mb-0">Orders</h5></div> <div class="text-muted mt-4"><h4 v-if="(orderDataLoading == false)">{{productstats[0].length}} <i class="mdi mdi-chevron-up ml-1 text-success"></i></h4><b-spinner v-else variant="primary" type="grow">
                                     </b-spinner> <div class="d-flex"><span class="badge badge-soft-success font-size-12"> + 0.2% </span> <span class="ml-2 text-truncate">From previous period</span></div></div>
                  
                </b-card>
            </b-col>
            <b-col>
              <b-card class="border-radius-1em shadow-sm mb-0">
                  <div class="d-flex align-items-center mb-3"><div class="avatar-xs mr-3"><span class="avatar-title rounded-circle bg-soft-primary text-white font-size-18"><i class="bx bx-pound"></i></span></div> <h5 class="font-size-14 mb-0">Revenue</h5></div> <div class="text-muted mt-4"><h4  v-if="(orderDataLoading == false) && (lineChart.series[0].data.length > 0)"><span class="currency">{{companyCurrency}}</span>{{(lineChart.series[0].data[lineChart.series[0].data.length - 1]).toFixed(2).toString().replace(/B(?=(d{3})+(?!d))/g, ",")}}</h4>
                                    <h4 v-if="(orderDataLoading == false) && (lineChart.series[0].data.length == 0)"><span class="currency">{{companyCurrency}}</span>0.00</h4><b-spinner v-if="orderDataLoading == true" variant="primary" type="grow">
                                     </b-spinner> <div class="d-flex"><span class="badge badge-soft-success font-size-12"> + 0.2% </span> <span class="ml-2 text-truncate">From previous period</span></div></div>
                </b-card>
            </b-col>
            <b-col>
              <b-card class="border-radius-1em shadow-sm mb-0">
                  <div class="d-flex align-items-center mb-3"><div class="avatar-xs mr-3"><span class="avatar-title rounded-circle bg-warning text-white font-size-18"><i class="bx bx-star"></i></span></div> <h5 class="font-size-14 mb-0">Product Reviews</h5></div> <div class="text-muted mt-4">
                    <h4 v-if="(orderDataLoading == false) && (productData.reviews_avg_rating !== null)">{{parseFloat(productData.reviews_avg_rating).toFixed(2)}} / <small class="font-size-15 text-dark">5</small></h4>
                <h4 class="" v-else-if="(orderDataLoading == false) && (productData.reviews_avg_rating == null)">0 / <small class="font-size-15 text-dark">5</small></h4>
                <b-spinner v-else variant="primary" type="grow">
                                     </b-spinner>
                <div class="d-flex"><span class="ml text-truncate">Avg. Product Review</span></div>
                    
                                  </div>
                </b-card>
            </b-col>
        </b-row>
          <b-row v-if="view == 'sales-stats'">
            <b-col md="12">
              <b-row>
              <b-col md="8">
              <div class="card border-radius-1em">
                <div class="card-body">
                    <div class="clearfix">
                       <!-- <div class="float-right">
                            <div class="input-group input-group-sm">
                                <b-form-input type="date" v-model="ordersstartdate">
                                </b-form-input>
                
                                <b-form-input type="date" v-model="ordersenddate">
                                </b-form-input>
                                <a class="btn btn-light btn-rounded" @click="getProductOrderStats(productData.id)">
                                    Update
                                </a>
                            </div>
                        </div>-->
                        <h4 class="card-title mb-4">Earning</h4>
                    </div>

                    <div class="row">
                        <div class="col-lg-6">
                            <div class="text-muted">
                                <div class="mb-4">
                                    <p>From {{ordersstartdate | luxon}} to {{ordersenddate | luxon}}</p>
                                    
                                </div>

                                

                                <div class="mt-4">
                                    
                                </div>

                            </div>
                      
                        </div>
                        <div class="col-lg-4">
                            <div class="text-muted text-right">
                              <h4  v-if="(orderDataLoading == false) && (lineChart.series[0].data.length > 0)"><span class="currency">{{companyCurrency}}</span>{{(lineChart.series[0].data[lineChart.series[0].data.length - 1]).toFixed(2).toString().replace(/B(?=(d{3})+(?!d))/g, ",")}}</h4>
                                    <h4 v-if="(orderDataLoading == false) && (lineChart.series[0].data.length == 0)"><span class="currency">{{companyCurrency}}</span>0.00</h4>
                                    <div v-if="orderDataLoading == false"><span class="badge font-size-12 mr-1" :class="{'badge-soft-success' : (((parseFloat(lineChart.series[0].data[lineChart.series[0].data.length - 1]) - parseFloat(orderprevioustotal)) / parseFloat(orderprevioustotal)) * parseFloat(100)).toFixed(2) > 0, 'badge-soft-danger': (((parseFloat(lineChart.series[0].data[lineChart.series[0].data.length - 1]) - parseFloat(orderprevioustotal)) / parseFloat(orderprevioustotal)) * parseFloat(100)).toFixed(2) < 0}"> 
                                    <span v-if="(((parseFloat(lineChart.series[0].data[lineChart.series[0].data.length - 1]) - parseFloat(orderprevioustotal)) / parseFloat(orderprevioustotal)) * parseFloat(100)).toFixed(2) > 0">+</span>{{(((parseFloat(lineChart.series[0].data[lineChart.series[0].data.length - 1]) - parseFloat(orderprevioustotal)) / parseFloat(orderprevioustotal)) * parseFloat(100)).toFixed(2) }} %</span> From previous period</div>
                                     <b-spinner v-else variant="primary" type="grow">
                                     </b-spinner>
                            </div>
                        </div>
                        <div class="col-lg-2">
                            <div class="text-muted text-right">
                                
                                    <h4 v-if="orderDataLoading == false"><span class="currency">{{companyCurrency}}</span>{{orderprevioustotal.toFixed(2)}}</h4>
                                    <b-spinner v-else variant="primary" type="grow">
                                     </b-spinner>
                                     <p class="mt-2">Previous Period</p>
                            </div>
                        </div>
                        <div class="col-md-12 pt-3 border-top">
                            <div class="input-group input-group ">
                                  <div class="d-flex w-100" >
                                    
                                    <a class="btn btn-light btn-sm" @click="updateDates(1)">
                                      Last Month
                                    </a>
                                    <a class="btn btn-light btn-sm" @click="updateDates(3)">
                                      Last 3 Months
                                    </a>
                                    <a class="btn btn-light btn-sm" @click="updateDates(6)">
                                      Last 6 Months
                                    </a>
                                    <a class="btn btn-light btn-sm" @click="updateDates(12)">
                                      Last 12 Months
                                    </a>
                                    <b-form-select v-model="chartDisplay" @change="compileOrderStatCharts($event)" class="ml-auto">
                                      <b-form-select-option value="by-date">
                                        By Date
                                      </b-form-select-option>
                                      <b-form-select-option value="by-order">
                                        By Order
                                      </b-form-select-option>
                                    </b-form-select>
                                    <div class="input-group input-group-sm ml-3" style="width: 30%">
                                <b-form-input type="date" v-model="ordersstartdate">
                                </b-form-input>
                
                                <b-form-input type="date" v-model="ordersenddate">
                                </b-form-input>
                                <a class="btn btn-light btn-rounded" @click="getProductOrderStats(productData.id)">
                                    Update
                                </a>
                            </div>
                                  </div>
                                </div>
                            </div>
                        <div class="col-lg-12">
                            <apexchart v-if="orderDataLoading == false" class="apex-charts" :options="lineChart.chartOptions" :series="lineChart.series" dir="ltr" height="460"/>
                            <b-spinner v-else variant="primary" type="grow">
                            </b-spinner>
                        </div>
                    </div>
                </div>
            </div>
          </b-col>
            <div class="col-xl-4">
            <div class="card border-radius-1em">
                <div class="card-body">
                    <h4 class="card-title mb-4">Sales Analytics</h4>

                    <div :style="[orderDataLoading == false ? {'width' : '100%' } : {'width' : '98%' }]">
                        <apexchart class="apex-charts" :options="donutChart.chartOptions" :series="donutChart.series" dir="ltr" height="420" />
                    </div>

                    <div class="text-center text-muted">
                        <div class="row">
                            <div class="col" v-for="(label, index) in donutChart.chartOptions.labels">
                                <div class="mt-4">
                                    <p class="mb-2 text-truncate"><i class="mdi mdi-circle mr-1" :style="{'color': donutChart.chartOptions.colors[index] }"></i> {{label}}</p>
                                    <h5><span class="currency">{{companyCurrency}}</span> {{(donutChart.series[index]).toFixed(2)}}</h5>
                                </div>
                            </div>
                            <!--<div class="col">
                                <div class="mt-4">
                                    <p class="mb-2 text-truncate"><i class="mdi mdi-circle text-success mr-1"></i> Product B</p>
                                    <h5>$ 1,763</h5>
                                </div>
                            </div>
                            <div class="col">
                                <div class="mt-4">
                                    <p class="mb-2 text-truncate"><i class="mdi mdi-circle text-danger mr-1"></i> Product C</p>
                                    <h5>$ 973</h5>
                                </div>
                            </div>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </b-row>
              <b-row>
                
        <div class="col-xl-6">
            <div class="card border-radius-1em">
                <div class="card-body">
                    <div class="clearfix">
                        <div class="float-right">
                            <div class="input-group input-group-sm">
                                <select class="custom-select custom-select-sm">
                                    <option selected>Jan</option>
                                    <option value="1">Dec</option>
                                    <option value="2">Nov</option>
                                    <option value="3">Oct</option>
                                </select>
                                <div class="input-group-append">
                                    <label class="input-group-text">Month</label>
                                </div>
                            </div>
                        </div>
                        <h4 class="card-title mb-4">Top Salespeople</h4>
                    </div>

                     <div class="card text-muted text-center border border-radius-1em pb-4 shadow-none mb-2" v-if="topsellersdata.length > 0">
                        <div class="w-100 bg-light bg-soft" style="height: 100px">

                        </div>
                        <b-card-body class="card-body pt-0">
                            <div class="row">
                              <div class="col-sm-12 text-center">
                                <div class="avatar-md profile-user-wid mb-2 mx-auto" style="margin-top: -45px !important;">
                        <img v-if="topsellersdata[0].user.avatar !== null" :src="storageurl+topsellersdata[0].user.avatar" class="avatar-md rounded-circle mx-auto">
                        <img v-else src="/images/product-placeholder.png" class="avatar-md rounded-circle mx-auto">
                       
                                </div>
                            </div>
                            <div class="col-md-12 text-center">
                                <span class="badge badge-primary bg-light text-warning">
                                            #1 Top Seller <i class="bx bx-star"></i>
                                        </span>
                        <h5 class="mt-2 mb-0">{{topsellersdata[0].user.name}} {{topsellersdata[0].user.lastname}}</h5>
                        <p class="text-muted"> 
                            {{topsellersdata[0].user.email}}
                        </p>
                                 

                                  <b-row>
                            <b-col>
                                
                            <h4><span class="currency">{{companyCurrency}}</span>{{(topsellersdata[0].sales_sum_total_order_inc).toFixed(2)}}</h4>
                            <p class="text-muted mb-1">Order Value</p>
                            </b-col>
                            <b-col>
                                
                                <h4 class="mb-0 mt-1">{{topsellersdata[0].sales_count}}</h4>
                                <p class="text-muted mb-1">Sold</p>
                            </b-col>
                        </b-row>
                        <p class="mt-4 mb-0"><a class="btn-sm btn btn-rounded btn-primary" target="_blank" :href="'/company/user/'+topsellersdata[0].uid">
                                User Profile <i class="bx bx-right-arrow-alt"></i>
                            </a></p>
                            </div>
                        </div>
                    </b-card-body>
                    </div>
                    <b-card class="border-radius-1em border shadow-none mb-0" v-else style="min-height: 536px;" body-class="d-flex flex-column justify-content-center align-items-center">
                        <i class="bx bx-user-circle font-size-42 text-muted d-block"></i>
                        <h5 class="text-muted">No Top Sellers</h5>
                        <p>Start selling directly or via your Point of Sale now!</p>
                    </b-card>
                     <simplebar  v-if="topsellersdata.length > 0" style="height: 285px; max-height: 285px" class="border border-radius-1em mt-3">
                    <div class="table-responsive mb-0">
                        <table class="table table-centered mb-0">
                            <thead style="visibility: hidden;">
                                <td>
                                    
                                </td>
                                <td class="w-25">
                                    
                                </td>
                                <td>
                                    
                                </td>
                                <td>
                                    
                                </td>
                            </thead>
                            <tbody>
                                <tr v-for="product in topsellersdata">
                                    <td valign="middle">
                                        <a :href="'/company/user/'+product.uid" class="d-flex">

                                          <img v-if="product.user.avatar !== null" :src="storageurl+product.user.avatar" class="avatar-sm rounded-circle ml-2 mr-3">
                                        <img v-else src="/images/product-placeholder.png" class="avatar-sm rounded-circle ml-2 mr-3">
                                      <div class="my-auto">
                                        <h5 class="font-size-14 mb-0">{{product.user.name}} {{product.user.lastname}}</h5>
                                        <p class="text-muted mb-0 text-truncate">{{product.user.email}}</p>
                                      </div>
                                        </a>
                                    </td>

                                    <td colspan="2" valign="middle">
                                        <p class="text-muted mb-1">Sold</p>
                                        <b-progress :max="topsellersdata[0].sales_count" :value="product.sales_count" >
                                        </b-progress>
                                        <h5 class="mb-0 mt-1">{{product.sales_count}}</h5>
                                    </td>
                                    
                                    <td>
                                        <p class="text-muted mb-1">Sales Value</p>
                                        <h5 class="mb-0"><span class="currency">{{companyCurrency}}</span>{{(product.sales_sum_total_order_inc).toFixed(2)}}</h5>
                                    </td>
                                </tr>
                               
                            </tbody>
                        </table>
                    </div>
                </simplebar>
                </div>
            </div>
        </div>

        <div class="col-xl-6">
            <div class="card border-radius-1em">
                <div class="card-body">
                    <div class="clearfix">
                        <div class="float-right">
                            <div class="input-group input-group-sm">
                                <select class="custom-select custom-select-sm">
                                    <option selected>Jan</option>
                                    <option value="1">Dec</option>
                                    <option value="2">Nov</option>
                                    <option value="3">Oct</option>
                                </select>
                                <div class="input-group-append">
                                    <label class="input-group-text">Month</label>
                                </div>
                            </div>
                        </div>
                        <h4 class="card-title mb-4">Top Selling Shops</h4>
                    </div>

                     <b-card class="text-muted text-center border border-radius-1em pb-4 pt-0 px-0 shadow-none"  v-if="toplistingsdata.length > 0" no-body>
                        <div class="">
                          <gmap-map v-if="toplistingsdata[0].gplace_id !== 0" :center="{ lat: parseFloat(toplistingsdata[0].latitude), lng: parseFloat(toplistingsdata[0].longitude) }" :zoom="6" style="height: 100px" class="border-radius-1em">
                          <gmap-marker
                            :position="{ lat: parseFloat(toplistingsdata[0].latitude), lng: parseFloat(toplistingsdata[0].longitude) }"
                            :clickable="true"
                            :draggable="true"
                            @click="center = { lat: parseFloat(toplistingsdata[0].latitude), lng: parseFloat(toplistingsdata[0].longitude) }"
                          ></gmap-marker>
                        </gmap-map>
                        </div>
                        <!--<div class="avatar-md mx-auto profile-user-wid rounded-circle">
                            <img v-if="toplistingsdata[0].logo !== null" :src="storageurl+toplistingsdata[0].logo" class="avatar-md rounded-circle mx-auto">
                            <span v-else class="avatar-md rounded-circle mx-auto"> 
                                <span class="avatar-title">
                                    {{toplistingsdata[0].name.slice(0,1)}}
                                </span>
                            </span>
                        </div>-->
                        <b-card-body class="card-body pt-0">
                            <div class="row">
                              <div class="col-sm-12 pb-1 text-center">
                                <div class="avatar-md profile-user-wid mb-2 mx-auto" style="margin-top: -45px !important;">
                                  
                                  <a href="javascript:;" class="avatar-title avatar-md bg-light text-warning rounded-circle" v-b-tooltip.hover>
                                    <i class="bx bx-star font-size-20"></i>
                                  </a>
                                </div>
                                <h5 class="mb-0 text-truncate">{{toplistingsdata[0].name}}</h5>
                                <p class="text-muted mb-0">
                                  <span v-if="toplistingsdata[0].address_1 !== null">{{toplistingsdata[0].address_1}},</span>
                                  <span v-if="toplistingsdata[0].city !== null">{{toplistingsdata[0].city}},</span>
                                  <span v-if="toplistingsdata[0].county !== null">{{toplistingsdata[0].county.name}},</span>
                                  <span v-if="toplistingsdata[0].postcode !== null">{{toplistingsdata[0].postcode}}</span>
                                </p>
                                <span class="badge badge-light border-radius-1em bg-light text-dark font-size-11 lh-16 my-2 mx-auto" v-if="toplistingsdata[0].listing_type !== null">
                                  {{toplistingsdata[0].listing_type.name}}
                                </span>
                              </div>
                              <div class="col-md-12">
                                <b-row>
                                    <b-col>
                                        
                                    <h4><span class="currency">{{companyCurrency}}</span>{{(toplistingsdata[0].orders_sum_total_order_inc).toFixed(2)}}</h4>
                                    <p class="text-muted mb-1">Order Value</p>
                                    </b-col>
                                    <b-col>
                                        
                                        <h4 class="mb-0 mt-1">{{toplistingsdata[0].orders_count}}</h4>
                                        <p class="text-muted mb-1">Sold</p>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                 <p class="mt-4 mb-0"><a class="btn-sm btn btn-rounded btn-primary" target="_blank" :href="'/company/shop/'+toplistingsdata[0].uid">
                                Shop Profile <i class="bx bx-right-arrow-alt"></i>
                            </a></p>
                        </b-col>
                    </b-row>
                              </div>
                          </div>
                      </b-card-body>
                    </b-card>
                    <b-card class="border-radius-1em border shadow-none mb-0" v-else style="min-height: 536px;" body-class="d-flex flex-column justify-content-center align-items-center">
                        <i class="bx bx-store font-size-42 text-muted d-block"></i>
                        <h5 class="text-muted">No Top Shops</h5>
                        <p>Add a shop or stock room and start listing products.</p>
                    </b-card>
                     <simplebar  v-if="toplistingsdata.length > 0" style="height: 285px; max-height: 285px"  class="border border-radius-1em mt-3">
                    <div class="table-responsive mb-0">
                        <table class="table table-centered mb-0">
                            <thead style="visibility: hidden;">
                                <td>
                                    
                                </td>
                                <td class="w-25">
                                    
                                </td>
                                <td>
                                    
                                </td>
                                <td>
                                    
                                </td>
                            </thead>
                            <tbody>
                                <tr v-for="product in toplistingsdata">
                                    <td valign="middle" colspan="1">
                                        <h5 class="font-size-14 mb-0">{{product.name}}</h5>
                                        <p class="text-muted mb-0 paragraph-truncate-line2">
                                          <span v-if="product.address_1 !== null">{{product.address_1}},</span>
                                          <span v-if="product.city !== null">{{product.city}},</span>
                                          <span v-if="product.county !== null">{{product.county.name}},</span>
                                          <span v-if="product.postcode !== null">{{product.postcode}}</span>
                                        </p>
                                    </td>

                                    <td colspan="2" valign="middle">
                                        <p class="text-muted mb-1">Sold</p>
                                        <b-progress :max="toplistingsdata[0].orders_count" :value="product.orders_count" >
                                        </b-progress>
                                        <h5 class="mb-0 mt-1">{{product.orders_count}}</h5>
                                    </td>
                                    
                                    <td>
                                        <p class="text-muted mb-1">Sales Value</p>
                                        <h5 class="mb-0"><span class="currency">{{companyCurrency}}</span>{{(product.orders_sum_total_order_inc).toFixed(2)}}</h5>
                                    </td>
                                </tr>
                               
                            </tbody>
                        </table>
                    </div>
                </simplebar>
                </div>
            </div>
        </div>
              </b-row>
            </b-col>
          </b-row>
        
      </form>
      </b-overlay>
      </div>
      <div class="col-md-10" >
        <b-overlay :show="loadingForm">
          <div class="row px-3" v-if="($attrs.user.companies[0].permissions.view.includes($attrs.active_menu)) && ((editable == false) && (showDetails == false))">
            <b-card class="border-radius-1em border shadow-none">
              <ProductContent :company="$attrs.company" :user="$attrs.user" :product="productData"></ProductContent>
            </b-card>
          </div>
          <div class="row px-3" v-if="($attrs.user.companies[0].userrole.name == ('Owner' || 'Manager')) && ((editable == false) && (showDetails == false))">
            <b-card class="border-radius-1em border shadow-none">
              <ProductContent :company="$attrs.company" :user="$attrs.user" :product="productData"></ProductContent>
            </b-card>
          </div>
        </b-overlay>
      </div>
    
    </div>
    <!-- end row -->
    
    <!--<b-row v-if="(editable == false) && (showDetails == true)">
      <ProductDetails :user="$attrs.user" :company="$attrs.company" :product="productData" :shopsData="shopsData" @close-details="showDetails = false, editable = true" :all_tax_rules="all_tax_rules" :active_tax_rule="active_tax_rule" ></ProductDetails>
    </b-row>-->
      <SideSlidePanel :panelClass="'edit-sidepanel-fullwidth bg-white'" :editPanel="editImage"  @close-panel="editImage = false" >
                  <ImageCropper :imageUrl="editImageUrl" @close-editor="editImage = false, view = 'basic-info', updateHistory(view)" :checkCrossOrigin="false"></ImageCropper>
      </SideSlidePanel>
     <b-modal id="publish-integration-updates" ref="publish-integration-updates" size="lg" hide-footer hide-header centered>
      <b-card class="border-radius-1em border shadow-none">
      <b-row>
        <b-col md="12" class="d-flex">
              <a class="btn btn-outline-light btn-rounded rounded-circle font-size-16 ml-auto mb-2" @click="$bvModal.hide('publish-integration-updates')">
                  <i class="mdi mdi-close"></i>
              </a>
          </b-col>
        <b-col md="12">
          <h4>Publish Updates</h4>
          <p>
            Sync product data and information from or to your external channel. To export data, use the push function and to import updated from your external channel use the pull function:
          </p>
      <b-tabs pills content-class="pt-3">

        <b-tab title="Push Data">
          <b-card class="border-radius-1em border shadow-none">
                <b-form-group>
                  <b-form-checkbox-group
                      id="checkbox-group-2"
                      v-model="channel_product_update_keys"
                      :aria-describedby="ariaDescribedby"
                      stacked
                    >
                        <b-form-checkbox
                            v-for="key in Object.keys(productData)"  
                            :id="'checkbox'+key"
                            v-if="!gemesys_excluded_export_keys.includes(key)"
                            :name="'checkbox'+key"
                            :value="key"
                            :class="{'d-flex' : key == 'total_qty'}"
                          >
                          <span class="ml-2 text-capitalize">{{key.replaceAll('_', ' ')}}</span>
                          <div class="d-flex" v-if="(channel_product_update_keys.includes('total_qty') == true) && (key == 'total_qty')">

                            <b-button variant="light" class="btn btn-light btn-rounded my-auto" @click="push_quantity--" :disabled="push_quantity < 1">
                              <i class="bx bx-minus"></i>
                            </b-button>

                            <b-form-input type="number" v-model="push_quantity" class="my-auto mx-2">
                            </b-form-input>

                            <b-button variant="light" class="btn btn-light btn-rounded my-auto" @click="push_quantity++" >
                              <i class="bx bx-plus"></i>
                            </b-button>
                          </div>
                          </b-form-checkbox>

                          
                  </b-form-checkbox-group>
                </b-form-group>
                <a class="btn btn-primary btn-rounded mt-3" @click="updateChannelData('push')">
                  Push Data      
                </a>
          </b-card>
        </b-tab>
        <b-tab title="Pull Data">
          <b-card class="border-radius-1em border shadow-none">
                <b-form-group>
                  <b-form-checkbox-group
                      id="checkbox-group-2"
                      v-model="channel_product_update_keys"
                      :aria-describedby="ariaDescribedby"
                      stacked
                    >
                        <b-form-checkbox
                            v-for="key in Object.keys(channel_product)"  
                            :id="'checkbox'+key"
                            v-if="!channels_excluded_export_keys.includes(key)"
                            :name="'checkbox'+key"
                            :value="key"
                          >
                          <span class="ml-2 text-capitalize">{{key.replaceAll('_', ' ')}}</span>
                          </b-form-checkbox>
                  </b-form-checkbox-group>
                </b-form-group>
                <a class="btn btn-primary btn-rounded mt-3" @click="updateChannelData('pull')">
                  Pull Data      
                </a>
          </b-card>
        </b-tab>
      </b-tabs>

    </b-col>
  </b-row>
</b-card>
        </b-modal>
     <b-modal id="manual-link" ref="manual-link" size="lg" hide-footer hide-header centered>
          <b-card class="border-radius-1em border shadow-none">
            <h5>Link This Product:</h5>
            <b-card class="border-radius-1em border shadow-none">
            <div class="d-flex" v-if="productData !== ''">
              <div class="my-auto" v-if="productData.cover_image !== null">
            <img v-if="'thumb' in productData.cover_image" :src="storageurl+(productData.cover_image.thumb).replaceAll('//', '/')" class="avatar-sm rounded-circle mr-2" />
            <img v-else-if="'gallery' in productData.cover_image" :src="storageurl+(productData.cover_image.gallery).replaceAll('//', '/')" class="avatar-sm rounded-circle mr-2" />
            <img v-else-if="'src' in productData.cover_image" :src="storageurl+(productData.cover_image.src).replaceAll('//', '/')" class="avatar-sm rounded-circle mr-2" />
            <span v-else class="avatar-title avatar-md">
              {{productData.name.slice(0, 1)}}
            </span>
          </div>
          <div class="d-flex flex-column my-auto">
                  <span>SKU: {{productData.reference}}</span>
                  <h5 class="mb-0">{{productData.name}}</h5>
                </div>
        </div>
        </b-card>
            <h5>To {{selected_integration.name}} Product</h5>

            <b-card class="border-radius-1em border shadow-none" body-class="p-0" v-if="selected_channel_products !== ''">
                 <b-overlay :show="loadingProducts" variant="white" opacity="0.95">
          <template #overlay>
              <b-row>
                  <b-col md="12" class="offset-md-2" >
                  <b-card class="border-radius-1em border shadow-none mb-0">  
                      <div class="cube-wrapper mx-auto"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
                      <h6 class="text-center">Loading Your Integration</h6>
                       <b-progress class="w-50 mx-auto" variant="primary"></b-progress>
                  </b-card>
                  </b-col>
                  </b-row>
                </template>
            <b-list-group flush v-if="'products' in selected_channel_products && selected_channel_products.products.length > 0" class="widget-scrollbox">
              <b-list-group-item v-for="(product, key) in selected_channel_products.products" class="d-flex">
                <img v-if="product.imageUrl !== null" :src="product.imageUrl" class="avatar-sm rounded-circle mr-2 my-auto" />
                
                <div class="d-flex flex-column my-auto">
                  <div class="d-flex">
                  <div class="d-flex flex-column my-auto">
                  <span>SKU: {{product.sku}}</span>
                  <h5 class="mb-0">{{product.name}}</h5>
                  
                  </div>  
                  
                  </div>
                  
                </div>

                
                <div class="d-flex flex-column ml-auto">
                  <a class="btn btn-primary my-1 ml-3 btn-rounded" v-if="(checkGemesysLink(product) == true) && (checkExistingProduct(product) == false)" @click="channelResourceLink(productData, product, false, 'product')">
                    Link
                  </a>
                  <a class="btn btn-light my-1 ml-3 btn-rounded" v-else>
                  <i class="bx bx-check-circle text-success"></i>
                  Linked
                  </a>
                </div>
              </b-list-group-item>
            </b-list-group>
          </b-overlay>
          
      </b-card>
      <b-card v-else class="border-radius-1em border shadow-none">
        <p>No Products Loaded.</p>
        <a class="">Reload Integration Products</a>
      </b-card>
          </b-card>
        </b-modal>
        <!--<b-modal id="linked-product" size="md" hide-footer hide-header centered>
          <b-card class="border-radius-1em border shadow-none">
            <h4>Linked To:</h4>
            <div class="d-flex flex-column" v-if="linked_product_view.length > 0">
           
          <b-card class="border-radius-1em border shadow-sm text-left mb-0 mt-3">
          <div class="mx-auto text-center">
            <img  v-if="linked_product_view[0].imageUrl !== null" :src="linked_product_view[0].imageUrl" class="avatar-md rounded-circle mt-3 mb-2" />
            <span v-else class="avatar-title avatar-md">
              {{linked_product_view[0].name.slice(0, 1)}}
            </span>
          </div>
          <div class="d-flex flex-column my-auto text-center">
                  <span>SKU: {{linked_product_view[0].sku}}</span>
                  <h5 class="mb-2">{{linked_product_view[0].name}}</h5>
                  <p class="mb-2" v-html="linked_product_view[0].description"></p>
                </div>
                <div class="d-flex flex-column my-auto">
                  <h6 class="spaced-title text-uppercase">Quantity</h6>
                  <p class="mb-2" v-html="linked_product_view[0].quantity"></p>
                  <h6 class="spaced-title text-uppercase">Price</h6>
                  <p class="mb-0">{{parseFloat(linked_product_view[0].price).toFixed(2)}}</p>

                  
                </div>
              </b-card>

        <b-card class="border-radius-1em border shadow-sm text-left mb-0 mt-3" body-class="d-flex">
                    <div>
                    <h5 class="text-capitalize">{{selected_integration.integration_name}} ID:</h5>
                    <p class="mb-0">{{linked_product_view[0].id}}</p>
                    </div>
                    <a class="btn btn-primary ml-auto my-auto btn-rounded" :href="selected_integration.url" target="_blank">Go to {{selected_integration.integration_name}} <i class="bx bx-link-external"></i></a>
                  </b-card>
        </div>
          </b-card>
        </b-modal>
      -->
      <b-modal id="unlink-channel" ref="unlink-channel" size="lg" hide-footer hide-header centered>
        <b-row>
         <b-col md="12" class="d-flex">
              <a class="btn btn-outline-light btn-rounded rounded-circle font-size-16 ml-auto mb-2" @click="$bvModal.hide('unlink-channel')">
                  <i class="mdi mdi-close"></i>
              </a>
          </b-col>
          <b-col md="12" >
             <b-card class="border-radius-1em border shadow-none text-center mb-0">
            <h4>Unlink Product</h4>
            <p>You are about to unlink this product from this channel. This will remove the link between this product and the same product on your external website or marketplace. The product will remain on both sites, but will no longer be linked. Please confirm you wish to perform this action.</p>

            <div class="text-center w-100">
            <a class="btn btn-success" @click="channelResourceUnlink(productData, selected_product, false, 'product')">
              Unlink Product
            </a>
            <a class="btn btn-danger" @click="$bvModal.hide('unlink-channel')">
              Cancel
            </a>
            </div>
          </b-card>
          </b-col>
        </b-row>
      </b-modal>
      <b-modal id="delete-channel" size="lg" hide-footer hide-header centered>
        <b-row>
         <b-col md="12" class="d-flex">
              <a class="btn btn-outline-light btn-rounded rounded-circle font-size-16 ml-auto mb-2" @click="$bvModal.hide('delete-channel')">
                  <i class="mdi mdi-close"></i>
              </a>
          </b-col>
           <b-card class="border-radius-1em border shadow-none text-center mb-0">
          <b-col md="12" >
            <h4>Delete Product from Channel</h4>
            <p>You are about to delete this product from this channel. This will delete the product from both the external site and any link to this product. Please confirm you wish to perform this action.</p>
            <div class="text-center w-100">
            <a class="btn btn-success" @click="deleteChannelProduct(productData, selected_product, false, 'product')">
              Delete Product
            </a>
            <a class="btn btn-danger" @click="$bvModal.hide('delete-channel')">
              Cancel
            </a>
            </div>
          </b-col>
        </b-card>
        </b-row>
      </b-modal>

      <b-modal id="channel-details" size="lg" hide-footer hide-header centered>
       <b-row>
          <b-col md="12" class="d-flex">
              <a class="btn btn-outline-light btn-rounded rounded-circle font-size-16 ml-auto mb-2" @click="$bvModal.hide('channel-details')">
                  <i class="mdi mdi-close"></i>
              </a>
          </b-col>
          <b-col md="12">
          <b-card class="border-radius-1em border shadow-none mb-0">
            <b-overlay :show="loadingIntegrationDetails" variant="white" opacity="0.95">
          <template #overlay>
              <b-row>
                  <b-col md="12" class="offset-md-2" >
                  <b-card class="border-radius-1em border shadow-none mb-0">  
                      <div class="cube-wrapper mx-auto"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
                      <h6 class="text-center">Loading Your Integration</h6>
                       <b-progress class="w-50 mx-auto" variant="primary"></b-progress>
                  </b-card>
                  </b-col>
                  </b-row>
                </template>
              <b-row>
                <b-col md="4">  
                  <div v-if="'images' in channel_product_details && channel_product_details.images.length > 0">
                    <img :src="channel_product_details.images[0].url" class="w-100 border-radius-1em">
                  </div>
                  <div v-else class="bg-light h-100 d-flex flex-column justify-content-center align-items-center border-radius-1em">
                      <a v-if="selected_integration.integration_name == 'Woocommerce'" :href="selected_integration.url+'wp-admin/post.php?post='+channel_product_details.id+'&action=edit'" class="p-4 d-flex" target="_blank">
                        <i class="bx bx-link-external text-primary font-size-20 pr-2">
                        </i>
                        Add Images
                      </a>

                  </div>
                </b-col>
                <b-col md="8" class="d-flex flex-column">
                  <div class="d-flex flex-column justify-content-center my-auto h-100">
                  <h4>{{channel_product_details.name}}</h4>
                  <p v-html="channel_product_details.description"></p>
                  <a class="btn btn-primary btn-rounded" v-if="channel_product_details.link !== null" :href="channel_product_details.link" target="_blank">
                    <i class="bx bx-link-external"></i>
                    See On Website
                  </a>
                  </div>
                  <b-row>
                <b-col md="8" class="mt-3 d-flex flex-column">

                  <div class="w-100">
                    <label>Quantity listed on {{selected_integration.integration_name}}</label>
                  </div>
                  <div class="w-100 d-flex">
                  <b-button @click="channel_product_details.quantity--" variant="light" class="btn-rounded rounded-circle">
                    <i class="mdi mdi-minus"></i>
                  </b-button>
                  <b-form-group class="flex-grow-1 mx-1">
                    <b-form-input type="number" v-model="channel_product_details.quantity">
                    </b-form-input>
                    </b-form-group>
                    <b-button @click="channel_product_details.quantity++" variant="light" class="btn-rounded rounded-circle">
                    <i class="mdi mdi-plus"></i>
                  </b-button>
                  </div>
                </b-col>
                <b-col md="4" class="mt-3 d-flex">
                  <a class="btn btn-primary btn-rounded mt-auto w-100" :href="channel_product_details.link" target="_blank">
                    Update Quantity
                  </a>
                </b-col>
              </b-row>
                </b-col>

              </b-row>
            </b-overlay>
          </b-card>
          </b-col>
        </b-row>
      </b-modal>
       <b-modal id="print-label" size="xl" hide-footer centered>
            <ProductLabel :code_type="'barcode'"  :qr_value="qrData"></ProductLabel>
          </b-modal>
           <b-modal id="delete-product" size="xl" hide-footer centered>
              <b-card class="border-radius-1em border shadow-none" body-class="d-flex flex-column justify-content-center align-items-center"> 
                <h4>Are You Sure You Want to Delete this Product?</h4>
                <div class="d-flex">
                <a class="btn btn-rounded btn-success mr-1" @click="destroyProduct()">
                  Yes, delete it!
                </a>
                <a class="btn btn-rounded btn-danger ml-1" @click="$bvModal.hide('delete-product')">
                  No, Keep it!
                </a>
                </div>
              </b-card>
          </b-modal>
          <b-modal id="export-product" size="md" hide-footer hide-header centered>

                  <b-card class="border-radius-1em border shadow-none">
                    <h4 class="text-capitalize text-center">Add to {{selected_integration.integration_name}}</h4>
                    <div class="d-flex flex-column pt-3">
                      <div
                                class="form-check form-switch form-switch-sm my-1 mx-auto"
                                dir="ltr"
                              >
                              <input
                                  class="form-check-input"
                                  type="checkbox"
                                  :id="`SwitchCheckSize-synconline`"
                                  v-model="syncOnlineQty"
                                  value="1"
                                  unchecked-value="0"
                                  name="iscompany"
                                />
                                <label class="form-check-label" :for="`SwitchCheckSize-synconline`" 
                                  > Sync All Online Stock</label
                                >
                            </div>
                            <div
                                class="form-check form-switch form-switch-sm my-1 mx-auto"
                                dir="ltr"
                              >
                              <input
                                  class="form-check-input"
                                  type="checkbox"
                                  :id="`SwitchCheckSize-syncall`"
                                  v-model="syncAllQty"
                                  value="1"
                                  unchecked-value="0"
                                  name="iscompany"
                                />
                                <label class="form-check-label" :for="`SwitchCheckSize-syncall`" 
                                  > Sync All Stock</label
                                >
                            </div>
                            <div
                                class="form-check form-switch form-switch-sm my-1 mx-auto"
                                dir="ltr"
                              >
                              <input
                                  class="form-check-input"
                                  type="checkbox"
                                  :id="`SwitchCheckSize-addmanual`"
                                  v-model="addManualQty"
                                  value="1"
                                  unchecked-value="0"
                                  name="iscompany"
                                />
                                <label class="form-check-label" :for="`SwitchCheckSize-addmanual`" 
                                  > Add Quantity Manually</label
                                >
                            </div>
                    </div>
                    <div class="d-flex pt-3" v-if="addManualQty == true">
                  <b-button variant="light" class="btn btn-light btn-rounded my-auto" @click="push_quantity--" :disabled="push_quantity < 1">
                    <i class="bx bx-minus"></i>
                  </b-button>

                  <b-form-input type="number" v-model="push_quantity" class="my-auto mx-2">
                  </b-form-input>

                  <b-button variant="light" class="btn btn-light btn-rounded my-auto" @click="push_quantity++" >
                    <i class="bx bx-plus"></i>
                  </b-button>
                </div>
                <!--<div class="d-flex flex-column pt-3">

                  <b-form-group label="Select Category">
                    <b-form-select class="form-control"  v-model="push_category">
                      <b-form-select-option v-for="cat in selected_channel_categories.categories" :value="cat">
                        {{cat.name}}
                      </b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                </div>-->
                <a class="btn btn-primary btn-rounded btn-block mt-3" @click="getActiveChannelExportPass('product', productData, false, selected_integration )">
                  Export to {{selected_integration.channel_type}}
                </a>
              </b-card>
      </b-modal>
  </Layout>
</template>
