<template>
	<swiper id="slider-widget" :swiperOptions="swiperOptions" class="overflow-hidden slider-widget" :ref="'sliderWidgetCarousel-'+$attrs.id" v-if="$attrs.content[filterStylesKey($attrs.content, 'layout')].layout.value == 'Layout 1'" :modules="modules" :pagination="{ clickable: true }" :styles="'height: 100px;'"> 
		<swiper-slide v-for="slide in $attrs.content[filterStylesKey($attrs.content, 'list')].list" :caption="slide[filterStylesKey(slide, 'title')].value" :style="'width: '+checkExists($attrs.styles[filterStylesKey($attrs.styles, 'width')].width.value, $attrs.styles[filterStylesKey($attrs.styles, 'width')].width.units)+'; height:'+checkExists($attrs.styles[filterStylesKey($attrs.styles, 'height')].height.value, $attrs.styles[filterStylesKey($attrs.styles, 'height')].height.units)" >
			<b-card overlay class="shadow-none" :img-src="slide[filterStylesKey(slide, 'image')].image.value" body-class="d-flex p-0" :align="slide[filterStylesKey(slide, 'image_alignment')].image_alignment.value" :img-height="checkExists($attrs.styles[filterStylesKey($attrs.styles, 'height')].height.value, $attrs.styles[filterStylesKey($attrs.styles, 'height')].height.units)" :style="'height: ' + checkExists($attrs.styles[filterStylesKey($attrs.styles, 'height')].height.value, $attrs.styles[filterStylesKey($attrs.styles, 'height')].height.units)">
				<div class="slider-caption d-flex flex-column" v-if="(slide[filterStylesKey(slide, 'title')].title.value !== '') || (slide[filterStylesKey(slide, 'description')].description.value !== '')" :class="[$attrs.content[filterStylesKey($attrs.content, 'caption_text_alignment')]['caption_text_alignment'].value == 'center' ? 'align-items-center mx-auto' : '', $attrs.content[filterStylesKey($attrs.content, 'caption_text_alignment')]['caption_text_alignment'].value == 'right' ? 'align-items-end' : '', $attrs.content[filterStylesKey($attrs.content, 'caption_text_alignment')]['caption_text_alignment'].value == 'left' ? 'align-items-start' : '', $attrs.content[filterStylesKey($attrs.content, 'caption_vertical_alignment')]['caption_vertical_alignment'].value == 'center' ? 'my-auto' : '', $attrs.content[filterStylesKey($attrs.content, 'caption_vertical_alignment')]['caption_vertical_alignment'].value == 'bottom' ? 'mt-auto' : '', $attrs.content[filterStylesKey($attrs.content, 'caption_vertical_alignment')]['caption_vertical_alignment'].value == 'top' ? 'mb-auto' : '']" :style="compileElementStyles('caption', ['background', 'padding', 'text_alignment', 'width'])">
					<component :is="headerComponent(slide[filterStylesKey(slide, 'title')].title.value)" :style="compileElementStyles('title', ['color', 'text'])"  />
					<!--<h5>{{slide[filterStylesKey(slide, 'title')].title.value}}</h5>-->
					<p :style="compileElementStyles('description', ['text_alignment'])">{{slide[filterStylesKey(slide, 'description')].description.value}}</p>
					<component :is="linkType" v-if="slide[filterStylesKey(slide, 'show_button')].show_button.value == true" class="btn btn-white" :href="slide[filterStylesKey(slide, 'button_url')].button_url.value" :style="compileElementStyles('button', ['background', 'border', 'color'])" :class="[$attrs.content[filterStylesKey($attrs.content, 'button_alignment')]['button_alignment'].value == 'center' ? 'mx-auto' : '', $attrs.content[filterStylesKey($attrs.content, 'button_alignment')]['button_alignment'].value == 'right' ? 'mr-auto' : '', $attrs.content[filterStylesKey($attrs.content, 'button_alignment')]['button_alignment'].value == 'left' ? 'ml-auto' : '']" >
						{{slide[filterStylesKey(slide, 'button_text')].button_text.value}}
					</component>
				</div>
			</b-card>
		</swiper-slide>
	</swiper>
	<b-carousel v-else-if="$attrs.content[filterStylesKey($attrs.content, 'layout')].layout.value == 'Layout 2'"
		id="carousel-fade"
		style="text-shadow: 0px 0px 2px #000"
		controls
		fade
		indicators
		class="slider-widget"
		:imgWidth="checkExists($attrs.styles[filterStylesKey($attrs.styles, 'width')].width.value, $attrs.styles[filterStylesKey($attrs.styles, 'width')].width.units)"
		:imgHeight="checkExists($attrs.styles[filterStylesKey($attrs.styles, 'height')].height.value, $attrs.styles[filterStylesKey($attrs.styles, 'height')].height.units)"
	>
		<b-carousel-slide v-for="slide in $attrs.content[filterStylesKey($attrs.content, 'list')].list" :img-src="slide[filterStylesKey(slide, 'image')].image.value">
			
			<div class="slider-caption d-flex flex-column" v-if="(slide[filterStylesKey(slide, 'title')].title.value !== '') || (slide[filterStylesKey(slide, 'description')].description.value !== '')" :class="[$attrs.content[filterStylesKey($attrs.content, 'caption_text_alignment')]['caption_text_alignment'].value == 'center' ? 'align-items-center mx-auto' : '', $attrs.content[filterStylesKey($attrs.content, 'caption_text_alignment')]['caption_text_alignment'].value == 'right' ? 'align-items-end' : '', $attrs.content[filterStylesKey($attrs.content, 'caption_text_alignment')]['caption_text_alignment'].value == 'left' ? 'align-items-start' : '', $attrs.content[filterStylesKey($attrs.content, 'caption_vertical_alignment')]['caption_vertical_alignment'].value == 'center' ? 'my-auto' : '', $attrs.content[filterStylesKey($attrs.content, 'caption_vertical_alignment')]['caption_vertical_alignment'].value == 'bottom' ? 'mt-auto' : '', $attrs.content[filterStylesKey($attrs.content, 'caption_vertical_alignment')]['caption_vertical_alignment'].value == 'top' ? 'mb-auto' : '']" :style="compileElementStyles('caption', ['background', 'padding', 'text_alignment', 'width'])">
					<component :is="headerComponent(slide[filterStylesKey(slide, 'title')].title.value)" :style="compileElementStyles('title', ['color', 'text'])"  />
					
					<!--<h5>{{slide[filterStylesKey(slide, 'title')].title.value}}</h5>-->
					<p :style="compileElementStyles('description', ['text_alignment'])">{{slide[filterStylesKey(slide, 'description')].description.value}}</p>
					<component :is="linkType" v-if="slide[filterStylesKey(slide, 'show_button')].show_button.value == true" class="btn btn-white mx-auto" :href="slide[filterStylesKey(slide, 'button_url')].button_url.value" :style="compileElementStyles('button', ['background', 'border', 'color'])" >
						{{slide[filterStylesKey(slide, 'button_text')].button_text.value}}
					</component>
				</div>
		</b-carousel-slide>
	</b-carousel>
	<swiper :swiperOptions="swiperOptions" class="overflow-hidden slider-widget" :ref="'sliderWidgetCarousel-'+$attrs.id" v-else-if="$attrs.content[filterStylesKey($attrs.content, 'layout')].layout.value == 'Layout 3'" :modules="modules" :pagination="{ clickable: true }" > 
		<swiper-slide v-for="slide in $attrs.content[filterStylesKey($attrs.content, 'list')].list" :caption="slide[filterStylesKey(slide, 'title')].value">
			<b-row :style="'width: '+checkExists($attrs.styles[filterStylesKey($attrs.styles, 'width')].width.value, $attrs.styles[filterStylesKey($attrs.styles, 'width')].width.units)+ '; height: '+checkExists($attrs.styles[filterStylesKey($attrs.styles, 'height')].height.value, $attrs.styles[filterStylesKey($attrs.styles, 'height')].height.units)">
				<b-col md="6" align-self="center" :class="[$attrs.content[filterStylesKey($attrs.content, 'caption_text_alignment')]['caption_text_alignment'].value == 'center' ? 'align-items-center mx-auto' : '', $attrs.content[filterStylesKey($attrs.content, 'caption_text_alignment')]['caption_text_alignment'].value == 'right' ? 'align-items-end' : '', $attrs.content[filterStylesKey($attrs.content, 'caption_text_alignment')]['caption_text_alignment'].value == 'left' ? 'align-items-start' : '', $attrs.content[filterStylesKey($attrs.content, 'caption_vertical_alignment')]['caption_vertical_alignment'].value == 'center' ? 'my-auto' : '', $attrs.content[filterStylesKey($attrs.content, 'caption_vertical_alignment')]['caption_vertical_alignment'].value == 'bottom' ? 'mt-auto' : '', $attrs.content[filterStylesKey($attrs.content, 'caption_vertical_alignment')]['caption_vertical_alignment'].value == 'top' ? 'mb-auto' : '']" :style="'width: '+checkExists($attrs.styles[filterStylesKey($attrs.styles, 'width')].width.value, $attrs.styles[filterStylesKey($attrs.styles, 'width')].width.units)+ '; height: '+checkExists($attrs.styles[filterStylesKey($attrs.styles, 'height')].height.value, $attrs.styles[filterStylesKey($attrs.styles, 'height')].height.units)">
						<div class="slider-caption d-flex flex-column" v-if="(slide[filterStylesKey(slide, 'title')].title.value !== '') || (slide[filterStylesKey(slide, 'description')].description.value !== '')"  :class="[$attrs.content[filterStylesKey($attrs.content, 'caption_text_alignment')]['caption_text_alignment'].value == 'center' ? 'align-items-center mx-auto' : '', $attrs.content[filterStylesKey($attrs.content, 'caption_text_alignment')]['caption_text_alignment'].value == 'right' ? 'align-items-end' : '', $attrs.content[filterStylesKey($attrs.content, 'caption_text_alignment')]['caption_text_alignment'].value == 'left' ? 'align-items-start' : '']" :style="compileElementStyles('caption', ['background', 'padding', 'text_alignment'])">
							<component :is="headerComponent(slide[filterStylesKey(slide, 'title')].title.value)" :style="compileElementStyles('title', ['color', 'text'])"  />
							<!--<h5>{{slide[filterStylesKey(slide, 'title')].title.value}}</h5>-->
							<p :style="compileElementStyles('description', ['text_alignment'])">{{slide[filterStylesKey(slide, 'description')].description.value}}</p>
							<component :is="linkType" v-if="slide[filterStylesKey(slide, 'show_button')].show_button.value == true" class="btn btn-white" :href="slide[filterStylesKey(slide, 'button_url')].button_url.value" :style="compileElementStyles('button', ['background', 'border', 'color'])">
								{{slide[filterStylesKey(slide, 'button_text')].button_text.value}}
							</component>
						</div>
				</b-col>
				<b-col md="6" :style="'width: '+checkExists($attrs.styles[filterStylesKey($attrs.styles, 'width')].width.value, $attrs.styles[filterStylesKey($attrs.styles, 'width')].width.units)+ '; height: '+checkExists($attrs.styles[filterStylesKey($attrs.styles, 'height')].height.value, $attrs.styles[filterStylesKey($attrs.styles, 'height')].height.units)" align-self="center">
					<b-card overlay class="shadow-none" :img-src="slide[filterStylesKey(slide, 'image')].image.value" body-class="d-flex" :align="slide[filterStylesKey(slide, 'image_alignment')].image_alignment.value" :img-height="checkExists($attrs.styles[filterStylesKey($attrs.styles, 'height')].height.value, $attrs.styles[filterStylesKey($attrs.styles, 'height')].height.units)" >
					
					</b-card>
				</b-col>
			</b-row>
		</swiper-slide>
	</swiper>
</template>

<script type="text/javascript">
	import SwiperClass, { Pagination } from 'swiper'
	import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
	//import 'swiper/css'
  	import 'swiper/swiper.min.css'
	export default {
		components: {
		    Swiper,
		    SwiperSlide,
		},
		setup() {
	      return {
	        modules: [Pagination]
	      }
	    },
		directives: {
		    swiper: directive
		},
		data() {
	      return {
	      	linkType: 'a',
	      	storageurl: 'https://'+this.$attrs.company.nickname+'.gemesys.co.uk/image/',
	      	swiperOptions: {
		          slidesPerView: 1,
		          spaceBetween: 0,
		          loop: true,
		          loopFillGroupWithBlank: false,
		          pagination: {
		            el: '.swiper-pagination',
		            clickable: true,
		          },
		       }
		   }
	    },
	    computed: {
	      swiper() {
	        return this.$refs['sliderWidgetCarousel-'+this.$attrs.id].$swiper
	      },
	      /*headerComponent: function() {
			      return {
			        template: `<${this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'title')].title.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'title')].title.subfields, 'heading_level')].heading_level.value}>${this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'title')].title.value}</${this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'title')].title.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'title')].title.subfields, 'heading_level')].heading_level.value} >`,
			        methods: {
			          someAction() {
			            console.log("Action!");
			          }
			        }
			      }
			 },*/
	    },
	    mounted() {
		    console.log('Current Swiper instance object', this.swiper);
		      this.swiper.slideTo(this.startSlide, 1000, false);
		      if(this.$attrs.liveMode == true){
		      	this.linkType = 'router-link';
		      }
		},
	    methods: {
		    	headerComponent: function(title_val) {
				      return {
				        template: `<${this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'title')].title.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'title')].title.subfields, 'heading_level')].heading_level.value}>${title_val}</${this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'title')].title.subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'title')].title.subfields, 'heading_level')].heading_level.value} >`,
				        methods: {
				          someAction() {
				            console.log("Action!");
				          }
				        }
				      }
				 },
	    	compileElementStyles(fieldname, types){
				var style = '';
				if(types.includes('background') == true){
					if(fieldname !== 'column'){
						if(fieldname == 'caption'){
							style += 'background-color:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_background_colour')][fieldname+'_background_colour'].value+' !important;';
						}else{
				  			style += 'background-color:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_colour')][fieldname+'_colour'].value+' !important;';
				  		}
				  	}else{
				  		style += 'background-color:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'background_colour')].background_colour.value+' !important;'
				  	}
				}
				if(types.includes('color') == true){
					if(fieldname !== 'column'){
						style += 'color:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_text_colour')][fieldname+'_text_colour'].value+' !important;'
					}else{
						style += 'color:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'text_colour')].text_colour.value+' !important;'
					}
				}
				if(types.includes('border') == true){
					if(fieldname !== 'column'){
						//style +=  'border-color: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border_colour')][fieldname+'_border_colour'].value+' !important; border-style:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border_style')][fieldname+'_border_style'].value+' !important; border-radius:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border_radius')][fieldname+'_border_radius'].top_left+' '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border_radius')][fieldname+'_border_radius'].top_right+' '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border_radius')][fieldname+'_border_radius'].bottom_right+' '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border_radius')][fieldname+'_border_radius'].bottom_left+' !important; border-left:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border')][fieldname+'_border'].left+'px !important; border-right: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border')][fieldname+'_border'].right+'px !important; border-top: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border')][fieldname+'_border'].top+'px !important; border-bottom: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border')][fieldname+'_border'].bottom+'px !important;'

						style += 'border-radius:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border_radius')][fieldname+'_border_radius'].top_left+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border_radius')][fieldname+'_border_radius'].units +' '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border_radius')][fieldname+'_border_radius'].top_right+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border_radius')][fieldname+'_border_radius'].units +' '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border_radius')][fieldname+'_border_radius'].bottom_right+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border_radius')][fieldname+'_border_radius'].units +' '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border_radius')][fieldname+'_border_radius'].bottom_left+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border_radius')][fieldname+'_border_radius'].units +' !important;'
						style += ' border-left:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border')][fieldname+'_border'].left+'px !important; border-right: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border')][fieldname+'_border'].right+'px !important; border-top: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border')][fieldname+'_border'].top+'px !important; border-bottom: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border')][fieldname+'_border'].bottom+'px !important; ';
						style += 'border-style: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border_style')][fieldname+'_border_style'].value+' !important; ';
						style +=  ' border-color: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border_colour')][fieldname+'_border_colour'].value+' !important;' ;
					}else{
						style +=  'border-color: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'border_colour')].border_colour.value+' !important;'

						style += 'border-style:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'border_style')].border_style.value+' !important;'

						style += 'border-radius:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'border_radius')].border_radius.top_left+' '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'border_radius')].border_radius.top_right+' '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'border_radius')].border_radius.bottom_right+' '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'border_radius')].border_radius.bottom_left+' !important;'
						style += 'border-left:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'border')].border.left+'px !important; border-right: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'border')].border.right+'px !important; border-top: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'border')].border.top+'px !important; border-bottom: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'border')].border.bottom+'px !important;'
					}

				}
				if(types.includes('margin') == true){
					if(fieldname !== 'column'){
					style +=  'margin-left:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_margin')][fieldname+'_margin'].left+'px !important;';
					style +=  'margin-right: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_margin')][fieldname+'_margin'].right+'px !important;';
					style +=  'margin-top: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_margin')][fieldname+'_margin'].top+'px !important;';
					style +=  'margin-bottom: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_margin')][fieldname+'_margin'].bottom+'px !important;';
					}else{
					style +=  'margin-left:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'margin')]['margin'].left+'px !important;'
					style +=  'margin-right: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'margin')]['margin'].right+'px !important;'
					style +=  'margin-top: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'margin')]['margin'].top+'px !important;'
					style +=  'margin-bottom: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'margin')]['margin'].bottom+'px !important;'
					}
				}
				if(types.includes('padding') == true){
					if(fieldname !== 'column'){
					style +=  'padding-left:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_padding')][fieldname+'_padding'].left+'px !important;'
					style +=  'padding-right: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_padding')][fieldname+'_padding'].right+'px !important;'
					style +=  'padding-top: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_padding')][fieldname+'_padding'].top+'px !important;'
					style +=  'padding-bottom: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_padding')][fieldname+'_padding'].bottom+'px !important;'
					}else{
						style +=  'padding-left:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'padding')]['padding'].left+'px !important;'
					style +=  'padding-right: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'padding')]['padding'].right+'px !important;'
					style +=  'padding-top: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'padding')]['padding'].top+'px !important;'
					style +=  'padding-bottom: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'padding')]['padding'].bottom+'px !important;'
					}
				}
				if(types.includes('size') == true){
					if(fieldname !== 'column'){
					style +=  'height:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_height')][fieldname+'_height'].value+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_height')][fieldname+'_height'].units+' !important;';
					style +=  'max-height:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_max_height')][fieldname+'_max_height'].value+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_max_height')][fieldname+'_max_height'].units+' !important;';
					style +=  'width:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_width')][fieldname+'_width'].value+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_width')][fieldname+'_width'].units+' !important;'
					style +=  'max-width:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_max_width')][fieldname+'_max_width'].value+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_max_width')][fieldname+'_max_width'].units+' !important;'
					}else{
					style +=  'height:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'height')]['height'].value+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'height')]['height'].units+' !important;';
					style +=  'max-height:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'max_height')]['max_height'].value+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'max_height')]['max_height'].units+' !important;';
					style +=  'width:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'width')]['width'].value+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'width')]['width'].units+' !important;'
					style +=  'max-width:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'max_width')]['max_width'].value+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'max_width')]['max_width'].units+' !important;'
					} 
				}
				
				if(types.includes('text') == true){
				  style += 'font-family: \"'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'font_family')].font_family.value.family+'\", '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'font_family')].font_family.value.category+' !important; font-size:'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'font_size')].font_size.value+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'font_size')].font_size.units+' !important; text-transform: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields[this.filterStylesKey(this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname)][fieldname].subfields, 'text_transform')].text_transform.value+'!important;';
				}

				if(types.includes('text_alignment') == true){
					style += 'text-align: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_text_alignment')][fieldname+'_text_alignment'].value+''
				}


				if(types.includes('width') == true){
					
					style +=  'width: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_width')][fieldname+'_width'].value+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_width')][fieldname+'_width'].units+' !important;'
				}

				return style;
			},
	    	checkExists(value, units){
	    		if(value !== ''){
	    			return value+units;
	    		}else{
	    			return null;
	    		}
	    	},
		    filterStylesKey(styles, key){
	            for(var i = 0; i < styles.length; i++){
	               var keys = Object.keys(styles[i])
	               if(keys.includes(key)){
	                 return i;
	               }
	            }
	        },
	        compiledStyles(styles){
	        	var style = 'will-change: auto; ';
	        	for(var i = 0; i < (styles.length - 1); i++ ){
	        		for(var key in styles[i] ){
	        			
	        		if(styles[i][key].active == true){
		        		if(key.includes('_label') == false){

		        			if(key == 'background'){
		        				//var stylename = key.replace('_', '-');
		        				if(styles[i][key].value[0] == 'background-image'){
		        					style += ''+styles[i][key].value[0]+': url('+styles[i][key].value[1]+'); '
		        				}else{
		        					style += ''+styles[i][key].value[0]+': '+styles[i][key].value[1]+'; '
		        				}
		        				style += 'background-size: '+styles[i][key].size+'; '
		        				style += 'background-position: '+styles[i][key].position+'; '
		        				style += 'background-repeat: '+styles[i][key].repeat+'; '
		        			}
		        			else if(styles[i][key].type == 'numbergroup'){
		        				//var stylename = key.replace('_', '-');
		        				for(var numgroupkey in styles[i][key]){
		        					if((numgroupkey !== 'parentlabel') || (numgroupkey !== 'type')){
		        						if((key.includes('_radius') == false) && (key.includes('border') == true)){

		        							style += ''+key+'-'+numgroupkey.replace('_','-')+': '+styles[i][key][numgroupkey]+'px '+styles[this.filterStylesKey(styles, 'border_style')]["border_style"].value+' '+styles[this.filterStylesKey(styles, 'border_colour')]["border_colour"].value+' !important; '

		        						}else	if(key.includes('_radius') == false){

		        							style += ''+key+'-'+numgroupkey.replace('_','-')+': '+styles[i][key][numgroupkey]+'px !important; '
		        						
		        						}else{
		        						
		        							style += ''+key.replace('_radius', '')+'-'+numgroupkey.replace('_','-')+'-radius: '+styles[i][key][numgroupkey]+'px !important; '
		        						
		        						}
		        					}
		        				}
		        			}else if(styles[i][key].type == 'numbergroup-wcolor'){
		        				//var stylename = key.replace('_', '-');
		        						if(key.includes('_shadow') == true){
		        						style += ''+key.replace('_shadow', '')+'-shadow: '+styles[i][key].h_offset+'px '+styles[i][key].h_offset+'px '+ styles[i][key].blur + ' ' + styles[i][key].spread + ' ' + styles[i][key].value + ' ' + styles[i][key].opacity + '!important; ';
		        					}
		        							
		        						
		        					
		        			}
		        			else if(styles[i][key].type == 'range'){
		        					if('units' in styles[i][key]){
		        						//console.log(styles[i][key].value);
		        						if(styles[i][key].value !== null){
			        						var stylename = key.replace('_', '-');
					        				style += ''+stylename+': '+parseFloat(styles[i][key].value)+''+styles[i][key].units+'; '; 
				        					}
		        					 }else{
		        					 		style += ''+stylename+': '+styles[i][key].value+'; '
		        					 }
		        			}else{
		        				var stylename = key.replace('_', '-');
		        				stylename = stylename.replace('colour', 'color');
		        				if(styles[i][key].value.length !== 0){
		        					style += ''+stylename+': '+styles[i][key].value+' !important; '
		        				}else{
		        					style += ''+stylename+': '+styles[i][key].value+' !important; '
		        				}
		        			}
		        		}
	        		}
	        	}
	        }
	        	return style;
	        },
	    }
	}

</script>

<style type="text/css" scoped>
	.slider-widget img{
		 object-fit: cover;
  		height: 100%;
	}
	.slider-widget .card .position-relative{
		height: 100%;
	}
</style>