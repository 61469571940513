<script type="text/javascript">
import VueSlideBar from 'vue-slide-bar';
import VueSlider from 'vue-slider-component';
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import SelectCompanyProductCategories from "../../../components/widgets/SelectCompanyProductCategories"
import SelectCompanyProductCollections from "../../../components/widgets/SelectCompanyProductCollections"
import SelectValuationSignature from '../../../components/widgets/SelectValuationSignature';
import SelectValuationStamp from '../../../components/widgets/SelectValuationStamp';
import SelectValuationNote from '../../../components/widgets/SelectValuationNote';
import ModalWindow from "../../../components/widgets/ModalWindow";
import StorageWindow from "../../../components/widgets/file-manager/StorageWindow";
import CompanyCustomerSearch from "../../../components/widgets/CompanyCustomerSearch";
import SearchCompanyCustomerAddress from "../../../components/widgets/SearchCompanyCustomerAddress";
import draggable from 'vuedraggable';
import Swal from "sweetalert2";
import simplebar from "simplebar-vue";
//import WebsiteProductList from "../../../components/widgets/ecommerce/web/WebsiteProductList";
import WebsiteMenus from "./widgets/web/WebsiteMenus";
import ImageGalleryWidget from "./widgets/web/ImageGalleryWidget";
import ImageWidget from "./widgets/web/ImageWidget";
import CategoryImageWidget from "./widgets/web/CategoryImageWidget";
import TextWidget from "./widgets/web/TextWidget";
import ProductsListWidget from "./widgets/web/ProductsListWidget";
import CategoryListWidget from "./widgets/web/CategoryListWidget";
import SpacerDividerWidget from "./widgets/web/SpacerDividerWidget";
import ListingWidget from "./widgets/web/ListingWidget";
import MapWidget from "./widgets/web/MapWidget";
import SocialWidget from "./widgets/web/SocialWidget";
import FormWidget from "./widgets/web/FormWidget";
import SubscriptionWidget from "./widgets/web/SubscriptionWidget";
import SearchWidget from "./widgets/web/SearchWidget";
import SliderWidget from "./widgets/web/SliderWidget";
import ButtonWidget from "./widgets/web/ButtonWidget";
import CardsListWidget from "./widgets/web/CardsListWidget";
import SubcategoryGrid from "./widgets/template/subcategory-grid";
import CategoryGrid from "./widgets/template/category-grid";
import CategoryFilters from "./widgets/template/category-filters";
import CategoryTitle from "./widgets/template/category-title";
import ProductTitle from "./widgets/template/product-title-block";
import ProductReference from "./widgets/template/product-reference";
import CategoryDescription from "./widgets/template/category-description";
import stylesTab from "./widgets/stylesTab";
import widgetConfigsTab from "./widgets/widgetConfigsTab";
import widgetContentFilters from "./widgets/widgetContentFilters";
import widgetContentList from "./widgets/widgetContentList";
import widgetEditBoxView from './widgetEditBox';
import { containerStyles, rowStyles, columnStyles, widgetStyles } from "./page-styles";
//import VueGoogleAutocomplete from "vue-google-autocomplete";
import { EventBus } from "../../../app";
	export default{
		components: {
			ckeditor: CKEditor.component,
			SelectValuationSignature,
			SelectValuationStamp,
			SelectValuationNote,
			SelectCompanyProductCategories,
			SelectCompanyProductCollections,
			ModalWindow,
			StorageWindow,
			CompanyCustomerSearch,
			SearchCompanyCustomerAddress,
			draggable,
			simplebar,
			VueSlideBar,
			VueSlider,
			WebsiteMenus,
			ImageWidget,
			ImageGalleryWidget,
			TextWidget,
			SocialWidget,
			FormWidget,
			SubscriptionWidget,
			SearchWidget,
			SliderWidget,
			ProductsListWidget,
			CategoryListWidget,
			CardsListWidget,
			ListingWidget,
			MapWidget,
			//VueGoogleAutocomplete,
			stylesTab,
			widgetConfigsTab,
			widgetContentFilters,
			widgetContentList,
			SpacerDividerWidget,
			CategoryImageWidget,
			widgetEditBoxView,
			SubcategoryGrid,
			CategoryGrid,
			CategoryFilters,
			CategoryTitle,
			CategoryDescription,
			ProductTitle,
			ProductReference,
			ButtonWidget,
		},
		data: function(){
			return{
				containerStyles: containerStyles,
				moveSectionIndex: -1,
				moveCol: -1,
				moveRow: -1,
				moveElement: -1,
				isLive: false,
				historyStates: [],
				imageDelete: -1,
				sectionSettings: -1,
				hoverCIndex: -1,
				hoverBIndex: -1,
				hoverRIndex: -1,
				hoverSIndex: -1,
				sectionHover: -1,
				showColTools: -1,
				showRowTools: -1,
				showSectionTools: -1,
				addMapKey: false, 
				selectedFiles: [],
				widgetSearch: '',
				widgetBoxFixedMode: false,
				widgetBoxLoading: false,
				googleSearchAddress: '',
				counties: [],
				countries: [],
				listing_types: [],
				menus: [],
				col_default: {styles: columnStyles, blocks: []},
				row_default: {styles: rowStyles, cols: []},
				widgetStyles: widgetStyles,
				editor: ClassicEditor,
				showFileManager: false,
          		fileUrl: '',
          		hoverElement: '',
	          	fileResource: '',
	          	fileRowIndex: '',
	          	fileColIndex: '',
	          	storageurl: 'https://'+this.$attrs.company.nickname+'.s3.eu-west-2.amazonaws.com/',
	          	showColOptions: false,
	          	widgetBoxEditMode: false,
	          	widgetBoxEditContent: '',
	          	widgetEditBlockIndex: null,
	          	widgetBoxSubForm: false,
	          	widgetView: 'sidepanel',
	          	fileBlockIndex: '',
			}
		},
		props: {
			page: {
				type: Object,
			},
			viewPortSize: {
				type: String,
				default: () => 'xl',
			},
			loadingWidgetSettings: {
				type: Boolean,
				default: false
			},
			widgetBox: {
				type: String,
				default: () => ''
			},
			type: {
				type:String,

			},
			sidePanelMaxHeight: {
				type: Number,
				default: () => 420
			},
			preview_type: {
				type: String,
				default: () => 'document',
			},
			preview: {
				type: Boolean,
				default: () => false,
			},
			allow_preview: {
				type: Boolean,
				default: () => true,
			},
			row_limit: {
				type: Number,
				default: () => -1,
			},
			col_limit: {
				type: Number,
				default: () => -1,
			},
			widget_limit: {
				type: Number,
				default: () => -1,
			},

			data: {
				type: Object,
				default: () => { rows: [] },
			},
			template_areas: {
				type: Array,
				default: () => []
			},
			widgets: {
				type: Array,
				default: () => [
				            {
				              widgetname: 'Image',
				              widgettype: 'image',
				              widgeticon: 'bx bx-image',
				              widgetcontent: {
				                url: '',
				                width: 300,
				                height: 300,
				                alignment: 'text-left',
				                fullwidth: false,
				              },
				              editable: 0,
				              id: '',
				            },
				            {
				              widgetname: 'Text',
				              widgettype: 'text',
				              widgeticon: 'bx bx-text',
				              widgetcontent: {
				                title: '',
				                text: '',
				              },
				              editable: 0,
				              id: '',
				            },
				            {
				              widgetname: 'Spacer',
				              widgettype: 'spacer',
				              widgeticon: 'mdi mdi-arrow-split-horizontal',
				              widgetcontent: {
				                height: ''
				              },
				              editable: 0,
				              id: '',
				            },
				            {
				              widgetname: 'Divider',
				              widgettype: 'divider',
				              widgeticon: 'mdi mdi-border-horizontal',
				              widgetcontent: {
				                bordertype: 'solid',
				                width: 1,
				                color: '#000',
				                opacity: '',
				              },
				              widgetoptions:{
				                bordertypes: ['dotted','dashed','solid','double','groove','ridge','inset','outset','none','hidden']
				              },
				              editable: 0,
				              id: '',
				            }
           				]
			}
		},
		computed: {
			dataUpdate(){
				return this.data;
			},
			filteredWidgets(){
	    	if(this.widgetSearch !== ''){
   				return this.widgets.filter(item => (item.widgetname).toLowerCase().includes(this.widgetSearch.toLowerCase()));
   			}else{
   				return this.widgets;
   			}
   		},
   		filteredTemplateWidgets(){
	    	if(this.widgetSearch !== ''){
   				return this.template_areas.filter(item => (item.widgetname).toLowerCase().includes(this.widgetSearch.toLowerCase()));
   			}else{
   				return this.template_areas;
   			}
   		},
		},
		mounted(){
			let self = this;
			EventBus.$on('close-widget-editbox', function(){
				self.loadingWidgetSettings = true;
				self.widgetBoxEditMode = false;
				self.widgetBox = '';
				setTimeout(function() {
					self.loadingWidgetSettings = false;
				}, 500);
			});
			EventBus.$on('close-subpanel', function(){
				self.sectionSettings = -1;
			});
			EventBus.$on('menu-lodaded', function(){
				self.loadingWidgetSettings = false;
			});

			
		},
		created(){
			this.getCountries();
			//this.getListingTypes();
			this.getMenus(1);
			this.includeGoogle('maps.googleapis.com/maps/api/js?key=AIzaSyCO-2c9bJAX3z7cP3WN2O6x7ndipzOvZsU&libraries=places');
		},
		methods: {
			showTools(area, index, section){
					
								parent.postMessage({
									action: 'showTools',
									section: area,
									sectionIndex: index,
									styles: section
								}, '*');
			},
			templateArea(widget_name){
				var index = this.template_areas.findIndex(item => item.widgettype == widget_name);
				if(index > -1){
					return true;
				}else{
					return false;
				}
			},
			/*compiledStyles(styles){
	        	var style = 'will-change: auto; ';
	        	for(var i = 0; i < (styles.length - 1); i++ ){
	        		for(var key in styles[i] ){
	        			
	        		if(styles[i][key].active == true){
		        		if(key.includes('_label') == false){

		        			if(key == 'background'){
		        				//var stylename = key.replace('_', '-');
		        				if(styles[i][key].value[0] == 'background-image'){
		        					style += ''+styles[i][key].value[0]+': url('+styles[i][key].value[1]+'); '
		        				}else{
		        					style += ''+styles[i][key].value[0]+': '+styles[i][key].value[1]+'; '
		        				}
		        				style += 'background-size: '+styles[i][key].size+'; '
		        				style += 'background-position: '+styles[i][key].position+'; '
		        				style += 'background-repeat: '+styles[i][key].repeat+'; '
		        			}
		        			else if(styles[i][key].type == 'numbergroup'){
		        				//var stylename = key.replace('_', '-');
		        				for(var numgroupkey in styles[i][key]){
		        					if((numgroupkey !== 'parentlabel') || (numgroupkey !== 'type')){
		        						if((key.includes('_radius') == false) && (key.includes('border') == true)){

		        							style += ''+key+'-'+numgroupkey.replace('_','-')+': '+styles[i][key][numgroupkey]+'px '+styles[this.filterStylesKey(styles, 'border_style')]["border_style"].value+' '+styles[this.filterStylesKey(styles, 'border_colour')]["border_colour"].value+' !important; '

		        						}else	if(key.includes('_radius') == false){

		        							style += ''+key+'-'+numgroupkey.replace('_','-')+': '+styles[i][key][numgroupkey]+'px !important; '
		        						
		        						}else{
		        						
		        							style += ''+key.replace('_radius', '')+'-'+numgroupkey.replace('_','-')+'-radius: '+styles[i][key][numgroupkey]+'px !important; '
		        						
		        						}
		        					}
		        				}
		        			}
		        			else if(styles[i][key].type == 'range'){
		        					if('units' in styles[i][key]){
		        						//console.log(styles[i][key].value);
		        						if(styles[i][key].value !== null){
			        						var stylename = key.replace('_', '-');
					        				style += ''+stylename+': '+parseFloat(styles[i][key].value)+''+styles[i][key].units+'; '; 
				        					}
		        					 }else{
		        					 		style += ''+stylename+': '+styles[i][key].value+'; '
		        					 }

		        			}else{
		        				var stylename = key.replace('_', '-');
		        				stylename = stylename.replace('colour', 'color');
		        				if(styles[i][key].value.length !== 0){
		        					style += ''+stylename+': '+styles[i][key].value+' !important; '
		        				}else{
		        					style += ''+stylename+': '+styles[i][key].value+' !important; '
		        				}
		        			}
		        		}
	        		}
	        	}
	        }
	        	return style;
	        },*/
	        compiledStyles(styles){
	        	var style = 'will-change: auto; ';
	        	for(var i = 0; i < (styles.length - 1); i++ ){
	        		for(var key in styles[i] ){
	        			
	        		if(styles[i][key].active == true){
		        		if(key.includes('_label') == false){

		        			if(key == 'background'){
		        				//var stylename = key.replace('_', '-');
		        				if(styles[i][key].value[0] == 'background-image'){
		        					if(styles[i][key].value[1] !== null){
		        						/*var imgvalue = styles[i][key].value[1].replaceAll(' ', '%20');
		        						var imgpath = styles[i][key].value[1].split('/');
		        						var imgpathstr = imgpath[imgpath.length - 1];
		        						var imgfull = '';
		        						for(var imgi = 0; imgi < (imgpath.length -1); imgi++){
		        							imgfull += ''+imgpath[imgi].replaceAll(' ', '%20')+'/';
		        						}
		        						imgfull += imgpathstr;*/
		        						style += 'background-image: url(\''+styles[i][key].value[1].replaceAll(' ', '%20')+'\'); ';
		        						/*styles[i][key].value[1] = imgfull;*/
		        					}
		        				}else{
		        					style += ''+styles[i][key].value[0]+': '+styles[i][key].value[1]+'; '
		        				}
		        				style += 'background-size: '+styles[i][key].size+'; '
		        				style += 'background-position: '+styles[i][key].position+'; '
		        				style += 'background-repeat: '+styles[i][key].repeat+'; '
		        			}
		        			else if(styles[i][key].type == 'numbergroup'){
		        				//var stylename = key.replace('_', '-');
		        				for(var numgroupkey in styles[i][key]){
		        					if((numgroupkey !== 'parentlabel') || (numgroupkey !== 'type')){
		        						if((key.includes('_radius') == false) && (key.includes('border') == true)){

		        							style += ''+key+'-'+numgroupkey.replace('_','-')+': '+styles[i][key][numgroupkey]+'px '+styles[this.filterStylesKey(styles, 'border_style')]["border_style"].value+' '+styles[this.filterStylesKey(styles, 'border_colour')]["border_colour"].value+' !important; '

		        						}else	if(key.includes('_radius') == false){

		        							style += ''+key+'-'+numgroupkey.replace('_','-')+': '+styles[i][key][numgroupkey]+'px !important; '
		        						
		        						}else{
		        						
		        							style += ''+key.replace('_radius', '')+'-'+numgroupkey.replace('_','-')+'-radius: '+styles[i][key][numgroupkey]+'px !important; '
		        						
		        						}
		        					}
		        				}
		        			}
		        			else if(styles[i][key].type == 'range'){
		        					if('units' in styles[i][key]){
		        						//console.log(styles[i][key].value);
		        						if(styles[i][key].value !== null){
			        						var stylename = key.replace('_', '-');
					        				style += ''+stylename+': '+parseFloat(styles[i][key].value)+''+styles[i][key].units+'; '; 
				        					}
		        					 }else{
		        					 		style += ''+stylename+': '+styles[i][key].value+'; '
		        					 }

		        			}else{
		        				var stylename = key.replace('_', '-');
		        				stylename = stylename.replace('colour', 'color');
		        				if(styles[i][key].value.length !== 0){
		        					style += ''+stylename+': '+styles[i][key].value+' !important; '
		        				}else{
		        					style += ''+stylename+': '+styles[i][key].value+' !important; '
		        				}
		        			}
		        		}
	        		}
	        	}
	        }
	        	return style;
	     },
			includeGoogle( URL, callback ){
                        let documentTag = document, tag = 'script',
                            object = documentTag.createElement(tag),
                            scriptTag = documentTag.getElementsByTagName(tag)[0];
                        object.src = '//' + URL;
                        if (callback) { object.addEventListener('load', function (e) { callback(null, e); }, false); }
                        scriptTag.parentNode.insertBefore(object, scriptTag);
       },
       filterStylesKey(styles, key){
       		for(var i = 0; i < styles.length; i++){
       			 var keys = Object.keys(styles[i])
       			 if(keys.includes(key)){
       			 	 return i;
       			 }
       		}
       },
       getMenus(page){
        axios.get('/api/company/web/menus?page='+page+'&limit=25&sortby=id&order=desc', {headers: { 'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
          this.menus = response.data;
        }).catch(error => {
          this.error_message = true;
        });
    	},
			getCountries(){
        if(this.countries.length == 0){
          axios.get('/api/countries', {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
            this.countries = response.data;
          }).catch(error => {
            this.load_error = error.data;
          })
        }
      },
			getCounties(country_id){
        // get counties as country selected
        axios.get('/api/country/'+country_id+'/counties', {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }}).then(response => {
          this.counties = response.data;
        }).catch(error => {
          this.load_error = error.data;
        })
      },
      getAddressData: function (addressData, placeResultData, id) {
        this.googleSearchAddress = addressData;
        const lat = addressData.geometry.location.lat();
        const lng = addressData.geometry.location.lng();
        this.widgetBoxEditContent.widgetcontent.value.latitude.value = lat;
        this.widgetBoxEditContent.widgetcontent.value.longitude.value = lng;
        this.widgetBoxEditContent.widgetcontent.value.gplace_id.value = this.googleSearchAddress.place_id;
        /**if(addressData.name){
         this.widgetBoxEditContent.widgetcontent.value.address1.value = placeResultData.name;
         if(addressData.street_number){
              this.widgetBoxEditContent.widgetcontent.value.address2.value = addressData.street_number + ' ' +addressData.route; 
          }else{
              this.widgetBoxEditContent.widgetcontent.value.address2.value = addressData.route; 
          }
        }else{
         if(addressData.street_number){
              this.widgetBoxEditContent.widgetcontent.value.address1.value = addressData.street_number + ' ' +addressData.route; 
          }else{
              this.widgetBoxEditContent.widgetcontent.value.address1.value = addressData.route; 
          }
        }
        if(addressData.locality){
          this.widgetBoxEditContent.widgetcontent.value.address3.value = addressData.locality;
        }
        this.widgetBoxEditContent.widgetcontent.value.postcode.value = addressData.postal_code;

        var country = this.countries.filter(item => item.name == addressData.country);
        this.widgetBoxEditContent.widgetcontent.value.country_id.value = country[0].id;
        this.selected_country_id = country[0].id;
        **/
        var country = [];
        for(var i = 0; i < this.googleSearchAddress.address_components.length; i++){
        		var fieldname = '';
		         if((this.googleSearchAddress.address_components[i].types[0] == 'street_number') || (this.googleSearchAddress.address_components[i].types[0] == 'route') ){
		              fieldname = "address1";
		          }
		          if(this.googleSearchAddress.address_components[i].types[0] == 'postal_town'){
		          	 fieldname = 'address2';
		          }
		          if(this.googleSearchAddress.address_components[i].types[0] == 'postal_code'){
		          	 fieldname = 'postcode';
		          }
		          if(fieldname !== ''){
			          if(this.googleSearchAddress.address_components[i].types[0] !== 'route'){
			         		this.widgetBoxEditContent.widgetcontent.value[fieldname]["value"] = this.googleSearchAddress.address_components[i].long_name; 
			         	}else{
			         		if(this.widgetBoxEditContent.widgetcontent.value[fieldname]["value"] == ''){
			         		this.widgetBoxEditContent.widgetcontent.value[fieldname]["value"] = this.googleSearchAddress.address_components[i].long_name;
			         		}else{
			         			this.widgetBoxEditContent.widgetcontent.value[fieldname]["value"] += ' '+this.googleSearchAddress.address_components[i].long_name;
			         		}
			         	}
		         	}
		         	
		         	if(this.googleSearchAddress.address_components[i].types[0] == 'country'){
		        		country = this.countries.filter(item => item.name == this.googleSearchAddress.address_components[i].long_name);
		        		this.widgetBoxEditContent.widgetcontent.value.country_id.value = country[0].id;
		        		this.selected_country_id = country[0].id;
		        	}

		       
		       
        }
         	if(country.length > 0){
            //this.getCounties(country[0].id);
		            var self = this;
		            axios.all([getCounties(country[0].id, self.$attrs.user.api_token, self.googleSearchAddress.address_components[filterAddressTypes(self.googleSearchAddress.address_components, 'administrative_area_level_2')].long_name)]).then(axios.spread(function (response) {
		              //companyList[ia].push(response.data)
		              console.log(response);
		              self.counties = response[0].data;
		              var county = response[0].data.filter(item => item.name == response[1]);
		              self.widgetBoxEditContent.widgetcontent.value.county_id = county[0].id;
		              self.selected_county_id = county[0].id;
		            }));
		            
		          }
        
        //this.googleSearchAddress["place"] = placeResultData;
        
        /**for(var i = 0; i < placesData.address_components.length; i++){
          var county = placesData.address_components[i].types.filter(item == 'route');
          var county = placesData.address_components[i].types.filter(item == 'street_number');
          
          var town = placesData.address_components[i].types.filter(item == 'locality');
          var city = placesData.address_components[i].types.filter(item == 'postal_town');
          var country = placesData.address_components[i].types.filter(item == 'country');

          this.newListing.address3 = town[0].long_name;

        
        }**/
        async function getCounties(country_id, api_token, county_name){
               return [await axios.get('/api/country/'+country_id+'/counties?api_token='+api_token), county_name];
              }

         async function filterAddressTypes(components, type_name){
					return this.components.findIndex(item => item.types[0] == type_name);
				}

      },
      async getListingTypes(){
	      const response = await fetch('/api/company/listing/types', {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }});
	      return await response.json();
    	},
    	async getCategories(page, limit, sort, order, search, parent){
	      const response = await fetch('/api/company/product/categories?page='+page+'&limit='+limit+'&sortby='+sortby+'&order='+order+'&search='+search+'&parent_id='+parent+'&marketplace_categories='+this.marketplace_categories, {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }});
	      return await response.json();
    	},
    	async getCollections(page, limit, sort, order, search, parent){
	      const response = await fetch('/api/company/product/collections?page='+page+'&limit='+limit+'&sortby='+sortby+'&order='+order+'&search='+search+'&parent_id='+parent+'&active=&public_active=&trade_active=', {headers: {
                  'Authorization': 'Bearer ' + this.$attrs.user.api_token
              }});
	      return await response.json();
    	},
			limitReached(element, limit){
				Swal.fire({
		        position: "top-end",
		        icon: "error",
		        title: "You have reached the "+element+" limit for this section",
		        showConfirmButton: false,
		        timer: 1500
		      });
			},
			filterAddressTypes(components, type_name){
				return this.components.findIndex(item => item.types[0] == 'administrative_area_level_2');
			},
		    selectValue(payload){
		      this.widgetBoxEditContent.widgetcontent = payload;
		    },
		    selectCustomer(payload){
		      this.widgetBoxEditContent.widgetcontent.selectedcustomer = payload;
		    },
		    selectAddress(payload){
		      this.widgetBoxEditContent.widgetcontent.selectedaddress = payload;
		    },
		    enforceFullwidthImage(){
		      this.widgetBoxEditContent.widgetcontent.width = '100%';
		      this.widgetBoxEditContent.widgetcontent.height = 'auto';
		      this.widgetBoxEditContent.widgetcontent.fullwidth = true;
		    },
		    fileSelected(payload){
		    		if(this.widgetBoxEditContent.widgettype == 'image'){
		      	this.widgetBoxEditContent.widgetcontent[this.filterStylesKey(this.widgetBoxEditContent.widgetcontent, 'url')].url.value = 'https://'+this.$attrs.company.nickname+'.s3.eu-west-2.amazonaws.com'+payload.file+'';
		      	this.showFileManager = false;
		    	}else if(this.widgetBoxEditContent.widgettype == 'imagegallery'){
		    		var imageObject = {src: ''+this.storageurl+''+payload.file+'', caption_text: ''};

		    		this.widgetBoxEditContent.widgetcontent[this.filterStylesKey(this.widgetBoxEditContent.widgetcontent, 'list')].list.push(imageObject);
		    		this.selectedFiles = JSON.parse(JSON.stringify(this.widgetBoxEditContent.widgetcontent[this.filterStylesKey(this.widgetBoxEditContent.widgetcontent, 'list')].list));
		    	}else if(this.widgetBoxEditContent.widgettype == 'slider'){
		    		this.widgetBoxEditContent.widgetcontent[this.filterStylesKey(this.widgetBoxEditContent.widgetcontent, 'list')].list[this.widgetBoxEditContent.widgetcontent.list_index].imageUrl = ''+this.storageurl+''+payload.file+'';
		    		this.showFileManager = false;
		    	}else if(this.widgetBoxEditContent.widgettype == 'menu'){
		    		this.widgetBoxEditContent.widgetcontent[this.filterStylesKey(this.widgetBoxEditContent.widgetcontent, 'logo')].logo = ''+this.storageurl+''+payload.file+'';

		    		this.showFileManager = false;
		    	}
		    },
		    addNewWidget(widget, sindex, rindex, cindex){
		    	this.loadingWidgetSettings = true;
		      this.page[this.viewPortSize].sections[sindex].rows[rindex].cols[cindex].blocks.push(widget);
		      this.selectedFiles = [];
		      	
		      	if(widget.widgettype == 'products'){
		          this.productQuery(rindex, cindex, (this.page[this.viewPortSize].sections[sindex].rows[rindex].cols[cindex].blocks.length - 1));
		        }
		        if(widget.widgettype == 'menu'){
		        	//this.$refs['WebsiteMenus'].getMenu(this.widgetBoxEditContent.widgetfilters.active_filters.menus.value);
		        }
		        let self = this;
		        setTimeout(function(){
				         		//self.getActiveShippingIntegrations();
				         		self.loadingWidgetSettings = false;
				         		//self.historyStates.unshift(self.data);
								  }, 1000);
		    },
		    async newWidgetOptionsStorage(widget, sindex, rindex, cindex){
						var runQuery = {
		        message: 'addNewWidget',
		        widgetBoxEditContent: this.widgetBoxEditContent,
						widgetBox: this.widgetBox,
						sindex: sindex,
						rindex: rindex,
						cindex: cindex,
						widgetBoxLoading: this.widgetBoxLoading,
						widgetBoxEditMode: this.widgetBoxEditMode,
						widgetBoxSubForm: this.widgetBoxSubForm,
						filteredWidgets: this.filteredWidgets,
						fileResource: this.fileResource,
						fileRowIndex: this.fileRowIndex,
						fileColIndex: this.fileColIndex,
						showFileManager: this.showFileManager,
						user: this.$attrs.user,
						company: this.$attrs.company,
						data: this.page[this.viewPortSize].sections[sindex],
						selectedFiles: this.selectedFiles,
						widgets: this.widgets,
						template_areas: this.template_areas,
						sidePanelMaxHeight: this.sidePanelMaxHeight,
						widgetStyles: this.widgetStyles,
						widgetSearch: this.widgetSearch,
		      	};
						parent.postMessage(runQuery, '*');
		    },
		    async newWidgetOptions(widget){
		    	this.widgetBoxEditContent = JSON.parse(JSON.stringify(widget));
		    	this.widgetBoxEditContent['widgetstyles'] = JSON.parse(JSON.stringify(this.widgetStyles));
		    	if(widget.widgettype == 'listings'){
		    			//const listing_types = await this.getListingTypes();
		        	this.widgetBoxEditContent.widgetfilters.filter.listing_type.options = await this.getListingTypes();
		      }
		      if(widget.widgettype == 'listingsmap'){
		    			//const listing_types = await this.getListingTypes();
		        	this.widgetBoxEditContent.widgetfilters.filter.listing_type.options = await this.getListingTypes();
		      }
		      if(widget.widgettype == 'menu'){
		      		this.widgetBoxEditContent.widgetfilters.filter.menus.options = this.menus.data;
		      		if(this.$attrs.company.logo !== ''){
		      			this.widgetBoxEditContent.widgetcontent[this.filterStylesKey(this.widgetBoxEditContent.widgetcontent, 'logo')].logo.value = ''+this.storageurl+''+this.$attrs.company.logo+'';
		      		}
		      }
		      this.widgetBoxEditMode = true;
		      let self = this;
				         setTimeout(function(){
				         		//self.getActiveShippingIntegrations();
				         		self.widgetBoxLoading = false;
								  }, 1000);
		    },
		    editWidget(sindex, rindex, cindex, bindex){
		    	this.loadingWidgetSettings = true;
		      this.widgetBoxEditContent = this.page[this.viewPortSize].sections[sindex].rows[rindex].cols[cindex].blocks[bindex];
		      this.widgetBoxEditMode = true;
		      this.widgetBox = ''+rindex+''+cindex+'';
		      this.widgetEditBlockIndex = bindex;
		      if(this.widgetBoxEditContent.widgettype == 'imagegallery'){
		    		this.selectedFiles = JSON.parse(JSON.stringify(this.widgetBoxEditContent.widgetcontent.list));
		    	}
		    	if(this.widgetBoxEditContent.widgettype == 'products'){
		          this.productQuery(rindex, cindex, (this.page[this.viewPortSize].sections[sindex].rows[rindex].cols[cindex].blocks.length - 1));
		        }
		    	if(this.widgetBoxEditContent.widgettype == 'menu'){
		        	//this.$refs['WebsiteMenus'].getMenu(this.widgetBoxEditContent.widgetfilters.active_filters.menus.value);
		        }
		      var runQuery = {
		        message: 'editWidget',
		        widgetBoxEditContent: this.widgetBoxEditContent,
						widgetBox: this.widgetBox,
						sindex: sindex,
						rindex: rindex,
						cindex: cindex,
						bindex: bindex,
						widgetBoxLoading: this.widgetBoxLoading,
						widgetBoxEditMode: this.widgetBoxEditMode,
						widgetBoxSubForm: this.widgetBoxSubForm,
						filteredWidgets: this.filteredWidgets,
						fileResource: this.fileResource,
						fileRowIndex: this.fileRowIndex,
						fileColIndex: this.fileColIndex,
						showFileManager: this.showFileManager,
						user: this.$attrs.user,
						company: this.$attrs.company,
						data: this.data,
						selectedFiles: this.selectedFiles,
						widgets: this.widgets,
						template_areas: this.template_areas,
						sidePanelMaxHeight: this.sidePanelMaxHeight,
						widgetStyles: this.widgetStyles,
						widgetSearch: this.widgetSearch,
		      	};
						parent.postMessage(runQuery, '*');
		    	let self = this;
		        setTimeout(function(){
				         		//self.getActiveShippingIntegrations();
				         		self.loadingWidgetSettings = false;
								  }, 1000);
		    },
		    updateEditedWidget(){

		    },
		    updateEvent(){
		    	EventBus.$emit('update-widget');
		    	this.historyStates.unshift(this.data);
		    },
		    deleteWidget(sindex, rindex, cindex, bindex){
		    	this.loadingWidgetSettings = true;
		    	Object.keys(this.page).forEach(val => {
		      		this.page[val].sections[sindex].rows[rindex].cols[cindex].blocks.splice(bindex, 1);
		  		});
		      this.loadingWidgetSettings = false;
		      //this.addParentResourceWidget('delete-widget', rindex, cindex, bindex, null);
		      EventBus.$emit('send-to-parent');
		    },
		    addColumns(sindex, rindex, col_data){
		    	Object.keys(this.page).forEach(val => {
				this.page[val].sections[sindex].rows[rindex].cols.push(JSON.parse(JSON.stringify(col_data)));
				});

				EventBus.$emit('send-to-parent');
		    },
		    deleteColumn(sindex, rindex, cindex){
		    this.showColTools = -1;
		      Object.keys(this.page).forEach(val => {
			
				this.page[val].sections[sindex].rows[rindex].cols.splice(cindex, 1);
			
				});
		      
		      this.showColTools = cindex - 1;
		      EventBus.$emit('send-to-parent');
		      //this.addParentResource('delete-col', rindex, cindex);
		    },
		    addRow(sindex){
					 //this.addParentResource('delete-row', rindex, null, null);
		      
			Object.keys(this.page).forEach(val => {
				this.page[val].sections[sindex].rows.push(JSON.parse(JSON.stringify(this.row_default)));
				});
		      
		      this.showRowTools = ''+ sindex + '' + (this.page[this.viewPortSize].sections[sindex].rows.length - 1) +'';
		     EventBus.$emit('send-to-parent');
		    },
		    deleteRow(sindex, rindex){
				this.showRowTools = -1;
				Object.keys(this.page).forEach(val => {
					this.page[val].sections[sindex].rows.splice(rindex, 1);
				});
		      	this.showRowTools = rindex - 1;
		      	EventBus.$emit('send-to-parent');
		    },
		    addSection(){
				Object.keys(this.page).forEach(val => {
					this.page[val].sections.push({styles: JSON.parse(JSON.stringify(this.containerStyles)), rows: []});
				});
		      
		      this.showSectionTools = (this.page[this.viewPortSize].sections.length - 1);
		      EventBus.$emit('send-to-parent');
		     
		    },
		    deleteSection(sindex){
		    	this.showSectionTools = -1;
					if((sindex == 0) && (this.page[this.viewPortSize].sections.length == 1)){
						Swal.fire("You can't delete this", "Every page needs at least one section!", "error");
					}else{
						Object.keys(this.page[this.edit_area]).forEach(val => {
							this.page[this.edit_area][val].sections.splice(index, 1);
					});
					}
		      
		      this.showSectionTools = sindex - 1;
		      EventBus.$emit('send-to-parent');
		    },
		    duplicateRow(sindex, rindex, section){
					Object.keys(this.page).forEach(val => {
					this.page[val].sections[sindex].rows.splice(rindex, 0, JSON.parse(JSON.stringify(section)));
					});
							EventBus.$emit('send-to-parent');
					//this.addParentResource('duplicate-row', rindex, null, section);
				},
				duplicateColumn(sindex, rindex, cindex, section){
					Object.keys(this.page).forEach(val => {
					this.page[val].sections[sindex].rows[rindex].cols.splice(cindex, 0, JSON.parse(JSON.stringify(section)));
				});
							EventBus.$emit('send-to-parent');
					//this.addParentResource('duplicate-col', rindex, cindex, section);
				},
				duplicateWidget(sindex, rindex, cindex, bindex, section){
					Object.keys(this.page).forEach(val => {
					this.page[val].sections[sindex].rows[rindex].cols[cindex].blocks.splice(bindex, 0, JSON.parse(JSON.stringify(section)));
					});
							EventBus.$emit('send-to-parent');
					//this.addParentResourceWidget('duplicate-widget', rindex, cindex, bindex, section);
				},
				runFilter(list, filtertype){

						//return await response.json();
				},
		    productQuery(rindex, cindex, bindex){
      			axios.get('/api/company/web/products', {headers : {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
      				this.page[this.viewPortSize].sections[this.$attrs.sindex].rows[rindex].cols[cindex].blocks[bindex].widgetcontent.list = response.data;
      			}).catch(error => {

      			});
    		},
    		elementMoved(element, sindex, rindex, cindex, payload){
    			console.log(element + ' moved:' + payload.oldIndex + ' to:' + payload.newIndex + ' newsindex: '+this.moveSectionIndex+ ' rindex: '+ rindex + ' cindex: '+ cindex + '' + ' newrindex: ' + this.moveRow + ' newcindex: '+ this.moveCol + '');

    			if(element == 'section'){
    				Object.keys(this.page).forEach(val => {
    					if(this.viewPortSize !== val){
    					var area = this.page[val].sections[payload.oldIndex];
    					this.page[val].sections.splice(payload.oldIndex, 1);
    					this.page[val].sections.splice(payload.newIndex , 0, JSON.parse(JSON.stringify(area)));
    					}
    				});
    			}

    			if(element == 'row'){
    				Object.keys(this.page).forEach(val => {
    					if(this.viewPortSize !== val){
    					var area = this.page[val].sections[sindex].rows[payload.oldIndex];
    					this.page[val].sections[sindex].rows.splice(payload.oldIndex, 1);
    					this.page[val].sections[this.moveSectionIndex].rows.splice(payload.newIndex, 0, JSON.parse(JSON.stringify(area)));
    					}
    				});
    			}


    			if(element == 'block'){
    				Object.keys(this.page).forEach(val => {
    					if(this.viewPortSize !== val){
    					var area = this.page[val].sections[sindex].rows[rindex].cols[cindex].blocks[payload.oldIndex];
    					this.page[val].sections[sindex].rows[rindex].cols[cindex].blocks.splice(payload.oldIndex, 1);
    					this.page[val].sections[this.moveSectionIndex].rows[(this.moveRow == -1) ? 0 : this.moveRow].cols[(this.moveCol == -1) ? 0 : this.moveCol].blocks.splice(payload.newIndex, 0, JSON.parse(JSON.stringify(area)));
    					}
    				});
    			}

    			//EventBus.$emit('element-moved', element, this.$attrs.sindex,rindex, cindex, payload.oldIndex, payload.newIndex, this.moveRow, this.moveCol, this.moveElement);
    			EventBus.$emit('send-to-parent');
    		},
    		addParentResourceWidget(type, rindex, cindex, bindex, data){
    			if(type == 'duplicate-widget'){
    				var runQuery = {
		        	message: 'duplicateWidget',
		        	sindex: this.$attrs.sindex,
		        	rindex: rindex,
		        	cindex: cindex,
		        	bindex: bindex,
		        	data: data
    				};
    				parent.postMessage(runQuery, '*');
    			}
    			if(type == 'delete-widget'){
    				var runQuery = {
		        	message: 'deleteWidget',
		        	sindex: this.$attrs.sindex,
		        	rindex: rindex,
		        	cindex: cindex,
		        	bindex: bindex,
		        	data: data
    				};
    				parent.postMessage(runQuery, '*');
    			}
    		},
    		addParentResource(type, rindex, cindex, data){
    			if(type == 'row'){
    				var runQuery = {
		        	message: 'addRow',
		        	sindex: this.$attrs.sindex,
		        	data: data
    				};
    				parent.postMessage(runQuery, '*');
    			}
    			if(type == 'delete-row'){
    				var runQuery = {
		        	message: 'deleteRow',
		        	sindex: this.$attrs.sindex,
		        	rindex: rindex,
    				};
    				parent.postMessage(runQuery, '*');
    			}
    				if(type == 'duplicate-row'){
    				var runQuery = {
		        	message: 'duplicateRow',
		        	sindex: this.$attrs.sindex,
		        	rindex: rindex,
		        	data: data
    				};
    				parent.postMessage(runQuery, '*');
    			}
    			if(type == 'col'){
    				var runQuery = {
		        	message: 'addCol',
		        	sindex: this.$attrs.sindex,
		        	rindex: rindex,
		        	data: data
    				};
    				parent.postMessage(runQuery, '*');
    			}
    			if(type == 'duplicate-col'){
    				var runQuery = {
		        	message: 'duplicateCol',
		        	sindex: this.$attrs.sindex,
		        	rindex: rindex,
		        	cindex: cindex,
		        	data: data
    				};
    				parent.postMessage(runQuery, '*');
    			}
    			if(type == 'delete-col'){
    				var runQuery = {
		        	message: 'deleteCol',
		        	sindex: this.$attrs.sindex,
		        	rindex: rindex,
		        	cindex: cindex,
		        	data: data
    				};
    				parent.postMessage(runQuery, '*');
    			}
    		}
		},
	}

</script>
<template>
	<b-row>
		<b-col md="12" v-if="preview == true">
			<b-row v-if="preview_type == 'webpage'">
			</b-row>
			<b-row v-if="preview_type == 'document'" class="bg-light">
				<div class="document-preview mx-auto">
					Here's a document
				</div>
			</b-row>
		</b-col>
		<b-col md="12" v-else>
			<div>
			 <ModalWindow :editModal="showFileManager" :panelClass="'product-modal d-flex flex-column justify-content-center'" @close-modal="showFileManager = !showFileManager" :formReponse="''">
			        <StorageWindow :user="$attrs.user" :company="$attrs.company" @close-window="showFileManager = false" @file-selected="fileSelected" :selectedFiles="selectedFiles" ></StorageWindow>
			      </ModalWindow>
			</div>
		<draggable handle=".sechandle"
  				:list="page[viewPortSize].sections"
                      ghost-class="ghost" @end="elementMoved('section', null, null, null, $event)" @mouseenter="showSectionTools = sindex" @mouseleave="showSectionTools = -1">
                      <div v-for="(section, sindex) in page[viewPortSize].sections" :class="section.styles[1].containerStyle.value" :style="compiledStyles(section.styles)" class="page-builder-element-outline position-relative" @dragover="moveSectionIndex = sindex">
                      <transition name="fade-top">
                      <span v-if="showSectionTools == sindex" class="d-flex flex-row sechandle z-index-7 position-absolute top-0 w-100 right-0 left-0">
                      	<span class="text-white bg-success top-0 mx-auto section-tab d-flex">
                      <span class="text-uppercase my-auto d-flex">
                      	<span class="my-auto mr-3">Section {{sindex + 1}} </span>
                      	<i class="mdi mdi-cursor-move my-auto"></i>
                      	</span>
                      	<a class="ml-1 my-auto d-flex" @click="moveSection(sindex, 'up')" >
												<i class="bx bx-chevron-up text-white font-size-16 my-auto"></i>
												</a>
												<a class="ml-1 my-auto d-flex" @click="moveSection(sindex, 'down')" >
												<i class="bx bx-chevron-down text-white font-size-16 mb-1"></i>
												</a>
                      <a v-b-tooltip.hover.bottom title="Duplicate Section" @click="duplicateSection(sindex, section)" :disabled="page[viewPortSize].sections.length < 2" class="mr-auto text-white" href="javascript:;"><i class="font-size-14 ml-1 mdi mdi-content-duplicate my-auto"></i></a>
                      <a v-b-tooltip.hover.bottom title="Delete Section" @click="deleteSection(sindex)" :disabled="page[viewPortSize].sections.length < 2" class="mr-auto text-white" href="javascript:;"><i class="font-size-14 ml-1 mdi mdi-close my-auto"></i></a>
                    	<a v-b-tooltip.hover.bottom title="Section Settings" @click="showTools('section', sindex, section.styles)" class="mx-auto text-white" href="javascript:;"><i class="font-size-14 ml-1 mdi mdi-cog my-auto"></i></a>
                    	
                    		</span>
                    	</span>
                    </transition>
                
			<div class="border"  @mouseleave="showRowTools = -1" @mouseenter="showSectionTools = sindex">
                  <draggable @end="elementMoved('row', sindex, null, null, $event)"
                      v-model="page[viewPortSize].sections[sindex].rows"
                      v-if="page[viewPortSize].sections[sindex].rows.length > 0"
                      handle=".handle"
                      group="rows"
                      ghost-class="ghost" >
                  <b-row v-for="(row, rindex) in page[viewPortSize].sections[sindex].rows" style="min-height: 100px; position: relative;" class="page-builder-element-outline handle" @mouseenter="showRowTools = ''+sindex+''+rindex+'', moveRow = rindex"  @mouseleave="showRowTools = -1" @dragover="moveRow = rindex" :class="{'border border-primary' : showRowTools == ''+sindex+''+rindex+''}" :style="compiledStyles(row.styles)">
                  	<transition name="fade-top">
                  	<span class="d-flex page-builder-tab text-white bg-primary row-tabs z-index-7" v-if="showRowTools == ''+sindex+''+rindex+''" >
	                    <span v-if="showRowTools == ''+sindex+''+rindex+''" class="my-auto">
	                    		<span class="my-auto mr-3">Row</span>
	                    	<i class="mdi mdi-cursor-move my-auto font-size-13"></i>
	                    
	                    	
	                    </span>
	                    <a v-b-tooltip.hover.bottom title="Duplicate Row" @click="duplicateRow(sindex, rindex, row)" class="mr-auto text-white" href="javascript:;"><i class="font-size-14 ml-1 mdi mdi-content-duplicate my-auto"></i></a>
	                    <a @click="deleteRow(sindex, rindex)" class="mx-auto text-white" href="javascript:;"><i class="font-size-14 ml-1 mdi mdi-close"></i></a>
	                    	<a @click="$emit('sidepanel-opened', 'row', sindex + '-' +rindex, row.styles)" class="mx-auto text-white" href="javascript:;"><i class="font-size-14 ml-1 mdi mdi-cog"></i></a>
	                    <span v-if="showRowTools == ''+sindex+''+rindex+''"  class="d-flex px-2">
	                    	

                    	</span>
                    </span>
                  </transition>
                  <transition name="fade-right">
                    <b-card class="page-builder-section-settings mb-0 shadow-none position-fixed top-0 bottom-0 right-0 px-0 shadow-none" body-class="px-0 pt-0" v-if="sectionSettings == rindex">
                    	<simplebar style="overflow-x: hidden;"  :style="{'max-height' : sidePanelMaxHeight+'px'}">
                    		<b-row>
                    			<b-col md="12" class="d-flex py-3 bg-primary">
                    				<h5 class="text-white my-auto ml-4">Row Options</h5>
                    				<a class="btn btn-primary btn-rounded ml-auto mr-3"  @click="sectionSettings = -1, row.styles = JSON.parse(JSON.stringify(sectionStyles_raw))"><i class="mdi mdi-close"></i></a>
                    			</b-col>
                    			<b-col md="12">
	                    				<b-tabs justified nav-class="nav-tabs-custom" content-class="pb-3 pt-0 text-muted">
	                    					<stylesTab :user="$attrs.user" :company="$attrs.company" :styles="row.styles" :widgetbox="false" @showFileManager="" :element="'row'" />
	                         

										            </b-tabs>
                    			</b-col>
                    		</b-row>
                    	</simplebar>
                    	<b-row class="row position-absolute bottom-0 right-0 left-0 bg-white py-3 z-index-1">
                    			<b-col md="6">
                    				<a class="btn btn-danger btn-rounded btn-block" @click="sectionSettings = -1, row.styles = JSON.parse(JSON.stringify(sectionStyles_raw))">
                    					Cancel
                    				</a>
                    			</b-col>
                    			<b-col md="6">
                    				<a class="btn btn-success btn-rounded btn-block" @click="sectionSettings = -1">
                    					Save
                    				</a>
                    			</b-col>
                    		</b-row>

                    </b-card>
                  </transition>
                    <draggable 
                      v-model="page[viewPortSize].sections[sindex].rows[rindex].cols"
                      handle=".handle2"
                      ghost-class="ghost"
                      group="cols"
                       v-if="(row.cols.length > 0)" v-for="(col, cindex) in row.cols"
                       @end="elementMoved('col', sindex, rindex, cindex, $event)"
                       tag="div"
                       :class="['col-xl-'+col.styles[filterStylesKey(col.styles, 'size')].size.xl+' col-lg-'+col.styles[filterStylesKey(col.styles, 'size')].size.lg+' col-md-'+col.styles[filterStylesKey(col.styles, 'size')].size.md+' col-sm-'+col.styles[filterStylesKey(col.styles, 'size')].size.sm+' col-xs-'+col.styles[filterStylesKey(col.styles, 'size')].size.xs, {'border border-info' : showColTools == ''+rindex + '' + cindex + ''}]"
                       class="page-builder-element-outline handle2"
                       :style="compiledStyles(col.styles)" >
                      <div @mouseenter="showColTools = ''+rindex + '' + cindex + '', showSectionTools = sindex, moveCol = cindex" @mouseleave="showColTools = -1" @dragover="moveCol = cindex">
                      <transition name="fade-top">
                      <span v-if="showColTools == ''+rindex + '' + cindex + ''"  class="d-flex page-builder-tools text-white bg-info col-tabs z-index-7">
                      	<span v-if="showColTools == ''+rindex + '' + cindex + ''" class="ml-auto">
                      		<span class="my-auto mr-3 font-size-11">Column</span>
                      		<i class="mdi mdi-cursor-move my-auto font-size-13"></i></span>
                      <span v-if="showColTools == ''+rindex + '' + cindex + ''" class="d-flex">
                      	<a v-b-tooltip.hover.bottom title="Duplicate Section" @click="duplicateColumn(sindex, rindex, cindex, col)" class="mr-auto text-white" href="javascript:;"><i class="font-size-14 ml-1 mdi mdi-content-duplicate my-auto"></i></a>
                      	<a @click="deleteColumn(sindex, rindex, cindex)" class="my-auto font-size-14 ml-1" href="javascript:;"><i class="mdi mdi-close"></i></a>
                      	<a @click="$emit('sidepanel-opened', 'col', sindex + '-' +rindex + '-' + cindex, col.styles)" class="my-auto font-size-14 ml-1" href="javascript:;"><i class="mdi mdi-cog"></i></a>
                      
                      	</span>
                      </span>
                    </transition>
                      <!--<transition name="fade-right">
                       <b-card class="page-builder-section-settings border-none shadow-none right-0 top-0 bottom-0 px-0 position-fixed mb-0 bg-white" body-class="px-0 pt-0" v-if="sectionSettings == rindex+'-'+cindex">
                    	<simplebar style="overflow-x: hidden;" :style="{'max-height' : sidePanelMaxHeight+'px'}">
                    		<b-row>
                    			<b-col md="12" class="d-flex py-3 bg-info">
                    				<h5 class="text-white my-auto ml-4">Column Options</h5>
                    				<a class="btn btn-info btn-rounded ml-auto mr-3"  @click="sectionSettings = -1, col.styles = JSON.parse(JSON.stringify(sectionStyles_raw))"><i class="mdi mdi-close"></i></a>
                    			</b-col>
                    			<b-col md="12">
	                    				<b-tabs justified nav-class="nav-tabs-custom info" nav-item-class="py-3" content-class="pb-3 pt-0 text-muted">
	                    					<stylesTab :user="$attrs.user" :company="$attrs.company" :styles="col.styles" :widgetbox="false" :element="'column'" />
	                         			

										            </b-tabs>
                    			</b-col>
                    		</b-row>
                    	</simplebar>
                    	<b-row class="row position-absolute bottom-0 right-0 left-0 bg-white py-3 z-index-1">
                    			<b-col md="6">
                    				<a class="btn btn-danger btn-rounded btn-block" @click="sectionSettings = -1, col.styles = JSON.parse(JSON.stringify(sectionStyles_raw))">
                    					Cancel
                    				</a>
                    			</b-col>
                    			<b-col md="6">
                    				<a class="btn btn-success btn-rounded btn-block" @click="sectionSettings = -1">
                    					Save
                    				</a>
                    			</b-col>
                    		</b-row>

                    </b-card>
                  </transition>-->
                      <draggable @end="elementMoved('block', sindex, rindex, cindex, $event)"

                      v-model="page[viewPortSize].sections[sindex].rows[rindex].cols[cindex].blocks"
                      handle=".handle3"
                      ghost-class="ghost"
                      group="widgets"
                      class="widget-pb-element"
											
                      >
                      <div v-if="col.blocks" class="widget-block position-relative handle3 border" v-for="(block, bindex) in col.blocks" :class="{'page-builder-element-outline': block.editable == 1, 'page-builder-element-outline': hoverElement == ''+rindex+''+cindex+''+bindex+'', 'border-white': hoverElement !== ''+rindex+''+cindex+''+bindex+''}" @mouseenter="hoverElement = ''+rindex+''+cindex+''+bindex+'', showColTools = ''+rindex + '' + cindex + '', moveElement = bindex" @mouseleave="hoverElement = ''" >
                      	
                      	<transition name="fade-top">
                      	<span v-if="hoverElement == ''+rindex+''+cindex+''+bindex+''" class="d-flex page-builder-tab text-white bg-secondary widget-tab z-index-9" :style="((bindex == 0) && (cindex == 0)) ? {'top' : '17px'} : {'top' : '0px'}">
                      		
                        <span class="my-auto mr-3 font-size-11" :class="{'invisible': hoverElement !== ''+rindex+''+cindex+''+bindex+''}">{{block.widgetname}} <i class="mdi mdi-cursor-move font-size-14 my-auto"></i></span><span class="text-white bg-secondary d-flex" :class="{'invisible': hoverElement !== ''+rindex+''+cindex+''+bindex+''}"><a v-b-tooltip.hover.bottom title="Duplicate Section" @click="duplicateWidget(sindex, rindex, cindex, bindex, block)" class="mr-auto text-white" href="javascript:;"><i class="font-size-14 ml-1 mdi mdi-content-duplicate my-auto"></i></a><a class="mr-auto text-white" href="javascript:;" @click="deleteWidget(sindex, rindex, cindex, bindex)"><i class="mdi mdi-close font-size-14 "></i></a><a class="mx-auto text-white" href="javascript:;" @click="editWidget(sindex, rindex, cindex, bindex)"><i class="mdi mdi-cog font-size-14 "></i></a></span>
                      		</span>
                        
                      </transition>
                    	<div v-if="(hoverElement == ''+rindex+''+cindex+''+bindex+'')"  v-on:dblclick="editWidget(sindex, rindex, cindex, bindex)" class="position-absolute top-0 left-0 right-0 bottom-0"></div>
                    	<b-overlay :show="loadingWidgetSettings" no-wrap variant="white" opacity="0">
                    		<template #overlay>
					<b-row>
	      			<b-col md="12" class="offset-md-2">
	      			<b-card class="border-radius-1em border shadow-none mb-0">	
			            <div class="cube-wrapper mx-auto"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
			  
			            <h6 class="text-center">Loading Widget</h6>
			             <b-progress class="w-50 mx-auto" :value="100" variant="primary"></b-progress>
	         		</b-card>
	      			</b-col>
	      			</b-row>
	      		</template>
                    	</b-overlay>
                    	<b-overlay :show="!block.widgetvisibility.size.includes(viewPortSize)" variant="white" opacity="1">
                    		<template #overlay>
					<b-row>
	      			<b-col md="12" class="offset-md-2">
	      			<b-card class="border-radius-1em border shadow-none mb-0" body-class="d-flex flex-column justify-content-center align-items-center">	
			            <i class="bx bx-hide font-size-16 text-center"></i>
			            <h6 class="text-center">Hidden on this device size</h6>
			             <span class="d-flex text-muted widget-tab z-index-9" :style="((bindex == 0) && (cindex == 0)) ? {'top' : '17px'} : {'top' : '0px'}">
                      		
                        <span class="my-auto mr-3 font-size-11" :class="{'invisible': hoverElement !== ''+rindex+''+cindex+''+bindex+''}">{{block.widgetname}} <i class="mdi mdi-cursor-move font-size-14 my-auto"></i></span><span class="text-muted d-flex" :class="{'invisible': hoverElement !== ''+rindex+''+cindex+''+bindex+''}"><a v-b-tooltip.hover.bottom title="Duplicate Section" @click="duplicateWidget(sindex, rindex, cindex, bindex, block)" class="mr-auto text-muted" href="javascript:;"><i class="font-size-14 ml-1 mdi mdi-content-duplicate my-auto"></i></a><a class="mr-auto text-muted" href="javascript:;" @click="deleteWidget(sindex, rindex, cindex, bindex)"><i class="mdi mdi-close font-size-14 "></i></a><a class="mx-auto text-muted" href="javascript:;" @click="editWidget(sindex, rindex, cindex, bindex)"><i class="mdi mdi-cog font-size-14 "></i></a></span>
                      		</span>
	         		</b-card>
	      			</b-col>
	      			</b-row>
	      		</template>
                    	
                        <div v-if="block.widgettype == 'text'">
                        	<TextWidget :id="'widget'+block.widgettype+'-'+sindex+''+rindex+''+cindex+''+bindex+''" :content="block.widgetcontent" :styles="block.widgetstyles" :configs="block.widgetconfigs" :style="compiledStyles(block.widgetstyles)" :company="$attrs.company" :user="$attrs.user" />
                        </div>
                        <div v-if="block.widgettype == 'button'" :style="'text-align: '+block.widgetcontent[filterStylesKey(block.widgetcontent, 'button_alignment')]['button_alignment'].value">
                        	<ButtonWidget :id="'widget'+block.widgettype+'-'+sindex+''+rindex+''+cindex+''+bindex+''" :content="block.widgetcontent" :styles="block.widgetstyles" :configs="block.widgetconfigs" :company="$attrs.company" :user="$attrs.user" :liveMode="false" />
                        </div>
                        <div v-if="block.widgettype == 'image'" :class="block.widgetcontent[filterStylesKey(block.widgetcontent, 'text_align')].text_align.value" >
                          <ImageWidget :id="'widget'+block.widgettype+'-'+sindex+''+rindex+''+cindex+''+bindex+''" :company="$attrs.company" :user="$attrs.user" :content="block.widgetcontent" :elements="block.widgetelements.active_elements"  :styles="block.widgetstyles" :style="compiledStyles(block.widgetstyles)" :liveMode="false" ></ImageWidget>
                        </div>
                        <div v-if="block.widgettype == 'address'">
                          [Store Address]
                        </div>
                        <div v-if="block.widgettype == 'customer-address'">
                          [Customer Address]
                        </div>
                        <div v-if="block.widgettype == 'spacer'" :style="{'height': block.widgetcontent[filterStylesKey(block.widgetcontent, 'spacer_height')].spacer_height.value+block.widgetcontent[filterStylesKey(block.widgetcontent, 'spacer_height')].spacer_height.units, 'width': block.widgetcontent[filterStylesKey(block.widgetcontent, 'spacer_width')].spacer_width.value+block.widgetcontent[filterStylesKey(block.widgetcontent, 'spacer_width')].spacer_width.units, 'max_height': block.widgetcontent[filterStylesKey(block.widgetcontent, 'spacer_max_height')].spacer_max_height.value+block.widgetcontent[filterStylesKey(block.widgetcontent, 'spacer_max_height')].spacer_max_height.units, 'max_width': block.widgetcontent[filterStylesKey(block.widgetcontent, 'spacer_max_width')].spacer_max_width.value+block.widgetcontent[filterStylesKey(block.widgetcontent, 'spacer_max_width')].spacer_max_width.units, 'background-color': block.widgetcontent[filterStylesKey(block.widgetcontent, 'spacer_background_colour')].spacer_background_colour.value+ '!important'}">
                        </div>
                        <hr v-if="block.widgettype == 'divider'" class="text-white" :style="{'border-style': block.widgetcontent[filterStylesKey(block.widgetcontent, 'bordertype')].bordertype.value, 'border-width': block.widgetcontent[filterStylesKey(block.widgetcontent, 'width')].width.value+block.widgetcontent[filterStylesKey(block.widgetcontent, 'width')].width.units, 'border-color': block.widgetcontent[filterStylesKey(block.widgetcontent, 'colour')].colour.value}">
                        <div v-if="block.widgettype == 'signature'">
                          <img :src="storageurl+block.widgetcontent.image">
                          <p class="mb-0">{{block.widgetcontent.firstname}} {{block.widgetcontent.lastname}}</p>
                          <strong>{{block.widgetcontent.companyname}}</strong>
                        </div>
                        <img v-if="block.widgettype == 'stamp'" :src="block.widgetcontent.url">
                        <div v-if="block.widgettype == 'note'" v-html="block.widgetcontent.content"></div>
                        		<div v-if="block.widgettype == 'slider'">
                        	 	<SliderWidget :id="'widget'+block.widgettype+'-'+sindex+''+rindex+''+cindex+''+bindex+''" :company="$attrs.company" :user="$attrs.user" :content="block.widgetcontent" :configs="block.widgetconfigs"  :styles="block.widgetstyles" :style="compiledStyles(block.widgetstyles)"  ></SliderWidget>
                            </div>
                             <div v-if="block.widgettype == 'imagegallery'">
                             	<ImageGalleryWidget :id="'widget'+block.widgettype+'-'+sindex+''+rindex+''+cindex+''+bindex+''" :company="$attrs.company" :user="$attrs.user" :content="block.widgetcontent"  :styles="block.widgetstyles" :style="compiledStyles(block.widgetstyles)" ></ImageGalleryWidget>
                            </div>
                            <div v-if="block.widgettype == 'cards'">
                             	<CardsListWidget :id="'widget'+block.widgettype+'-'+sindex+''+rindex+''+cindex+''+bindex+''" :liveMode="false" :company="$attrs.company" :user="$attrs.user" :content="block.widgetcontent"  :styles="block.widgetstyles" :elements="block.widgetelements.active_elements" :filters="block.widgetfilters.active_filters" :style="compiledStyles(block.widgetstyles)" ></CardsListWidget>
	                          </div>
                            <div v-if="block.widgettype == 'products'">
                            	<ProductsListWidget :id="'widget'+block.widgettype+'-'+sindex+''+rindex+''+cindex+''+bindex+''" :company="$attrs.company" :user="$attrs.user" :liveMode="false" :content="block.widgetcontent" :configs="block.widgetconfigs" :filters="block.widgetfilters.active_filters" :elements="block.widgetelements.active_elements" :style="compiledStyles(block.widgetstyles)" :currencies="$attrs.currencies" :exchange_rates="$attrs.exchange_rates" :selected_currency="$attrs.selected_currency" :selected_currency_symbol="$attrs.selected_currency_symbol" :viewPortSize="viewPortSize" :page="page" :block_array="[sindex,rindex,cindex,bindex]" ></ProductsListWidget>
                            </div>
                            <div v-if="block.widgettype == 'categories'">
                            	<CategoryListWidget :resource="block.widgettype" :id="'widget'+block.widgettype+'-'+sindex+''+rindex+''+cindex+''+bindex+''" :company="$attrs.company" :user="$attrs.user" :liveMode="false" :content="block.widgetcontent" :configs="block.widgetconfigs" :filters="block.widgetfilters.active_filters" :elements="block.widgetelements.active_elements" :style="compiledStyles(block.widgetstyles)" :page="page" :block_array="[sindex,rindex,cindex,bindex]"  ></CategoryListWidget>
                            </div>
                            <div v-if="block.widgettype == 'collections'">
                            	<CategoryListWidget :resource="block.widgettype" :id="'widget'+block.widgettype+'-'+sindex+''+rindex+''+cindex+''+bindex+''" :company="$attrs.company" :user="$attrs.user" :liveMode="false" :content="block.widgetcontent" :configs="block.widgetconfigs" :filters="block.widgetfilters.active_filters" :elements="block.widgetelements.active_elements" :style="compiledStyles(block.widgetstyles)"  :page="page" :block_array="[sindex,rindex,cindex,bindex]" ></CategoryListWidget>
                            </div>
                            <div v-if="(block.widgettype == 'listings') || (block.widgettype == 'listingsmap')">
                            	<ListingWidget :resource="block.widgettype" :id="'widget'+block.widgettype+'-'+sindex+''+rindex+''+cindex+''+bindex+''" :company="$attrs.company" :user="$attrs.user" :liveMode="false" :content="block.widgetcontent" :configs="block.widgetconfigs" :filters="block.widgetfilters.active_filters" :elements="block.widgetelements.active_elements" :style="compiledStyles(block.widgetstyles)" :block_array="[sindex,rindex,cindex,bindex]" :page="page" ></ListingWidget>
                            </div>
                            <div v-if="block.widgettype == 'map'">
                            	<MapWidget :resource="block.widgettype" :id="'widget'+block.widgettype+'-'+sindex+''+rindex+''+cindex+''+bindex+''" :company="$attrs.company" :user="$attrs.user" :liveMode="false" :content="block.widgetcontent" :configs="block.widgetconfigs" :elements="block.widgetelements.active_elements" :style="compiledStyles(block.widgetstyles)"></MapWidget>
                            </div>
                            <div v-if="block.widgettype == 'social'">
                            	<SocialWidget :action="block.widgetaction" :id="'widget'+block.widgettype+'-'+sindex+''+rindex+''+cindex+''+bindex+''" :company="$attrs.company" :user="$attrs.user" :liveMode="false" :content="block.widgetcontent" :configs="block.widgetconfigs" :style="compiledStyles(block.widgetstyles)"></SocialWidget>
                            </div>
                            <div v-if="block.widgettype == 'search'">
                            	<SearchWidget :action="block.widgetaction" :id="'widget'+block.widgettype+'-'+sindex+''+rindex+''+cindex+''+bindex+''" :company="$attrs.company" :user="$attrs.user" :liveMode="false" :content="block.widgetcontent" :configs="block.widgetconfigs" :filters="block.widgetfilters.active_filters" :elements="block.widgetelements.active_elements" :style="compiledStyles(block.widgetstyles)" :currencies="$attrs.currencies" :exchange_rates="$attrs.exchange_rates" :selected_currency="$attrs.selected_currency" :selected_currency_symbol="$attrs.selected_currency_symbol" ></SearchWidget>
                            </div>
                            <div v-if="block.widgettype == 'form'">
                            	 <FormWidget :action="block.widgetaction" :id="'widget'+block.widgettype+'-'+sindex+''+rindex+''+cindex+''+bindex+''" :company="$attrs.company" :user="$attrs.user" :liveMode="false" :content="block.widgetcontent" :configs="block.widgetconfigs" :style="compiledStyles(block.widgetstyles)" ></FormWidget>
                            </div>
                            <div v-if="block.widgettype == 'subscription-form'">
                            	 <SubscriptionWidget :action="block.widgetaction" :id="'widget'+block.widgettype+'-'+sindex+''+rindex+''+cindex+''+bindex+''" :company="$attrs.company" :user="$attrs.user" :liveMode="false" :content="block.widgetcontent" :configs="block.widgetconfigs" :style="compiledStyles(block.widgetstyles)" ></SubscriptionWidget>
                            </div>
                            <div v-if="block.widgettype == 'menu'" v-on:dblclick="editWidget(sindex, rindex, cindex, bindex)">
                            		<WebsiteMenus ref="WebsiteMenus" :id="'widget'+block.widgettype+'-'+sindex+''+rindex+''+cindex+''+bindex+''" :company="$attrs.company" :user="$attrs.user" :nickname="$attrs.company.nickname" :logo="storageurl+$attrs.company.logo" :content="block.widgetcontent"  :styles="block.widgetstyles" :filters="block.widgetfilters.active_filters" :elements="block.widgetelements.active_elements" :style="compiledStyles(block.widgetstyles)" :currencies="$attrs.currencies" :exchange_rates="$attrs.exchange_rates" :selected_currency="$attrs.selected_currency" :selected_currency_symbol="$attrs.selected_currency_symbol"></WebsiteMenus>
                            </div>
                       			<div id="template-content-area" v-if="templateArea(block.widgettype)">
                       				<div v-if="block.widgetcontent.length > 0">
                       							<div v-if="block.widgettype == 'category-image'" :class="block.widgetcontent[filterStylesKey(block.widgetcontent, 'image_text_align')].image_text_align.value">
				                          <CategoryImageWidget :id="'widget'+block.widgettype+'-'+sindex+''+rindex+''+cindex+''+bindex+''" :company="$attrs.company" :user="$attrs.user" :content="block.widgetcontent"  :styles="block.widgetstyles" :style="compiledStyles(block.widgetstyles)" :widget="block" :liveMode="isLive"></CategoryImageWidget>
				                        
                       					</div>
                       					<div v-else-if="block.widgettype == 'category-title'">
                       						<CategoryTitle :id="'widget'+block.widgettype+'-'+sindex+''+rindex+''+cindex+''+bindex+''" :company="$attrs.company" :user="$attrs.user" :content="block.widgetcontent" :widgetcontent="block.widgetcontent" :widget="block"  :styles="block.widgetstyles" :style="compiledStyles(block.widgetstyles)" :product="{name: 'Dynamic Category Title'}" :liveMode="isLive" ></CategoryTitle>
                       					</div>
                       					<div v-else-if="block.widgettype == 'category-description'">
                       						<CategoryDescription :id="'widget'+block.widgettype+'-'+sindex+''+rindex+''+cindex+''+bindex+''" :company="$attrs.company" :user="$attrs.user" :content="block.widgetcontent" :widget="block"  :styles="block.widgetstyles" :style="compiledStyles(block.widgetstyles)" :product="{description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'}" :liveMode="isLive"></CategoryDescription>
                       					</div>
                       					<div v-else-if="block.widgettype == 'subcategory-grid'">
                       						<SubcategoryGrid :id="'widget'+block.widgettype+'-'+sindex+''+rindex+''+cindex+''+bindex+''" :company="$attrs.company" :user="$attrs.user" :content="block.widgetcontent"  :styles="block.widgetstyles" :style="compiledStyles(block.widgetstyles)" :compiledStyles="compiledStyles(block.widgetstyles)" :liveMode="isLive" ></SubcategoryGrid>
                       					</div>
                       					<div v-else-if="block.widgettype == 'category-grid'">
                       						<CategoryGrid :id="'widget'+block.widgettype+'-'+sindex+''+rindex+''+cindex+''+bindex+''" v-if="block.widgettype == 'category-grid'" :company="$attrs.company" :user="$attrs.user" :liveMode="isLive" :content="block.widgetcontent" :configs="block.widgetconfigs" :filters="block.widgetfilters.active_filters" :elements="block.widgetelements.active_elements" :style="compiledStyles(block.widgetstyles)" :exchange_rates="$attrs.exchange_rates"  :currencies="$attrs.currencies" :selected_currency="$attrs.selected_currency" :selected_currency_symbol="$attrs.selected_currency_symbol" :product="resource_result" ></CategoryGrid>
                       					</div>
                       					<div v-else-if="block.widgettype == 'category-filters'">
                       						<CategoryFilters :id="'widget'+block.widgettype+'-'+sindex+''+rindex+''+cindex+''+bindex+''" v-if="block.widgettype == 'category-filters'" :company="$attrs.company" :user="$attrs.user" :liveMode="isLive" :content="block.widgetcontent" :configs="block.widgetconfigs" :filters="block.widgetfilters.active_filters" :style="compiledStyles(block.widgetstyles)" :exchange_rates="$attrs.exchange_rates"  :currencies="$attrs.currencies" :selected_currency="$attrs.selected_currency" :selected_currency_symbol="$attrs.selected_currency_symbol" :product="resource_result" ></CategoryFilters>
                       					</div>
                       					<div v-else-if="block.widgettype == 'product-reference'">
                       						<ProductReference :id="'widget'+block.widgettype+'-'+sindex+''+rindex+''+cindex+''+bindex+''" :company="$attrs.company" :user="$attrs.user" :content="block.widgetcontent" :widgetcontent="block.widgetcontent" :widget="block"  :styles="block.widgetstyles" :style="compiledStyles(block.widgetstyles)" :product="{reference: 'SKU000123'}" :liveMode="isLive" ></ProductReference>
                       					</div>
                       					<div v-else-if="block.widgettype == 'product-title-block'">
                       						<ProductTitle :id="'widget'+block.widgettype+'-'+sindex+''+rindex+''+cindex+''+bindex+''" :company="$attrs.company" :user="$attrs.user" :content="block.widgetcontent" :widgetcontent="block.widgetcontent" :widget="block"  :styles="block.widgetstyles" :style="compiledStyles(block.widgetstyles)" :product="{name: 'Dynamic Product Title'}" :liveMode="isLive" ></ProductTitle>
                       					</div>
                       					<div v-else-if="(block.widgetplaceholder !== '')" v-html="block.widgetplaceholder">
                       					</div>
                       				</div>
                       				
                       				<b-card class="border-radius-1em border shadow-none" body-class="d-flex flex-column justify-content-center align-items-center" style="min-height: 300px;" v-else-if="block.widgetplaceholder == '' || block.widgetplaceholder == null">
                       					<i class="font-size-48 text-primary" :class="block.widgeticon"></i>
                       					<h4 class="text-muted">{{block.widgetname}}</h4>
                       				</b-card>
                       				<div v-else-if="block.widgetcontent.length == 0" v-html="block.widgetplaceholder">
                       				</div>
                       				

                       			</div>
                       </b-overlay>
                      </div>
                    </draggable>

                      <div class="position-relative" @mouseenter="showColTools = ''+rindex + '' + cindex + ''" >
                      	<transition name="fade-top">
                        <div class="w-100 d-flex h-100" :style="{minHeight: ( col.blocks.length == 0 ? '100px' : '0px')}" >
                        <a v-if="widgetBox !== ''+rindex + '' + cindex + ''" v-b-tooltip.hover title="Add Widget" class="btn btn-secondary btn-rounded m-auto" href="javascript:;" :style="{position: ( col.blocks.length == 0 ? 'relative' : 'absolute'), bottom: ( col.blocks.length == 0 ? 'unset' : '-15px'), zIndex: ( col.blocks.length == 0 ? '2' : '5')}" @click="widgetBox = ''+rindex + '' + cindex + '', newWidgetOptionsStorage('', sindex, rindex, cindex)"><i class="mdi mdi-plus"></i></a>
                        <a v-else class="btn btn-secondary btn-rounded m-auto" href="javascript:;" @click="widgetBox = '', widgetBoxEditMode = false, widgetEditBlockIndex = null, widgetBoxEditContent = '', $emit('close-widget-editbox')"  :style="{position: ( col.blocks.length == 0 ? 'relative' : 'absolute'), bottom: ( col.blocks.length == 0 ? 'unset' : '-15px'), zIndex: ( col.blocks.length == 0 ? '2' : '5')}" ><i class="mdi mdi-close noti-icon font-size-14"></i></a>
                        </div>
                      </transition>
                       <!--<widgetEditBoxView :widgetBoxEditContent="widgetBoxEditContent" :widgetBox="widgetBox" :rindex="rindex" :cindex="cindex" :widgetBoxLoading="widgetBoxLoading" :widgetBoxEditMode="widgetBoxEditMode" :widgetBoxSubForm="widgetBoxSubForm" :filteredWidgets="filteredWidgets" :fileResource="fileResource" :fileRowIndex="fileRowIndex" :fileColIndex="fileColIndex" :showFileManager="showFileManager" :user="$attrs.user" :company="$attrs.company" :data="data" :selectedFiles="selectedFiles" :widgets="widgets" :template_areas="template_areas" :sidePanelMaxHeight="sidePanelMaxHeight" :widgetStyles="widgetStyles" :widgetSearch="widgetSearch"></widgetEditBoxView>-->
                      
                      </div>
                      </div>
                  </draggable>
                  	<transition name="fade-top">
                    <b-col md="12" class="d-flex text-center" :class="[{'mt-4' : (sindex == 0) && (page[viewPortSize].sections[sindex].rows.length == 0)}, {'position-absolute z-index-3 bottom-0' : (showRowTools == ''+sindex+''+rindex+'') && (page[viewPortSize].sections[sindex].rows.length !== 0)}]" :style="[{'marginBottom': '-20px'}]" @mouseenter="showRowTools = ''+rindex+''" v-if="((showRowTools == ''+sindex+''+rindex+'') && (page[viewPortSize].sections[sindex].rows.length > 1)) && (showRowTools !== (page[viewPortSize].sections[sindex].rows.length - 1))">
                    	
                    	<a  v-b-tooltip.hover title="Add New Row" v-if="(page[viewPortSize].sections[sindex].rows.length < row_limit) || (row_limit == -1)" class="btn btn-primary btn-rounded my-auto ml-2" :class="{'mr-auto' : page[viewPortSize].sections[sindex].rows.length == 0}" @click="page[viewPortSize].sections[sindex].rows.push(JSON.parse(JSON.stringify(row_default)))"><i class="mdi mdi-plus"></i></a>
		                  <a v-if="(page[viewPortSize].sections[sindex].rows.length >= row_limit) && (row_limit !== -1)" class="btn btn-primary btn-rounded m-auto" @click="limitReached('row', row_limit)"><i class="mdi mdi-plus"></i></a>
		                  <a class="btn btn-primary ml-auto page-builder-preview-btn btn-rounded" v-if="allow_preview" @click="preview = !preview" href="javascript:;"><i v-if="preview == false" class="mdi mdi-eye font-size-14"></i><i v-else class="mdi mdi-close font-size-14"></i></a>
                    </b-col>
                  </transition>
                  <transition name="fade-top">
                    <b-col md="10" class="text-center d-flex mx-auto"  v-if="('cols' in row) && ((row.cols.length < col_limit) && ((row.cols.length == 0) || showRowTools == ''+sindex+''+rindex+''))" :style="(row.cols.length == 0) ? {'minHeight' : '100px'} : {'marginBottom' : '-20px'}" :class="{'position-absolute z-index-4 bottom-0 left-0 right-0' : (showRowTools == ''+sindex+''+rindex+'') && (page[viewPortSize].sections[sindex].rows.length !== 0)}">
                    <a v-b-tooltip.hover title="Add Columns" class="btn btn-info btn-rounded m-auto" href="javascript:;" @click="showColOptions = ''+sindex+''+rindex+''" v-if="showColOptions !== ''+sindex+''+rindex+''"><i class="mdi mdi-plus"></i></a>
                    <a v-b-tooltip.hover title="Close" class="btn btn-info btn-rounded m-auto z-index-7" href="javascript:;" @click="showColOptions = ''" v-else><i class="mdi mdi-close noti-icon font-size-14"></i></a>
                    <b-row class="page-builder-column-select rounded-3" :style="(row.cols.length == 0) ? {'top' : '70px'} : {'top' : '25px'}">
                      <b-col md="8" class="text-center mx-auto bg-light border-radius-1em shadow-lg"  v-if="(row.cols.length < col_limit) && (showColOptions == ''+sindex+''+rindex+'')">
                      	<h6 class="mt-3 mb-0">Column Layouts</h6>
                      <a class="btn btn-info mx-auto my-3" @click="col_default.styles[4].size = col_default.styles[17].column_templates.oneColSize, addColumns(sindex, rindex, col_default), showColOptions = -1">Add 1 Column<div  class="d-flex w-100"><b-col md="12" class="border mock-column"></b-col></div></a>
                      <a class="btn btn-info mx-auto my-3" @click="col_default.styles[4].size = col_default.styles[17].column_templates.twoColSize, addColumns(sindex, rindex, col_default), addColumns(sindex, rindex, col_default), showColOptions = -1">Add 2 Columns<div  class="d-flex w-100"><b-col md="6" class="border mock-column"></b-col><b-col md="6" class="border mock-column"></b-col></div></a>
                      <a class="btn btn-info mx-auto my-3" @click="col_default.styles[4].size = col_default.styles[17].column_templates.threeColSize, addColumns(sindex, rindex, col_default), addColumns(sindex, rindex, col_default), addColumns(sindex, rindex, col_default), showColOptions = -1">Add 3 Columns<div  class="d-flex w-100"><b-col md="4" class="border mock-column"></b-col><b-col md="4" class="border mock-column"></b-col><b-col md="4" class="border mock-column"></b-col></div></a>
                      <a class="btn btn-info mx-auto my-3" @click="col_default.styles[4].size = col_default.styles[17].column_templates.fourColSize, addColumns(sindex, rindex, col_default), addColumns(sindex, rindex, col_default), addColumns(sindex, rindex, col_default), addColumns(sindex, rindex, col_default), showColOptions = -1">Add 4 Columns<div  class="d-flex w-100"><b-col md="3" class="border mock-column"></b-col><b-col md="3" class="border mock-column"></b-col><b-col md="3" class="border mock-column"></b-col><b-col md="3" class="border mock-column"></b-col></div></a>
                      <h6 class="mb-0">Custom Layouts</h6>
                      <a class="btn btn-info mx-auto my-3" @click="col_default.styles[4].size = col_default.styles[17].column_templates.oneColEightSize, addColumns(sindex, rindex, col_default), col_default.styles[4].size = col_default.styles[17].column_templates.threeColSize, addColumns(sindex, rindex, col_default)">Custom Columns <div  class="d-flex w-100"><b-col md="8" class="border mock-column"></b-col><b-col md="4" class="border mock-column"></b-col></div></a>
                      <a class="btn btn-info mx-auto my-3" @click="col_default.styles[4].size = col_default.styles[17].column_templates.threeColSize, addColumns(sindex, rindex, col_default), col_default.styles[4].size = col_default.styles[17].column_templates.oneColEightSize, addColumns(sindex, rindex, col_default)">Custom Columns <div  class="d-flex w-100"><b-col md="4" class="border mock-column"></b-col><b-col md="8" class="border mock-column"></b-col></div></a>
                      <a class="btn btn-info mx-auto my-3" @click="col_default.styles[4].size = col_default.styles[17].column_templates.twoColSize, addColumns(sindex, rindex, col_default), col_default.styles[4].size = col_default.styles[17].column_templates.fourColSize, addColumns(sindex, rindex, col_default), addColumns(sindex, rindex, col_default)">Custom Columns <div  class="d-flex w-100"><b-col md="6" class="border mock-column"></b-col><b-col md="3" class="border mock-column"></b-col><b-col md="3" class="border mock-column"></b-col></div></a>
                      <!--<a class="btn btn-info mx-auto my-3" @click="page[viewPortSize].sections[sindex].rows[rindex].cols.push(JSON.parse(JSON.stringify(col_default))), page[viewPortSize].sections[sindex].rows[rindex].cols.push(JSON.parse(JSON.stringify(col_default))), page[viewPortSize].sections[sindex].rows[rindex].cols.push(JSON.parse(JSON.stringify(col_default))), page[viewPortSize].sections[sindex].rows[rindex].cols.push(JSON.parse(JSON.stringify(col_default))), page[viewPortSize].sections[sindex].rows[rindex].cols.push(JSON.parse(JSON.stringify(col_default))), showColOptions = -1">Add 5 Columns</a>-->
                    </b-col>
                    </b-row>
                    </b-col>
                  </transition>
                  </b-row>
                  </draggable>
                  <b-row @mouseenter="showSectionTools = sindex">
                  	<transition name="fade-top">
                    <b-col md="12" class="d-flex text-center" :class="[{'mt-4' : (sindex == 0) && (page[viewPortSize].sections[sindex].rows.length == 0)}, {'position-absolute z-index-3' : (showSectionTools == sindex) && (page[viewPortSize].sections[sindex].rows.length !== 0)}]" :style="[(page[viewPortSize].sections[sindex].rows.length == 0) ? {'minHeight': '125px'} : {'minHeight': '0px'}, ((showSectionTools == sindex) && (page[viewPortSize].sections[sindex].rows.length !== 0)) ? {'marginTop': '-20px'} : {'marginTop': '0px'}]" @mouseenter="showSectionTools = sindex" v-if="(showSectionTools == sindex) || (page[viewPortSize].sections[sindex].rows.length == 0)">
                    	<a  v-b-tooltip.hover title="Add New Section" class="btn btn-success bg-soft btn-rounded ml-auto my-auto mr-2" @click="addSection()"><i class="mdi mdi-plus"></i></a>
		                  <a  v-b-tooltip.hover title="Add New Row" v-if="(page[viewPortSize].sections[sindex].rows.length < row_limit) || (row_limit == -1)" class="btn btn-primary btn-rounded my-auto ml-2" :class="{'mr-auto' : page[viewPortSize].sections[sindex].rows.length == 0}" @click="addRow(sindex)"><i class="mdi mdi-plus"></i></a>
		                  
		                  <a v-if="(page[viewPortSize].sections[sindex].rows.length >= row_limit) && (row_limit !== -1)" class="btn btn-primary btn-rounded m-auto" @click="limitReached('row', row_limit)"><i class="mdi mdi-plus"></i></a>
		                  <a class="btn btn-primary ml-auto page-builder-preview-btn btn-rounded" v-if="allow_preview" @click="preview = !preview" href="javascript:;"><i v-if="preview == false" class="mdi mdi-eye font-size-14"></i><i v-else class="mdi mdi-close font-size-14"></i></a>
                    </b-col>
                  </transition>
                  </b-row>
                </div>
            </div>
        </draggable>
            </b-col>
        </b-row>
</template>