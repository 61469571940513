<template>
	<div class="widget-box shadow-sm bg-white px-0 py-0" v-if="widgetBox === ''+rindex + '' + cindex + ''" >
		<widgetViewportSwitcher v-if="(widgetBoxEditMode == true) && (widgetEditBlockIndex !== null)" :viewPortSize="$attrs.viewPortSize" :windowWidth="$attrs.windowContentWidth" @copy-to-viewport="copyViewportEvent" :area="'widget'" ></widgetViewportSwitcher>
                        <div>
			 <ModalWindow :editModal="showFileManager" :panelClass="'product-modal d-flex flex-column justify-content-center'" @close-modal="showFileManager = !showFileManager" :formReponse="''">
			        <StorageWindow :user="user" :company="company" @close-window="showFileManager = false" @file-selected="fileSelected" :selectedFiles="selectedFiles" ></StorageWindow>
			      </ModalWindow>
			</div>
                      <div class="d-flex w-100  border-primary border-bottom text-center bg-primary py-3">
                          <h5 class="text-white my-auto ml-4">Add <span v-if="widgetBoxEditMode == true">{{widgetBoxEditContent.widgetname}}</span> Widget</h5>
                          <a class="btn btn-primary btn-rounded ml-auto my-auto mr-4" href="javascript:;" @click="widgetBox = '', widgetBoxEditMode = false, widgetEditBlockIndex = null, widgetBoxEditContent = [], $emit('close-widget-editbox')"><i class="mdi mdi-close noti-icon font-size-14"></i></a>
                        </div>
                 	<b-overlay :show="widgetBoxLoading" no-wrap  variant="white" opacity="0.95">
						<template #overlay>
					<b-row>
	      			<b-col md="12" class="offset-md-2" >
	      			<b-card class="border-radius-1em border shadow-none mb-0">	
			            <div class="cube-wrapper mx-auto"><div class="cube-folding"><span class="leaf1"></span><span class="leaf2"></span><span class="leaf3"></span><span class="leaf4"></span></div></div>
			  
			            <h6 class="text-center">Loading Module</h6>
			             <b-progress class="w-50 mx-auto" :value="100" variant="primary"></b-progress>
	         		</b-card>
	      			</b-col>
	      			</b-row>
	      		</template>
                 	</b-overlay>
                    <div  v-if="widgetBoxEditMode == false">
                  
                    	
                    	<b-tabs pills align="center" class="pt-3">
                    		<b-tab title="Widgets">
                    			  <simplebar style="min-height: 400px; overflow-x: hidden;" :style="{'max-height' : (sidePanelMaxHeight ) +'px'}">	
                    			<b-row class="w-100 pt-3 px-3 mx-auto">
                    		<b-col md="12">
                    			 <div class="mt-sm-0 form-inline w-100" >
					                  <div class="search-box m-0 ml-auto">
					                    <div class="position-relative">
					                      <input
					                        type="text"
					                        class="form-control w-100 border-0 bg-light"
					                        placeholder="Search Widget Name..."
					                        v-model="widgetSearch"
					                        @keydown.enter.prevent
					                      />
					                      <i class="bx bx-search-alt search-icon"></i>
					                    </div>
					                  </div>
					                </div>
                    		</b-col>
                    	</b-row>
                    	<b-row class="w-100 pt-3 px-3 mx-auto">
                    		
                    		<b-col md="6"  v-for="widget in filteredWidgets">
                   		<b-button
                      class="btn flex-grow-1 w-lg py-4 mb-3 btn-white border shadow-sm border-radius-1em"
                      value="0"
                      variant="white"
                      @click="widgetBoxLoading = true, newWidgetOptions(widget)"
                    ><i class="font-size-24 d-block text-primary my-2" :class="widget.widgeticon"></i> 
                     {{widget.widgetname}}
                    </b-button>
                  		</b-col>
                  	</b-row>
                  	</simplebar>
                  		</b-tab>
                  		<b-tab title="Template Areas" v-if="filteredTemplateWidgets.length > 0">
                  			 <simplebar style="min-height: 400px; overflow-x: hidden;" :style="{'max-height' : (sidePanelMaxHeight ) +'px'}">	
                  			<b-row class="w-100 pt-3 px-3 mx-auto">
                    		<b-col md="12">
                    			 <div class="mt-sm-0 form-inline w-100" >
					                  <div class="search-box m-0 ml-auto">
					                    <div class="position-relative">
					                      <input
					                        type="text"
					                        class="form-control w-100 border-0 bg-light"
					                        placeholder="Search Widget Name..."
					                        v-model="widgetSearch"
					                        @keydown.enter.prevent
					                      />
					                      <i class="bx bx-search-alt search-icon"></i>
					                    </div>
					                  </div>
					                </div>
                    		</b-col>
                    	</b-row>
                  	<b-row class="w-100 pt-3 px-3 mx-auto" v-if="filteredTemplateWidgets.length > 0">
                    	
                    		<b-col md="6"  v-for="widget in filteredTemplateWidgets">
                   		<b-button
                      class="btn flex-grow-1 w-lg py-4 mb-3 btn-white border shadow-sm border-radius-1em"
                      value="0"
                      variant="white"
                      @click="widgetBoxLoading = true, newWidgetOptions(widget)"
                    ><i class="font-size-24 d-block text-primary my-2" :class="widget.widgeticon"></i> 
                     {{widget.widgetname}}
                    </b-button>
                  		</b-col>
                  	</b-row>
                  </simplebar>
                  </b-tab>
                </b-tabs>
                  
                  	</div>
                    <div v-if="widgetBoxEditMode == true" class="pt-3 position-relative">

                        <div v-if="widgetBoxEditContent.widgettype == 'text'">
                        	<b-tabs justified nav-class="nav-tabs-custom" content-class="text-muted">
                         		<b-tab >
                         			 <template v-slot:title>
									                  <span class="d-inline-block d-sm-none">
									                    <i class="bx bx-notepad"></i>
									                  </span>
									                  <span class="d-none d-sm-inline-block">Content</span>
									                </template>
									       <b-form-group label="Title" class="px-3 py-3" >
									        <b-form-input v-model="widgetBoxEditContent.widgetcontent[filterStylesKey(widgetBoxEditContent.widgetcontent , 'title')].title.value"></b-form-input>
									      	</b-form-group>
                          <b-form-group label="Body text" class="px-3 pb-3" >
                             <ckeditor id="issue" v-model="widgetBoxEditContent.widgetcontent[filterStylesKey(widgetBoxEditContent.widgetcontent , 'text')].text.value" :editor="editor" rows="6" ></ckeditor>
                          </b-form-group>
                        </b-tab>
                        <b-tab >
                         			 <template v-slot:title>
									                  <span class="d-inline-block d-sm-none">
									                    <i class="bx bx-notepad"></i>
									                  </span>
									                  <span class="d-none d-sm-inline-block">Configs</span>
									                </template>
									                <widgetConfigsTab :user="user" :company="company" :widgetbox="true" :configs="widgetBoxEditContent.widgetconfigs" :content="widgetBoxEditContent.widgetcontent" />
									        </b-tab>
									        <stylesTab :user="user" :company="company" :styles="widgetBoxEditContent.widgetstyles"  />
									        <visibilityTab @visibility-updated="updateVisibility" :user="user" :company="company" :visibility="widgetBoxEditContent.widgetvisibility" />
                      </b-tabs>

                         </div>
                         <div v-if="widgetBoxEditContent.widgettype == 'button'">
                        	<b-tabs justified nav-class="nav-tabs-custom" content-class="text-muted">
                         		<b-tab >
                         			 <template v-slot:title>
									                  <span class="d-inline-block d-sm-none">
									                    <i class="bx bx-notepad"></i>
									                  </span>
									                  <span class="d-none d-sm-inline-block">Content</span>
									                </template>
									       <b-form-group label="Button Text" class="px-3 py-3" >
									        <b-form-input v-model="widgetBoxEditContent.widgetcontent[filterStylesKey(widgetBoxEditContent.widgetcontent , 'text')].text.value"></b-form-input>
									      	</b-form-group>
                          <b-form-group label="Button Link" class="px-3 pb-3" >
                               <b-form-input v-model="widgetBoxEditContent.widgetcontent[filterStylesKey(widgetBoxEditContent.widgetcontent , 'url')].url.value"></b-form-input>
									     
                          </b-form-group>
                        </b-tab>
                        <b-tab >
                         			 <template v-slot:title>
									                  <span class="d-inline-block d-sm-none">
									                    <i class="bx bx-notepad"></i>
									                  </span>
									                  <span class="d-none d-sm-inline-block">Configs</span>
									                </template>
									                <widgetConfigsTab :user="user" :company="company" :widgetbox="true" :configs="widgetBoxEditContent.widgetconfigs" :content="widgetBoxEditContent.widgetcontent" />
									        </b-tab>
									        <stylesTab :user="user" :company="company" :styles="widgetBoxEditContent.widgetstyles"  />
									        <visibilityTab @visibility-updated="updateVisibility" :user="user" :company="company" :visibility="widgetBoxEditContent.widgetvisibility" />
                      </b-tabs>

                         </div>
                         <div v-if="widgetBoxEditContent.widgettype == 'divider'">
                         	<b-tabs justified nav-class="nav-tabs-custom" content-class="text-muted">
                         		<b-tab >
                         			 <template v-slot:title>
									                  <span class="d-inline-block d-sm-none">
									                    <i class="bx bx-notepad"></i>
									                  </span>
									                  <span class="d-none d-sm-inline-block">Content</span>
									                </template>
									                <widgetConfigsTab :user="user" :company="company" :widgetbox="true" :configs="widgetBoxEditContent.widgetconfigs" :content="widgetBoxEditContent.widgetcontent" />
					                      </b-tab>
					                      <stylesTab :user="user" :company="company" :styles="widgetBoxEditContent.widgetstyles"  />
					                      <visibilityTab @visibility-updated="updateVisibility" :user="user" :company="company" :visibility="widgetBoxEditContent.widgetvisibility" />
                    </b-tabs>
                          </div>
                          <div v-if="widgetBoxEditContent.widgettype == 'spacer'">
                          	<b-tabs justified nav-class="nav-tabs-custom" content-class="text-muted">
                         		<b-tab >
                         			 <template v-slot:title>
									                  <span class="d-inline-block d-sm-none">
									                    <i class="bx bx-notepad"></i>
									                  </span>
									                  <span class="d-none d-sm-inline-block">Content</span>
									                </template>

									                  <widgetConfigsTab :user="user" :company="company" :widgetbox="true" :configs="widgetBoxEditContent.widgetconfigs" :content="widgetBoxEditContent.widgetcontent" />
                        </b-tab>
                        <stylesTab :user="user" :company="company" :styles="widgetBoxEditContent.widgetstyles"  />
                        <visibilityTab @visibility-updated="updateVisibility" :user="user" :company="company" :visibility="widgetBoxEditContent.widgetvisibility" />
                      </b-tabs>
                          </div>
                         <div v-if="widgetBoxEditContent.widgettype == 'menu'">
                         	<b-tabs justified nav-class="nav-tabs-custom" content-class="p-0 text-muted">
                         		<b-tab >
                         			 <template v-slot:title>
									                  <span class="d-inline-block d-sm-none">
									                    <i class="bx bx-notepad"></i>
									                  </span>
									                  <span class="d-none d-sm-inline-block">Content</span>
									                </template>
									               <div class="widget-inner-scrollbox"  :style="{'max-height' : sidePanelMaxHeight+'px', 'height' : sidePanelMaxHeight+'px'}">
									               <div class="bg-light p-3 d-flex">
									                	<i class="bx bx-chevron-right my-auto text-primary mr-2"></i> <h5  class="mb-0">Filters</h5>
									                </div>
									                <widgetContentFilters :widgetbox="false" :user="user"  :company="company" :filter="widgetBoxEditContent.widgetfilters.filter" :active_filters="widgetBoxEditContent.widgetfilters.active_filters" ></widgetContentFilters>
									                 <div class="bg-light p-3 d-flex">
									                	<i class="bx bx-chevron-right  my-auto text-primary mr-2"></i> <h5  class="mb-0">Elements</h5>
									                </div>
									                	<widgetConfigsTab :user="user" :company="company" :widgetbox="false" :configs="widgetBoxEditContent.widgetelements.elements" :content="widgetBoxEditContent.widgetelements.active_elements" />
									              	</div>
									               </b-tab>
									                <b-tab>
																		<template v-slot:title>
																			<span class="d-inline-block d-sm-none">
																				<i class="bx bx-notepad"></i>
																			</span>
																			<span class="d-none d-sm-inline-block">Configs</span>
																		</template>
									               <widgetConfigsTab :user="user" :company="company" :widgetbox="true" :configs="widgetBoxEditContent.widgetconfigs" :content="widgetBoxEditContent.widgetcontent" />
									             </b-tab>
									              <stylesTab :user="user" :company="company" :styles="widgetBoxEditContent.widgetstyles" />
									              <visibilityTab @visibility-updated="updateVisibility" :user="user" :company="company" :visibility="widgetBoxEditContent.widgetvisibility" />
                         	</b-tabs>
                         </div>
                         <div v-if="widgetBoxEditContent.widgettype == 'form'">
                         	<b-tabs justified nav-class="nav-tabs-custom" content-class="p-0 text-muted">
                         		
									                <b-tab>
																		<template v-slot:title>
																			<span class="d-inline-block d-sm-none">
																				<i class="bx bx-notepad"></i>
																			</span>
																			<span class="d-none d-sm-inline-block">Configs</span>
																		</template>
									               <widgetConfigsTab :user="user" :company="company" :widgetbox="true" :configs="widgetBoxEditContent.widgetconfigs" :content="widgetBoxEditContent.widgetcontent" />
									             </b-tab>
									              <stylesTab :user="user" :company="company" :styles="widgetBoxEditContent.widgetstyles" />
									              <visibilityTab @visibility-updated="updateVisibility" :user="user" :company="company" :visibility="widgetBoxEditContent.widgetvisibility" />
                         	</b-tabs>
                         </div>
                          <div v-if="widgetBoxEditContent.widgettype == 'address'">
                         	<b-tabs justified nav-class="nav-tabs-custom" content-class="p-0 text-muted">
                         		<b-tab >
                         			 <template v-slot:title>
									                  <span class="d-inline-block d-sm-none">
									                    <i class="bx bx-notepad"></i>
									                  </span>
									                  <span class="d-none d-sm-inline-block">Content</span>
									                </template>
									                <div class="bg-light p-3 d-flex">
									                	<i class="bx bx-chevron-right my-auto text-primary mr-2"></i> <h5  class="mb-0">Filters</h5>
									                </div>
									                <widgetContentFilters v-if="'filters' in widgetBoxEditContent.widgetcontent.config" :user="user"  :company="company" :filter="widgetBoxEditContent.widgetfilters.filter" :active_filters="widgetBoxEditContent.widgetfilters.active_filters" ></widgetContentFilters>
									                <b-card class="border-none mb-0 shadow-none">
									                <b-form-group class="mt-2"  label="Address 1">
									                  <b-form-input type="text" name="address1" v-model="widgetBoxEditContent.widgetcontent.value.address1.value"></b-form-input>
									                </b-form-group>
									                <b-form-group class="mt-2" label="Address 2">
									                  <b-form-input type="text" name="address2" v-model="widgetBoxEditContent.widgetcontent.value.address2.value"></b-form-input>
									                </b-form-group>
									                <b-form-group class="mt-2" label="Address 3">
									                  <b-form-input type="text" name="address3" v-model="widgetBoxEditContent.widgetcontent.value.address3.value"></b-form-input>
									                </b-form-group>
									                
									                <b-form-group class="mt-2" label="Country">
									                  <b-form-select class="form-control" v-model="widgetBoxEditContent.widgetcontent.value.country_id.value" name="country_id"  @input="getCounties(widgetBoxEditContent.widgetcontent.value.country_id.value)">
									                    <b-form-select-option v-for="country in countries" :value="country.id">
									                      {{country.name}}
									                    </b-form-select-option>
									                  </b-form-select>
									                </b-form-group>
									                <b-form-group class="mt-2" label="County/State">
									                  <b-form-select class="form-control" name="county_id" v-model="widgetBoxEditContent.widgetcontent.value.county_id.value" :disabled="(counties.length == 0)">
									                    <b-form-select-option v-for="county in counties" :value="county.id" >
									                      {{county.name}}
									                    </b-form-select-option>
									                  </b-form-select>
									                </b-form-group>
									                <b-form-group class="mt-2" label="City">
									                  <b-form-input type="text" name="city" v-model="widgetBoxEditContent.widgetcontent.value.city.value"></b-form-input>
									                </b-form-group>
									                <b-form-group class="mt-2" label="Postcode/Zip">
									                  <b-form-input type="text" name="postcode" v-model="widgetBoxEditContent.widgetcontent.value.postcode.value"></b-form-input>
									                </b-form-group>
									              </b-card>
									              </b-tab>
									              <b-tab>
																		<template v-slot:title>
																			<span class="d-inline-block d-sm-none">
																				<i class="bx bx-notepad"></i>
																			</span>
																			<span class="d-none d-sm-inline-block">Configs</span>
																		</template>
									               <widgetConfigsTab :user="user" :company="company" :widgetbox="true" :configs="widgetBoxEditContent.widgetconfigs" :content="widgetBoxEditContent.widgetcontent" />
									             </b-tab>
									              <stylesTab :user="user" :company="company" :styles="widgetBoxEditContent.widgetstyles" />
									              <visibilityTab @visibility-updated="updateVisibility" :user="user" :company="company" :visibility="widgetBoxEditContent.widgetvisibility" />
									            </b-tabs>
									          </div>
                         <div v-if="widgetBoxEditContent.widgettype == 'image'">
                         	<b-tabs justified nav-class="nav-tabs-custom" content-class="p-0 text-muted">
                         		<b-tab >
                         			 <template v-slot:title>
									                  <span class="d-inline-block d-sm-none">
									                    <i class="bx bx-notepad"></i>
									                  </span>
									                  <span class="d-none d-sm-inline-block">Content</span>
									                </template>

						<div class="border-none shadow-none widget-inner-scrollbox"  :style="{'max-height' : (sidePanelMaxHeight - 100) +'px', 'height' : (sidePanelMaxHeight - 100) +'px'}" @mouseover="hoverElement = 'image'" @mouseleave="hoverElement = ''">						
                          <div class="bg-light mb-3 d-flex position-relative" style="min-height: 150px">
                          <a v-if="widgetBoxEditContent.widgetcontent[filterStylesKey(widgetBoxEditContent.widgetcontent, 'url')].url.value == ''" class="btn btn-primary w-75 m-auto" href="javascript:;"  @click="fileResource = fileRowIndex = rindex, fileColIndex = cindex, showFileManager = !showFileManager">Select File</a>
                          <img v-else :src="widgetBoxEditContent.widgetcontent[filterStylesKey(widgetBoxEditContent.widgetcontent, 'url')].url.value" class="w-75 m-auto">


                          <div v-if="(hoverElement == 'image') && (widgetBoxEditContent.widgetcontent[filterStylesKey(widgetBoxEditContent.widgetcontent, 'url')].url.value !== '')" class=" bg-secondary bg-soft mx-auto w-75 position-absolute top-0 right-0 left-0 bottom-0 d-flex flex-column justify-content-center align-items-center">
                         				  	<a class="btn btn-primary w-75 m-auto mb-1" href="javascript:;"  @click="fileResourceKey = 'url', fileResourceIndex = filterStylesKey(widgetBoxEditContent.widgetcontent, 'url'), showFileManager = !showFileManager, $emit('storage-toggled', showFileManager)">Select File</a>
                         				  	<a class="btn btn-danger w-75 m-auto mt-1" href="javascript:;"  @click="widgetBoxEditContent.widgetcontent[filterStylesKey(widgetBoxEditContent.widgetcontent, 'url')].url.value = '', hoverElement = ''">Remove File</a>

                         				  </div>
                          </div>

                          <widgetConfigsTab  :user="user" :company="company" :widgetbox="false" :configs="widgetBoxEditContent.widgetelements.elements" :content="widgetBoxEditContent.widgetelements.active_elements" />
                      	</div>
                        </b-tab>
                        <b-tab>
																		<template v-slot:title>
																			<span class="d-inline-block d-sm-none">
																				<i class="bx bx-notepad"></i>
																			</span>
																			<span class="d-none d-sm-inline-block">Configs</span>
																		</template>
                        <widgetConfigsTab :user="user" :company="company" :widgetbox="true" :configs="widgetBoxEditContent.widgetconfigs" :content="widgetBoxEditContent.widgetcontent" />
                      	</b-tab>
                        <stylesTab :user="user" :company="company" :styles="widgetBoxEditContent.widgetstyles" />
                        <visibilityTab @visibility-updated="updateVisibility" :user="user" :company="company" :visibility="widgetBoxEditContent.widgetvisibility" />
                          
                          </b-tabs>                       
                         </div>
                         <div v-if="widgetBoxEditContent.widgettype == 'signature'">
                          <SelectValuationSignature :user="user" :isDisabled="false" :id="''" @brand-selected="selectValue"></SelectValuationSignature>
                         </div>
                         <div v-if="widgetBoxEditContent.widgettype == 'stamp'">
                          <SelectValuationStamp :user="user" :isDisabled="false" :id="''" @brand-selected="selectValue"></SelectValuationStamp>
                         </div>
                         <div v-if="widgetBoxEditContent.widgettype == 'note'">
                          <SelectValuationNote :user="user" :isDisabled="false" :id="''" @brand-selected="selectValue"></SelectValuationNote>
                         </div>
                         <div v-if="widgetBoxEditContent.widgettype == 'customer-address'">
                          <b-form-group class="mb-2" label="Use Individual Customer" description="If you want to select a particular customer for this template, check this box. Otherwise customer address will be added from your appraisal customer.">
                            <b-form-checkbox v-model="widgetBoxEditContent.widgetcontent.selectcustomer"  name="check-button" switch>
                                  Select Customer
                                </b-form-checkbox>
                          </b-form-group>   
                           <b-form-group class="mb-2" label="Layout" description="If you want to select a particular customer for this template, check this box. Otherwise customer address will be added from your appraisal customer.">
                            <b-form-checkbox v-model="widgetBoxEditContent.widgetcontent.selectcustomer"  name="check-button" value="inline">
                                  Inline
                                </b-form-checkbox>
                                <b-form-checkbox v-model="widgetBoxEditContent.widgetcontent.selectcustomer"  name="check-button" value="block">
                                  Block
                                </b-form-checkbox>
                          </b-form-group>    
                          <b-form-group class="mt-2" label="Customer"  v-if="(widgetBoxEditContent.widgetcontent.selectcustomer == true)">
                            <CompanyCustomerSearch v-if="(widgetBoxEditContent.widgetcontent.selectcustomer == true) && (widgetBoxEditContent.widgetcontent.selectedcustomer == null)" :id="widgetBoxEditContent.widgetcontent.selectedcustomer"  :api_token="user.api_token" @search-resource-selected="selectCustomer"></CompanyCustomerSearch>
                            <div class="d-flex w-100 h-100 border rounded-3 p-2 mt-3" v-if="widgetBoxEditContent.widgetcontent.selectedcustomer !== null">
                                <div class="avatar-sm mr-4 my-auto" v-if="widgetBoxEditContent.widgetcontent.selectedcustomer.user_details.avatar == null">
                                  <span class="avatar-title rounded-circle">{{widgetBoxEditContent.widgetcontent.selectedcustomer.firstname.slice(0, 1)}}</span>
                                </div>
                                 <img v-else :src="`${widgetBoxEditContent.widgetcontent.selectedcustomer.user_details.avatar}`" alt class="avatar-sm rounded-circle" />
                                <div class="option__desc d-flex flex-row w-75">
                                  <span class="option__title ml-2 my-auto w-100">{{widgetBoxEditContent.widgetcontent.selectedcustomer.firstname}} {{widgetBoxEditContent.widgetcontent.selectedcustomer.lastname}}<br><p class="m-0 lh-1 w-100 text-truncate"><small>{{widgetBoxEditContent.widgetcontent.selectedcustomer.user_details.email}}</small></p></span></div>
                                  <div class="d-flex flex-column flex-grow-1"><a class="btn btn-outline-light btn-rounded ml-auto my-auto mr-0"><i class="mdi mdi-close noti-icon font-size-16" href="javascript:;" @click="widgetBoxEditContent.widgetcontent.selectedcustomer = null" ></i></a></div></div>
                          </b-form-group>
                          <b-form-group class="mt-2" label="Address" v-if="(widgetBoxEditContent.widgetcontent.selectcustomer == true)">
                              <SearchCompanyCustomerAddress v-if="widgetBoxEditContent.widgetcontent.selectedcustomer !== null" :id="widgetBoxEditContent.widgetcontent.selectedaddress" :api_token="user.api_token" :options="widgetBoxEditContent.widgetcontent.selectedcustomer.addresses" @status-selected="selectAddress" :data_provided="true" ></SearchCompanyCustomerAddress>
                              
                         </b-form-group>
                         </div>
                         
                         <div v-if="widgetBoxEditContent.widgettype == 'slider'">
                         	<div v-if="widgetBoxEditContent.editable == 0">
                         		<b-tabs justified nav-class="nav-tabs-custom" content-class="text-muted">
                         		<b-tab >
                         			 <template v-slot:title>
									                  <span class="d-inline-block d-sm-none">
									                    <i class="bx bx-notepad"></i>
									                  </span>
									                  <span class="d-none d-sm-inline-block">Content</span>
									                </template>
									            <div class="widget-inner-scrollbox"  :style="{'max-height' : sidePanelMaxHeight+'px', 'height' : sidePanelMaxHeight+'px'}">
	                         		<!--<b-list-group >
	                         			<b-list-group-item v-for="(slide, index) in widgetBoxEditContent.widgetcontent.list" class="d-flex">
	                         				<span class="my-auto">{{slide.title}} {{index + 1}} </span>

	                         				<a class="ml-auto my-auto btn btn-primary" @click="widgetBoxEditContent.widgetcontent.list_index = index, widgetBoxEditContent.editable = 1"><i class="bx bx-pencil"></i></a>
	                         				<a class="my-auto btn btn-danger" @click="widgetBoxEditContent.widgetcontent.list.splice(index,1)"><i class="bx bx-trash-alt"></i></a>
	                         			</b-list-group-item>
	                         			<b-list-group-item class="bg-light text-muted text-uppercase text-center" @click="widgetBoxEditContent.widgetcontent.list.push(JSON.parse(JSON.stringify(widgetBoxEditContent.widgetcontent.list_item)))">
	                         				Add New Slide <i class="bx bx-plus"></i>
	                         			</b-list-group-item>
	                         		</b-list-group>-->
	                         		<div class="bg-light p-3 d-flex">
									                	<i class="bx bx-chevron-right my-auto text-primary mr-2"></i> <h5  class="mb-0">Slider Items</h5>
									                </div>
	                         		<widgetContentList :list="widgetBoxEditContent.widgetcontent[filterStylesKey(widgetBoxEditContent.widgetcontent, 'list')].list" :list_item="widgetBoxEditContent.widgetlistconfigs.list_item_values" :editable="widgetBoxEditContent.editable" :list_index="widgetBoxEditContent.widgetcontent[filterStylesKey(widgetBoxEditContent.widgetcontent, 'list_index')].list_index" @edit-list-item="widgetBoxEditContent.widgetcontent[filterStylesKey(widgetBoxEditContent.widgetcontent, 'list_index')].list_index = $event, widgetBoxEditContent.editable = 1, widgetBoxSubForm = true" ></widgetContentList>
	                         		</div>
                         		</b-tab>
                         		<b-tab >
                         			 <template v-slot:title>
									                  <span class="d-inline-block d-sm-none">
									                    <i class="bx bx-notepad"></i>
									                  </span>
									                  <span class="d-none d-sm-inline-block">Configs</span>
									                </template>
									                <widgetConfigsTab :user="user" :company="company" :widgetbox="true" :configs="widgetBoxEditContent.widgetconfigs" :content="widgetBoxEditContent.widgetcontent" />
									        </b-tab>
                         		 <stylesTab :user="user" :company="company" :styles="widgetBoxEditContent.widgetstyles" />
                         		 <visibilityTab @visibility-updated="updateVisibility" :user="user" :company="company" :visibility="widgetBoxEditContent.widgetvisibility" />
                         		</b-tabs>
                         	</div>
                         		<b-card class="border-none shadow-none widget-inner-scrollbox"  :style="{'max-height' : sidePanelMaxHeight+'px', 'height' : sidePanelMaxHeight+'px'}" body-class="p-0" v-else>
                         			<widgetConfigsTab :user="user" :company="company" :widgetbox="false" :configs="widgetBoxEditContent.widgetlistconfigs.list_item" :content="widgetBoxEditContent.widgetcontent[filterStylesKey(widgetBoxEditContent.widgetcontent, 'list')].list[widgetBoxEditContent.widgetcontent[filterStylesKey(widgetBoxEditContent.widgetcontent, 'list_index')].list_index]" />
                         			
                         		</b-card>
                          </div>
                           <div v-if="widgetBoxEditContent.widgettype == 'cards'">
                         	<div v-if="widgetBoxEditContent.editable == 0">
                         		<b-tabs justified nav-class="nav-tabs-custom" content-class="text-muted">
                         		<b-tab >
                         			 <template v-slot:title>
									                  <span class="d-inline-block d-sm-none">
									                    <i class="bx bx-notepad"></i>
									                  </span>
									                  <span class="d-none d-sm-inline-block">Content</span>
									                </template>
									            <div class="widget-inner-scrollbox"  :style="{'max-height' : sidePanelMaxHeight+'px', 'height' : sidePanelMaxHeight+'px'}">
	                         		<!--<b-list-group >
	                         			<b-list-group-item v-for="(slide, index) in widgetBoxEditContent.widgetcontent.list" class="d-flex">
	                         				<span class="my-auto">{{slide.title}} {{index + 1}} </span>

	                         				<a class="ml-auto my-auto btn btn-primary" @click="widgetBoxEditContent.widgetcontent.list_index = index, widgetBoxEditContent.editable = 1"><i class="bx bx-pencil"></i></a>
	                         				<a class="my-auto btn btn-danger" @click="widgetBoxEditContent.widgetcontent.list.splice(index,1)"><i class="bx bx-trash-alt"></i></a>
	                         			</b-list-group-item>
	                         			<b-list-group-item class="bg-light text-muted text-uppercase text-center" @click="widgetBoxEditContent.widgetcontent.list.push(JSON.parse(JSON.stringify(widgetBoxEditContent.widgetcontent.list_item)))">
	                         				Add New Slide <i class="bx bx-plus"></i>
	                         			</b-list-group-item>
	                         		</b-list-group>-->
	                         		<div class="bg-light p-3 d-flex">
									                	<i class="bx bx-chevron-right my-auto text-primary mr-2"></i> <h5  class="mb-0">Cards</h5>
									                </div>
									                <widgetContentList :list="widgetBoxEditContent.widgetcontent[filterStylesKey(widgetBoxEditContent.widgetcontent, 'list')].list" :list_item="widgetBoxEditContent.widgetlistconfigs.list_item_values" :editable="widgetBoxEditContent.editable" :list_index="widgetBoxEditContent.widgetcontent[filterStylesKey(widgetBoxEditContent.widgetcontent, 'list_index')].list_index" @edit-list-item="widgetBoxEditContent.widgetcontent[filterStylesKey(widgetBoxEditContent.widgetcontent, 'list_index')].list_index = $event, widgetBoxEditContent.editable = 1, widgetBoxSubForm = true" ></widgetContentList>
	                         		<!--<widgetContentList :list="widgetBoxEditContent.widgetcontent.list" :list_item="widgetBoxEditContent.widgetlistconfigs.list_item_values" :editable="widgetBoxEditContent.editable" :list_index="widgetBoxEditContent.widgetcontent.list_index" @edit-list-item="widgetBoxEditContent.widgetcontent.list_index = $event, widgetBoxEditContent.editable = 1, widgetBoxSubForm = true" ></widgetContentList>-->
	                         		<div class="bg-light p-3 d-flex">
									                	<i class="bx bx-chevron-right my-auto text-primary mr-2"></i> <h5  class="mb-0">Pagination</h5>
									                </div>
	                         		<widgetContentFilters :widgetbox="false" :user="user"  :company="company" :filter="widgetBoxEditContent.widgetfilters.filter" :active_filters="widgetBoxEditContent.widgetfilters.active_filters" ></widgetContentFilters>
	                         		<div class="bg-light p-3 d-flex">
									                	<i class="bx bx-chevron-right my-auto text-primary mr-2"></i> <h5  class="mb-0">Elements</h5>
									                </div>
									                <widgetConfigsTab :user="user" :company="company" :widgetbox="false" :configs="widgetBoxEditContent.widgetelements.elements" :content="widgetBoxEditContent.widgetelements.active_elements" />
	                         		</div>
                         		</b-tab>
                         		<b-tab >
                         			 <template v-slot:title>
									                  <span class="d-inline-block d-sm-none">
									                    <i class="bx bx-notepad"></i>
									                  </span>
									                  <span class="d-none d-sm-inline-block">Configs</span>
									                </template>
									                <widgetConfigsTab :user="user" :company="company" :widgetbox="true" :configs="widgetBoxEditContent.widgetconfigs" :content="widgetBoxEditContent.widgetcontent" />
									        </b-tab>
                         		 <stylesTab :user="user" :company="company" :styles="widgetBoxEditContent.widgetstyles" />
                         		 <visibilityTab @visibility-updated="updateVisibility" :user="user" :company="company" :visibility="widgetBoxEditContent.widgetvisibility" />
                         		</b-tabs>
                         	</div>
                         		<b-card class="border-none shadow-none widget-inner-scrollbox"  :style="{'max-height' : sidePanelMaxHeight+'px', 'height' : sidePanelMaxHeight+'px'}" body-class="p-0" v-else>
                         			<!--<widgetConfigsTab :user="user" :company="company" :widgetbox="false" :configs="widgetBoxEditContent.widgetlistconfigs.list_item" :content="widgetBoxEditContent.widgetcontent.list[widgetBoxEditContent.widgetcontent.list_index]" />-->

                         			<widgetConfigsTab :user="user" :company="company" :widgetbox="false" :configs="widgetBoxEditContent.widgetlistconfigs.list_item" :content="widgetBoxEditContent.widgetcontent[filterStylesKey(widgetBoxEditContent.widgetcontent, 'list')].list[widgetBoxEditContent.widgetcontent[filterStylesKey(widgetBoxEditContent.widgetcontent, 'list_index')].list_index]" />
                         			
                         		</b-card>
                          </div>
                          <div v-if="widgetBoxEditContent.widgettype == 'imagegallery'">
                          		<b-tabs justified nav-class="nav-tabs-custom" content-class="text-muted">
                         		<b-tab >
                         			 <template v-slot:title>
									                  <span class="d-inline-block d-sm-none">
									                    <i class="bx bx-notepad"></i>
									                  </span>
									                  <span class="d-none d-sm-inline-block">Content</span>
									                </template>
									                <div class="widget-inner-scrollbox p-3"  :style="{'max-height' : sidePanelMaxHeight+'px', 'height' : sidePanelMaxHeight+'px'}">
									                <b-card class="border-radius-1em border shadow-none">
									                	<b-row>
									                		<b-col md="4" v-for="(image, index) in widgetBoxEditContent.widgetcontent[filterStylesKey(widgetBoxEditContent.widgetcontent, 'list')].list" class="position-relative mb-2" @mouseover="imageDelete = index" @mouseleave="imageDelete = -1">
									                			<img :src="image.src" class="avatar-md border border-radius-1em">
									                			<a v-if="imageDelete == index" href="javascript:;" class="border-radius-1em position-absolute top-0 left-0 right-0 bottom-0 border-danger border avatar-md mx-auto d-flex flex-column justify-content-end text-right" @click="widgetBoxEditContent.widgetcontent.list.splice(index, 1)">
									                				<i class="bx bx-trash-alt text-danger font-size-24 p-2"></i>
									                			</a>
									                		</b-col>
									                		<b-col md="4" class="d-flex">
									                			<a  class="border border-radius-1em avatar-md d-flex"  href="javascript:;"  @click="fileResource = fileRowIndex = rindex, fileColIndex = cindex, showFileManager = !showFileManager">
									                					<i class="mdi mdi-plus my-auto mx-auto"></i>
									                				
									                			</a>
									                		</b-col>
									                	</b-row>
									                </b-card>
									              	</div>
									              </b-tab>
									              <b-tab >
                         			 <template v-slot:title>
									                  <span class="d-inline-block d-sm-none">
									                    <i class="bx bx-notepad"></i>
									                  </span>
									                  <span class="d-none d-sm-inline-block">Configs</span>
									                </template>
									                 <widgetConfigsTab :user="user" :company="company" :widgetbox="true" :configs="widgetBoxEditContent.widgetconfigs" :content="widgetBoxEditContent.widgetcontent" />
									              </b-tab>
									             
									               <stylesTab :user="user" :company="company" :styles="widgetBoxEditContent.widgetstyles" />
									               <visibilityTab @visibility-updated="updateVisibility" :user="user" :company="company" :visibility="widgetBoxEditContent.widgetvisibility" />
									            </b-tabs>
                          		
                          </div>
                          <div v-if="widgetBoxEditContent.widgettype == 'products'">
                          		<b-tabs justified nav-class="nav-tabs-custom" content-class="p-0 text-muted">
                         		<b-tab >
                         			 <template v-slot:title>
									                  <span class="d-inline-block d-sm-none">
									                    <i class="bx bx-notepad"></i>
									                  </span>
									                  <span class="d-none d-sm-inline-block">Content</span>
									                </template>
									                <div class="widget-inner-scrollbox"  :style="{'max-height' : sidePanelMaxHeight+'px', 'height' : sidePanelMaxHeight+'px'}">
									                <div class="bg-light p-3 d-flex">
									                	<i class="bx bx-chevron-right my-auto text-primary mr-2"></i> <h5  class="mb-0">Filters</h5>
									                </div>
									                
									                <widgetContentFilters :widgetbox="false" :user="user"  :company="company" :filter="widgetBoxEditContent.widgetfilters.filter" :active_filters="widgetBoxEditContent.widgetfilters.active_filters" ></widgetContentFilters>
									                 <div class="bg-light p-3 d-flex" v-if="widgetBoxEditContent.widgetcontent[filterStylesKey(widgetBoxEditContent.widgetcontent, 'view')].view.value !== 'carousel'">
									                	<i class="bx bx-chevron-right  my-auto text-primary mr-2"></i> <h5  class="mb-0">Elements</h5>
									                </div>
									                <widgetConfigsTab  v-if="widgetBoxEditContent.widgetcontent[filterStylesKey(widgetBoxEditContent.widgetcontent, 'view')].view.value !== 'carousel'" :user="user" :company="company" :widgetbox="false" :configs="widgetBoxEditContent.widgetelements.elements" :content="widgetBoxEditContent.widgetelements.active_elements" />
									                
									               </div>
									              </b-tab>
									              <b-tab >
                         			 <template v-slot:title>
									                  <span class="d-inline-block d-sm-none">
									                    <i class="bx bx-notepad"></i>
									                  </span>
									                  <span class="d-none d-sm-inline-block">Configs</span>
									                </template>
									                
									                 <widgetConfigsTab :user="user" :company="company" :widgetbox="true" :configs="widgetBoxEditContent.widgetconfigs" :content="widgetBoxEditContent.widgetcontent" />
									                
									              </b-tab>
									               <stylesTab :user="user" :company="company" :styles="widgetBoxEditContent.widgetstyles" />
									               <visibilityTab @visibility-updated="updateVisibility" :user="user" :company="company" :visibility="widgetBoxEditContent.widgetvisibility" />
									            </b-tabs>
									          </div>
									          <div v-if="widgetBoxEditContent.widgettype == 'ProductCard'">
                          		<b-tabs justified nav-class="nav-tabs-custom" content-class="p-0 text-muted">
                         		<b-tab >
                         			 <template v-slot:title>
									                  <span class="d-inline-block d-sm-none">
									                    <i class="bx bx-notepad"></i>
									                  </span>
									                  <span class="d-none d-sm-inline-block">Content</span>
									                </template>
									                <div class="widget-inner-scrollbox"  :style="{'max-height' : sidePanelMaxHeight+'px', 'height' : sidePanelMaxHeight+'px'}">
									                <div class="bg-light p-3 d-flex">
									                	<i class="bx bx-chevron-right my-auto text-primary mr-2"></i> <h5  class="mb-0">Filters</h5>
									                </div>
									                
									                <widgetContentFilters :widgetbox="false" :user="user"  :company="company" :filter="widgetBoxEditContent.widgetfilters.filter" :active_filters="widgetBoxEditContent.widgetfilters.active_filters" ></widgetContentFilters>
									                <widgetConfigsTab  v-if="widgetBoxEditContent.widgetcontent[filterStylesKey(widgetBoxEditContent.widgetcontent, 'view')].view.value !== 'carousel'" :user="user" :company="company" :widgetbox="false" :configs="widgetBoxEditContent.widgetelements.elements" :content="widgetBoxEditContent.widgetelements.active_elements" />
									                
									               </div>
									              </b-tab>
									              <b-tab >
                         			 <template v-slot:title>
									                  <span class="d-inline-block d-sm-none">
									                    <i class="bx bx-notepad"></i>
									                  </span>
									                  <span class="d-none d-sm-inline-block">Configs</span>
									                </template>
									                
									                 <widgetConfigsTab :user="user" :company="company" :widgetbox="true" :configs="widgetBoxEditContent.widgetconfigs" :content="widgetBoxEditContent.widgetcontent" />
									                
									              </b-tab>
									               <stylesTab :user="user" :company="company" :styles="widgetBoxEditContent.widgetstyles" />
									               <visibilityTab @visibility-updated="updateVisibility" :user="user" :company="company" :visibility="widgetBoxEditContent.widgetvisibility" />
									            </b-tabs>
									          </div>
									          <div v-if="widgetBoxEditContent.widgettype == 'services'">
                          		<b-tabs justified nav-class="nav-tabs-custom" content-class="p-0 text-muted">
                         		<b-tab >
                         			 <template v-slot:title>
									                  <span class="d-inline-block d-sm-none">
									                    <i class="bx bx-notepad"></i>
									                  </span>
									                  <span class="d-none d-sm-inline-block">Content</span>
									                </template>
									                <div class="widget-inner-scrollbox"  :style="{'max-height' : sidePanelMaxHeight+'px', 'height' : sidePanelMaxHeight+'px'}">
									                <div class="bg-light p-3 d-flex">
									                	<i class="bx bx-chevron-right my-auto text-primary mr-2"></i> <h5  class="mb-0">Filters</h5>
									                </div>
									                
									                <widgetContentFilters :widgetbox="false" :user="user"  :company="company" :filter="widgetBoxEditContent.widgetfilters.filter" :active_filters="widgetBoxEditContent.widgetfilters.active_filters" ></widgetContentFilters>
									                 <div class="bg-light p-3 d-flex">
									                	<i class="bx bx-chevron-right  my-auto text-primary mr-2"></i> <h5  class="mb-0">Elements</h5>
									                </div>
									                <widgetConfigsTab :user="user" :company="company" :widgetbox="false" :configs="widgetBoxEditContent.widgetelements.elements" :content="widgetBoxEditContent.widgetelements.active_elements" />
									              </div>
									              </b-tab>
									              <b-tab >
                         			 <template v-slot:title>
									                  <span class="d-inline-block d-sm-none">
									                    <i class="bx bx-notepad"></i>
									                  </span>
									                  <span class="d-none d-sm-inline-block">Configs</span>
									                </template>
									                <widgetConfigsTab :user="user" :company="company" :widgetbox="true" :configs="widgetBoxEditContent.widgetconfigs" :content="widgetBoxEditContent.widgetcontent" />
									              </b-tab>
									               <stylesTab :user="user" :company="company" :styles="widgetBoxEditContent.widgetstyles" />
									               <visibilityTab @visibility-updated="updateVisibility" :user="user" :company="company" :visibility="widgetBoxEditContent.widgetvisibility" />
									            </b-tabs>
									          </div>
									          <div v-if="widgetBoxEditContent.widgettype == 'collections'">
                          		<b-tabs justified nav-class="nav-tabs-custom" content-class="p-0 text-muted">
                         		<b-tab >
                         			 <template v-slot:title>
									                  <span class="d-inline-block d-sm-none">
									                    <i class="bx bx-notepad"></i>
									                  </span>
									                  <span class="d-none d-sm-inline-block">Content</span>
									                </template>
									                <div class="widget-inner-scrollbox"  :style="{'max-height' : sidePanelMaxHeight+'px', 'height' : sidePanelMaxHeight+'px'}">
									                <div class="bg-light p-3 d-flex">
									                	<i class="bx bx-chevron-right my-auto text-primary mr-2"></i> <h5  class="mb-0">Filters</h5>
									                </div>
									                <widgetContentFilters :user="user"  :company="company" :filter="widgetBoxEditContent.widgetfilters.filter" :active_filters="widgetBoxEditContent.widgetfilters.active_filters" ></widgetContentFilters>
									                 <div class="bg-light p-3 d-flex">
									                	<i class="bx bx-chevron-right my-auto text-primary mr-2"></i> <h5  class="mb-0">Elements</h5>
									                </div>
									                <widgetConfigsTab :user="user" :company="company" :widgetbox="false" :configs="widgetBoxEditContent.widgetelements.elements" :content="widgetBoxEditContent.widgetelements.active_elements" />
									                </div>
									              </b-tab>
									              <b-tab >
                         			 <template v-slot:title>
									                  <span class="d-inline-block d-sm-none">
									                    <i class="bx bx-notepad"></i>
									                  </span>
									                  <span class="d-none d-sm-inline-block">Configs</span>
									                </template>
									                <widgetConfigsTab :user="user" :company="company" :widgetbox="true" :configs="widgetBoxEditContent.widgetconfigs" :content="widgetBoxEditContent.widgetcontent" />
									                 
									              </b-tab>
									               <stylesTab :user="user" :company="company" :styles="widgetBoxEditContent.widgetstyles" />
									               <visibilityTab @visibility-updated="updateVisibility" :user="user" :company="company" :visibility="widgetBoxEditContent.widgetvisibility" />
									            </b-tabs>
									          </div>
									          <div v-if="widgetBoxEditContent.widgettype == 'categories'">
                          		<b-tabs justified nav-class="nav-tabs-custom" content-class="p-0 text-muted">
                         		<b-tab >
                         			 <template v-slot:title>
									                  <span class="d-inline-block d-sm-none">
									                    <i class="bx bx-notepad"></i>
									                  </span>
									                  <span class="d-none d-sm-inline-block">Content</span>
									                </template>
									                <div class="widget-inner-scrollbox"  :style="{'max-height' : sidePanelMaxHeight+'px', 'height' : sidePanelMaxHeight+'px'}"> 
									                <div class="bg-light p-3 d-flex">
									                	<i class="bx bx-chevron-right my-auto text-primary mr-2"></i> <h5  class="mb-0">Filters</h5>
									                </div>
									                <widgetContentFilters :user="user"  :company="company" :filter="widgetBoxEditContent.widgetfilters.filter" :active_filters="widgetBoxEditContent.widgetfilters.active_filters" ></widgetContentFilters>
									                  
 																	<div class="bg-light p-3 d-flex">
									                	<i class="bx bx-chevron-right my-auto text-primary mr-2"></i> <h5  class="mb-0">Elements</h5>
									                </div>
									                <widgetConfigsTab :user="user" :company="company" :widgetbox="false" :configs="widgetBoxEditContent.widgetelements.elements" :content="widgetBoxEditContent.widgetelements.active_elements" />
									              	</div>
									              </b-tab>
									              <b-tab >
                         			 <template v-slot:title>
									                  <span class="d-inline-block d-sm-none">
									                    <i class="bx bx-notepad"></i>
									                  </span>
									                  <span class="d-none d-sm-inline-block">Configs</span>
									                </template>
									                <widgetConfigsTab :user="user" :company="company" :widgetbox="true" :configs="widgetBoxEditContent.widgetconfigs" :content="widgetBoxEditContent.widgetcontent" />
									              </b-tab>
									               <stylesTab :user="user" :company="company" :styles="widgetBoxEditContent.widgetstyles" />
									               <visibilityTab @visibility-updated="updateVisibility" :user="user" :company="company" :visibility="widgetBoxEditContent.widgetvisibility" />
									            </b-tabs>
									          </div>
									          <div v-if="widgetBoxEditContent.widgettype == 'map'">
									          	<b-tabs  justified nav-class="nav-tabs-custom" content-class="p-0 text-muted">
									          		<b-tab >
                         			 <template v-slot:title>
									                  <span class="d-inline-block d-sm-none">
									                    <i class="bx bx-notepad"></i>
									                  </span>
									                  <span class="d-none d-sm-inline-block">Content</span>
									                </template>
									                <div class="widget-inner-scrollbox"  :style="{'max-height' : sidePanelMaxHeight+'px', 'height' : sidePanelMaxHeight+'px'}">
									                <div class="bg-light p-3 d-flex">
									                	<i class="bx bx-chevron-right my-auto text-primary mr-2"></i> <h5  class="mb-0">Google Maps API Key</h5>
									                </div>
									               <b-card class="border-none mb-0 shadow-none">
									               		<h6>API Keys</h6>
									               		<small>How to get an API Key</small>
									               		<b-form-group label="Select Key">
									               			 <b-form-select class="form-control w-100" v-model="widgetBoxEditContent.widgetcontent.apikey">
									               			 	 <!--<b-form-select-option v-for="gkey in companykeys.google" :value="gkey.key">
									               			 	 	{{gkey.name}} : {{gkey.key.splice(0,5)}}
									               			 	 </b-form-select-option>-->
									               			 </b-form-select>
									               		</b-form-group>
									               		<a class="btn btn-light w-100 mt-2 btn-rounded" @click="addMapKey = !addMapKey">
									               			Add New Key <i class="mdi mdi-plus"></i>
									               		</a>
									               		<div class="mt-3" v-if="addMapKey == true">
									               			<hr>
									               			<b-form-group label="API Key">
									               				<b-form-input type="text"> </b-form-input>
									               			</b-form-group>
									               			<a class="btn btn-primary btn-block btn-rounded">
									               				Submit Key
									               			</a>
									               		</div>
									               </b-card>
									               <div class="bg-light p-3 d-flex">
									                	<i class="bx bx-chevron-right my-auto text-primary mr-2"></i> <h5  class="mb-0">Address Details</h5>
									                </div>
									               <b-card class="border-none mb-0 shadow-none" body-class="pb-0">
							                <b-form-group label="Search Address">
							                  <!--<vue-google-autocomplete id="map" :enableGeolocation="true" types="establishment" :fields="['address_components', 'adr_address', 'alt_id', 'formatted_address', 'geometry', 'icon', 'id', 'name', 'business_status', 'photo', 'place_id', 'scope', 'type', 'url', 'utc_offset', 'vicinity', ]" classname="form-control" placeholder="Start typing" v-on:placechanged="getAddressData">
							                    </vue-google-autocomplete>-->

							                     <GmapAutocomplete @place_changed="getAddressData" />
							                </b-form-group>
							                <gmap-map v-if="googleSearchAddress !== ''" :center="{ lat: widgetBoxEditContent.widgetcontent.value.latitude.value, lng: widgetBoxEditContent.widgetcontent.value.longitude.value }" :zoom="10" style="height: 350px" class="mt-2 border-radius-1em">
							                  <gmap-marker
							                    
							                    :key="index"
							                    :position="{ lat: widgetBoxEditContent.widgetcontent.value.latitude.value, lng: widgetBoxEditContent.widgetcontent.value.longitude.value }"
							                    :clickable="true"
							                    :draggable="true"
							                    @click="center = { lat: widgetBoxEditContent.widgetcontent.value.latitude.value, lng: widgetBoxEditContent.widgetcontent.value.longitude.value }"
							                  ></gmap-marker>
							                </gmap-map>
							              </b-card>
							              <b-card class="border-none mb-0 shadow-none bg-light mx-auto" body-class="d-flex flex-column" v-if="googleSearchAddress !== ''">
							                <div class="mx-auto mb-2" v-html="googleSearchAddress.formatted_address"></div>
							              
							                <a class="btn btn-danger mx-auto btn-rounded" @click="googleSearchAddress = '', widgetBoxEditContent.widgetcontent.value.gplace_id.value = '', widgetBoxEditContent.widgetcontent.value.longitude.value = 0, widgetBoxEditContent.widgetcontent.value.latitude.value = 0">Remove Listing</a>
							              </b-card>
									               <b-card class="border-none mb-0 shadow-none">
									                <b-form-group class="mt-2"  label="Address 1">
									                  <b-form-input type="text" name="address1" v-model="widgetBoxEditContent.widgetcontent.value.address1.value"></b-form-input>
									                </b-form-group>
									                <b-form-group class="mt-2" label="Address 2">
									                  <b-form-input type="text" name="address2" v-model="widgetBoxEditContent.widgetcontent.value.address2.value"></b-form-input>
									                </b-form-group>
									                <b-form-group class="mt-2" label="Address 3">
									                  <b-form-input type="text" name="address3" v-model="widgetBoxEditContent.widgetcontent.value.address3.value"></b-form-input>
									                </b-form-group>
									                
									                <b-form-group class="mt-2" label="Country">
									                  <b-form-select class="form-control" v-model="widgetBoxEditContent.widgetcontent.value.country_id.value" name="country_id"  @input="getCounties(widgetBoxEditContent.widgetcontent.value.country_id.value)">
									                    <b-form-select-option v-for="country in countries" :value="country.id">
									                      {{country.name}}
									                    </b-form-select-option>
									                  </b-form-select>
									                </b-form-group>
									                <b-form-group class="mt-2" label="County/State">
									                  <b-form-select class="form-control" name="county_id" v-model="widgetBoxEditContent.widgetcontent.value.county_id.value" :disabled="(counties.length == 0)">
									                    <b-form-select-option v-for="county in counties" :value="county.id" >
									                      {{county.name}}
									                    </b-form-select-option>
									                  </b-form-select>
									                </b-form-group>
									                <b-form-group class="mt-2" label="City">
									                  <b-form-input type="text" name="city" v-model="widgetBoxEditContent.widgetcontent.value.city.value"></b-form-input>
									                </b-form-group>
									                <b-form-group class="mt-2" label="Postcode/Zip">
									                  <b-form-input type="text" name="postcode" v-model="widgetBoxEditContent.widgetcontent.value.postcode.value"></b-form-input>
									                </b-form-group>
									              </b-card>
									              <div class="bg-light p-3 d-flex">
									                	<i class="bx bx-chevron-right my-auto text-primary mr-2"></i> <h5  class="mb-0"> Map Elements</h5>
									                </div>
									                <widgetConfigsTab :user="user" :company="company" :widgetbox="false" :configs="widgetBoxEditContent.widgetelements.elements" :content="widgetBoxEditContent.widgetelements.active_elements" />									            </div>
									            </b-tab>
									           
									                <stylesTab :user="user" :company="company" :styles="widgetBoxEditContent.widgetstyles" />
									                <visibilityTab @visibility-updated="updateVisibility" :user="user" :company="company" :visibility="widgetBoxEditContent.widgetvisibility" />
									          	</b-tabs>
									          </div>
									          <div v-if="widgetBoxEditContent.widgettype == 'search'">
									          	<b-tabs justified nav-class="nav-tabs-custom" content-class="text-muted">
                         		<b-tab >
                         			 <template v-slot:title>
									                  <span class="d-inline-block d-sm-none">
									                    <i class="bx bx-notepad"></i>
									                  </span>
									                  <span class="d-none d-sm-inline-block">Content</span>
									                </template>
									                <div class="widget-inner-scrollbox"  :style="{'max-height' : sidePanelMaxHeight+'px', 'height' : sidePanelMaxHeight+'px'}"> 
									                <div class="bg-light p-3 d-flex">
									                	<i class="bx bx-chevron-right my-auto text-primary mr-2"></i> <h5  class="mb-0">Filters</h5>
									                </div>
									                <widgetContentFilters :user="user"  :company="company" :filter="widgetBoxEditContent.widgetfilters.filter" :active_filters="widgetBoxEditContent.widgetfilters.active_filters" ></widgetContentFilters>
									                  
 																	<div class="bg-light p-3 d-flex">
									                	<i class="bx bx-chevron-right my-auto text-primary mr-2"></i> <h5  class="mb-0">Elements</h5>
									                </div>
									                <widgetConfigsTab :user="user" :company="company" :widgetbox="false" :configs="widgetBoxEditContent.widgetelements.elements" :content="widgetBoxEditContent.widgetelements.active_elements" />
									              	</div>
									                
									              </b-tab>
									              <b-tab >
                         			 <template v-slot:title>
									                  <span class="d-inline-block d-sm-none">
									                    <i class="bx bx-notepad"></i>
									                  </span>
									                  <span class="d-none d-sm-inline-block">Configs</span>
									                </template>
									                <widgetConfigsTab :user="user" :company="company" :widgetbox="true" :configs="widgetBoxEditContent.widgetconfigs" :content="widgetBoxEditContent.widgetcontent" />
									              </b-tab>
									               <stylesTab :user="user" :company="company" :styles="widgetBoxEditContent.widgetstyles" />
									               <visibilityTab @visibility-updated="updateVisibility" :user="user" :company="company" :visibility="widgetBoxEditContent.widgetvisibility" />
									            </b-tabs>

									          </div>
									          <div v-if="widgetBoxEditContent.widgettype == 'listings'">
									          	<b-tabs  justified nav-class="nav-tabs-custom" content-class="p-0 text-muted">
									          		<b-tab >
                         			 <template v-slot:title>
									                  <span class="d-inline-block d-sm-none">
									                    <i class="bx bx-notepad"></i>
									                  </span>
									                  <span class="d-none d-sm-inline-block">Content</span>
									                </template>
									                <div class="widget-inner-scrollbox"  :style="{'max-height' : sidePanelMaxHeight+'px', 'height' : sidePanelMaxHeight+'px'}"> 
									                <div class="bg-light p-3 d-flex">
									                	<i class="bx bx-chevron-right my-auto text-primary mr-2"></i> <h5  class="mb-0">Filters</h5>
									                </div>
									                <widgetContentFilters :user="user"  :company="company" :filter="widgetBoxEditContent.widgetfilters.filter" :active_filters="widgetBoxEditContent.widgetfilters.active_filters" ></widgetContentFilters>
									                  
 																	<div class="bg-light p-3 d-flex">
									                	<i class="bx bx-chevron-right my-auto text-primary mr-2"></i> <h5  class="mb-0">Elements</h5>
									                </div>
									                <widgetConfigsTab :user="user" :company="company" :widgetbox="false" :configs="widgetBoxEditContent.widgetelements.elements" :content="widgetBoxEditContent.widgetelements.active_elements" />
									              	</div>
									           </b-tab>
									           <b-tab >
                         			 <template v-slot:title>
									                  <span class="d-inline-block d-sm-none">
									                    <i class="bx bx-notepad"></i>
									                  </span>
									                  <span class="d-none d-sm-inline-block">Configs</span>
									                </template>
									                <widgetConfigsTab :user="user" :company="company" :widgetbox="true" :configs="widgetBoxEditContent.widgetconfigs" :content="widgetBoxEditContent.widgetcontent" />
									              </b-tab>
									               <stylesTab :user="user" :company="company" :styles="widgetBoxEditContent.widgetstyles" />
									               <visibilityTab @visibility-updated="updateVisibility" :user="user" :company="company" :visibility="widgetBoxEditContent.widgetvisibility" />
									         </b-tabs>
									       </div>
									       <div v-if="widgetBoxEditContent.widgettype == 'listingsmap'">
									          	<b-tabs  justified nav-class="nav-tabs-custom" content-class="p-0 text-muted">
									          		<b-tab >
                         			 <template v-slot:title>
									                  <span class="d-inline-block d-sm-none">
									                    <i class="bx bx-notepad"></i>
									                  </span>
									                  <span class="d-none d-sm-inline-block">Content</span>
									                </template>
									                <div class="widget-inner-scrollbox"  :style="{'max-height' : sidePanelMaxHeight+'px', 'height' : sidePanelMaxHeight+'px'}"> 
									                <div class="bg-light p-3 d-flex">
									                	<i class="bx bx-chevron-right my-auto text-primary mr-2"></i> <h5  class="mb-0">Filters</h5>
									                </div>
									                <widgetContentFilters :user="user"  :company="company" :filter="widgetBoxEditContent.widgetfilters.filter" :active_filters="widgetBoxEditContent.widgetfilters.active_filters" ></widgetContentFilters>
									                  
 																	<div class="bg-light p-3 d-flex">
									                	<i class="bx bx-chevron-right my-auto text-primary mr-2"></i> <h5  class="mb-0">Elements</h5>
									                </div>
									                <widgetConfigsTab :user="user" :company="company" :widgetbox="false" :configs="widgetBoxEditContent.widgetelements.elements" :content="widgetBoxEditContent.widgetelements.active_elements" />
									              	</div>
									           </b-tab>
									           <b-tab >
                         			 <template v-slot:title>
									                  <span class="d-inline-block d-sm-none">
									                    <i class="bx bx-notepad"></i>
									                  </span>
									                  <span class="d-none d-sm-inline-block">Configs</span>
									                </template>
									                <widgetConfigsTab :user="user" :company="company" :widgetbox="true" :configs="widgetBoxEditContent.widgetconfigs" :content="widgetBoxEditContent.widgetcontent" />
									              </b-tab>
									               <stylesTab :user="user" :company="company" :styles="widgetBoxEditContent.widgetstyles" />
									               <visibilityTab @visibility-updated="updateVisibility" :user="user" :company="company" :visibility="widgetBoxEditContent.widgetvisibility" />
									         </b-tabs>
									       </div>
									          <div v-if="widgetBoxEditContent.widgettype == 'social'">
									          	<b-tabs justified nav-class="nav-tabs-custom" content-class="px-0 text-muted">
                         		<b-tab >
                         			 <template v-slot:title>
									                  <span class="d-inline-block d-sm-none">
									                    <i class="bx bx-notepad"></i>
									                  </span>
									                  <span class="d-none d-sm-inline-block">Content</span>
									                </template>

                         	<div class="widget-inner-scrollbox"  :style="{'max-height' : sidePanelMaxHeight+'px', 'height' : sidePanelMaxHeight+'px'}" v-if="widgetBoxEditContent.editable == 0">
                         		<div class="bg-light p-3 d-flex">
									                	<i class="bx bx-chevron-right my-auto text-primary mr-2"></i> <h5  class="mb-0">Social Networks</h5>
									                </div>
                         		<!--<widgetContentList :list="widgetBoxEditContent.widgetcontent.list" :list_item="widgetBoxEditContent.widgetlistconfigs.list_item_values" :editable="widgetBoxEditContent.editable" :list_index="widgetBoxEditContent.widgetcontent.list_index" @edit-list-item="widgetBoxEditContent.widgetcontent.list_index = $event, widgetBoxEditContent.editable = 1, widgetBoxSubForm = true" ></widgetContentList>-->

                         		<widgetContentList :list="widgetBoxEditContent.widgetcontent[filterStylesKey(widgetBoxEditContent.widgetcontent, 'list')].list" :list_item="widgetBoxEditContent.widgetlistconfigs.list_item_values" :editable="widgetBoxEditContent.editable" :list_index="widgetBoxEditContent.widgetcontent[filterStylesKey(widgetBoxEditContent.widgetcontent, 'list_index')].list_index" @edit-list-item="widgetBoxEditContent.widgetcontent[filterStylesKey(widgetBoxEditContent.widgetcontent, 'list_index')].list_index = $event, widgetBoxEditContent.editable = 1, widgetBoxSubForm = true" ></widgetContentList>


                         	
                         	</div>
                         	<b-card  class="shadow-none widget-inner-scrollbox"  :style="{'max-height' : sidePanelMaxHeight+'px', 'height' : sidePanelMaxHeight+'px'}" body-class="p-0" v-else>
                         		<!--<widgetConfigsTab :user="user" :company="company" :widgetbox="false" :configs="widgetBoxEditContent.widgetlistconfigs.list_item" :content="widgetBoxEditContent.widgetcontent.list[widgetBoxEditContent.widgetcontent.list_index]" />-->
                         		<widgetConfigsTab :user="user" :company="company" :widgetbox="false" :configs="widgetBoxEditContent.widgetlistconfigs.list_item" :content="widgetBoxEditContent.widgetcontent[filterStylesKey(widgetBoxEditContent.widgetcontent, 'list')].list[widgetBoxEditContent.widgetcontent[filterStylesKey(widgetBoxEditContent.widgetcontent, 'list_index')].list_index]" />
                         	</b-card>
                        
                         	</b-tab>
                         	<b-tab >
                         			 <template v-slot:title>
									                  <span class="d-inline-block d-sm-none">
									                    <i class="bx bx-notepad"></i>
									                  </span>
									                  <span class="d-none d-sm-inline-block">Configs</span>
									                </template>
									            <widgetConfigsTab :user="user" :company="company" :widgetbox="true" :configs="widgetBoxEditContent.widgetconfigs" :content="widgetBoxEditContent.widgetcontent" />
									         </b-tab>
									         <stylesTab :user="user" :company="company" :styles="widgetBoxEditContent.widgetstyles" />
									         <visibilityTab @visibility-updated="updateVisibility" :user="user" :company="company" :visibility="widgetBoxEditContent.widgetvisibility" />
                         </b-tabs>
                         </div>
                         <div v-if="templateArea(widgetBoxEditContent.widgettype)">
                         	<p class="px-4 py-2">{{widgetBoxEditContent.widgetdescription}}</p>
                         	<div v-if="widgetBoxEditContent.widgettype == 'category-description'">
                          		<b-tabs justified nav-class="nav-tabs-custom" content-class="p-0 text-muted">
									              <b-tab>
                         			 <template v-slot:title>
									                  <span class="d-inline-block d-sm-none">
									                    <i class="bx bx-notepad"></i>
									                  </span>
									                  <span class="d-none d-sm-inline-block">Configs</span>
									                </template>
									                
									                 <widgetConfigsTab :user="user" :company="company" :widgetbox="true" :configs="widgetBoxEditContent.widgetconfigs" :content="widgetBoxEditContent.widgetcontent" />
									                
									              </b-tab>
									               <stylesTab :user="user" :company="company" :styles="widgetBoxEditContent.widgetstyles" />
									               <visibilityTab @visibility-updated="updateVisibility" :user="user" :company="company" :visibility="widgetBoxEditContent.widgetvisibility" />
									            </b-tabs>
									          </div>
									          <div v-if="(widgetBoxEditContent.widgettype == 'product-desc') && widgetBoxEditContent.widgettype == 'product-short-desc'">
                          		<b-tabs justified nav-class="nav-tabs-custom" content-class="p-0 text-muted">
									              <b-tab>
                         			 <template v-slot:title>
									                  <span class="d-inline-block d-sm-none">
									                    <i class="bx bx-notepad"></i>
									                  </span>
									                  <span class="d-none d-sm-inline-block">Configs</span>
									                </template>
									                
									                 <widgetConfigsTab :user="user" :company="company" :widgetbox="true" :configs="widgetBoxEditContent.widgetconfigs" :content="widgetBoxEditContent.widgetcontent" />
									                
									              </b-tab>
									               <stylesTab :user="user" :company="company" :styles="widgetBoxEditContent.widgetstyles" />
									               <visibilityTab @visibility-updated="updateVisibility" :user="user" :company="company" :visibility="widgetBoxEditContent.widgetvisibility" />
									            </b-tabs>
									          </div>
									          <div v-if="widgetBoxEditContent.widgettype == 'product-cart-button'">
                        	<b-tabs justified nav-class="nav-tabs-custom" content-class="text-muted">
                        <b-tab >
                         			 <template v-slot:title>
									                  <span class="d-inline-block d-sm-none">
									                    <i class="bx bx-notepad"></i>
									                  </span>
									                  <span class="d-none d-sm-inline-block">Configs</span>
									                </template>
									                <widgetConfigsTab :user="user" :company="company" :widgetbox="true" :configs="widgetBoxEditContent.widgetconfigs" :content="widgetBoxEditContent.widgetcontent" />
									        </b-tab>
									        <stylesTab :user="user" :company="company" :styles="widgetBoxEditContent.widgetstyles"  />
									        <visibilityTab @visibility-updated="updateVisibility" :user="user" :company="company" :visibility="widgetBoxEditContent.widgetvisibility" />
                      </b-tabs>

                         </div>
					<div v-if="widgetBoxEditContent.widgettype == 'category-image'">
                         	<b-tabs justified nav-class="nav-tabs-custom" content-class="p-0 text-muted">
                         		
                        <b-tab>
							<template v-slot:title>
								<span class="d-inline-block d-sm-none">
									<i class="bx bx-notepad"></i>
								</span>
								<span class="d-none d-sm-inline-block">Configs</span>
							</template>
                        <widgetConfigsTab :user="user" :company="company" :widgetbox="true" :configs="widgetBoxEditContent.widgetconfigs" :content="widgetBoxEditContent.widgetcontent" />
                      	</b-tab>
                        <stylesTab :user="user" :company="company" :styles="widgetBoxEditContent.widgetstyles" />
                        <visibilityTab @visibility-updated="updateVisibility" :user="user" :company="company" :visibility="widgetBoxEditContent.widgetvisibility" />
                          
                          </b-tabs>                       
                         </div>
									          <div v-if="widgetBoxEditContent.widgettype == 'category-title'">
                          		<b-tabs justified nav-class="nav-tabs-custom" content-class="p-0 text-muted">
									              <b-tab >
                         			 <template v-slot:title>
									                  <span class="d-inline-block d-sm-none">
									                    <i class="bx bx-notepad"></i>
									                  </span>
									                  <span class="d-none d-sm-inline-block">Configs</span>
									                </template>
									                
									                 <widgetConfigsTab :user="user" :company="company" :widgetbox="true" :configs="widgetBoxEditContent.widgetconfigs" :content="widgetBoxEditContent.widgetcontent" />
									                
									              </b-tab>
									               <stylesTab :user="user" :company="company" :styles="widgetBoxEditContent.widgetstyles" />
									               <visibilityTab @visibility-updated="updateVisibility" :user="user" :company="company" :visibility="widgetBoxEditContent.widgetvisibility" />
									            </b-tabs>
									          </div>
									          <div v-if="widgetBoxEditContent.widgettype == 'product-title-block'">
                          		<b-tabs justified nav-class="nav-tabs-custom" content-class="p-0 text-muted">
									              <b-tab >
                         			 <template v-slot:title>
									                  <span class="d-inline-block d-sm-none">
									                    <i class="bx bx-notepad"></i>
									                  </span>
									                  <span class="d-none d-sm-inline-block">Configs</span>
									                </template>
									                
									                 <widgetConfigsTab :user="user" :company="company" :widgetbox="true" :configs="widgetBoxEditContent.widgetconfigs" :content="widgetBoxEditContent.widgetcontent" />
									                
									              </b-tab>
									               <stylesTab :user="user" :company="company" :styles="widgetBoxEditContent.widgetstyles" />
									               <visibilityTab @visibility-updated="updateVisibility" :user="user" :company="company" :visibility="widgetBoxEditContent.widgetvisibility" />
									            </b-tabs>
									          </div>
									          <div v-if="widgetBoxEditContent.widgettype == 'product-reference'">
                          		<b-tabs justified nav-class="nav-tabs-custom" content-class="p-0 text-muted">
									              <b-tab >
                         			 <template v-slot:title>
									                  <span class="d-inline-block d-sm-none">
									                    <i class="bx bx-notepad"></i>
									                  </span>
									                  <span class="d-none d-sm-inline-block">Configs</span>
									                </template>
									                
									                 <widgetConfigsTab :user="user" :company="company" :widgetbox="true" :configs="widgetBoxEditContent.widgetconfigs" :content="widgetBoxEditContent.widgetcontent" />
									                
									              </b-tab>
									               <stylesTab :user="user" :company="company" :styles="widgetBoxEditContent.widgetstyles" />
									               <visibilityTab @visibility-updated="updateVisibility" :user="user" :company="company" :visibility="widgetBoxEditContent.widgetvisibility" />
									            </b-tabs>
									          </div>
                         	<div v-if="widgetBoxEditContent.widgettype == 'category-filters'">
                          		<b-tabs justified nav-class="nav-tabs-custom" content-class="p-0 text-muted">
                         		<b-tab >
                         			 <template v-slot:title>
									                  <span class="d-inline-block d-sm-none">
									                    <i class="bx bx-notepad"></i>
									                  </span>
									                  <span class="d-none d-sm-inline-block">Content</span>
									                </template>
									                <div class="widget-inner-scrollbox"  :style="{'max-height' : sidePanelMaxHeight+'px', 'height' : sidePanelMaxHeight+'px'}">
									                	<div class="bg-light p-3 d-flex">
									                	<i class="bx bx-chevron-right my-auto text-primary mr-2"></i> <h5  class="mb-0">Filters</h5>
									                </div>
									                <widgetContentFilters :widgetbox="false" :user="user"  :company="company" :filter="widgetBoxEditContent.widgetfilters.filter" :active_filters="widgetBoxEditContent.widgetfilters.active_filters" ></widgetContentFilters>
									                
									                
									               </div>
									              </b-tab>
									              <b-tab >
                         			 <template v-slot:title>
									                  <span class="d-inline-block d-sm-none">
									                    <i class="bx bx-notepad"></i>
									                  </span>
									                  <span class="d-none d-sm-inline-block">Configs</span>
									                </template>
									                
									                 <widgetConfigsTab :user="user" :company="company" :widgetbox="true" :configs="widgetBoxEditContent.widgetconfigs" :content="widgetBoxEditContent.widgetcontent" />
									                
									              </b-tab>
									               <stylesTab :user="user" :company="company" :styles="widgetBoxEditContent.widgetstyles" />
									               <visibilityTab @visibility-updated="updateVisibility" :user="user" :company="company" :visibility="widgetBoxEditContent.widgetvisibility" />
									            </b-tabs>
									          </div>
                         	<div v-if="widgetBoxEditContent.widgettype == 'category-grid'">
                          		<b-tabs justified nav-class="nav-tabs-custom" content-class="p-0 text-muted">
                         		<b-tab >
                         			 <template v-slot:title>
									                  <span class="d-inline-block d-sm-none">
									                    <i class="bx bx-notepad"></i>
									                  </span>
									                  <span class="d-none d-sm-inline-block">Content</span>
									                </template>
									                <div class="widget-inner-scrollbox"  :style="{'max-height' : sidePanelMaxHeight+'px', 'height' : sidePanelMaxHeight+'px'}">
									                	<div class="bg-light p-3 d-flex">
									                	<i class="bx bx-chevron-right my-auto text-primary mr-2"></i> <h5  class="mb-0">Filters</h5>
									                </div>
									                <widgetContentFilters :widgetbox="false" :user="user"  :company="company" :filter="widgetBoxEditContent.widgetfilters.filter" :active_filters="widgetBoxEditContent.widgetfilters.active_filters" ></widgetContentFilters>
									                 <div class="bg-light p-3 d-flex" v-if="widgetBoxEditContent.widgetcontent[filterStylesKey(widgetBoxEditContent.widgetcontent, 'view')].view.value !== 'carousel'">
									                	<i class="bx bx-chevron-right  my-auto text-primary mr-2"></i> <h5  class="mb-0">Elements</h5>
									                </div>
									                <widgetConfigsTab  v-if="widgetBoxEditContent.widgetcontent[filterStylesKey(widgetBoxEditContent.widgetcontent, 'view')].view.value !== 'carousel'" :user="user" :company="company" :widgetbox="false" :configs="widgetBoxEditContent.widgetelements.elements" :content="widgetBoxEditContent.widgetelements.active_elements" />
									                
									               </div>
									              </b-tab>
									              <b-tab >
                         			 <template v-slot:title>
									                  <span class="d-inline-block d-sm-none">
									                    <i class="bx bx-notepad"></i>
									                  </span>
									                  <span class="d-none d-sm-inline-block">Configs</span>
									                </template>
									                
									                 <widgetConfigsTab :user="user" :company="company" :widgetbox="true" :configs="widgetBoxEditContent.widgetconfigs" :content="widgetBoxEditContent.widgetcontent" />
									                
									              </b-tab>
									               <stylesTab :user="user" :company="company" :styles="widgetBoxEditContent.widgetstyles" />
									               <visibilityTab @visibility-updated="updateVisibility" :user="user" :company="company" :visibility="widgetBoxEditContent.widgetvisibility" />
									            </b-tabs>
									          </div>
                         	 <div v-if="widgetBoxEditContent.widgettype == 'subcategory-grid'">
                         	<div v-if="widgetBoxEditContent.editable == 0">
                         		<b-tabs justified nav-class="nav-tabs-custom" content-class="text-muted">
                         		<b-tab >
                         			 <template v-slot:title>
									                  <span class="d-inline-block d-sm-none">
									                    <i class="bx bx-notepad"></i>
									                  </span>
									                  <span class="d-none d-sm-inline-block">Configs</span>
									                </template>
									                <widgetConfigsTab :user="user" :company="company" :widgetbox="true" :configs="widgetBoxEditContent.widgetconfigs" :content="widgetBoxEditContent.widgetcontent" />
									        </b-tab>
                         		 <stylesTab :user="user" :company="company" :styles="widgetBoxEditContent.widgetstyles" />
                         		 <visibilityTab @visibility-updated="updateVisibility" :user="user" :company="company" :visibility="widgetBoxEditContent.widgetvisibility" />
                         		</b-tabs>
                         	</div>
                         		<b-card class="border-none shadow-none widget-inner-scrollbox"  :style="{'max-height' : sidePanelMaxHeight+'px', 'height' : sidePanelMaxHeight+'px'}" body-class="p-0" v-else>
                         			<widgetConfigsTab :user="user" :company="company" :widgetbox="false" :configs="widgetBoxEditContent.widgetlistconfigs.list_item" :content="widgetBoxEditContent.widgetcontent[filterStylesKey(widgetBoxEditContent.widgetcontent, 'list')].list[widgetBoxEditContent.widgetcontent[filterStylesKey(widgetBoxEditContent.widgetcontent, 'list_index')].list_index]" />
                         			
                         		</b-card>
                          </div>
                         </div>
                          <div class="widget-box-inner-actions bg-white pt-3 px-3 pb-0 d-flex border-top" v-if="(widgetBoxSubForm == false) && (widgetBoxEditContent.editable == 0)">
                          	<a class="btn btn-danger btn-block btn-rounded my-2 mr-1" v-if="widgetEditBlockIndex == null" @click=" widgetBoxEditContent = [], widgetBoxEditMode = false"> Cancel </a>
                         <a class="btn btn-success btn-block btn-rounded my-2 ml-1" v-if="widgetEditBlockIndex == null" @click="addNewWidget(widgetBoxEditContent, sindex, rindex, cindex), widgetBoxEditMode = false, widgetBoxEditContent = []"> Add</a>
                          <a v-else class="btn btn-success btn-block btn-rounded my-2 ml-1" @click="updateEvent()">Update</a>
                    			</div>

                    			 <div class="widget-box-inner-actions bg-white pt-3 px-3 pb-0 d-flex border-top" v-else>
                    					<a class="btn btn-success btn-rounded btn-block" @click="widgetBoxEditContent.editable = 0, widgetBoxSubForm = false">Save</a>
                    			</div>
                    </div>

                </div>
                      </div>
</template>

<script type="text/javascript">
	import { EventBus } from '../../../app'
import VueSlideBar from 'vue-slide-bar';
import VueSlider from 'vue-slider-component';
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import SelectCompanyProductCategories from "../../../components/widgets/SelectCompanyProductCategories"
import SelectCompanyProductCollections from "../../../components/widgets/SelectCompanyProductCollections"
import SelectValuationSignature from '../../../components/widgets/SelectValuationSignature';
import SelectValuationStamp from '../../../components/widgets/SelectValuationStamp';
import SelectValuationNote from '../../../components/widgets/SelectValuationNote';
import ModalWindow from "../../../components/widgets/ModalWindow";
import StorageWindow from "../../../components/widgets/file-manager/StorageWindow";
import CompanyCustomerSearch from "../../../components/widgets/CompanyCustomerSearch";
import SearchCompanyCustomerAddress from "../../../components/widgets/SearchCompanyCustomerAddress";
import widgetConfigsTab from "./widgets/widgetConfigsTab";
import widgetContentFilters from "./widgets/widgetContentFilters";
import widgetContentList from "./widgets/widgetContentList";
import widgetViewportSwitcher from "./widgetViewportSwitcher";
import stylesTab from "./widgets/stylesTab";
import visibilityTab from "./widgets/visibilityTab";
import simplebar from "simplebar-vue";
	export default {
		props: {
			widgetEditBlockIndex: {
				type: Number,
			},
			widgetBoxEditContent: {
				type: Array,
			},
			widgetBox: {
				type: String,
			},
			sindex: {
				type: Number,
			},
			rindex: {
				type: Number,
			},
			cindex: {
				type: Number,
			},
			widgetBoxLoading: {
				type: Boolean,
			},
			widgetBoxEditMode: {
				type: Boolean,
			},
			widgetBoxSubForm: {
				type: Boolean,
			},
			fileResource: {
				type: String,
			},
			fileRowIndex: {
				type: String,
			},
			fileColIndex: {
				type: String,
			},
			showFileManager: {
				type: Boolean,
			},
			user: {
				type: Object,
			},
			company: {
				type: Object,
			},
			data: {
				type: Object,
			},
			selectedFiles: {
				type: Array,
			},
			widgets: {
				type: Array,
			},
			template_areas: {
				type: Array,
			},
			sidePanelMaxHeight: {
				type: Number,
			},
			widgetStyles: {
				type: Array,
			},
		},
		components: {
			SelectCompanyProductCategories,
			SelectCompanyProductCollections,
			SelectValuationSignature,
			SelectValuationStamp,
			SelectValuationNote,
			ModalWindow,
			StorageWindow,
			CompanyCustomerSearch,
			SearchCompanyCustomerAddress,
			simplebar,
			widgetConfigsTab,
			widgetContentFilters,
			widgetContentList,
			stylesTab,
			visibilityTab,
			ckeditor: CKEditor.component,
			VueSlideBar,
			VueSlider,
			widgetViewportSwitcher
		},
		data() {
			return{
			storageurl: 'https://'+this.company.nickname+'.s3.eu-west-2.amazonaws.com/',
			widgetSearch: '',
			menus: [],
			countries: [],
			googleSearchAddress: '',
			counties: [],
			countries: [],
			listing_types: [],
			menus: [],
			editor: ClassicEditor,
			hoverElement: '',
			}
		},
		watch: {
			computedWidget: {
		      handler(newVal, oldVal) {
		        //console.log('Styles changed:', newVal);
		        EventBus.$emit('widget-auto-update');
		        // Perform any desired actions when styles change
		      },
		      deep: true
		    }
		},
		computed: {
			computedWidget() {
				return this.widgetBoxEditContent;
			},
			updateVisibility(event){
				this.$emit('update-widget-visibility', event);
			},
			filteredWidgets(){
	    	if(this.widgetSearch !== ''){
   				return this.widgets.filter(item => (item.widgetname).toLowerCase().includes(this.widgetSearch.toLowerCase()));
   			}else{
   				return this.widgets;
   			}
   		},
   		filteredTemplateWidgets(){
	    	if(this.widgetSearch !== ''){
   				return this.template_areas.filter(item => (item.widgetname).toLowerCase().includes(this.widgetSearch.toLowerCase()));
   			}else{
   				return this.template_areas;
   			}
   		},
		},
		created(){
			this.getCountries();
			//this.getListingTypes();
			this.getMenus(1);
			this.includeGoogle('maps.googleapis.com/maps/api/js?key=AIzaSyCO-2c9bJAX3z7cP3WN2O6x7ndipzOvZsU&libraries=places');
		},
		methods: {
			templateArea(widget_name){
				var index = this.template_areas.findIndex(item => item.widgettype == widget_name);
				if(index > -1){
					return true;
				}else{
					return false;
				}
			},
			/*compiledStyles(styles){
	        	var style = 'will-change: auto; ';
	        	for(var i = 0; i < (styles.length - 1); i++ ){
	        		for(var key in styles[i] ){
	        			
	        		if(styles[i][key].active == true){
		        		if(key.includes('_label') == false){

		        			if(key == 'background'){
		        				//var stylename = key.replace('_', '-');
		        				if(styles[i][key].value[0] == 'background-image'){
		        					style += ''+styles[i][key].value[0]+': url('+styles[i][key].value[1]+'); '
		        				}else{
		        					style += ''+styles[i][key].value[0]+': '+styles[i][key].value[1]+'; '
		        				}
		        				style += 'background-size: '+styles[i][key].size+'; '
		        				style += 'background-position: '+styles[i][key].position+'; '
		        				style += 'background-repeat: '+styles[i][key].repeat+'; '
		        			}
		        			else if(styles[i][key].type == 'numbergroup'){
		        				//var stylename = key.replace('_', '-');
		        				for(var numgroupkey in styles[i][key]){
		        					if((numgroupkey !== 'parentlabel') || (numgroupkey !== 'type')){
		        						if((key.includes('_radius') == false) && (key.includes('border') == true)){

		        							style += ''+key+'-'+numgroupkey.replace('_','-')+': '+styles[i][key][numgroupkey]+'px '+styles[this.filterStylesKey(styles, 'border_style')]["border_style"].value+' '+styles[this.filterStylesKey(styles, 'border_colour')]["border_colour"].value+' !important; '

		        						}else	if(key.includes('_radius') == false){

		        							style += ''+key+'-'+numgroupkey.replace('_','-')+': '+styles[i][key][numgroupkey]+'px !important; '
		        						
		        						}else{
		        						
		        							style += ''+key.replace('_radius', '')+'-'+numgroupkey.replace('_','-')+'-radius: '+styles[i][key][numgroupkey]+'px !important; '
		        						
		        						}
		        					}
		        				}
		        			}
		        			else if(styles[i][key].type == 'range'){
		        					if('units' in styles[i][key]){
		        						//console.log(styles[i][key].value);
		        						if(styles[i][key].value !== null){
			        						var stylename = key.replace('_', '-');
					        				style += ''+stylename+': '+parseFloat(styles[i][key].value)+''+styles[i][key].units+'; '; 
				        					}
		        					 }else{
		        					 		style += ''+stylename+': '+styles[i][key].value+'; '
		        					 }

		        			}else{
		        				var stylename = key.replace('_', '-');
		        				stylename = stylename.replace('colour', 'color');
		        				if(styles[i][key].value.length !== 0){
		        					style += ''+stylename+': '+styles[i][key].value+' !important; '
		        				}else{
		        					style += ''+stylename+': '+styles[i][key].value+' !important; '
		        				}
		        			}
		        		}
	        		}
	        	}
	        }
	        	return style;
	        },*/
	        compiledStyles(styles){
	        	var style = 'will-change: auto; ';
	        	for(var i = 0; i < (styles.length - 1); i++ ){
	        		for(var key in styles[i] ){
	        			
	        		if(styles[i][key].active == true){
		        		if(key.includes('_label') == false){

		        			if(key == 'background'){
		        				//var stylename = key.replace('_', '-');
		        				if(styles[i][key].value[0] == 'background-image'){
		        					style += ''+styles[i][key].value[0]+': url('+styles[i][key].value[1]+'); '
		        				}else{
		        					style += ''+styles[i][key].value[0]+': '+styles[i][key].value[1]+'; '
		        				}
		        				style += 'background-size: '+styles[i][key].size+'; '
		        				style += 'background-position: '+styles[i][key].position+'; '
		        				style += 'background-repeat: '+styles[i][key].repeat+'; '
		        			}
		        			else if(styles[i][key].type == 'numbergroup'){
		        				//var stylename = key.replace('_', '-');
		        				for(var numgroupkey in styles[i][key]){
		        					if((numgroupkey !== 'parentlabel') || (numgroupkey !== 'type')){
		        						if((key.includes('_radius') == false) && (key.includes('border') == true)){

		        							style += ''+key+'-'+numgroupkey.replace('_','-')+': '+styles[i][key][numgroupkey]+'px '+styles[this.filterStylesKey(styles, 'border_style')]["border_style"].value+' '+styles[this.filterStylesKey(styles, 'border_colour')]["border_colour"].value+' !important; '

		        						}else	if(key.includes('_radius') == false){

		        							style += ''+key+'-'+numgroupkey.replace('_','-')+': '+styles[i][key][numgroupkey]+'px !important; '
		        						
		        						}else{
		        						
		        							style += ''+key.replace('_radius', '')+'-'+numgroupkey.replace('_','-')+'-radius: '+styles[i][key][numgroupkey]+'px !important; '
		        						
		        						}
		        					}
		        				}
		        			}
		        			else if(styles[i][key].type == 'range'){
		        					if('units' in styles[i][key]){
		        						//console.log(styles[i][key].value);
		        						if(styles[i][key].value !== null){
			        						var stylename = key.replace('_', '-');
					        				style += ''+stylename+': '+parseFloat(styles[i][key].value)+''+styles[i][key].units+'; '; 
				        					}
		        					 }else{
		        					 		style += ''+stylename+': '+parseFloat(styles[i][key].value)+'; '
		        					 }

		        			}else{
		        				var stylename = key.replace('_', '-');
		        				stylename = stylename.replace('colour', 'color');
		        				if(styles[i][key].value.length !== 0){
		        					style += ''+stylename+': '+styles[i][key].value+' !important; '
		        				}else{
		        					style += ''+stylename+': '+styles[i][key].value+' !important; '
		        				}
		        			}
		        		}
	        		}
	        	}
	        }
	        	return style;
	     },
			includeGoogle( URL, callback ){
                        let documentTag = document, tag = 'script',
                            object = documentTag.createElement(tag),
                            scriptTag = documentTag.getElementsByTagName(tag)[0];
                        object.src = '//' + URL;
                        if (callback) { object.addEventListener('load', function (e) { callback(null, e); }, false); }
                        scriptTag.parentNode.insertBefore(object, scriptTag);
       },
       filterStylesKey(styles, key){
       		for(var i = 0; i < styles.length; i++){
       			 var keys = Object.keys(styles[i])
       			 if(keys.includes(key)){
       			 	 return i;
       			 }
       		}
       },
       getMenus(page){
        axios.get('/api/company/web/menus?page='+page+'&limit=25&sortby=id&order=desc', {headers: { 'Authorization' : 'Bearer '+this.user.api_token}}).then(response => {
          this.menus = response.data;
        }).catch(error => {
          this.error_message = true;
        });
    	},
			getCountries(){
        if(this.countries.length == 0){
          axios.get('/api/countries', {headers: {
                  'Authorization': 'Bearer ' + this.user.api_token
              }}).then(response => {
            this.countries = response.data;
          }).catch(error => {
            this.load_error = error.data;
          })
        }
      },
			getCounties(country_id){
        // get counties as country selected
        axios.get('/api/country/'+country_id+'/counties', {headers: {
                  'Authorization': 'Bearer ' + this.user.api_token
              }}).then(response => {
          this.counties = response.data;
        }).catch(error => {
          this.load_error = error.data;
        })
      },
      getAddressData: function (addressData, placeResultData, id) {
        this.googleSearchAddress = addressData;
        const lat = addressData.geometry.location.lat();
        const lng = addressData.geometry.location.lng();
        this.widgetBoxEditContent.widgetcontent.value.latitude.value = lat;
        this.widgetBoxEditContent.widgetcontent.value.longitude.value = lng;
        this.widgetBoxEditContent.widgetcontent.value.gplace_id.value = this.googleSearchAddress.place_id;
        /**if(addressData.name){
         this.widgetBoxEditContent.widgetcontent.value.address1.value = placeResultData.name;
         if(addressData.street_number){
              this.widgetBoxEditContent.widgetcontent.value.address2.value = addressData.street_number + ' ' +addressData.route; 
          }else{
              this.widgetBoxEditContent.widgetcontent.value.address2.value = addressData.route; 
          }
        }else{
         if(addressData.street_number){
              this.widgetBoxEditContent.widgetcontent.value.address1.value = addressData.street_number + ' ' +addressData.route; 
          }else{
              this.widgetBoxEditContent.widgetcontent.value.address1.value = addressData.route; 
          }
        }
        if(addressData.locality){
          this.widgetBoxEditContent.widgetcontent.value.address3.value = addressData.locality;
        }
        this.widgetBoxEditContent.widgetcontent.value.postcode.value = addressData.postal_code;

        var country = this.countries.filter(item => item.name == addressData.country);
        this.widgetBoxEditContent.widgetcontent.value.country_id.value = country[0].id;
        this.selected_country_id = country[0].id;
        **/
        var country = [];
        for(var i = 0; i < this.googleSearchAddress.address_components.length; i++){
        		var fieldname = '';
		         if((this.googleSearchAddress.address_components[i].types[0] == 'street_number') || (this.googleSearchAddress.address_components[i].types[0] == 'route') ){
		              fieldname = "address1";
		          }
		          if(this.googleSearchAddress.address_components[i].types[0] == 'postal_town'){
		          	 fieldname = 'address2';
		          }
		          if(this.googleSearchAddress.address_components[i].types[0] == 'postal_code'){
		          	 fieldname = 'postcode';
		          }
		          if(fieldname !== ''){
			          if(this.googleSearchAddress.address_components[i].types[0] !== 'route'){
			         		this.widgetBoxEditContent.widgetcontent.value[fieldname]["value"] = this.googleSearchAddress.address_components[i].long_name; 
			         	}else{
			         		if(this.widgetBoxEditContent.widgetcontent.value[fieldname]["value"] == ''){
			         		this.widgetBoxEditContent.widgetcontent.value[fieldname]["value"] = this.googleSearchAddress.address_components[i].long_name;
			         		}else{
			         			this.widgetBoxEditContent.widgetcontent.value[fieldname]["value"] += ' '+this.googleSearchAddress.address_components[i].long_name;
			         		}
			         	}
		         	}
		         	
		         	if(this.googleSearchAddress.address_components[i].types[0] == 'country'){
		        		country = this.countries.filter(item => item.name == this.googleSearchAddress.address_components[i].long_name);
		        		this.widgetBoxEditContent.widgetcontent.value.country_id.value = country[0].id;
		        		this.selected_country_id = country[0].id;
		        	}

		       
		       
        }
         	if(country.length > 0){
            //this.getCounties(country[0].id);
		            var self = this;
		            axios.all([getCounties(country[0].id, self.user.api_token, self.googleSearchAddress.address_components[filterAddressTypes(self.googleSearchAddress.address_components, 'administrative_area_level_2')].long_name)]).then(axios.spread(function (response) {
		              //companyList[ia].push(response.data)
		              console.log(response);
		              self.counties = response[0].data;
		              var county = response[0].data.filter(item => item.name == response[1]);
		              self.widgetBoxEditContent.widgetcontent.value.county_id = county[0].id;
		              self.selected_county_id = county[0].id;
		            }));
		            
		          }
        
        //this.googleSearchAddress["place"] = placeResultData;
        
        /**for(var i = 0; i < placesData.address_components.length; i++){
          var county = placesData.address_components[i].types.filter(item == 'route');
          var county = placesData.address_components[i].types.filter(item == 'street_number');
          
          var town = placesData.address_components[i].types.filter(item == 'locality');
          var city = placesData.address_components[i].types.filter(item == 'postal_town');
          var country = placesData.address_components[i].types.filter(item == 'country');

          this.newListing.address3 = town[0].long_name;

        
        }**/
        async function getCounties(country_id, api_token, county_name){
               return [await axios.get('/api/country/'+country_id+'/counties?api_token='+api_token), county_name];
              }

         async function filterAddressTypes(components, type_name){
					return this.components.findIndex(item => item.types[0] == type_name);
				}

      },
      async getListingTypes(){
	      const response = await fetch('/api/company/listing/types', {headers: {
                  'Authorization': 'Bearer ' + this.user.api_token
              }});
	      return await response.json();
    	},
    	async getCategories(page, limit, sort, order, search, parent){
	      const response = await fetch('/api/company/product/categories?page='+page+'&limit='+limit+'&sortby='+sortby+'&order='+order+'&search='+search+'&parent_id='+parent+'&marketplace_categories='+this.marketplace_categories, {headers: {
                  'Authorization': 'Bearer ' + this.user.api_token
              }});
	      return await response.json();
    	},
    	async getCollections(page, limit, sort, order, search, parent){
	      const response = await fetch('/api/company/product/collections?page='+page+'&limit='+limit+'&sortby='+sortby+'&order='+order+'&search='+search+'&parent_id='+parent+'&active=&public_active=&trade_active=', {headers: {
                  'Authorization': 'Bearer ' + this.user.api_token
              }});
	      return await response.json();
    	},
			limitReached(element, limit){
				Swal.fire({
		        position: "top-end",
		        icon: "error",
		        title: "You have reached the "+element+" limit for this section",
		        showConfirmButton: false,
		        timer: 1500
		      });
			},
			filterAddressTypes(components, type_name){
				return this.components.findIndex(item => item.types[0] == 'administrative_area_level_2');
			},
		    selectValue(payload){
		      this.widgetBoxEditContent.widgetcontent = payload;
		    },
		    selectCustomer(payload){
		      this.widgetBoxEditContent.widgetcontent.selectedcustomer = payload;
		    },
		    selectAddress(payload){
		      this.widgetBoxEditContent.widgetcontent.selectedaddress = payload;
		    },
		    enforceFullwidthImage(){
		      this.widgetBoxEditContent.widgetcontent.width = '100%';
		      this.widgetBoxEditContent.widgetcontent.height = 'auto';
		      this.widgetBoxEditContent.widgetcontent.fullwidth = true;
		    },
		    fileSelected(payload){
		    		if(this.widgetBoxEditContent.widgettype == 'image'){
		      	this.widgetBoxEditContent.widgetcontent[this.filterStylesKey(this.widgetBoxEditContent.widgetcontent, 'url')].url.value = ''+this.storageurl+''+payload.file+'';
		      	this.showFileManager = false;
		    	}else if(this.widgetBoxEditContent.widgettype == 'imagegallery'){
		    		var imageObject = {src: ''+this.storageurl+''+payload.file+'', caption_text: ''};

		    		this.widgetBoxEditContent.widgetcontent[this.filterStylesKey(this.widgetBoxEditContent.widgetcontent, 'list')].list.push(imageObject);
		    		this.selectedFiles = JSON.parse(JSON.stringify(this.widgetBoxEditContent.widgetcontent[this.filterStylesKey(this.widgetBoxEditContent.widgetcontent, 'list')].list));
		    	}else if(this.widgetBoxEditContent.widgettype == 'slider'){
		    		this.widgetBoxEditContent.widgetcontent[this.filterStylesKey(this.widgetBoxEditContent.widgetcontent, 'list')].list[this.widgetBoxEditContent.widgetcontent.list_index].imageUrl = ''+this.storageurl+''+payload.file+'';
		    		this.showFileManager = false;
		    	}else if(this.widgetBoxEditContent.widgettype == 'menu'){
		    		this.widgetBoxEditContent.widgetcontent[this.filterStylesKey(this.widgetBoxEditContent.widgetcontent, 'logo')].logo = ''+this.storageurl+''+payload.file+'';

		    		this.showFileManager = false;
		    	}
		    },
		    addNewWidget(widget, sindex, rindex, cindex){
		    	this.loadingWidgetSettings = true;
		      this.data.rows[rindex].cols[cindex].blocks.push(widget);
		      this.selectedFiles = [];
		      	if(widget.widgettype == 'products'){
		          this.productQuery(rindex, cindex, (this.data.rows[rindex].cols[cindex].blocks.length - 1));
		        }
		        if(widget.widgettype == 'menu'){
		        	//this.$refs['WebsiteMenus'].getMenu(this.widgetBoxEditContent.widgetfilters.active_filters.menus.value);
		        }
		        EventBus.$emit('widget-added', widget, sindex, rindex, cindex);
		        let self = this;
		        setTimeout(function(){
				         		//self.getActiveShippingIntegrations();
				         		self.loadingWidgetSettings = false;
				         		self.historyStates.unshift(self.data);
								  }, 1000);
		    },
		    async newWidgetOptions(widget){
		    	this.widgetBoxEditContent = JSON.parse(JSON.stringify(widget));
		    	this.widgetBoxEditContent['widgetstyles'] = JSON.parse(JSON.stringify(this.widgetStyles));
		    	if(widget.widgettype == 'listings'){
		    			//const listing_types = await this.getListingTypes();
		        	this.widgetBoxEditContent.widgetfilters.filter.listing_type.options = await this.getListingTypes();
		      }
		      if(widget.widgettype == 'listingsmap'){
		    			//const listing_types = await this.getListingTypes();
		        	this.widgetBoxEditContent.widgetfilters.filter.listing_type.options = await this.getListingTypes();
		      }
		      if(widget.widgettype == 'menu'){
		      		this.widgetBoxEditContent.widgetfilters.filter.menus.options = this.menus.data;
		      		if(this.company.logo !== ''){
		      			this.widgetBoxEditContent.widgetcontent[this.filterStylesKey(this.widgetBoxEditContent.widgetcontent, 'logo')].logo.value = ''+this.storageurl+''+this.company.logo+'';
		      		}
		      }
		      this.widgetBoxEditMode = true;

		      let self = this;
				         setTimeout(function(){
				         		//self.getActiveShippingIntegrations();
				         		self.widgetBoxLoading = false;
								  }, 1000);
		    },
		    editWidget(rindex, cindex, bindex){
		    	this.loadingWidgetSettings = true;
		      this.widgetBoxEditContent = this.data.rows[rindex].cols[cindex].blocks[bindex];
		      this.widgetBoxEditMode = true;
		      this.widgetBox = ''+rindex+''+cindex+'';
		      this.widgetEditBlockIndex = bindex;
		      if(this.widgetBoxEditContent.widgettype == 'imagegallery'){
		    		this.selectedFiles = JSON.parse(JSON.stringify(this.widgetBoxEditContent.widgetcontent.list));
		    	}
		    	if(this.widgetBoxEditContent.widgettype == 'products'){
		          this.productQuery(rindex, cindex, (this.data.rows[rindex].cols[cindex].blocks.length - 1));
		        }
		    	if(this.widgetBoxEditContent.widgettype == 'menu'){
		        	//this.$refs['WebsiteMenus'].getMenu(this.widgetBoxEditContent.widgetfilters.active_filters.menus.value);
		        }
		        
		    	let self = this;
		        setTimeout(function(){
				         		//self.getActiveShippingIntegrations();
				         		self.loadingWidgetSettings = false;
								  }, 1000);
		    },
		    updateEditedWidget(){

		    },
		    updateEvent(){
		    	EventBus.$emit('widget-updated');
		    	EventBus.$emit('update-widget');
		    	this.widgetBoxEditMode = false;
		    	this.widgetBoxEditContent = [];
		    	this.widgetBox = '';
		    	this.widgetEditBlockIndex = null;
		    	//this.historyStates.unshift(this.data);
		    },
		    deleteWidget(rindex, cindex, bindex){
		      this.data.rows[rindex].cols[cindex].blocks.splice(bindex, 1);
		    },
		    deleteColumn(rindex, cindex){
		      this.data.rows[rindex].cols.splice(cindex, 1);
		    },
		    deleteRow(rindex){
		      this.data.rows.splice(rindex, 1);
		    },
		    duplicateRow(rindex, section){
					this.data.rows.splice(rindex, 0, JSON.parse(JSON.stringify(section)));
				},
				duplicateColumn(rindex, cindex, section){
					this.data.rows[rindex].cols.splice(cindex, 0, JSON.parse(JSON.stringify(section)));
				},
				duplicateWidget(rindex, cindex, bindex, section){
					this.data.rows[rindex].cols[cindex].blocks.splice(bindex, 0, JSON.parse(JSON.stringify(section)));
				},
				runFilter(list, filtertype){

						//return await response.json();
				},
			copyViewportEvent(to_viewport, from_viewport){
				console.log(to_viewport);
				if(to_viewport !== from_viewport){
				this.copyWidgetAcrossViewport(to_viewport, from_viewport, this.sindex, this.rindex, this.cindex, this.$attrs.bindex);
				}
			},
			copyWidgetAcrossViewport(to_viewport, from_viewport, sindex, rindex, cindex, bindex){
				EventBus.$emit('copy-widget-across-viewport', to_viewport, from_viewport, sindex, rindex, cindex, bindex);
		    },
		    productQuery(rindex, cindex, bindex){
      			axios.get('/api/company/web/products', {headers : {'Authorization' : 'Bearer '+this.user.api_token}}).then(response => {
      				this.data.rows[rindex].cols[cindex].blocks[bindex].widgetcontent.list = response.data;
      			}).catch(error => {

      			});
    		}, 
		}
	}
</script>